import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const MicroWrapper: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1600px;
  iframe {
    width: 100%;
    height: 100vh;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 13.5px 90px 0;
  }
`;
