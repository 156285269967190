import React, { FC, useContext } from "react";
import { Context, getPage } from "src/common";
import { useDealerSearchResult } from "src/common/hooks/useDealerSearch";
import { Container, Section } from "../../common/Grid";
import Header from "../../common/Header";
import Hero from "../../common/Hero";
 ;
import ContactContent from "../../content/ContactContent";
import { HeroWrapper } from "../../content/MainContent/MainContent.style";
import SearchContent from "../../content/SearchContent/SearchContent";

const PageSearchT25: FC = () => {
  return (
    <>
      <Header />
      <SearchContent />
       
    </>
  );
};
PageSearchT25.displayName = "PageSearchT25";
export { PageSearchT25 };
