import { CommonPageBrandOverviewMainContent } from "src/common/brand-area/PageBrandOverview/CommonPageBrandOverviewMainContent";

import React, { FunctionComponent } from "react";

import { Banner } from "../../Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import Spacer from "../../../../../common/BaseComponents/Spacer/Spacer";

const PageBrandOverviewT4: FunctionComponent = () => (
  <div id="PageBrandOverviewT400">
    <div id="PageBrandOverviewT4T41"></div>
    <div id="PageBrandOverviewT4T42">
      <MainNavigation />
    </div>
    <Spacer bronze={80} />
    <div id="PageBrandOverviewT4T43">
      <CommonPageBrandOverviewMainContent />
    </div>
    <div id="PageBrandOverviewT4T44">
      <Banner />
    </div>
    <div id="footer">
      <Footer />
    </div>
  </div>
);

PageBrandOverviewT4.displayName = "PageBrandOverviewT4";
export { PageBrandOverviewT4 };
