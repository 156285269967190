import { css } from "styled-components";

import colors from "../../../constants/colors";


export const richText: any = css`
    margin-bottom: 10px;
    br {
        margin-top: 10px;
    }
    h3,
    h4 {
        color: ${colors.blue};
        font-size: 20px;
        line-height: 120% !important;
    }
    ul {
        margin-left: calc(1em + 2px);
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: disc;
        }
    }
    ol {
        margin-left: calc(2rem + 2px);
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: decimal;
        }
    }
`;

export const box: any = css`
    border: solid ${colors.lightBlue} 2px;
    h2 {
      font-size: 24px;
      color: #008bc5;
    }
`;
