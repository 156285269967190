import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { Context, IContext } from "src/common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import RichText from "../../../BaseComponents/RichText/RichText";
import UniversalComponentRenderer from "../../../BaseComponents/UniversalCompoentRenderer/UniversalComponentRenderer";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import { bigHeading, smallHeading } from "../../../constants/textStyles";
import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { ContactForm } from "./contactForm/ContactForm";
import * as styles from "./pageContact.style";
import { getOpeningHoursContent } from "../../../../../common/utils/getOpeningHoursContent";

interface ILocation 
{
  lat: number;
  lng: number;
}

const PageContactT6: FunctionComponent = () => 
{
  const props = { ...useContext<IContext>(Context), ...useContext<IContext>(Context) };
  const openingHoursContent = getOpeningHoursContent(false);

  const companyInformationPublic = props.CompanyInformationPublic;
  const contactPage = props?.PageContact;
  const universalTexts = props?.UniversalTexts;

  const elementsWithoutMainAsset = props.PageContact?.elements?.filter(element => element?.__typename !== "Asset");

  const [inputUserLocation, setInputUserLocation] = useState("");
  const [companyLocation, setCompanyLocation] = useState<ILocation>(null);

  const mapElementId = "map";
  const routePanelId = "routePanel";
  const googleApiKey = process.env.NEXT_PUBLIC_MAPSAPIKEY || process.env.MAPSAPIKEY;

  useEffect(() => 
  {
    setCompanyLocation({
      lat: contactPage.location.latitude,
      lng: contactPage.location.longitude
    });
  }, []);

  useEffect(() => 
  {
    if(!companyLocation) 
    {
      return;
    }

    // @ts-ignore
    if(window.google) 
    {
      setMap();
      return;
    }

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = `https://maps.google.com/maps/api/js?key=${googleApiKey}`;

    const x = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);

    s.addEventListener("load", setMap);

    return () => s.removeEventListener("load", setMap);
  }, [companyLocation]);

  const getRouteDescriptions = (): void => 
  {
    document.getElementById(routePanelId).innerHTML = null;

    // @ts-ignore
    const windowGoogleMap = window.google.maps;
    const directionsService = new windowGoogleMap.DirectionsService();
    const directionsRenderer = new windowGoogleMap.DirectionsRenderer();

    const map = new windowGoogleMap.Map(document.getElementById(mapElementId));

    directionsRenderer.setMap(map);
    directionsRenderer.setPanel(document.getElementById(routePanelId));

    const request = {
      origin: inputUserLocation,
      destination: `${companyLocation.lat},${companyLocation.lng}`,
      travelMode: "DRIVING"
    };

    directionsService.route(request, (result, status) => 
    {
      if(status === "OK") 
      {
        directionsRenderer.setDirections(result);
      }
      else 
      {
        console.log(status, result);
        setMap();
      }
    });
  };

  const setMap = (): void => 
  {
    if(!companyLocation) 
    {
      return;
    }

    // @ts-ignore
    const windowGoogleMap = window.google.maps;

    const mapId = document.getElementById(mapElementId);
    const map = new windowGoogleMap.Map(mapId, {
      zoom: contactPage?.location?.zoom || 12,
      center: companyLocation
    });
    new windowGoogleMap.Marker({ position: companyLocation, map });
  };

  const getUserLocation = (): void => 
  {
    if(navigator.geolocation) 
    {
      navigator.geolocation.getCurrentPosition(
        position => 
        {
          setInputUserLocation(`${position.coords.latitude},${position.coords.longitude}`);
        },
        error => 
        {
          console.info("Standort konnte nicht abgerufen werden: ", error);
        }
      );
    }
    else 
    {
      console.info("Standort konnte nicht abgerufen werden");
    }
  };

  if(!contactPage?.location?.latitude || !contactPage?.location?.longitude) 
  {
    return null;
  }

  return (
    <Fragment>
      <Header />
      <UniversalComponentRenderer items={elementsWithoutMainAsset} />
      <div css={`${contentWrapper}${styles.companyInfoAndContactForm}`}>
        <div css={centeredContent}>
          {companyInformationPublic?.companyNameOverwriteContact || companyInformationPublic?.companyName  && (
            <h1 css={bigHeading}>{getContactCompanyNameShort() }</h1>
          )}
          <div css={styles.columnsWrapper}>
            <div css={styles.companyInfosWrapper}>
              {companyInformationPublic?.managingDirector && (
                <h3 css={smallHeading}>{companyInformationPublic?.managingDirector}</h3>
              )}
              {companyInformationPublic?.street && <p>{companyInformationPublic.street}</p>}
              {companyInformationPublic?.postcode && companyInformationPublic.place && (
                <p>
                  {companyInformationPublic.postcode} {companyInformationPublic.place}
                </p>
              )}
              <br />
              {companyInformationPublic?.phone && <p>Tel: {companyInformationPublic.phone}</p>}
              {companyInformationPublic?.fax && <p>Fax: {companyInformationPublic.fax}</p>}
              <h3 css={smallHeading} style={{ marginTop: 9 }}>
                                Öffnungszeiten
              </h3>
              {openingHoursContent && (
                <RichText
                  overwriteStyle={styles.openingHoursRichtext}
                  textContent={openingHoursContent}
                />
              )}
            </div>
            <div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <div css={`${contentWrapper}${styles.mapWrapper}`}>
        <div css={centeredContent}>
          <div css={styles.map}>
            <div id={mapElementId} />
          </div>
          <div style={{ minHeight: 180 }}>
            <div css={styles.inputWrapper}>
              <label htmlFor="routeInput">Startadresse</label>
              <div css={styles.routeBerechnenWrapper}>
                <div css={styles.locationInputWrapper}>
                  <input
                    id={"routeInput"}
                    type="text"
                    name={"UserLocation"}
                    placeholder={universalTexts?.yourLocation || "Ihr Standort"}
                    value={inputUserLocation}
                    onChange={event => setInputUserLocation(event.target.value)}
                  />
                  <svg
                    onClick={getUserLocation}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 11h-2.051c-.469-4.725-4.224-8.48-8.949-8.95v-2.05h-2v2.05c-4.725.47-8.48 4.225-8.949 8.95h-2.051v2h2.051c.469 4.725 4.224 8.48 8.949 8.95v2.05h2v-2.05c4.725-.469 8.48-4.225 8.949-8.95h2.051v-2zm-11 8.931v-3.931h-2v3.931c-3.611-.454-6.478-3.32-6.931-6.931h3.931v-2h-3.931c.453-3.611 3.32-6.477 6.931-6.931v3.931h2v-3.931c3.611.454 6.478 3.319 6.931 6.931h-3.931v2h3.931c-.453 3.611-3.32 6.477-6.931 6.931zm1-7.931c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z" />
                  </svg>
                </div>
                <button onClick={getRouteDescriptions} name={"submit"}>
                  {universalTexts?.CalculateRoute || "Route berechnen"}
                </button>
              </div>
            </div>
            <div id={routePanelId} css={styles.routePanel} />
          </div>
        </div>
      </div>
      <Footer showLogos={false} />
    </Fragment>
  );
};

PageContactT6.displayName = "PageContactT6";
export { PageContactT6 };
