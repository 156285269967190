import React, { Fragment } from "react";
import { Img, ITextElement, RichText, Context, IContext } from "src/common";
import { GuestbookStars } from "src/common/components/GuestbookStars/GuestbookStars";

import { ImgGallery } from "../../../common/imgGallery/ImgGallery";
import * as styles from "../PageGuestbookT3.style";
import * as initialPageStyles from "./Inital.style";
import { HeadlineWrapper, TextWrapper, ContentWrapper } from "./Inital.style";

const Initial = (props: {universalTexts: any; onClick: any; textBlocks: any; bannerImage: any; guestbookEntries: any; options: any}): any => 
{ 
  const p = React.useContext<IContext>(Context);
  const palette = p?.Settings;

  return (
    <Fragment>
      {props.textBlocks?.map((tb: ITextElement, i: number) => <Fragment key={`textblock${i}`}> 
        {props.bannerImage.length === 1 ?
          (<div css={initialPageStyles.imageWrapper}>
            <Img
              key={"bannerImage"}
              quality={720}
              src={props.bannerImage[0]?.src}
              alt={props.bannerImage[0]?.title}
            />
          </div>
        ) : (
          <div css={initialPageStyles.imageGalleryWrapper}>
            <ImgGallery
              images={props.bannerImage.map((i) => ({
                src: i?.src,
                subtitle: i?.description,
              }))}
            />
          </div>)
        }
        {tb.headline &&
                        <HeadlineWrapper color={palette.themeTextColor}>
                          <h1>
                            {tb.headline}
                          </h1>
                        </HeadlineWrapper>
        }
        <TextWrapper textColor={palette.themeTextColor}>
          <RichText overwriteStyle={styles.richtextStyle} textContent={tb.text}></RichText>
        </TextWrapper>
      </Fragment>
                )}
      <ContentWrapper textColor={palette.themeTextColor} primary={palette.themeColorPrimary} background={palette.themeBackgroundColourIsland} colorIsland={palette.themeTextColourIsland}>
        <div className="entry">
          {props.guestbookEntries?.map((entry, i) => 
            <Fragment key={i}>
              <hr />
              <span>
                {entry.firstname} schrieb am{" "}
                {new Date(entry._meta.publishedAt).toLocaleDateString(
                  "de-DE",
                  props.options
                )}
                :
              </span>
              {entry.Evaluation === 0 || !entry.Evaluation ? ( // TODO in t6 we needed this, maybe here too?
                <h3>{entry.title}</h3>
              ) : (
                <div className="title_stars_wrapper">
                  <h3>{entry.title}</h3>
                  <GuestbookStars
                    changeable={false}
                    amountOfStars={entry.Evaluation}
                    starSize={18}
                  />
                </div>
              )}
              {entry.Message.content && (
                <RichText textContent={entry.Message}></RichText>
              )}
            </Fragment>)}
        </div>
        {/* <div className="write_posting_wrapper">
          <p>HINTERLASSEN SIE UNS DOCH AUCH EINE NACHRICHT!</p>
          <button onClick={props.onClick}>
            {props.universalTexts?.guestbook_writeContribution ?
              <RichText overwriteStyle={styles.labelRichtext} textContent={props.universalTexts?.guestbook_writeContribution}></RichText>
              : "Beitrag verfassen"
            }
          </button>
        </div> */}
      </ContentWrapper>
    </Fragment>
  );
};

export { Initial };
