import { getAllTextBlockCompined, Context, IContext, ITextElement, getPage, ImprintStart } from "src/common";
import React, { Fragment, useContext } from "react";

import RichText from "../../../BaseComponents/RichText/RichText";
import { flexItem100, centeredContent, flexWrapper } from "../../../constants/layout";
import * as styles from "./styles";
import Header from "../../common/header/Header";
import { FooterT5 } from "../../common/footer/Footer";
import { ImprintNameAndAddressOfThePersonInCharge } from "src/common/components/ImprintNameAndAddressOfThePersonInCharge";

const PageImprintT5 = () => {
    const props = useContext<IContext>(Context);
    React.useEffect(() => {
        if (typeof window !== 'undefined' && window.location.hash && typeof document !== 'undefined') {
            const searchTream = `${window.location.hash}`.replace('#', '');
            const xpath = `//span[contains(text(),'${searchTream}')]`;
            const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)?.singleNodeValue;
            if (matchingElement) {
                //@ts-ignore
                matchingElement.scrollIntoView();
            }
        }
    }, []);
    const page = getPage(props);
    // @ts-ignore
    const textElements = page?.elements?.filter((e) => e?.__typename === "TextElement") as ITextElement[];
    return (
        <div css={centeredContent}>
            <Header />
            <div css={flexWrapper}>
                <div css={`${flexItem100}${styles.imprintWrapper}`}>
                    <div css={styles.imprintRichtext}>
                        <h1 >{textElements?.[0]?.headline}</h1>
                        <ImprintStart />
                        {textElements.map((e, i) => (
                            <Fragment key={`ey${i}`}>
                                {e?.text && (
                                    <RichText textContent={e?.text} overwriteStyle={styles.imprintRichtext} />
                                )}
                                {i === 0 &&
                                    <ImprintNameAndAddressOfThePersonInCharge />
                                }
                            </Fragment>
                        ))}
                    </div>
                </div>
                <FooterT5 />
            </div>
        </div>
    );
};
PageImprintT5.displayName = "PageImprintT5";
export { PageImprintT5 };
