import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import CourseContent from "../../content/CourseContent";
import MainContent from "../../content/MainContent";

const PageCourseT25: FC = () => (
  <>
    <Header />
    <MainContent>
      <CourseContent />
    </MainContent>
    <Footer />
     
  </>
);

PageCourseT25.displayName = "PageCourseT25";
export { PageCourseT25 };
