import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useState,
} from "react";
import {
  IContext,
  Context,
  getIfNewsShouldBeDisplayByDate,
  INews,
  IAsset,
  IThemingColor,
  ITextElement,
} from "src/common";
import ImageWithAspectRatio from "../../../../../common/BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import {
  flexItem100,
  flexItem50,
  centeredContent,
  flexWrapper,
  imageWrapper,
  galleryItemIcon,
  galleryItemText,
  galleryWrapper,
  articleContent,
} from "../../../constants/layout";
import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import * as styles from "./styles";

const PageNewsT5: FunctionComponent = () => {
  const props = useContext<IContext>(Context);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const news = props.PageNews;
  let color =
    (
      news?.elements?.find(
        (e) => e?.__typename === "ThemingColor"
      ) as IThemingColor
    )?.color || "#fed061";

  const newsArticles =
    news?.NewsArticles?.filter((article) =>
      getIfNewsShouldBeDisplayByDate({
        startDate: article?.startDate,
        endDate: article?.endDate,
      })
    ).filter((article) => article !== null) || [];
  const newsCustomArticles =
    news?.NewsArticlesCustom?.filter((article) =>
      getIfNewsShouldBeDisplayByDate({
        startDate: article?.startDate,
        endDate: article?.endDate,
      })
    ).filter((article) => article !== null) || [];

  const articles = [...newsCustomArticles, ...newsArticles];

  const itemsPerPage = 10;

  const articlesToRender: INews[] = [];
  const amountOfPages = Math.ceil(articles.length / itemsPerPage);

  for (
    let i = (currentPage - 1) * itemsPerPage;
    i < (currentPage - 1) * itemsPerPage + itemsPerPage;
    i++
  ) {
    articlesToRender.push(articles[i]);
  }

  console.log(news);
  // @ts-ignore
  const textElements = news?.elements?.filter(
    (e) => e?.__typename === "TextElement"
  ) as ITextElement[];
  // @ts-ignore
  const assetElements = news?.elements?.filter(
    (e) => e?.__typename === "Asset" && !!(e as IAsset)
  ) as IAsset[];

  return (
    <div css={centeredContent}>
      <Header />
      <div css={imageWrapper}>
        {assetElements?.[0] && (
          // <Img
          //   quality={1600}
          //   src={assetElements[0]?.src}
          //   alt={assetElements[0]?.description}
          // />
          <ImageWithAspectRatio
            src={assetElements[0]?.src}
            bronze={1 / 2}
            srcSetWidths={{
              bronze: 720,
              silver: 1440,
              gold: 1920,
              diamond: 3600,
            }}
          />
        )}
      </div>
      <div css={galleryWrapper} color={color}>
        <div
          css={`
            ${flexItem50}${articleContent};
          `}
          color={color}
        >
          <h6>{textElements?.[0]?.headline}</h6>
          <div css={galleryItemText} color={color}>
            <RichText textContent={textElements?.[0]?.text} fragment></RichText>
          </div>
        </div>

        <div
          css={`
            ${flexItem50}${styles.articleImage};
          `}
        >
          <div css={galleryItemIcon}>
            {assetElements?.[1]?.src && (
              <Img
                quality={750}
                src={assetElements?.[1]?.src}
                alt={assetElements?.[1]?.description}
              />
            )}
          </div>
        </div>
      </div>
      <div css={flexWrapper}>
        {articlesToRender.map((article, i) => {
          if (!article) {
            return null;
          }

          const assets = article?.extras?.filter(
            (extra) => extra?.__typename === "Asset"
          ) as IAsset[];

          return (
            <Fragment key={i}>
              <div
                css={`
                  ${flexItem50}${styles.flexItem}${styles.articleImage}
                `}
              >
                {assets?.map((asset, i) => (
                  <Fragment key={`ASSETT5Fr${i}`}>
                    {asset && (
                      <Img
                        quality={700}
                        src={asset?.src}
                        alt={asset.description || "article image"}
                      />
                    )}
                    {asset.description && <p>{asset.description}</p>}
                  </Fragment>
                ))}
              </div>
              <div
                css={`
                  ${flexItem50}${styles.flexItem}${styles.articleContent}
                `}
                color={color}
              >
                {article?.headline && <h4>{article?.headline}</h4>}
                {article?.text && (
                  <RichText textContent={article?.text}></RichText>
                )}
              </div>
            </Fragment>
          );
        })}
        <div css={flexItem100} style={{ padding: 0 }}>
          <nav css={styles.nextAndPrevPage}>
            {currentPage < amountOfPages && (
              <a
                onClick={(e) => {
                  setCurrentPage(currentPage + 1);
                }}
                href={"#"}
              >
                Ältere Nachrichten
              </a>
            )}
            <a
              onClick={(e) => {
                setCurrentPage(currentPage - 1);
              }}
              style={
                currentPage > 1
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
              href={"#"}
            >
              Neuere Nachrichten
            </a>
          </nav>
        </div>
        <FooterT5 />
      </div>
    </div>
  );
};

PageNewsT5.displayName = "PageNewsT5";
export { PageNewsT5 };
