import { RichText } from "src/common";
import React from "react";
import * as styles from "./../../PageGuestbookT4.style";


const Confirmation = (props: {universalTexts: any, onClick: any}): any => {
    return (
        <>
            <div css={styles.contentWrapper}>
                <div css={styles.headlineWrapper}>
                    <h1 className="headline_wrapper__confirmation">Danke für Ihren Eintrag!</h1>
                    <p>Ihr Beitrag wurde entgegen genommen und wird in Kürze freigeschaltet.</p>
                </div>
                <div className="write_posting_wrapper">
                    <button onClick={props.onClick}>
                        {props.universalTexts.gestbook__back ? <RichText overwriteStyle={styles.richtextStyle} textContent={props.universalTexts.gestbook__back}></RichText> : "Zum Gästebuch"}
                    </button>
                </div>
            </div>
        </>
    );
};

export { Confirmation };
