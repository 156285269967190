import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { css } from "styled-components";

export const img: any = css`
  :not(:first-child) {
    margin-top: 20px;
    span {
      margin-top: 20px;
    }
  }

width: 100%;


  span {
    display: inline-block;
    width: 230px;
  }
  > div,
  img {
    width: 100%;
    aspect-ratio: 480/319;
  }
`;
