import React from "react";
import styled from "styled-components";

export const Star: any = styled.svg`
  margin-right: 12px;
  cursor: pointer;
  transform: scale(1.2);
  transform-origin: top left;
  margin-top: 3px;
  fill: rgb(255, 204, 102);
`;
