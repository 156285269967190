import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "src/common/constants/mediaquerys";

const Default = css`
  > div {
    position: relative;

    input {
      @media (max-width: 768px) {
        height: 40px;
        padding: 10px 15px;
        line-height: 20px;
        font-size: 15px;
      }
    }
    > div {
      position: absolute;
      background-color: #fff;
      height: 5rem;
      width: 130px;
      padding: 0;
      border: 0;
      top: 0;
      right: 0;
      @media (max-width: 768px) {
        height: 40px;
        width: 40px;
      }
      > button,
      svg {
        height: 5rem;
        width: 130px;
        @media (max-width: 768px) {
          height: 40px;
          width: 40px;
        }
      }
      svg {
        margin-right: -40px;
        @media (max-width: 768px) {
          margin-right: 0;
        }
      }
      > span {
        @media (max-width: 768px) {
          display: none;
        }
        :after {
          border-top: 3px solid #fed061;
          border-right: 3px solid #fed061;
          display: block;
          content: "";
          position: absolute;
          top: 5px;
          left: -50px;
          width: 70px;
          height: 70px;
          transform: rotate(45deg);
        }
      }
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SDealerSearchTop = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
