import React, { Fragment } from "react";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { Footer } from "../../common/footer/Footer";
import { Banner } from "../../Banner/Banner";


const PageRepairInquiryT4 = () => {
    return (

        <div id="PageRepairInquiryT400">
            <div id="PageRepairInquiryT4T41">
                 
            </div>
            <div id="PageRepairInquiryT4T42">
                <MainNavigation />
            </div>
            <div id="PageRepairInquiryT4T44">
                <Banner />
            </div>
            <div id="footer">
                <Footer />
            </div>
        </div>
    );
};
PageRepairInquiryT4.displayName = "PageRepairInquiryT4";
export { PageRepairInquiryT4 };
