import React, { Fragment, FC, useState } from "react";
import { IAsset } from "src/common";

import FullscreenImage from "../../../common/FullscreenImage";
import { RichText } from "../../../common/RichText";
import { Wrapper, HeadingWrapper, NewsContent, Images, ImageCaption } from "./NewsArticleItem.style";

interface IProps 
{
  headline: string;
  text: any;
  index: number;
  images: IAsset[];
}

const NewsArticleItem: FC<IProps> = props => 
{
  const [isActive, setIsActive] = useState(props?.index === 0);
  if(!props.headline || !props?.text) 
  {
    return null;
  }

  const [subtitle, ...content] = props.text.content;
  return (
    <Wrapper isActive={isActive}>
      <HeadingWrapper onClick={() => setIsActive(!isActive)}>
        <h3>{props.headline}</h3>
      </HeadingWrapper>

      <p>{subtitle?.children && subtitle.children[0].text}</p>

      <NewsContent isActive={isActive}>
        <Images>
          {props.images?.map((asset: IAsset, i: number) => 
            (
              <Fragment key={`${i}${asset?.src}`}>
                {asset && (
                  <>
                    <FullscreenImage
                      img={[asset]}
                      subtitle={asset.description}
                    />
                    {asset.description && (
                      <ImageCaption>
                        {asset.description}
                      </ImageCaption>
                    )}
                  </>
                )}
              </Fragment>
            ))}
        </Images>
        <RichText textContent={content} />
      </NewsContent>

      <a onClick={() => setIsActive(!isActive)}>
        SCHLIESSEN
      </a>
    </Wrapper>
  );
};

export default NewsArticleItem;
