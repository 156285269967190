import React, { Fragment, FunctionComponent } from "react";

import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import Micro from "../../common/Micro";
import { CommonPageBrandMicroMainContent } from "src/common/brand-area/PageBrandMicro/CommonPageBrandMicroMainContent";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandMicroT6: FunctionComponent = () => 
{
  return (
    <Fragment>
      <Header />
      <Spacer bronze={24} silver={48} gold={72}/>
      <CommonPageBrandMicroMainContent />
      <Footer showLogos={false} />
    </Fragment>
  );
};

PageBrandMicroT6.displayName = "PageBrandMicroT6";
export { PageBrandMicroT6 };
