import SBrandBannerVertical from "./Styles/SBrandBannerVertical";
import SShadowOverlay from "./Styles/SShadowOverlay";
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
} from "react";
import Img from "../../../BaseComponents/Img/Img";
import { IBrand, Context } from "src/common";
import { css } from "styled-components";

const BrandBannerVertical: FunctionComponent = () => {
  const props = useContext(Context);
  const allBrands = props.AllBrand;
  if (allBrands.length <= 1) {
    return null;
  }
  const companies = allBrands.map((company, i) => {
    if (
      !company?.logo ||
      company.logo.length === 0 ||
      !company.logo?.[0]?.src
    ) {
      return null;
    }
    return (
      <a key={i}>
        <Img
          src={company?.logo}
          lazyload={false}
          placeholderHeight={"31px"}
          quality={200}
          alt={company?.name || ""}
        ></Img>
      </a>
    );
  });
  const allCompanies1: any[] = [];
  const allCompanies2: any[] = [];
  for (let i of Array(8).keys()) {
    allCompanies1.push(<div key={`aaa${i}`}> {companies} </div>);
    allCompanies2.push(<div key={`aa${i}`}> {companies} </div>);
  }
  return (
    <SBrandBannerVertical amount={allBrands.length}>
      <div
        css={css`
          opacity: 0;
          width: 100%;
          max-width: 712px;
          pointer-events: none;
          position: absolute;
        `}
      >
        <div> {companies} </div>
      </div>
      <SShadowOverlay />
      <div className="container">
        <div className="marquee">{allCompanies1}</div>
        <div className="marquee">{allCompanies2}</div>
      </div>
      <SShadowOverlay />
    </SBrandBannerVertical>
  );
};

export default BrandBannerVertical;
