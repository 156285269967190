import React, { FC } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import ImprintContent from "../../content/ImprintContent";

const PageImprintT25: FC = () => (
  <>
    <Header />
    <ImprintContent/>
    <Footer />
     
  </>
);
PageImprintT25.displayName="PageImprintT25";
export { PageImprintT25 };
