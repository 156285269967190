import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const contactPageContent: any = css`
    display: block;

    @media (${BREAKPOINTS_DM.silver_768})
    {
        display: flex;
    }
`;

export const wrapperPublicInfo: any = css`
h1,h2,h3,h4{
  color: ${colors.yellow};
  font-size: 24px;
  line-height: 1.75;
}
h2 {
  margin-bottom: 20px;
}
h4{
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .02em;
}
> div {
  display: flex;
  flex-direction: row;
  > div {
    width: 50%;
  }
}
td:nth-child(2) {
  p{
    min-width: 20px;
  }
}
td:nth-child(3) {
  p{
    min-width: 100px;
  }
}
`;

export const wrapper: any = css`
  margin-bottom: 70px;
  * {
    line-height: 140%;
  }
  @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-left: 16px;
        width: 50%;
    }
`;

export const companyTitle: any = css`
    color: ${colors.yellow};
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 30px;
`;

export const columnsWrapper: any = css`
    display: flex;
    > div {
      width: 50%;
      &:first-child {
        padding-right: 30px;
      }
    }
`;

export const directionsDescription: any = css`
    h6 {
        color: ${colors.yellow};
        font-size: 19px;
    }
`;

export const contact: any = css`
    margin-top: 20px;
`;

export const sidebarText: any = css`
    background-color: rgba(255,255,255,0.2);
    margin-top: 30px;
    padding: 10px 10px 15px;
    border-radius: 8px;
    max-width: 250px;
    br:first-child {
      margin-top: 0;
    }
    p {
      margin-bottom: 20px;
    }
    div > p:last-child {
      margin-bottom: 0;
    }
    strong p {
      margin-bottom: 0;
    }
`;

export const contactForm: any = css`
    margin-top: 40px;
    h3 {
        color: ${colors.yellow};
        font-weight: 400;
        text-transform: uppercase;
        font-size: 15px;
    }

    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 20px;
            margin-top: 0;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const inputsWrapper: any = css`
    *:not(label) {
      padding: 3px 8px;
      width: 100%;
      margin-bottom: 20px;
    }
`;

export const submitWrapper: any = css`
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: flex-start;
    }
    button {
      max-width: 40%;
      width: 250px;
      background-color: ${colors.yellow};
      color: white;
      padding: 12px;
      border-radius: 3px;
      &:hover {
        background-color: black;
      }
    }
`;

export const datenschutzAkzeptierenWrapper: any = css`
    display: flex;
    max-width: 50%;
`;

export const datenschutzAkzeptieren: any = css`
    font-size: 12px;
    margin-left: 5px;
    a {
      color: ${colors.yellow};
    }
`;
