import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { theme } from "./themes";

export const centeredContent: any = css`
    max-width: ${theme.maxWidth}px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;

    @media (${BREAKPOINTS_DM.silver_768})
    {
        padding: 0 30px;
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        padding: 0 12px;
    }
`;
export const flexWrapper: any = css`
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
`;

const flexItem: any = css`
    width: calc(50%);
    flex-grow: 0;
    flex-shrink: 1;
`;

export const flexItem25: any = css`
    ${flexItem};
    width: calc(25%);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: calc(50%);
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        width: 100%;
    }
`;

export const flexItem50: any = css`
    ${flexItem};
    width: calc(50%);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: 100%;
    }
`;

export const flexItem100: any = css`
    ${flexItem};
    width: 100%;
`;
