import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const box: any = css`
    border: solid ${colors.blue} 2px;
`;

export const richText: any = css`
    h1 {
      color: ${colors.blue};
      font-size: 24px;
      margin-bottom: 12px;
    }
    h1, h2 {
      text-transform: uppercase; 
      margin-top: 12px; 
    };
    a{
      color: ${colors.blue};
    }
    h2, h3, h4 {
      color: ${colors.blue};
      font-size: 20px;
      margin-bottom: 8px;
    }
`;

export const map: any = css`
    min-height: 400px;
    background-color: ${colors.blue};
    > div {
      width: 100%;
      height: 100%;
      max-height: 600px;
    }
`;

export const routeBerechnenWrapper: any = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 10px;
      color: white;
      background-color: ${colors.blue};
    }
    > * {
      width: 47%;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: column;
        > * {
          width: 100%;
        }
    }
`;

export const inputWrapper: any = css`
    margin: 30px 0;
    label {
      font-weight: 300;
    }
`;

export const locationInputWrapper: any = css`
    position: relative;
    input {
        height: 41px;
        list-style: none;
        box-sizing: border-box;
        font-family: inherit;
        cursor: pointer;
        border-width: 1px;
        width: 100%;
        padding-right: 40px;
        padding-left: 8px;
    }
    svg {
      opacity: .4;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 7px;
    }
`;

export const routePanel: any = css`
    max-height: 50vh;
    overflow-y: auto;
`;

export const richTextWrapper: any = css`
    max-width: -webkit-fill-available;
    margin: 13.5px 0;
    img{
      width: calc(50% - 7px);
      margin-right: 14px;
      margin-bottom: 6px;
      float: left;
    }
`;

export const imgWrapper: any = css`
    max-width: -webkit-fill-available;
    margin: 13.5px 0;
`;

export const contactPublicInformation: any = css`
    td {
        padding-right: 24px;
    }
`;
