import * as React from "react";

import { Banner } from "../../common/Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import { ImprintContent } from "../../common/ImprintContent/ImprintContent";
import MainNavigation from "../../common/MainNavigation/MainNavigation";


const PageImprintT1 = () => 
  (
    <>
             
      <MainNavigation />
      <ImprintContent/>
      <Banner />
      <Footer />
    </>
  );
PageImprintT1.displayName="PageImprintT1";
export { PageImprintT1 };
