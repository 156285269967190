import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";
import NewsContent from "../../content/NewsContent";

const PageNewsT24: FC = () => (
  <>
    <Header />
    <MainContent showSideBar={false}>
      <NewsContent />
    </MainContent>
    <Footer />
     
  </>
);

PageNewsT24.displayName = "PageNewsT24";
export { PageNewsT24 };
