import React from "react";
import { css } from "styled-components";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import { SPageLayoutWrapper } from "../PageLanding";

const PageMicroT7 = () => (
  <div
    css={css`
      width: 100%;
    `}
  >
    <div>
      <ResponsiveSidebar />
    </div>

    <SPageLayoutWrapper>
      <div>
        <MainSidebarLeft />
      </div>
      <div
        css={css`
          width: 100%;
        `}
      >
        <MainAreaWrapper micro />
      </div>
    </SPageLayoutWrapper>
  </div>
);

PageMicroT7.displayName = "PageMicroT7";
export { PageMicroT7 };
