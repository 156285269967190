import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const HeadingWrapper: any = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  > h3 {
    color: ${(props) => props.theme.palette.primary};
    font-weight: 700;
    margin: 0;
  }
`;

export const NewsContent: any = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
  margin-top: 21px;
  width: 100%;
  font-weight: 300;
  max-height: ${({ isActive }) => (isActive ? "auto" : "0")};

  > div:nth-child(2) {
    ${({ isActive }) =>
      !isActive &&
      css`
        height: 100px;
      `}

    &,
    > p, ul, li {
      ${({ isActive }) =>
        !isActive &&
        css`
          margin: 0;
          padding: 0;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        `}
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    max-height: initial;
    flex-direction: row;
    align-items: flex-start;

    > div:nth-child(2) {
      width: 50%;
    }
  }

  ul {
    padding-left: 35px;
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: disc;
    }
  }
  strong ol li {
    font-weight: 700;
  }
  ul,
  ol {
    display: inline-block;
    text-align: left;
    li,
    span {
      text-align: left;
    }
  }
  ol {
    padding-left: 15px;
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: decimal;
    }
  }
  a {
    &,
    span {
      color: #d30032;
    }
    span {
      &::after {
        content: "";
        display: inline-block;
        background-image: url("/images/sprite-s82c5aa31b4.png");
        background-repeat: no-repeat;
        background-position: -20px -36px;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        transform: translateY(3px);
      }
    }
  }
  h1,
  h2:not(:first-child),
  h3,
  h4 {
    &,
    span {
      font-size: 14px;
      color: ${(props) => props.theme.palette.primary};
      font-weight: 700;
    }
  }
  h3,
  h4,
  h2 {
    &,
    span {
      font-weight: 400;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.palette.textTertiary};
      font-size: 1.2rem;
    }
  }

  .c-empty:first-child {
    display: none;
  }

  p {
    &,
    span {
      font-weight: 300;
    }
  }
`;

export const Images: any = styled.div<{ isActive: boolean }>`
  width: 100%;
  margin-bottom: 20px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-right: 20px;
    width: 50%;
    opacity: ${({ isActive }) => (isActive ? "1" : "0")};
    transition: opacity 0.3s ease-in-out;
  }
  display: flex;
  flex-direction: column;
`;

export const ImageCaption: any = styled.p`
  margin-bottom: 10.5px;
  padding: 10.5px;
  background: #d0d2d3;
  font-size: 12px;
`;

export const Wrapper: any = styled.div<{ isActive: boolean }>`
  margin-bottom: 60px;
  position: relative;

  svg {
    transform: scale(0.7);
    padding-left: 10px;
  }

  ${HeadingWrapper} {
  }

  > a {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary};
    font-weight: 700;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    transition: top 0.3s ease-in-out;

    img {
      display: block;
    }
    &[data-open] {
      margin-left: 20px;
      img {
        transform: rotate(90deg);
      }
    }
  }

  p:not(.c-empty) {
    font-weight: 300;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-bottom: 40px;

    > div:nth-child(1),
    > p,
    > a {
      padding-left: 51%;
    }

    ${({ isActive }) =>
      !isActive &&
      css`
        > a {
          position: absolute;
          top: 40%;
          left: 0;
        }
      `}
  }
`;
