import React, { FunctionComponent } from "react";
import Header from "../../common/header/Header";
import { FooterT3 } from "../../common/footer/Footer";
import { Banner } from "../../common/Banner/Banner";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";
import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";
import Spacer from "../../../../../common/BaseComponents/Spacer/Spacer";

const PageBrandT3: FunctionComponent = () => {
    return (
        <>
            <Banner />
            <PlanningDateModal />
            <Header />
            <Spacer bronze={80} silver={60} platinum={38} />
            <CommonPageBrandMainContent />
            <FooterT3 />
        </>
    );
};

PageBrandT3.displayName = "PageBrandT3";
export { PageBrandT3 };
