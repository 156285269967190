import styled from "styled-components";

export const Wrapper: any = styled.div`
  width: calc(100% / 4 * 3);
  padding: 15px;
`;

export const CourseItemWrapper: any = styled.div`
  h3 {
    color: #80C7DC;
    margin-bottom: 16px;
    font-size: 16px;
  }

  > div {
    display: flex;
  }
`;

export const CourseContentWrapper: any = styled.div`
  display: flex;
  width: 75%;

  [data-image] {
    width: 33.3%;
    margin-right: 16px;
    max-height: 110px;
  }

  [data-content] {
    width: 66.7%;

    p {
      &, span {
        font-family: helvetica,sans-serif;
        font-size: 12px;
        line-height: 17.1428px;
      }
    }

    a {
      color: #80C7DC;
    }
  }
`;

export const CourseDateWrapper: any = styled.div`
  width: 25%;
  padding-left: 32px;
  h3 {
    margin-bottom: 4px;
  }

  h3 > span { 
    color: rgb(128, 199, 220);
    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }

  p {
    color: rgb(239, 239, 239);
    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  p {
    &, span {
      font-size: 14px;
      line-height: 17px;
    }
  }
  a {
    background-color: #80C7DC;
    border: 1px solid #80C7DC;
    color: #efefef;
    cursor: pointer;
    display: inline-block;
    line-height: 2;
    margin-top: 20px;
    padding: 0 10px;
    text-align: center;
    width: 100%;
  }
`;

export const CourseImageWrapper: any = styled.div`
  width: 33.3%;
  margin-right: 34px;
  max-height: 290px;
`;
