import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import FurtherAwards from "../../common/FurtherAwards/FurtherAwards";
import Header from "../../common/Header";
 ;
import ContactContent from "../../content/ContactContent";

const PageContactT8: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <ContactContent />
      </Section>
    </Grid>
    <FurtherAwards />
    <Footer />
     
  </>
);

PageContactT8.displayName = "PageContactT8";
export { PageContactT8 };
