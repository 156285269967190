import { css } from "styled-components";

import colors from "../../../constants/colors";

export const flexItem: any = css`
    background-color: ${props => props.theme.palette.red};
`;

export const articleImage: any = css`
    > div {
      height: auto !important;
      margin-top: 35px;
      &:first-child{
        margin-top: 0;
      }
    }
    img {
      width: 100%;
      height: auto !important;
    }
    p {
      margin-top: 6px;
      color: white;
      font-size: 12px;
      font-style: italic;
    }
`;

export const articleContent: any = css`
    * {
      color: white;
    }
    br {
      margin-top: 12px;
    }
    h3 {
      span {
        font-size:  20px;
      }
    }
    h2, h4, h5, h6 {
      font-size: 20px;
    }
    h4 {
      span {
        font-weight: bold;
      }
    }
`;

export const nextAndPrevPage: any = css`
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    margin: 12px 0;
    a {
      background-color: ${colors.blue};
      color: white;
      padding: 4px 15px;
      text-align: center;
      &:first-child {
        padding-left: 44px;
        margin-right: 7px;
      }
      &:nth-child(2) {
        margin-left: 7px;
        padding-right: 44px;
      }
    }
    @media (max-width: 500px)
    {
        a {
          padding: 4px 10px !important;
        }
    }
`;
