import { BREAKPOINTS, BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

import { Col } from "../../../BaseComponents/Grid";

export const ContentContainer: any = styled.div`
  padding: 106px 0;
  max-width: 1100px;
  @media (${BREAKPOINTS_DM.gold_1024}){
    padding: 0 60px 0px;
  }

  ${Col} {
    // padding: 0;
  }

  p, span, h1, h2, h3, h4 {
    font-weight: 300;
  }

  h2 {
    color: #fff;
    font-size: 20px;
  }
  
`;

export const MapWrapper: any = styled.div`
  padding: 0 10px;
  @media (${BREAKPOINTS_DM.gold_1024}){
    width: 50%;
    padding: 0 10px 0 0;
  }
`;
export const ContactWrapper: any = styled.div`
  padding: 0 10px;
  @media (${BREAKPOINTS_DM.gold_1024}){
    width: 50%;
    padding: 0 0 0 10px;
  }
  `;
