import React, { Fragment, FunctionComponent, useContext } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import * as styles from "./Form.style";
import { Context } from "src/common";
import { handleFormSubmit } from "../../../../../../common/utils/formSubmit";
import { css } from "styled-components";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

export const PageDateForm: FunctionComponent = () => {
    const slugProps = useContext(Context);

    const { register, handleSubmit, errors, reset } = useForm();
    const [success, setSuccess] = React.useState(false)
    const footer = slugProps?.Footer;
    const universalTexts = slugProps?.UniversalTexts;


    const onSubmit = (data): void => {
        //Kontaktformular: d-26922068a30343d98a725c3fdaa663da
        //Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6 
        //Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441 
        //Produktanfrage: d-ee2129a87d2d4e70bbcf21f1bff6491e

        const fcValue = (document.querySelector('.frc-captcha-solution') as HTMLInputElement)?.value;

        let title: string | undefined = undefined;
        if (typeof window !== 'undefined') {
            title = document.title
        }
        handleFormSubmit({
            fcValue: !!fcValue ? fcValue : sessionStorage.getItem('fcaptcha'),
            ...data,
      line: slugProps?.CompanyInformationPrivate?.line,
      subject: `Terminanfrage ${data?.Name ? `von ${data?.Name} ` : ''}${title ? `- ${title}` : ''}`,
            dealerMail: slugProps?.CompanyInformationPublic?.email,
            templateId: 'd-deb56cdeb9c34309be7a1a521bda38e6',
            // cc: [{ email: "serviceseite@gedk.de" }]
        }).then(r => {
            reset()
            setSuccess(true)
        })
    };

    const dateOptions = [];
    const today = new Date();

    for (let i = 1; i < 9; i++) {
        const newDate = new Date();
        newDate.setDate(today.getDate() + i);
        dateOptions.push(
            <option key={i} value={newDate.toLocaleDateString()}>
                {newDate.toLocaleDateString()}
            </option>
        );
    }

    const timeOptions: React.ReactElement[] = [];
    const timeOptions2: React.ReactElement[] = [];

    for (let j = 8; j < 22; j++) {
        let value;
        let value2;

        if (j < 10) {
            value = `0${j}:00`;
            value2 = `0${j}:30`;
        } else {
            value = `${j}:00`;
            value2 = `${j}:30`;
        }

        timeOptions.push(
            <Fragment key={j}>
                <option value={value}>{value}</option>
                <option value={value2}>{value2}</option>
            </Fragment>
        );

        timeOptions2.push(
            <Fragment key={j + 999}>
                {j !== 8 && <option value={value}>{value}</option>}
                <option value={value2}>{value2}</option>
            </Fragment>
        );
    }

    const brandOptions = [];

    for (const brand of slugProps.AllBrand) {
        brandOptions.push(
            <option key={brand?.id} value={brand?.name}>
                {brand?.name}
            </option>
        );
    }

    const vorname = "*Vorname";
    const nachname = "*Nachname";
    const email = "*E-Mail";
    const tel = "Telefonnummer";
    const date = "Datum";
    const uhrzeitVon = "Uhrzeit (von)";
    const uhrzeitBis = "Uhrzeit (bis)";
    const kommentar = "Kommentar";

    // Liebe Kollegen,
    // ein Interessent hat eine Service-/Reparaturanfrage gestellt.

    // Kontaktdaten des Interessenten:
    // Vorname: {{vorname}}
    // Nachname: {{nachname}}
    // E-Mail: {{EMail}}
    // Telefonnummer: {{tel}}

    // Terminwunsch:
    // Datum: {{date}}
    // von: {{uhrzeitVon}}
    // bis: {{uhrzeitBis}}

    // Kommentar: {{kommentar}}

    return (
        <div css={styles.fromWrapper}>
            {success ?
                <form>
                    <h1>DANKE!</h1>
                        Wir werden uns sobald wie möglich bei Ihnen melden.
                    </form>
                : <form onSubmit={handleSubmit(onSubmit)}>
                    <div css={styles.inputsWrapper}>
                        <div css={styles.twoColumnsRow}>
                            <div>
                                <label htmlFor={"vorname"}>{vorname}</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="vorname"
                                    id={"vorname"}
                                    ref={register({ required: true })}
                                />
                            </div>
                            <div>
                                <label htmlFor={"nachname"}>{nachname}</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="nachname"
                                    id={"nachname"}
                                    ref={register({ required: true })}
                                />
                            </div>
                        </div>

                        <div css={styles.twoColumnsRow}>
                            <div>
                                <label htmlFor={"email"}>{email}</label>
                                <input
                                    required={true}
                                    type="email"
                                    id={"email"}
                                    name="email"
                                    ref={register({ required: true, min: 4 })}
                                />
                            </div>
                            <div>
                                <label htmlFor={"tel"}>{tel}</label>
                                <input type="text" name="tel" id={"tel"} ref={register()} />
                            </div>
                        </div>

                        <div css={`${styles.twoColumnsRow}${styles.threeColumns}`}>
                            <div>
                                <label htmlFor={"date"}>{date}</label>
                                <select name="date" id="date" ref={register()}>
                                    {dateOptions}
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"uhrzeitVon"}>{uhrzeitVon}</label>
                                <select name="uhrzeitVon" id="uhrzeitVon" ref={register()}>
                                    {timeOptions}
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"uhrzeitBis"}>{uhrzeitBis}</label>
                                <select name="uhrzeitBis" id="uhrzeitBis" ref={register()}>
                                    {timeOptions2}
                                </select>
                            </div>
                        </div>

                        <label htmlFor={"kommentar"}>{kommentar}</label>
                        <textarea rows={8} name="kommentar" id={"kommentar"} ref={register()} />
                    </div>
                    <FriendlyCaptcha />
                    <div css={styles.submitWrapper}>
                        <div>
                            <input
                                css={css`
                                    position: absolute;
                                    opacity: 0;
                                    pointer-events: none;
                                `}
                                type="privacy"
                                placeholder="Ich akzeptiere die Datenschutzerklärung"
                                name="privacy"
                            />
                            <input
                                required={true}
                                type="checkbox"
                                placeholder="Ich akzeptiere die Datenschutzerklärung"
                                name="checkbox"
                                id={"checkbox"}
                                ref={register({ required: true })}
                            />
                            <label htmlFor={"checkbox"} css={styles.datenschutzAkzeptieren}>
                                Ich akzeptiere die <a target="_blank" href={`/${slugProps.PageImprint?.slug}`}> Datenschutzerklärung</a>
                            </label>
                        </div>
                        <button type="submit">{universalTexts?.Submit || "Absenden"}</button>
                    </div>
                </form>}
        </div>
    );
};
