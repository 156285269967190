import styled, { css } from "styled-components";

export const MicroWrapper: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  iframe { 
    width: 100%; 
    max-width: 1170px;
    padding-right: 15px;
    padding-left: 15px;
    height: 80vh;
  }
`;
