import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";
import NewsContent from "../../content/NewsContent";

const PageNewsT26: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent news/>
      </Section>
    </Grid>
    <Footer />
     
  </>
);

PageNewsT26.displayName = "PageNewsT26";
export { PageNewsT26 };

