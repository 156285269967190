import React, { Fragment } from "react";
import { Img, ITextElement, RichText, IAsset } from "src/common";
import { GuestbookStars } from "src/common/components/GuestbookStars/GuestbookStars";
import { ImgGallery } from "src/templates/t3/components/common/imgGallery/ImgGallery";

import CommonRichText from "../../../../../BaseComponents/RichText/RichText";
import * as styles from "../../PageGuestbookT4.style";
import * as initialPageStyles from "./InitialGuestbookPage.style";

const InitialGuestbookPage = (props: {
  universalTexts: any;
  textBlocks: any;
  bannerImage?: IAsset[];
  guestbookEntries: any;
  onClick: any;
}): any => 
{
  const options = {
    weekday: "long", year: "numeric", month: "long", day: "numeric" 
  };

  return (
    <Fragment>
      {props.textBlocks?.map((tb: ITextElement, i: number) => (
        <Fragment key={`textblock${i}`}>
          {props.bannerImage.length === 1 ? (
            <div css={initialPageStyles.imageWrapper}>
              <Img
                key={"bannerImage"}
                quality={720}
                src={props.bannerImage[0]?.src}
                alt={props.bannerImage[0]?.title}
              />
            </div>
          ) : (
            <div css={initialPageStyles.imageGalleryWrapper}>
              <ImgGallery
                images={props.bannerImage.map((i) => 
                  ({
                    src: i?.src,
                    subtitle: i?.description,
                  }))}
              />
            </div>
          )}
          {tb.headline && (
            <div css={styles.headlineWrapper}>
              <h1>{tb.headline}</h1>
            </div>
          )}
          <RichText overwriteStyle={styles.richtextStyle} textContent={tb.text}></RichText>
        </Fragment>
      ))}
      <div css={styles.contentWrapper}>
        <div className="write_posting_wrapper">
          <button onClick={props.onClick}>
            {props.universalTexts?.guestbook_writeContribution ? (
              <RichText
                overwriteStyle={styles.labelRichtext}
                textContent={props.universalTexts?.guestbook_writeContribution}
              ></RichText>
            ) : (
              "Beitrag verfassen"
            )}
          </button>
        </div>
        <div className="entry">
          {props.guestbookEntries?.map((entry, i) => 
            (
              <Fragment key={i}>
                <hr />
                <span>
                  {entry.firstname} {entry.lastname} schrieb am{" "}
                  {new Date(entry.date).toLocaleDateString("de-DE")}:
                </span>
                {!entry.Evaluation || entry.Evaluation === 0 ? (
                  <h3>{entry.title}</h3>
                ) : (
                  <div className="title_stars_wrapper">
                    <h3>{entry.title}</h3>
                    <GuestbookStars
                      changeable={false}
                      starSize={16}
                      amountOfStars={entry.Evaluation}
                    />
                  </div>
                )}
                {entry?.Message?.content && (
                  <CommonRichText textContent={entry.Message.content}></CommonRichText>
                )}
                {entry.commentOfDealer && (
                  <>
                    <div style={{ padding: "0 20px" }}>
                      <h3>kommentar</h3>
                      <CommonRichText
                        textContent={entry.commentOfDealer.content}
                      ></CommonRichText>
                    </div>
                  </>
                )}
              </Fragment>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export { InitialGuestbookPage };
