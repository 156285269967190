import { Context } from "src/common";

import React, { useContext, FC } from "react";

import { IGenPageBrandTopic } from "../../__generated/root/types";
import {
    BrandAreaComponentRouter,
    IBrandAreaComponentRouterElement,
} from "../component-router/BrandAreaComponentRouter";
import { SCommonPageBrandTopicMainContentTwoColumnsWrapper } from "./styles/SCommonPageBrandTopicMainContentTwoColumnsWrapper";
import { BrandAreaContainer } from "../components/brand-area-container/BrandAreaContainer";

export const CommonPageBrandTopicMainContent: FC<any> = () => {
    const c = useContext(Context);
    const page = c?.PageBrandTopic as IGenPageBrandTopic;

    if (!c || !page) {
        return null;
    }

    // here the like lvl2 .. at index 0 link back to overview page + 50% dropdown
    // at index 1 is the first component
    // at index 2 is the header with logo and back to brand only
    // at index 3 30% header links + 70% the component
    // ...rest starts from index 4

    const rawElements = page.elements || [];

    const headerElements = [
        {
            __typename: "automaticallyBackToOverviewLinkMobile",
            id: "automaticallyBackToOverviewLinkMobile",
        },
        {
            __typename: "automaticallyOverviewLinkWithDropdown",
            id: "backToOverviewLink",
        },
        ...rawElements.slice(0, 1),
        {
            __typename: "automaticallyIntegratedSubtopicHeader",
            id: "subtopicHeader",
        },
    ] as IBrandAreaComponentRouterElement[];

    const twoColumnElements = [
        {
            __typename: "automaticallyIntegratedSubtopicHeaderLinks",
            id: "subtopicHeaderLinks",
        },
        ...rawElements.slice(1, 2),
    ] as IBrandAreaComponentRouterElement[];

    const restOfElements = rawElements.slice(2) as IBrandAreaComponentRouterElement[];

    return (
        <div className="brand-area brand-area-topic">
            <BrandAreaComponentRouter elements={headerElements} />

            <BrandAreaContainer>
                <SCommonPageBrandTopicMainContentTwoColumnsWrapper>
                    <BrandAreaComponentRouter elements={twoColumnElements} />
                </SCommonPageBrandTopicMainContentTwoColumnsWrapper>
            </BrandAreaContainer>

            <BrandAreaComponentRouter elements={restOfElements} />
        </div>
    );
};
