import React, { Fragment, FunctionComponent, useContext } from "react";
import { getRandomColor, IThemingColor, IAsset, IPage, ITextElement,  Context } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import { flexItem100, flexItem50, centeredContent, flexWrapper } from "../../../constants/layout";
import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import * as styles from "./styles";

const PageBrandOldT5: FunctionComponent<any> = () => 
{
  const props = useContext(Context);
  const pageData: IPage = props?.PageBrand as any;
  let color = (pageData?.elements?.find(e => e?.__typename === "ThemingColor") as IThemingColor)?.color;
  const textElements = pageData?.elements?.filter(extra => extra?.__typename === "TextElement") as ITextElement[];
  const asset = pageData?.elements?.find((field, index) => field?.__typename === "Asset" && index === 0) as IAsset;
  const icon = pageData?.elements?.find((field, index) => field?.__typename === "Asset" && index > 0) as IAsset;

  if(!color) 
  {
    color = getRandomColor();
  }
  return (
    <div css={centeredContent}>
      <Header />
      <div css={flexWrapper}>
        <Fragment>
          {asset && (
            <div css={`${flexItem100}${styles.heroImage}`}>
              <Img
                quality={1600}
                src={asset?.src}
                alt={asset?.description || "bild"}
              />
            </div>
          )}
          <div css={`${flexItem50}${styles.contentBox}`} style={{ border: `solid 2px ${color}` }}>
            {textElements?.length > 0 && (
              <>
                {textElements[0]?.headline && <h2 style={{ color }}>{textElements[0]?.headline}</h2>}
                <RichText
                  textContent={textElements[0]?.text}
                  overwriteStyle={styles.richText}
                ></RichText>
              </>
            )}
          </div>
          <div css={`${flexItem50}${styles.iconBox}`} style={{ backgroundColor: color }}>
            {icon?.src && (
              <Img
                quality={700}
                src={icon?.src}
                alt={icon?.description || "icon"}
              />
            )}
          </div>
          {textElements?.map((textElement, i) => 
          { 
            console.log(textElement)
            if(textElement?.__typename === "TextElement") 
            {
              if(i === 0) 
              {
                return null;
              }

              if(!textElement?.text) 
              {
                return null;
              }

              return (
                <Fragment key={i}>
                  {textElement?.icon[0]?.src ?

                    <div css={`${flexItem50}${styles.articleAssetBox}`} style={{ backgroundColor: color }}>
                      <Img
                        quality={1200}
                        src={textElement?.icon[0]?.src}
                        alt={textElement?.icon[0]?.description}
                      />
                    </div>
                    :
                    <div css={`${flexItem50}${styles.hiddenOnMobile}`} style={{ backgroundColor: color }}/>
                  }

                  <div css={`${flexItem50}${styles.articleContentBox}`} style={{ backgroundColor: color }}>
                    {textElement?.text && (
                      <>
                        {textElement?.headline && (
                          <h2 style={{ color }}>{textElement?.headline}</h2>
                        )}
                        <RichText
                          textContent={textElement?.text}
                          overwriteStyle={styles.richText}
                        ></RichText>
                      </>
                    )}
                  </div>
                </Fragment>
              );
            }
          })}
          {(pageData as any)?.connectedArticles?.map((article, i) => 
          {
            if(article?.__typename === "Article") 
            {
              const articleAsset = article?.extras?.find(extra => extra?.__typename === "Asset") as IAsset;
              return (
                <Fragment key={i}>
                  <div css={`${flexItem50}${styles.articleAssetBox}`} style={{ backgroundColor: color }}>
                    {articleAsset?.src && (
                      <Img
                        quality={1200}
                        src={articleAsset?.src}
                        alt={articleAsset?.description}
                      />
                    )}
                  </div>
                  <div css={`${flexItem50}${styles.articleContentBox}`} style={{ backgroundColor: color }}>
                    {article?.headline && <h2 style={{ color }}>{article?.headline}</h2>}
                    {article?.mainText && (
                      <RichText
                        textContent={article?.mainText}
                        overwriteStyle={styles.richText}
                      ></RichText>
                    )}
                  </div>
                </Fragment>
              );
            }
          })}
        </Fragment>
        <FooterT5 />
      </div>
    </div>
  );
};
PageBrandOldT5.displayName = "PageBrandOldT5";
export { PageBrandOldT5 };
