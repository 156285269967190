import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";

import React, { FunctionComponent } from "react";

import { Banner } from "../../Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import Spacer from "../../../../../common/BaseComponents/Spacer/Spacer";

const PageBrandT4: FunctionComponent = () => (
  <div id="PageBrandT400">
    <div id="PageBrandT4T41"></div>
    <div id="PageBrandT4T42">
      <MainNavigation />
    </div>
    <Spacer bronze={80} />
    <div id="PageBrandT4T43">
      <CommonPageBrandMainContent />
    </div>
    <div id="PageBrandT4T44">
      <Banner />
    </div>
    <div id="footer">
      <Footer />
    </div>
  </div>
);

PageBrandT4.displayName = "PageBrandT4";
export { PageBrandT4 };
