import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";
import NewsContent from "../../content/NewsContent";

const PageNewsT9: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent />
        <NewsContent />
      </Section>
    </Grid>
    <Banner />
    <Footer />
     
  </>
);

PageNewsT9.displayName = "PageNewsT9";
export { PageNewsT9 };

