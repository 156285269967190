import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const MapWrapper: any = styled.div`

`;

export const MapContainer: any = styled.div`
    height: 300px;
    margin: 0 -10px 20px -10px;

    @media (${BREAKPOINTS_DM.silver_768}) {
      margin: 0 0 20px 0;
    }
`;

export const serviceNumber: any = css`
    margin-bottom: 50px;
    align-items: flex-start;
    padding-left: 10px;
`;

export const RouteWrapper: any = styled.div`
    margin-top: 5px;
    display: flex;
    button {
      width: 50%;
      margin-left: 14px;
      font-weight: 300;
      background-color: #92c2d6;
      color: #EFEFEF;
      padding: 15px;
      &:hover {
        background-color: #706f6f;
      }
    }
`;

export const LocationInput: any = styled.div`
    position: relative;
    margin-right: 14px;
    width: 50%;
    input {
      padding: 6px 35px 6px 12px;
      width: 100%;
      height: 100%;
    }
    svg {
      opacity: .5;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 7px;
    }
`;

export const DirectionsPanel: any = styled.div`
    margin-top: 30px;
    background-color: #FFD533;
    table:not(.adp-placemark)
    {
        tr {
              td {
                padding: 8px 0;
                border-top-width: 1px;
                border-top-color: ${props => props.theme.palette.green} !important;
                line-height: 150%;
                &:first-child {
                  padding-left: 5px;
                  width: 10% !important;
                }
                &:nth-child(2) {
                  width: 5% !important;
                  font-weight: 500;
                  font-size: 14px;
                }
                &:nth-child(3) {
                  width: 70% !important;
                  font-weight: 500;
                }
                &:nth-child(4) {
                  width: 15% !important;
                }
          }
      }
    }
`;

export const catchmentArea: any = css`
`;
