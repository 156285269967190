import { CommonPageBrandTopicMainContent } from "src/common/brand-area/PageBrandTopic/CommonPageBrandTopicMainContent";

import React, { FunctionComponent } from "react";

import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { centeredContent } from "src/templates/t5/constants/layout";

const PageBrandTopicT5: FunctionComponent = () => (
    <>
        <div css={centeredContent}>
            <Header />
        </div>
        <CommonPageBrandTopicMainContent />
        <div css={centeredContent}>
            <FooterT5 />
        </div>
    </>
);

PageBrandTopicT5.displayName = "PageBrandTopicT5";
export { PageBrandTopicT5 };
