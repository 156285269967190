import React, { useState, useContext, Fragment, useEffect, useRef, FC } from "react";
import useCollapse from "react-collapsed";
import { Context, getPage, RichText, ITextElement, IAsset, LogoBannerHorizontal, Img } from "src/common";
import { css } from "styled-components";

import ImageWithAspectRatio from "../../../../BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import SRichTextGeneral from "../../../../BaseComponents/RichText/Styles/SRichTextGeneral";
import Articles from "../../../Articles/Articles";
import * as styles from "../MainContent.style";
import { ContentWrapper } from "../MainContent.style";

export const TwoColumn: FC<any> = (props) => {
    const p = useContext(Context);
    const page = getPage(p);
    // @ts-ignore
    const textElements = page?.elements?.filter((e) => e?.__typename === "TextElement") as ITextElement[];
    // @ts-ignore
    const assetElements = page?.elements?.filter((e) => e?.__typename === "Asset" && !!(e as IAsset)) as IAsset[];
    const [isExpanded, setExpanded] = useState(false);
    const [showToggle, setShowToggle] = useState(false);
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded, collapsedHeight: 350 });
    const selectionRef = useRef();
    useEffect(() => {
        tryToSetToggleMultipleTimes(0);
    }, [selectionRef]);

    // Recursive function to use another timeout if the previous did not work.
    const tryToSetToggleMultipleTimes = (retryNumber: number) => {
        if (retryNumber < 5) {
            setTimeout(() => {
                // @ts-ignore
                selectionRef?.current?.clientHeight > 350
                    ? setShowToggle(true)
                    : tryToSetToggleMultipleTimes(retryNumber + 1);
            }, 300);
        }
    };
    return (
        <div css={styles.flexxer}>
            {assetElements?.map((e, i) => (
                <Fragment key={i}>
                    {e && (
                        <div css={styles.mainImageWrapper}>
                            <ImageWithAspectRatio
                                src={e?.src}
                                width={356}
                                height={512}
                                alt={"mainImage"}
                                quality={1200}
                                css={styles.mainImage}
                            />
                        </div>
                    )}
                </Fragment>
            ))}
            <div
                css={
                    assetElements?.length >= 1
                        ? isExpanded
                            ? styles.mainTextWrapper
                            : styles.mainTextWrapperHidden
                        : undefined
                }
            >
                {Array.isArray(props.children) ? props.children?.[1] : props.children}
                <div>
                    {assetElements?.length >= 1 ? (
                        <>
                            <section {...getCollapseProps()}>
                                <>
                                    <div ref={selectionRef}>
                                        {textElements.map((e, i) => (
                                            <>
                                                {i === 0 && (
                                                    <div css={styles.textWrapper}>
                                                        <div css={styles.imgWrapper}>
                                                            {e?.icon &&
                                                                e?.icon
                                                                    ?.filter((icon) => icon?.src)
                                                                    .map((icon, x) => (
                                                                        <>
                                                                            <Img
                                                                                src={icon && [icon]}
                                                                                alt={icon?.title}
                                                                            />
                                                                            <div css={styles.imageDescripton}>
                                                                                {" "}
                                                                                {icon?.title}{" "}
                                                                            </div>
                                                                        </>
                                                                    ))}
                                                        </div>
                                                        <RichText
                                                            fragment
                                                            textContent={e?.text}
                                                            overwriteStyle={styles.richTextWrapper}
                                                        ></RichText>
                                                        <div css={styles.clearFix}></div>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                        <Articles />
                                        {p.PageBrandOverview?.slug &&
                                            p?.slug &&
                                            p.PageBrandOverview?.slug === p?.slug && <LogoBannerHorizontal />}
                                    </div>
                                </>
                            </section>
                            {!isExpanded && showToggle && (
                                <img
                                    css={
                                        css`
                                            cursor: pointer;
                                        ` as any
                                    }
                                    src={"/images/more.png"}
                                    {...getToggleProps({
                                        onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                                    })}
                                ></img>
                            )}
                        </>
                    ) : (
                        <>
                            {textElements.map((e, i) => (
                                <>
                                    {i === 0 && (
                                        <ContentWrapper>
                                            <RichText textContent={e?.text} overwriteStyle={SRichTextGeneral} />
                                        </ContentWrapper>
                                    )}
                                </>
                            ))}
                            <Articles />
                        </>
                    )}
                </div>
                {props.bottom}
            </div>
        </div>
    );
};
