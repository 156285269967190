import { Context,  IContext, IPage, ITextElement, IThemingColor, RichText, ServiceForm } from "src/common";
import React, { FunctionComponent, useContext } from "react";
import { flexItem50, centeredContent, flexWrapper } from "../../../constants/layout";
import * as styles from "./Service.style";
import stylesServiceForm from "./ServiceForm.style";
import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";

const PageRepairInquiryT5: FunctionComponent = () => {

    const props = useContext<IContext>(Context);
    const pageData: IPage = props?.PageRepairInquiry || (props?.PageRepairInquiry as any);
    let color = (pageData?.elements?.find((e) => e?.__typename === "ThemingColor") as IThemingColor)?.color;
    const textElements = pageData?.elements?.filter((extra) => extra?.__typename === "TextElement") as ITextElement[];
   
    return (<div css={centeredContent}>
        <Header />
        <div css={flexWrapper}>
            <div css={`${flexItem50}${styles.box}`} style={{ border: `solid 2px ${color}` }} >
                {textElements?.length > 0 && (
                    <>
                        {textElements[0]?.headline && <h2 style={{ color }}>{textElements[0]?.headline}</h2>}
                        <RichText
                            textContent={textElements[0]?.text}
                            overwriteStyle={styles.richText}
                        ></RichText>
                    </>
                )}
            </div>
            <div css={`${flexItem50}${styles.box}${stylesServiceForm}`}>
                <ServiceForm />
            </div>
            <FooterT5 />
        </div>
    </div>
    )
};
PageRepairInquiryT5.displayName = "PageRepairInquiryT5";
export { PageRepairInquiryT5 };
