import React, { FC, Fragment, useContext } from "react";
import {
  ImprintStart,
  Context,
  getPage,
  ITextElement,
  ISidebarText,
  IAsset
} from "src/common";
import { ImprintNameAndAddressOfThePersonInCharge } from "src/common/components/ImprintNameAndAddressOfThePersonInCharge";

import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import Img from "../../../BaseComponents/Img";
import Articles from "../../common/Articles";
import Gallery from "../../common/Gallery";
import { Container, Section } from "../../common/Grid";
import RichText, { RichTextGeneral } from "../../common/RichText";
import SubNavRight from "../../common/SubNavRight";
import TextBoxRight from "../../common/TextBoxRight";
import * as styles from "./ImprintContent.style";
import { ContentContainer } from "./ImprintContent.style";

const flatten = (input): any[] => 
{
  const stack = [...input];
  const res = [];
  while(stack.length) 
  {
    // pop value from stack
    const next = stack.pop();
    if(Array.isArray(next)) 
    {
      // push back array items, won't modify the original input
      stack.push(...next);
    }
    else 
    {
      res.push(next);
    }
  }
  // reverse to restore input order
  return res.reverse();
};

const ImprintContent: FC<any> = props => 
{
  React.useEffect(() => 
  {
    if(typeof window !== "undefined" && window.location.hash && typeof document !== "undefined") 
    {
      const searchTream = `${window.location.hash}`.replace("#", "");
      const xpath = `//span[contains(text(),'${searchTream}')]`;
      const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)?.singleNodeValue;
      if(matchingElement) 
      {
        // @ts-ignore
        matchingElement.scrollIntoView();
      }
    }
  }, []);
  const p = useContext(Context);
  const page = getPage(p);
  const sideBarText = page?.extras?.find(e => e?.__typename === "SidebarText") as ISidebarText;
  // @ts-ignore
  const imgArray = page?.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const textElements = page?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[];
  const connectedArticles = (page as any)?.connectedArticles;
  const TopComponent = props.topComponent;
  const navItem = p?.PrimaryNavigation?.items?.find(
    i => !!i?.subitems?.find(s => !!s?.slug === props.slug) || i?.link?.[0]?.slug === props.slug
  );
  let imageOrGallery;
  if(imgArray?.[0]) 
  {
    if(imgArray.length > 1) 
    {
      imageOrGallery = <Gallery imgArray={flatten(imgArray?.map(i => i))} />;
    }
    else 
    {
      imageOrGallery = (
        <ImageWithAspectRatio
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={342}
          ratio={50}
          quality={1080}
        />
      );
    }
  }

  return (
    <Container>
      <Section>
        <ContentContainer>
          <div>
            <>
              {TopComponent ? (
                <TopComponent />
              ) : (
                <>
                  {imageOrGallery}
                  {imageOrGallery ? <div style={{ margin: "20px 0" }} /> : null}
                </>
              )}
            </>
            <h1 css={styles.h1}>{textElements?.[0]?.headline}</h1>
            <ImprintStart />

            {textElements.map((textElement, indexTextElement) => (
              <Fragment key={`indexTextElement${indexTextElement}`}>
                {textElement?.icon?.map((i, index) => 
                {
                  if(i) 
                  {
                    return <Img key={`i${index}`} quality={1020} src={[i]} alt={i.title}></Img>;
                  }
                  return null;
                })}
                {textElement?.text && <RichTextGeneral fragment textContent={textElement?.text} />}
                {indexTextElement === 0 && <ImprintNameAndAddressOfThePersonInCharge />}
              </Fragment>
            ))}

            <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
          </div>

          {(navItem || sideBarText) && (
            <div css={styles.sideBarWrapper}>
              <SubNavRight />
              <TextBoxRight text={sideBarText?.text} />
            </div>
          )}
          {!connectedArticles && page?.footnote && <RichText fragment textContent={page?.footnote} />}
        </ContentContainer>
        {props.children}

      </Section>
    </Container>
  );
};

export default ImprintContent;
