import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import FurtherAwards from "../../common/FurtherAwards";
import Header from "../../common/Header";
 ;
import GuestBookContent from "../../content/GuestBookContent";
import MainContent from "../../content/MainContent";

const PageGuestbookT8: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent/>
        <GuestBookContent />
      </Section>
    </Grid>
    <FurtherAwards/>
    <Footer />
     
  </>
);

PageGuestbookT8.displayName = "PageGuestbookT8";
export { PageGuestbookT8 };
