import React, { Fragment, FunctionComponent, useContext } from "react";
import { Context, IContext, IAsset, getPage } from "src/common";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import UniversalComponentRenderer from "../../../BaseComponents/UniversalCompoentRenderer/UniversalComponentRenderer";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import { smallHeading } from "../../../constants/textStyles";
import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import * as styles from "./page.style";

const PageT6: FunctionComponent = () => 
{
  const props = useContext(Context);
  const page = getPage(props);
  const elementsWithoutMainAsset = props.Page?.elements?.filter(element => element?.__typename !== "Asset" && element?.__typename !== "ContinuingPageGallery" && element?.__typename !== "PhotoGallery");
  // @ts-ignore
  const universlElements = page?.elements?.filter(
        (e) => e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery"
    ) as any[];

  return (
    <Fragment>
      <Header />
      <div>
        <UniversalComponentRenderer items={elementsWithoutMainAsset} />
      </div>
      {(props.Page as any)?.connectedArticles?.map((article, i) => 
      {
        if(!article) 
        {
          return null;
        }

        const assets = article?.extras?.filter(
                    extra => extra?.__typename === "Asset"
                ) as IAsset[];

        return (
          <div css={`${styles.articleWrapper}${i % 2 === 1 ? styles.odd : styles.even}`} key={i}>
            <div css={centeredContent}>
              <div css={contentWrapper}>
                <div css={`${null}${styles.articleContent}`}>
                  {article?.headline && (
                    <h3 css={`${smallHeading}${styles.articleHeading}`}>{article?.headline}</h3>
                  )}
                  {article?.mainText && <RichText textContent={article?.mainText} />}
                </div>
                <div css={`${null}${styles.articleAssets}`}>
                  {assets?.map((asset, j) => (
                    <div key={j} title={asset.description} css={styles.assetWithSubtitle}>
                      <Img quality={500} src={asset?.src} alt={asset.description} />
                      {asset.description && <p>{asset.description}</p>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {props.Page?.footnote && (
        <div
          css={`
                        ${styles.footnote}
                        ${(props.Page as any)?.connectedArticles?.length % 2 === 0 ? styles.footnoteOdd : null}
                    `}
        >
          <div css={contentWrapper}>
            <div css={centeredContent}>
              <RichText overwriteStyle={styles.footnoteRichtext} textContent={props.Page.footnote} />
            </div>
          </div>
        </div>
      )}
      <div>
      <UniversalComponentsRenderer items={universlElements}></UniversalComponentsRenderer>
      </div>
      <Footer showLogos={false} />
    </Fragment>
  );
};

PageT6.displayName = "PageT6";
export { PageT6 };
