import React, { FC } from "react";

import Contact from "../../common/Contact";
import Map from "../../common/Map";
import { ContentContainer } from "./ContactContent.style";

const ContactContent: FC = () => (
  <ContentContainer>
    <Map />
    <Contact isHeader />
  </ContentContainer>
);

export default ContactContent;
