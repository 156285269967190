import dayjs from "dayjs";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useContext } from "react";
import {
  Context,
  DateFormatted,
  getPage,
  Img,
  IPageCourse,
  ITextElement,
  RichText,
} from "src/common";
import { IAsset } from "src/common/childTypes";
import Spacer from "../../../BaseComponents/Spacer/Spacer";
import { CenteredContent, PageContentWrapper } from "../MainContent/Slug.style";
import {
  Wrapper,
  CourseItemWrapper,
  CourseContentWrapper,
  CourseDateWrapper,
} from "./CourseContent.style";
import { CourseForm } from "./CourseForm/CourseForm";

export const CourseContent: FC<{
  courseId: string;
}> = ({ courseId }) => {
  const props = useContext(Context);
  const page = getPage(props) as IPageCourse;
  const courses = page?.courses;
  const course = courses?.find((c) => c?.id === courseId);
  const pageProps = useContext(Context);
  const palette: any = pageProps?.Settings;
  const primaryColor: any = pageProps?.Settings?.themeColorPrimary;
  const router = useRouter();

  return (
    <PageContentWrapper
      textColor={palette.themeTextColor}
      backgroundColor={palette.themeBackgroundColor}
    >
      <CenteredContent>
        <Wrapper>
          {course ? (
            <CourseForm course={course} />
          ) : (
            <>
              {courses?.map((currentCourse) => {
                const text = currentCourse.elements?.find(
                  (head) => head?.__typename === "TextElement"
                ) as ITextElement;
                const images = currentCourse.elements?.filter(
                  (head) => head?.__typename === "Asset"
                ) as IAsset[];
                return (
                  <CourseItemWrapper
                    primaryColor={primaryColor}
                    key={currentCourse.id}
                  >
                    <h3 className="color">{course?.nameInNavigation}</h3>
                    <Spacer bronze={28}></Spacer>
                    {currentCourse?.briefDescription && (
                      <>
                        <RichText
                          textContent={currentCourse?.briefDescription}
                        />
                        <Spacer bronze={28}></Spacer>
                      </>
                    )}
                    <div>
                      <CourseContentWrapper primaryColor={primaryColor}>
                        {images?.find((img) => img?.src && img?.id) ? (
                          <div className="imageWrapper">
                            {images?.map((image) => {
                              if (image && image.id && image.src) {
                                return (
                                  <div data-image key={image.id}>
                                    <Img src={image?.src} quality={720} />
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        ) : null}

                        <RichText fragment textContent={text?.text} />
                      </CourseContentWrapper>
                      <CourseDateWrapper primaryColor={primaryColor}>
                        <h3>
                          <DateFormatted date={currentCourse.startDate} />
                        </h3>
                        {(currentCourse.endDate && dayjs(currentCourse.startDate).date() !==
                          dayjs(currentCourse.endDate).date() || dayjs(currentCourse.startDate).month() !==
                          dayjs(currentCourse.endDate).month() || dayjs(currentCourse.startDate).year() !==
                          dayjs(currentCourse.endDate).year()) && (
                          <h3>
                            - <DateFormatted date={currentCourse.endDate} />
                          </h3>
                        )}

                        <p>
                          <strong>Beginn:</strong>{" "}
                          <DateFormatted
                            date={currentCourse.startDate}
                            format="HH:mm"
                          />{" "}
                          Uhr
                        </p>
                        <p>
                          <strong>Ende:</strong>{" "}
                          <DateFormatted
                            date={currentCourse.endDate}
                            format="HH:mm"
                          />{" "}
                          Uhr
                        </p>
                        <p>
                          <strong>Preis p.P.:</strong>
                          {currentCourse.pricePerParticipantInEuro} €
                        </p>
                        {currentCourse.kursNr ? (
                          <p>
                            {" "}
                            <strong>KursNr:</strong> {currentCourse.kursNr}
                          </p>
                        ) : null}
                        <p>
                          {" "}
                          <strong>Freie Plätze:</strong>{" "}
                          {currentCourse.maxParticipants}
                        </p>
                        <p>
                          <strong>Anmeldeschluss:</strong>{" "}
                          <DateFormatted
                            date={currentCourse.registrationDeadline}
                            format="DD.MM.YYYY HH:mm"
                          />{" "}
                          Uhr
                        </p>

                        <Link
                          href={
                            `${router.asPath}`.split("?")[0] +
                            "?course=" +
                            currentCourse.id
                          }
                          shallow={true}
                          passHref={true}
                        >
                          <a>Jetzt anmelden! </a>
                        </Link>
                      </CourseDateWrapper>
                    </div>
                    <Spacer bronze={56}></Spacer>
                  </CourseItemWrapper>
                );
              })}
            </>
          )}
        </Wrapper>
      </CenteredContent>
    </PageContentWrapper>
  );
};
