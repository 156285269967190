import React, { FunctionComponent } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

import MainContent from "../../content/MainContent";

const PageMicroT10: FunctionComponent = () => (
  <>
    <Header/>
    <MainContent micro/>
    <Footer />
     
  </>
);

PageMicroT10.displayName = "PageMicroT10";
export { PageMicroT10 };
