import { css } from "styled-components";

import colors from "../../../constants/colors";

export const wrapper: any = css`
    background-color: ${colors.lightGray};
`;

export const brands: any = css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > div {
      height: 35px;
      margin: 0 13px 18px;
      img {
        height: 100%;
        width: auto;
        object-fit: contain !important;
      }
    }
`;
