import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
    padding-bottom: 100px;
    min-height: 80vh;
`;

export const FormWrapper: any = styled.form<{color: string}>`
    color: ${({ color }) => color ? color : "#222"};
    display: grid;
    width: auto;
    padding: 0px 30px 40px 30px;
    margin-top: 40px;
  
    @media (${BREAKPOINTS_DM.silver_768})
    {
      margin: 100px 20% 0 0;
      padding: 0px 80px 100px 80px;
    }

    @media (${BREAKPOINTS_DM.gold_1024})
    {
      margin: 100px 20% 0 20%;
    }
`;

export const heading: any = css`
    p {
      margin: 10px 0 32px 0;
      letter-spacing: .02em;
    }
    span {
      font-size: 28px;
      font-weight: 600;
      line-height: 1.4;
      font-family: Helvetica,Arial,sans-serif;
    }
`;

export const formRow: any = css`
    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
      margin-bottom: 10px;
    }
    label {
      display: block;
      cursor: pointer;
    }
    input, select, textarea {
      width: 100%;
      height: 29px;
      padding: 3px 2px;
      display: block;
      border: 1px solid rgba(0,0,0,.3);
      //: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)) 1px;
      margin-bottom: 20px;
      &::placeholder {
        font-size: 13px;
      }
    }
    textarea {
      height: 100px;
      resize: vertical;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-bottom: 10px;
        flex-direction: row;
        justify-content: space-between;

        > div {
          width: 49%;
          margin-bottom: 0;
        }
    }
`;

export const fcWrapper = css`
    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 20px;
            margin-top: 0;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const formRowOneChild: any = css`
    display: block;
`;

export const labelRichtext: any = css`
    strong p {
      color: ${(props: any) => props.theme.palette.productBg1};
      font-weight: 400;
    }
`;

export const acceptPrivacyWrapper: any = css`
    display: flex;
    align-items: center;
    margin-top: 30px;
    input {
      margin-right: 8px;
    }
    label, a {
      font-size: 13px;
    }
    a {
      span { 
        color: ${props => props.theme.palette.guestbookPrimary};
      }
    }
`;

export const submitWrapper: any = css`
    flex-direction: column;

    @media (${BREAKPOINTS_DM.silver_768})
    {
      flex-direction: row;
      flex-wrap: wrap;
    }

    button {
      margin-top: 50px;
      font-size: 14px;
      font-weight: 600;

      > div {
        max-width: unset;
      }

      &:first-child {
        width: 100%;
        padding: 6px 0;
        margin-right: 0;
        background-color: ${(props: any) => props.theme.palette.guestbookPrimary}; //wouldnt this be better? #947294; instead of #d4c1d4;
        color: white;
        
        @media (${BREAKPOINTS_DM.silver_768})
        {
          width: unset;
          margin-right: 20px;
          padding: 6px 15%;
        }

        @media (${BREAKPOINTS_DM.gold_1024})
        {
          padding: 6px 20%;
        }
      }

      &:nth-child(2) {
        border: 1px solid green;
        color: black;
        margin-top: 15px;
        padding: 3px 0;
        width: 100%;

        &:hover {
          opacity: 0.6;
        }

        > div {
          margin-left: 0;

          > p > span {
            font-size: 12px;
            font-weight: 600;
          }
        }

        @media (${BREAKPOINTS_DM.silver_768})
        {
          width: unset;
          padding: 6px 6%;
          margin-top: 50px;

          > div > p > span {
            font-size: 14px;
          }
        }

        @media (${BREAKPOINTS_DM.gold_1024})
        {
          padding: 6px 10%;
        }
      }
    }
    label, a {
      cursor: pointer;
      font-size: 12px;
    }
    input {
      margin-right: 3px;
    }
    a {
      color: ${props => props.theme.palette.productPrime};
    }
`;

export const headlineWrapper: any = css`
    h1 {
      font-size: 24px;
      font-weight:600;
      text-transform: uppercase;
      margin: 0 0 10px 5%;

      @media (${BREAKPOINTS_DM.silver_768})
      {
        margin: 0 0 10px 20%;
      }
    }
`;

export const richtextStyle: any = css`
    font-size: 14px;
    font-weight: 300;
    margin-left: 5%;
    max-width: 60%;

    @media (${BREAKPOINTS_DM.silver_768})
      {
        margin-left: 20%;
      }
`;

export const submitted: any = css`
  margin: 0px 8%;
  font-family: Helvetica,Arial,sans-serif;

   h1 {
    margin-top: 15%;
    margin-bottom: 15px;
    font-weight: 600;
    line-height: 1.4;
    font-size: 24px;
   }

   > p {
    margin-bottom: 2rem;
   }

   button {
      color: white;
      text-align: center;
      width: 40%;

      > div {
        max-width: unset;
        margin: unset;
      }

      p {
        background-color: ${(props: any) => props.theme.palette.guestbookPrimary}; 
        width: 100%;

        span {
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0.08em;
        }
      }
    }

    @media (${BREAKPOINTS_DM.silver_768})
      {
        margin-left: 2%;

        h1 {
          margin-top: 6%;
        }

        button {
          width: 19%;
          > div {
            margin: 0;
          }
          p {
            padding: 4px 0px;
          }
        }
      }

    @media (${BREAKPOINTS_DM.gold_1024})
    {
      margin: 6% 30% 0 20%;

      button {
        width: 25%;
      }
    }
`;

export const labelAndError: any = css`
    display: flex;
    justify-content: space-between;

    .err-msg {
      color: ${(props: any) => props.theme.palette.productPrime};
    }
`;