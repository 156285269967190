import React, { Fragment, FunctionComponent } from "react";
import { GuestbookEntries } from "./guestbookEntries/GuestbookEntries";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import { SPageLayoutWrapper } from "../PageLanding";
import { css } from "styled-components";

const PageGuestbookT7: FunctionComponent = () => {
  return (
    <Fragment>
      <ResponsiveSidebar />
      <SPageLayoutWrapper>
        <div>
          <MainSidebarLeft />
        </div>
        <div
          css={css`
            width: 100%;
          `}
        >
          <MainAreaWrapper>
            <GuestbookEntries />
          </MainAreaWrapper>
        </div>
      </SPageLayoutWrapper>
    </Fragment>
  );
};
PageGuestbookT7.displayName = "PageGuestbookT7";
export { PageGuestbookT7 };
