import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
    margin-bottom: 40px;
`;

export const headingWrapper: any = css`
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const h3: any = css`
    color: ${props => props.theme.palette.red};
    font-weight: 700;
`;

export const svg: any = css`
    transform: scale(0.7);
    padding-left: 10px;
`;

export const isActive: any = css`
    overflow: hidden;
    height: auto;
    max-height: 2000px;
    transition: max-height 5s ease-out;
    ul {
        padding-left: 35px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: disc;
        }
    }
    strong ol li {
        font-weight: 700;
    }
    ul,
    ol {
        display: inline-block;
        text-align: left;
        li,
        span {
            text-align: left;
        }
    }
    ol {
        padding-left: 15px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: decimal;
        }
    }
    a {
        &,
        span {
            color: #d30032;
        }
        span {
            &::after {
                content: "";
                display: inline-block;
                background-image: url("/images/sprite-s82c5aa31b4.png");
                background-repeat: no-repeat;
                background-position: -20px -36px;
                width: 15px;
                height: 15px;
                margin-left: 5px;
                transform: translateY(3px);
            }
        }
    }
    h1,
    h2,
    h3,
    h4 {
        &,
        span {
            font-size: 14px;
            color: ${props => props.theme.palette.red};
            font-weight: 700;
        }
    }
    h3,
    h4 {
        &,
        span {
            font-weight: 400;
            letter-spacing: 0.02em;
            color: ${props => props.theme.palette.red};
            font-size: 1.2rem;
        }
    }
`;

export const isNotActive: any = css`
    overflow: hidden;
    height: auto;
    max-height: 0;
    transition: max-height 1s ease;
`;

export const imgAndText: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

export const imgAndSubtitleWrapper: any = css`
    @media (${BREAKPOINTS_DM.silver_768}) {
        margin-right: 20px;
    }

    display: flex;
    flex-direction: column;
`;

export const subTitle: any = css`
    &:not(:last-of-type) {
        margin-bottom: -20px;
    }
    background-color: ${props => props.theme.palette.trans50};
    padding: 10px;
    border-radius: 0 0 8px 8px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
`;
