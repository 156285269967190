import React, { FunctionComponent } from "react";
import { CommonPageBrandTopicMainContent } from "src/common/brand-area/PageBrandTopic/CommonPageBrandTopicMainContent";

const PageBrandTopicT0: FunctionComponent = () => {
  return (
    <>

      <CommonPageBrandTopicMainContent />
    </>
  );
};

PageBrandTopicT0.displayName = "PageBrandTopicT0";
export { PageBrandTopicT0 };
