import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css``;

export const PageContentWrapper = styled.div<{
  textColor: string;
  backgroundColor: string;
  primaryColor: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  color: ${({ textColor }) => (textColor ? textColor : "#222")};
  a {
    color: ${({ primaryColor, textColor }) => (primaryColor ? primaryColor :textColor ? textColor : "#222")};
  }
  position: relative;
  padding: 30px 0 60px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  > * {
    max-width: 1000px;
  }
  h1,
  h1 > span {
    font-weight: 600;
  }
  h2,
  h2 > span {
    font-weight: 900;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 13px;
  }
  ul {
    margin-bottom: 2rem;
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: disc;
    }
  }
  ol {
    margin-bottom: 2rem;
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: decimal;
    }
  }
  
  p,h1,h2,h3,h4,h5,h6{
    margin-bottom: 2rem;
  };

  /* ul, ol {
    margin-bottom: 1rem;
  } */
`;

export const flexWrapper: any = css`
  display: flex;
  flex-direction: column;
  > *:nth-child(2) {
    margin-top: 40px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    justify-content: space-between;
    > * {
      width: 48%;
    }
    > *:nth-child(2) {
      margin-top: 0;
    }
  }
`;

export const heading: any = css`
  font-size: 24px;
  display: block;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const mainText: any = css`
  font-size: 14px;
  strong p {
    margin-top: 20px;
  }
`;

// image not fixed //

export const mainImageNotFixed: any = css`
  margin-bottom: 25px;
  width: 100%;
`;

export const headingImageNotFixed: any = css`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 13px;
`;

export const article: any = css`
  margin-bottom: 50px;
  &:first-child {
    margin-top: 50px;
  }
  h3,
  h4 {
    font-weight: 600;
  }
  img {
    margin: 15px 15px 0 0;
    width: 90%;
    max-width: 300px;
  }
`;
