import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";

import React, { Fragment, FunctionComponent } from "react";

import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandT6: FunctionComponent = () => (
    <Fragment>
        <div>
            <Header noHeadComponent />
        </div>
        <Spacer bronze={80} />
        <CommonPageBrandMainContent />
        <Footer showLogos={false} />
    </Fragment>
);

PageBrandT6.displayName = "PageBrandT6";
export { PageBrandT6 };
