import React, { useContext, FC } from "react";
import { getRandomColor, IContext, Context, IThemingColor, IPage } from "src/common";

import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { PageMicroWrapper, ContentWrapper } from "../PageMicro/styles";
import { CommonPageBrandMicroMainContent } from "src/common/brand-area/PageBrandMicro/CommonPageBrandMicroMainContent";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandMicroT5: FC<any> = () => {
    const props = useContext<IContext>(Context);
    const pageData: IPage = props?.Page || (props?.PageBrandOverview as any);
    let color = (pageData?.elements?.find((e) => e?.__typename === "ThemingColor") as IThemingColor)?.color;

    if (!color) {
        color = getRandomColor();
    }
    return (
        <PageMicroWrapper>
            <Header />
            <ContentWrapper>
                <Spacer bronze={12} />
                <CommonPageBrandMicroMainContent />
                <FooterT5 />
            </ContentWrapper>
        </PageMicroWrapper>
    );
};
PageBrandMicroT5.displayName = "PageBrandMicroT5";
export { PageBrandMicroT5 };
