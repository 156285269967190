import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";

const PageT25: FC = () => (
  <>
    <Header />
    <MainContent />
    <Footer />
     
  </>
);

PageT25.displayName = "PageT25";
export { PageT25 };
