import React, { Fragment, FC, useState } from "react";
import { IAsset } from "src/common";

import FullscreenImage from "../../../common/FullscreenImage";
import RichText from "../../../common/RichText";
import * as styles from "./NewsArticleItem.style";
import { Wrapper, HeadingWrapper, ImgAndSubtitleWrapper, SubTitle } from "./NewsArticleItem.style";

interface IProps 
{
  headline: string;
  text: any;
  index: number;
  images: IAsset[];
}

const NewsArticleItem: FC<IProps> = props => 
{
  const [isActive, setIsActive] = useState(props?.index === 0);
  if(!props.headline || !props?.text) 
  {
    return null;
  }

  return (
    <Wrapper>
      <HeadingWrapper onClick={() => setIsActive(!isActive)}>
        <h3>{props.headline}</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
        </svg>
      </HeadingWrapper>

      <SubTitle>
        {!isActive && (
          <p>{props.text.content?.[0]?.children?.[0]?.text || ""}</p>
        )}
      </SubTitle>

      <div css={`${styles.imgAndText} ${isActive ? styles.isActive : styles.isNotActive}`}>
        <ImgAndSubtitleWrapper>
          {props.images?.map((asset: IAsset, i: number) => 
            (
              <Fragment key={`${i}${asset?.src}`}>
                {asset && (
                  <>
                    <FullscreenImage
                      img={[asset]}
                      subtitle={asset.description}
                    />
                    {asset.description && (
                      <p css={styles.subTitle}>
                        {asset.description}
                      </p>
                    )}
                  </>
                )}
              </Fragment>
            ))}
        </ImgAndSubtitleWrapper>
        <RichText
          css={isActive ? styles.isActive : styles.isNotActive}
          textContent={props?.text}
        />
      </div>

      <div
        css={`${isActive ? styles.isActive : styles.isNotActive}`}
        onClick={() => setIsActive(!isActive)}
      >
        <p>SCHLIESSEN</p>
        <svg
          style={{ transform: "rotate(180deg) scale(.7)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
        </svg>
      </div>
    </Wrapper>
  );
};

export default NewsArticleItem;
