import { useContext } from "react";
import { SDealerSearchBody } from "./styles/SDealerSearchBody";
import { SDealerSearchContainer } from "./styles/SDealerSearchContainer";
import { SDealerSearchTop } from "./styles/SDealerSearchTop";
import { useRouter } from "next/router";
import { Context } from "src/common";

import React from "react";
import { useDealerSearch } from "src/common/hooks/useDealerSearch";
import { css } from "styled-components";

const SearchSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      // @ts-ignore
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
  >
    <style>{".st10{fill:#3d3d3c}"}</style>
    <g id="Ebene_1">
      <path
        className="st10"
        d="m159 146-26-26c-3.9 6-9 11.2-15 15l26 26c4.2 4.2 10.9 4.2 15 0s4.1-10.9 0-15zM133.7 93.2c0-23.5-19-42.5-42.5-42.5s-42.5 19-42.5 42.5 19 42.5 42.5 42.5c23.5.1 42.5-19 42.5-42.5zm-42.5 31.9c-17.6 0-31.9-14.3-31.9-31.9 0-17.6 14.3-31.9 31.9-31.9 17.6 0 31.9 14.3 31.9 31.9 0 17.6-14.3 31.9-31.9 31.9zm0 0"
      />
      <path
        className="st10"
        d="M66.4 93.2h7.1c0-9.8 7.9-17.7 17.7-17.7v-7.1c-13.7 0-24.8 11.2-24.8 24.8zm0 0"
      />
    </g>
  </svg>
);

export function MapSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 212.6 212.6"
      style={{
        // @ts-ignore
        enableBackground: "new 0 0 212.6 212.6",
      }}
      xmlSpace="preserve"
    >
      <style>{".st10{fill:#3d3d3c}"}</style>
      <g id="Ebene_1">
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={209.394}
          y1={148.836}
          x2={140.635}
          y2={93.418}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#fff",
              stopOpacity: 0,
            }}
          />
          <stop
            offset={0.218}
            style={{
              stopColor: "#c6c6c7",
              stopOpacity: 0.2184,
            }}
          />
          <stop
            offset={0.428}
            style={{
              stopColor: "#919291",
              stopOpacity: 0.4281,
            }}
          />
          <stop
            offset={0.617}
            style={{
              stopColor: "#656564",
              stopOpacity: 0.617,
            }}
          />
          <stop
            offset={0.781}
            style={{
              stopColor: "#424241",
              stopOpacity: 0.7812,
            }}
          />
          <stop
            offset={0.914}
            style={{
              stopColor: "#222221",
              stopOpacity: 0.9144,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#000",
            }}
          />
        </linearGradient>
        <path
          style={{
            opacity: 0.2,
            fill: "url(#SVGID_1_)",
          }}
          d="M131.8 66.6 97.1 34.7l33.7 95.3 81.1 80.1.2-104.3-64.4-65.9z"
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={254.116}
          y1={245.7}
          x2={-7.09}
          y2={106.421}
        >
          <stop
            offset={0}
            style={{
              stopColor: "#fff",
              stopOpacity: 0,
            }}
          />
          <stop
            offset={0.224}
            style={{
              stopColor: "#c4c5c5",
              stopOpacity: 0.2236,
            }}
          />
          <stop
            offset={0.431}
            style={{
              stopColor: "#909190",
              stopOpacity: 0.4312,
            }}
          />
          <stop
            offset={0.618}
            style={{
              stopColor: "#646464",
              stopOpacity: 0.6184,
            }}
          />
          <stop
            offset={0.781}
            style={{
              stopColor: "#424241",
              stopOpacity: 0.7815,
            }}
          />
          <stop
            offset={0.914}
            style={{
              stopColor: "#212121",
              stopOpacity: 0.9143,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#000",
            }}
          />
        </linearGradient>
        <path
          style={{
            opacity: 0.2,
            fill: "url(#SVGID_2_)",
          }}
          d="m87.4 157-20.9 25.5 31.3 29.8 114.8.2-.2-1.7-81.6-80.8-73.3-3.5z"
        />
        <path
          className="st10"
          d="m111.3 139 28.4-28.4M155.5 77.3l-3.5-3.7-1-4.7h3.1c0-.1.1-.3.1-.4.3-6.8-.1-22.2-.1-22.2l-6.6-6.5H141l-18.5 7.6-16.2-12.6-17.8-2.3 5.8 18-10.6 5.9-5.7-6-8 2.7.1 11.1-4.8 6.8.1 15.3L53.6 94 50 111.7s3.2 6.9 3.2 8.2c.2 5.6 0 11.2.1 16.8 0 1 .8 2.9.8 2.9l20.9 9.3.3 4.3-9.6 29.3h21.9l4.1-4.3h7.9l1.1 4.3h9.1l30 .1v-4.2l-4.8-5.8 9.2-12.7-.1-16.3-13.3-13.6-.4-5.5s13.7.1 19.1 0c1.3 0 2.8-.6 3.7-1.5 3.2-2.9 9.3-9.2 9.3-9.2v-10.7l-7-25.8zM61.8 104c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9s1.9.8 1.9 1.9c0 1.1-.9 1.9-1.9 1.9zm24 23.5c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9s1.9.8 1.9 1.9c-.1 1-.9 1.9-1.9 1.9zm22.3-68.4c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9s1.9.8 1.9 1.9c0 1.1-.8 1.9-1.9 1.9zm12.1 112.3c-1 0-1.9-.8-1.9-1.9s.8-1.9 1.9-1.9 1.9.8 1.9 1.9-.8 1.9-1.9 1.9zm19.5-88.9c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9s1.9.8 1.9 1.9c0 1.1-.8 1.9-1.9 1.9zm13 32.9c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9s1.9.8 1.9 1.9c0 1.1-.8 1.9-1.9 1.9z"
        />
      </g>
    </svg>
  );
}

export const DachseiteSearchContact = () => {
  const props = useContext(Context);
  const router = useRouter();
  const { triggerSearch } = useDealerSearch();

  return (
    <>
      <SDealerSearchContainer>
        <div
          css={css`
            flex-grow: 0;
            flex-shrink: 1;
            background-color: #fed061;
            flex-basis: calc(50% - 13.5px);
            margin-right: 13.5px;
            margin-bottom: 13.5px;
            @media (max-width: 768px) {
              margin-right: 0;
              flex-basis: calc(100%);
            }
          `}
        >
          <MapSVG></MapSVG>
        </div>
        <div
          css={css`
            flex-grow: 0;
            flex-shrink: 1;
            margin-bottom: 13.5px;
            flex-wrap: nowrap;
            flex-basis: calc(50%);
            @media (max-width: 768px) {
              flex-basis: calc(100%);
            }
          `}
        >
          <div
            css={css`
              height: calc(50% - 6.75px);
              flex-grow: 0;
              flex-shrink: 1;
              margin-bottom: 13.5px;
              border: 2px solid #fed061;
              text-transform: uppercase;
              flex-wrap: wrap;
              text-align: center;
              padding: 27px;
            `}
          >
            <div
              css={css`
                height: 100%;
              `}
            >
              <div
                css={css`
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                `}
              >
                <p
                  css={css`
                    font-size: 1.2rem;
                    width: 100%;
                    margin-bottom: 13.5px;
                  `}
                >
                  Zuhause ist <br />
                  es doch am besten!
                </p>
                <p
                  css={css`
                    font-weight: 700;
                    font-size: 2.5rem;
                    margin-bottom: 13.5px;
                  `}
                >
                  Wir kommen <br />
                  von{" "}
                  <span
                    css={css`
                      font-weight: 700;
                      font-size: 2.5rem;
                      margin-bottom: 13.5px;
                      color: #008bc5;
                    `}
                  >
                    hier.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            css={css`
              height: calc(50% - 6.75px);
              background-color: #fed061;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 27px;
            `}
          >
            <form
              css={css`
                width: 100%;
                max-width: 700px;
                padding-left: 13.5px;
                padding-right: 13.5px;
              `}
              onSubmit={(e) => {
                e.preventDefault();
                triggerSearch((e.target as any)?.elements?.[0]?.value);
              }}
            >
              <SDealerSearchTop>
                <div>
                  <input
                    css={css`
                      color: #3c3c3b;
                      width: 100%;
                      outline: 0;
                      border: 0;
                      border-radius: 0;
                      box-shadow: none;
                      line-height: 30px;
                      font-size: 23px;
                      padding: 1.5rem 0.5rem 1.5rem 2.5rem;
                      font-weight: 300;
                    `}
                    name="place"
                    type={"text"}
                    placeholder={
                      (router.query?.search as any) || "PLZ oder Ort eingeben"
                    }
                  />
                  <div>
                    <span></span>
                    <button type="submit">
                      <SearchSVG></SearchSVG>
                    </button>
                  </div>
                </div>
              </SDealerSearchTop>
              <SDealerSearchBody>
                Finden Sie Ihren nächstgelegenen Hausgerätespezialisten!
              </SDealerSearchBody>
            </form>
          </div>
        </div>
      </SDealerSearchContainer>
    </>
  );
};
