import React, { FunctionComponent } from "react";
import Header from "../../common/header/Header";
import { FooterT3 } from "../../common/footer/Footer";
import MainContent from "../../common/MainContent";
import { Banner } from "../../common/Banner/Banner";

import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";

const PageBrandOverviewOldT3: FunctionComponent = () => {
  return (
    <>
      <Banner />
      <PlanningDateModal />
      <Header />
      <MainContent />
      <FooterT3 />
    </>
  );
};

PageBrandOverviewOldT3.displayName = "PageBrandOverviewOldT3";
export { PageBrandOverviewOldT3 };
