import { css } from "styled-components";

export const wrapper: any = css`
  padding: 0 !important;
  > div {
    border: solid 1px ${props => props.theme.secondary};
    padding: 27px;
  }
`;

export const imprintRichtext: any = css`
    h2 {
      font-size: 30px;
      color: ${props => props.theme.primary};
    }
    h3 {
      font-size: 24px;
      color: ${props => props.theme.primary};
    }
    strong p {
      margin-top: 12px;
      display: block;
    }
    p {
      margin-bottom: 10px;
      display: block;
    }
    a {
      display: inline-block;
      color: ${props => props.theme.primary};
    }
    ul {
      padding-left: 35px;
      list-style-position: inside;
      * {
        list-style-position: inside;
      }
      li {
        display: list-item;
        list-style: disc;
      }
    }
    strong ol li {
      font-weight: 700;
    }
    ol {
      padding-left: 15px;
      list-style-position: inside;
      * {
        list-style-position: inside;
      }
      li {
        display: list-item;
        list-style: decimal;
      }
    }
`;

export const additionalInfos: any = css`
    p {
      margin-bottom: 0;
    }
`;
