import React, { FunctionComponent, Fragment, useRef, useEffect, useState, useContext } from "react";
import {
  Context, extrasMapper, getPage, ITextElement, IAsset, ImprintStart, ISlider, Img 
} from "src/common";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import FadeSlider from "src/common/components/FadeSlider/Slider";
import { ImprintNameAndAddressOfThePersonInCharge } from "src/common/components/ImprintNameAndAddressOfThePersonInCharge";
import { IIcon } from "src/common/rootTypes";
import Articles from "../../Articles/Articles";
import RichText from "../../../BaseComponents/RichText/RichText";
import SRichTextGeneral from "../../../BaseComponents/RichText/Styles/SRichTextGeneral";
import { AccordeonContent } from "../../AccordeonContent/AccordeonContent";
import Micro from "../Micro";
import ScrollTopButton from "../scrollTopButton/ScrollTopButton";
import * as styles from "./MainContent.style";
import {
  ContentContainer,
  HeadlineWrapper,
  SliderWrapper,
  ContentWrapper,
  ImageInBox,
} from "./MainContent.style";
import { OneColumn } from "./OneColumn";
import { TwoColumn } from "./TwoColumn";

export const MainContent: FunctionComponent<any> = (props: any) => 
{
  useEffect(() => 
  {
    if(typeof window !== "undefined" && window.location.hash && typeof document !== "undefined") 
    {
      const searchTream = `${window.location.hash}`.replace("#", "");
      const xpath = `//span[contains(text(),'${searchTream}')]`;
      const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)?.singleNodeValue;
      if(matchingElement) 
      {
        // @ts-ignore
        matchingElement.scrollIntoView();
      }
    }
  }, []);
  const p = useContext(Context);
  const page = getPage(p);
  // @ts-ignore
  const slider = page?.elements?.filter((e) => e?.__typename === "Slider") as ISlider[];
  const sliderElements = (slider && !!slider?.[0]?.elements) ? slider?.[0]?.elements : [];
  // @ts-ignore
  const textElements = page?.elements?.filter((e) => e?.__typename === "TextElement") as ITextElement[];
  // @ts-ignore
  const assetElements = page?.elements?.filter(
        (e) => e?.__typename === "Asset" && !!(e as IAsset)
    ) as IAsset[];
    // @ts-ignore
  const icon = page?.elements?.find((e) => e?.__typename === "Icon") as IIcon;
  const imgArray = extrasMapper(p?.Page?.elements, "Asset");
  const [hasExcerpt, setHasExcerpt] = useState(false);
  const contentRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const updateWindowWidth = (): void => setWindowWidth(window.innerWidth);
  // @ts-ignore
  const universlElements = page?.elements?.filter(
        (e) => e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery"
    ) as any[];

  useEffect(() => 
  {
    updateWindowWidth();
    window.addEventListener("resize", updateWindowWidth, { passive: true });

    return () => window.removeEventListener("resize", updateWindowWidth);
  }, []);

  useEffect(() => 
  {
    if(imgArray[0]) 
    {
      if(windowWidth !== 0 && contentRef?.current?.clientHeight > 360 && windowWidth > 768) 
      {
        setHasExcerpt(true);
      }

      if(windowWidth !== 0 && windowWidth < 768) 
      {
        setHasExcerpt(false);
      }
    }
  }, [windowWidth, imgArray]);
  return (
    <Fragment>
      <ContentContainer>
        <HeadlineWrapper>
          {icon && <img alt={""} css={styles.image} key={icon.file?.[0]?.src} src={icon.file?.[0]?.src} />}
          {textElements?.map((e, i) => (
            <h1 key={`h1${i}`} css={styles.h1}>
              {e?.headline}
            </h1>
          ))}
        </HeadlineWrapper>
        {sliderElements.length > 0 &&
          <SliderWrapper>
            <FadeSlider elements={sliderElements} />
          </SliderWrapper>
        }
        {props.micro && <Micro />}
        <ImprintStart />
        {!(!p?.PageImprint?.slug || p?.PageImprint?.slug === p?.slug) &&
          <>
            {
              page?.twoColumnLayout ? (
                <TwoColumn bottom={props.bottom}>
                  {Array.isArray(props.children) ? props.children?.[1] : props.children}
                </TwoColumn>
              ) : (
                <OneColumn />
              )
            }
            {props.children?.[1]}
          </>
        }
        <div>
          {textElements?.map((e, i) => (
            <Fragment key={`ey${i}`}>
              {(i !== 0 || (!p?.PageImprint?.slug || p?.PageImprint?.slug === p?.slug)) && e?.text && (
                <ContentWrapper>
                  <div css={SRichTextGeneral}>
                    {e?.icon?.[0] && (
                      <ImageInBox>
                        {e?.icon.map((f, i) => 
                          <Fragment key={`fi${i}`}>
                            <Img
                              quality={700}
                              src={f?.src}
                              alt={f?.description || "icon"}
                            />
                          </Fragment>)}
                      </ImageInBox>
                    )}
                    <RichText textContent={e?.text} fragment overwriteStyle={SRichTextGeneral} />
                  </div>
                </ContentWrapper>
              )}
              {i === 0 && <ImprintNameAndAddressOfThePersonInCharge />}
            </Fragment>
          ))}
        </div>
        {!(!p?.PageImprint?.slug || p?.PageImprint?.slug === p?.slug) &&
          <div>
            <UniversalComponentsRenderer items={universlElements} />
          </div>
        }
      </ContentContainer>
      {p.PageNews?.slug === p.slug && <AccordeonContent />}
      <ScrollTopButton />
    </Fragment>
  );
};
