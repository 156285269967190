import styled from "styled-components";
import { BREAKPOINTS, BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const ContentContainer: any = styled.div`
  padding: 10px;

  @media (${BREAKPOINTS_DM.gold_1024}){
    padding: 106px 10px 63px 10px;
  }

  p, span, h1, h2, h3, h4 {
    font-weight: 300;
  }

  h2 {
    color: ${({ theme }) => theme?.palette.green};
    font-size: 20px;
  }
`;