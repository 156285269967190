import styled, { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const map: any = css`
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
`;

export const directionsPanel: any = css`
    margin-top: 30px;
    table:not(.adp-placemark)
    {
        tr {
              td {
                padding: 8px 0;
                border-top-width: 1px;
                line-height: 150%;
                &:first-child {
                  padding-left: 5px;
                  width: 10% !important;
                }
                &:nth-child(2) {
                  width: 5% !important;
                  font-weight: 500;
                  font-size: 14px;
                }
                &:nth-child(3) {
                  width: 70% !important;
                  font-weight: 500;
                }
                &:nth-child(4) {
                  width: 15% !important;
                }
          }
      }
    }
`;

export const Wrapper: any = styled.div<{color: string}>`
    color: ${({ color }) => color ? color : "#222"};
    button{
      &:not(.notheme){
        color: ${({ color }) => color ? color : "#222"};
        border: solid ${({ color }) => color ? color : "#222"} 1px;
      }
    }
    margin-top: 80px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        justify-content: space-between;
        > div {
          width: 48%;
        }
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        display: block;
        > div {
          &:first-child {
            width: 100%;
          }
        }
    }
`;

export const companyInfosAndContactForm: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        > div {
          width: 50%;
          &:nth-child(2) {
            padding-right: 40px;
          }
        }
        flex-direction: row-reverse;
    }
`;
export const openingHoursHeading: any = css`
    color: ${props => props.theme.primary};
`;
export const openingHoursRichtext: any = css`
    table {
      width: 100%;
    }
    td{
      width: auto;
    }
    p{
      min-height: 18px;
    }
    td,p, span{
          height: 18px;
    }
    td:nth-child(2), td:nth-child(3)  {
          text-align: right;
    }
    h1,h2,h3,h4,h5,h6 {
      color: ${props => props.theme.primary};
    }
`;
export const companyInfosWrapper: any = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
      width: 47%;
    }
    div > img {
      width: 100%;
      height: auto;
    }
`;

export const directions: any = css`
    h3, h4, h5, h6 {
      font-size: 16px;
      font-weight: 600;
    }
`;

export const addressWrapper: any = css`
    h1,h2,h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.375;
    }
    img {
      width: 100%;
      height: auto;
    }
`;

export const catchmentArea: any = css`
    margin-top: 20px;
    strong {
      > p {
        margin-top: 20px;
      }
    }
`;

export const closeDirectionsPanelSvg: any = css`
     width: 20px;
     fill: ${colors.brown};
     cursor: pointer;
     display: none;
     @media (${BREAKPOINTS_DM.gold_1024})
     {
         display: block;
     }
`;

export const contentArea_directionsPanel: any = css`
    position: relative;
    margin-top: 60px;
    h6 {
      color: ${colors.brown};
      margin-bottom: 20px;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-top: 0;
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        position: fixed;
        background-color: white;
        z-index: 50;
        padding: 14px 10px;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
        width: unset !important;
        display: flex;
        max-width: 500px;
        max-height: 70vh;
        overflow-y: auto;
        top: 20vh;
        right: 0;
        flex-direction: column;
    }
`;

export const locationInputWrapper: any = css`
    position: relative;
    input {
      padding: 6px 35px 6px 12px;
      width: 100%;
      height: 100%;
      border: solid black 1px;
    }
    svg {
      opacity: .7;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 7px;
    }
`;

export const routeBerechnenWrapper: any = css`
    display: flex;
    flex-direction: column;
`;

export const buttonLocationInputWrapper: any = css`
    display: flex;
    justify-content: space-between;
    > * {
      width: 47%;
    }

    button {
      border: solid black 1px;
      padding: 3px;
      &:hover {
        opacity: .7;
      }
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        display: block;
        > * {
          width: 100%;
        }
        button {
          margin-top: 13px;
        }
    }
`;