import React, { FC } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import ContactContent from "../../content/ContactContent";

const PageContactT25: FC = () => (
  <>
    <Header />
    <ContactContent />
    <Footer />
     
  </>
);

PageContactT25.displayName = "PageContactT25";
export { PageContactT25 };
