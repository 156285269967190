import React, { FC } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import ImprintContent from "../../content/ImprintContent";

const PageImprintT24: FC = () => (
  <>
    <Header />
    <ImprintContent/>
    <Footer />
     
  </>
);
PageImprintT24.displayName="PageImprintT24";
export { PageImprintT24 };
