import * as React from "react";

import { AccordeonContent } from "../../common/AccordeonContent/AccordeonContent";
import { Banner } from "../../common/Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import MainNavigation from "../../common/MainNavigation/MainNavigation";

const PageNewsT1 = () => 
  (
    <>
      <MainNavigation/>
      <MainContent/>
      <AccordeonContent/>
      <Banner/>
      <Footer/>
    </>
  );
PageNewsT1.displayName = "PageNewsT1";
export { PageNewsT1 };
