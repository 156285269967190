import React, { FC, useContext } from "react";

import {
  IContext,
  Context,
  getPage,
  ITextElement,
  ISidebarText,
} from "src/common";

import { Container, Section } from "../../common/Grid";
import RichTextGeneral from "../../common/RichText/";
import SubNavRight from "../../common/SubNavRight";
import TextBoxRight from "../../common/TextBoxRight";
import {
  Wrapper,
  ContentWrapper,
  BoxedContent,
  SideBarWrapper,
  ElementsWrapper,
} from "./GuestBookContent.style";

const GuestBookContent: FC = (): any => {
  const pageProps = useContext<IContext>(Context);

  const page = getPage(pageProps);

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) => e?.__typename === "TextElement"
  ) as ITextElement[];

  // const vorname = universalTexts?.guestbook__firstname || "Vorname";
  // const nachname = universalTexts?.guestbook__lastname || "Nachname";
  // const email = universalTexts?.guestbook__email || "E-Mail";
  // const titel = universalTexts?.guestbook__title || "Titel";
  // const nachricht = universalTexts?.guestbook__message || "Nachricht";
  // const bewertungAbgeben = universalTexts?.gestbook__evaluation || "Bewertung abgeben";
  const navItem = pageProps?.PrimaryNavigation?.items?.find(
    (i) =>
      !!i?.subitems?.find((s) => s?.slug === pageProps.slug) ||
      i?.link?.[0]?.slug === pageProps.slug
  );
  const sideBarText = page?.extras?.find(
    (e) => e?.__typename === "SidebarText"
  ) as ISidebarText;

  return (
    <Wrapper>
      <Container>
        <Section>
          {mixedElements?.map((element, i) => (
            <BoxedContent key={`text-${i}`}>
              <RichTextGeneral textContent={element.text}>
                <h2>{element.headline}</h2>
              </RichTextGeneral>
            </BoxedContent>
          ))}
          <ContentWrapper>
            <ElementsWrapper></ElementsWrapper>
            {(navItem || sideBarText) && (
              <SideBarWrapper>
                <SubNavRight />
                <TextBoxRight text={sideBarText?.text} />
              </SideBarWrapper>
            )}
          </ContentWrapper>
        </Section>
      </Container>
    </Wrapper>
  );
};

export default GuestBookContent;
