import React, { useContext, FC } from "react";
import {
  getRandomColor,
  IContext,
  Context,
  IThemingColor,
  IPage,
} from "src/common";

import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import Micro from "../../common/Micro";
import { PageMicroWrapper, ContentWrapper } from "./styles";

const PageMicroT5: FC<any> = () => 
{
  const props = useContext<IContext>(Context);
  const pageData: IPage = props?.Page || (props?.PageBrandOverview as any);
  let color = (pageData?.elements?.find((e) => e?.__typename === "ThemingColor") as IThemingColor)?.color;
 
  if(!color) 
  {
    color = getRandomColor();
  }
  return (
    <PageMicroWrapper>
      <Header/>
      <ContentWrapper>
        <Micro />
        <FooterT5 />
      </ContentWrapper>
    </PageMicroWrapper>
  );
};
PageMicroT5.displayName = "PageMicroT5";
export { PageMicroT5 };
