import { Context, IContext, ImprintStart } from "src/common";
import React, { Fragment, FunctionComponent, useContext } from "react";

import RichText from "../../../BaseComponents/RichText/RichText";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { ITextElement } from "../../elements/TextElement/TextElement";
import * as styles from "./pageImprint.style";
import { ImprintNameAndAddressOfThePersonInCharge } from "src/common/components/ImprintNameAndAddressOfThePersonInCharge";

const PageImprintT6: FunctionComponent = () => {
    React.useEffect(() => {
        if (typeof window !== 'undefined' && window.location.hash && typeof document !== 'undefined') {
            const searchTream = `${window.location.hash}`.replace('#', '');
            const xpath = `//span[contains(text(),'${searchTream}')]`;
            const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)?.singleNodeValue;
            if (matchingElement) {
                //@ts-ignore
                matchingElement.scrollIntoView();
            }
        }
    }, []);
    const props = { ...useContext<IContext>(Context), ...useContext<IContext>(Context) };

    const companyInformationPublic = props?.CompanyInformationPublic;
    const pageImprint = props?.PageImprint;
    const universalTexts = props?.UniversalTexts;
    const universalImprintTexts: string[] = [];

    for (const imprintText in universalTexts) {
        if (imprintText.includes("imprint")) {
            universalImprintTexts.push(universalTexts[imprintText]);
        }
    }

    const textElements = (pageImprint?.elements?.filter(
        element => element?.__typename === "TextElement"
    ) as unknown) as ITextElement[];
    return (
        <Fragment>
            <Header />
            <div css={styles.wrapper}>
                <div css={centeredContent}>
                    <div css={contentWrapper}>
                        <div css={`${styles.imprintRichtext}${styles.additionalInfos}`}>
                            <h1 >{textElements?.[0]?.headline}</h1>
                            <ImprintStart />
                            {textElements.map((e, i) => (
                                <Fragment key={`ey${i}`}>
                                    {e?.text && (
                                        <RichText textContent={e?.text} overwriteStyle={styles.imprintRichtext} />
                                    )}
                                    {i === 0 &&
                                        <ImprintNameAndAddressOfThePersonInCharge />
                                    }
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer showLogos={false} />
        </Fragment>
    );
};

PageImprintT6.displayName = "PageImprintT6";
export { PageImprintT6 };
