import React, { FC } from "react";
import { Grid, Section } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import GuestBookContent from "../../content/GuestBookContent";

const PageGuestbookT9: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <GuestBookContent />
      </Section>
    </Grid>
    <Banner />
    <Footer />
     
  </>
);

PageGuestbookT9.displayName = "PageGuestbookT9";
export { PageGuestbookT9 };
