import React, { FC, useContext } from "react";
import { Context, DateFormatted, getPage, Img, IPageCourse, ITextElement } from "src/common";
import { ICourse } from "src/common/childTypes";
import RichTextGeneral from "../../../common/RichText";
import { CourseFormWrapper, CourseContentWrapper, InputWrapper, CourseDateWrapper } from "./CourseForm.style";

const CourseForm: FC<{ course: ICourse; onSetCourse: (ICourse) => void }> = ({ course, onSetCourse }) => {
  const text = course.elements?.find(head => head?.__typename === "TextElement") as ITextElement;

  return (
    <CourseFormWrapper key={course.id}>
      <h3>{text.headline}</h3>
      <p onClick={() => onSetCourse(null)} className="back-btn">Zurück zur Übersicht</p>&nbsp;
      <CourseDateWrapper>
        <div><b className="principal"><DateFormatted date={course.startDate} /></b></div>
        <span>Beginn: <DateFormatted date={course.startDate} format="HH:mm" /> Uhr&nbsp;|&nbsp;</span>
        <span>Ende: <DateFormatted date={course.endDate} format="HH:mm" />  Uhr&nbsp;|&nbsp;</span>
        <span>Preis p.P.: {course.pricePerParticipantInEuro} €&nbsp;|&nbsp;</span>
        <span>Freie Plätze: {course.maxParticipants}&nbsp;|&nbsp;</span>
        <span>
          Anmeldeschluss: <DateFormatted date={course.registrationDeadline} format="MM.DD.YYYY hh:mm" />
        </span>
      </CourseDateWrapper>

      <h2>Hiermit melde ich mich verbindlich zur Veranstaltung &quot;<span>{text.headline}</span>&quot; an:</h2>
      <form>
        <InputWrapper>
          <label htmlFor="firstname">Vorname</label>
          <input id="firstname" name="firstname" minLength={2} type="text" placeholder="Vorname" required />
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="lastname">Nachname</label>
          <input id="lastname" name="lastname" minLength={2} type="text" placeholder="Nachname" required/>
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="street">Straße</label>
          <input id="street" name="street" minLength={2} type="text" placeholder="Straße" required />
        </InputWrapper>

        <InputWrapper>
          <div>
            <InputWrapper md={4}>
              <label htmlFor="zipcode">PLZ / Ort</label>
              <input id="zipcode" name="zipcode" minLength={2} type="text" placeholder="PLZ" required/>
            </InputWrapper>

            <InputWrapper md={8}>
              <label htmlFor="city">&nbsp;</label>
              <input id="city" name="city" minLength={2} type="text" placeholder="Ort" required/>
            </InputWrapper>
          </div>
        </InputWrapper>

        <InputWrapper>
          <div>
            <InputWrapper md={6}>
              <label htmlFor="telephone">Telefon</label>
              <input id="telephone" type="text" name="telephone" placeholder="Telefon" />
            </InputWrapper>

            <InputWrapper md={6}>
              <label htmlFor="email">E-Mail</label>
              <input id="email" type="email" name="email" placeholder="Email" />
            </InputWrapper>
          </div>
        </InputWrapper>


        <InputWrapper>
          Ich möchte insgesamt&nbsp;
          <select id="num_participants" name="num_participants" size={1}>
            <option value="1">1 Person</option>
            <option value="2">2 Personen</option>
            <option value="3">3 Personen</option>
            <option value="4">4 Personen</option>
            <option value="5">5 Personen</option>
          </select>
          &nbsp;anmelden.
        </InputWrapper>

        <InputWrapper>
          Betrag: {course.pricePerParticipantInEuro}
        </InputWrapper>

        <InputWrapper>
          <input type="checkbox" id="want_contacting" name="want_contacting" />
          &nbsp;Über weitere Angebote möchte ich in Zukunft&nbsp;
          <select name="contacting" id="contacting">
            <option value="email">per Email</option>
            <option value="telephone">per Telefon</option>
            <option value="telefax">per Fax</option>
          </select>
          &nbsp;informiert werden.
        </InputWrapper>

        <InputWrapper>
          <input type="checkbox" value="Akzeptiert" name="Betrag" required />
          &nbsp;Der Betrag wird nach Rechnungsstellung innerhalb von 8 Tagen überwiesen.
        </InputWrapper>

        <InputWrapper>
          <input type="checkbox" id="privacy_acknowledgement" value="true" name="privacy_acknowledgement" required />
          &nbsp;Die <a href="/impressum">AGB und die Datenschutzerklärung</a> von Lipp Küchen  (Layout GK D - Buche) habe ich gelesen und erkenne diese für verbindlich an. Preise verstehen sich inkl. der gesetzlichen MwSt.
        </InputWrapper>

        <button type="submit">Absenden</button>
      </form>
    </CourseFormWrapper>
  );
};

export default CourseForm;
