import React, { FunctionComponent } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

import MainContent from "../../content/MainContent";

const PageMicroT17: FunctionComponent = () => (
  <>
    <Header/>
    <MainContent micro/>
    <Footer />
     
  </>
);

PageMicroT17.displayName = "PageMicroT17";
export { PageMicroT17 };
