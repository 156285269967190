import React, { Fragment, FunctionComponent } from "react";

import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import Micro from "../../common/Micro";

const PageMicroT6: FunctionComponent = () => 
{
  return (
    <Fragment>
      <Header />
      <Micro />
      <Footer showLogos={false} />
    </Fragment>
  );
};

PageMicroT6.displayName = "PageMicroT6";
export { PageMicroT6 };
