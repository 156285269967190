import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const imageWrapper: any = css`
    margin: 0 5% 25px 5%;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        margin: 0 20% 25px 20%;
    }
`;

export const imageGalleryWrapper: any = css`
// TODO what of we have multiple pictures?
`;