
import { CommonPageBrandTopicMainContent } from "src/common/brand-area/PageBrandTopic/CommonPageBrandTopicMainContent";

import React, { FunctionComponent } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PageBrandTopicT23: FunctionComponent = () => 
  (
    <>
      <Header />
      <CommonPageBrandTopicMainContent />
      <Footer />
    </>
  );

PageBrandTopicT23.displayName = "PageBrandTopicT23";
export { PageBrandTopicT23 };
