import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const MapWrapper: any = styled.div`
  padding: 21px 10px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(50% - 10px);
    padding: 0;
    margin-right: 10px;
  }
`;

export const MapContainer: any = styled.div`
    height: 300px;

    margin-bottom: 20px;

    @media (${BREAKPOINTS_DM.silver_768}) {
      margin: 0 0 20px 0;
    }
`;

export const serviceNumber: any = css`
    margin-bottom: 50px;
    align-items: flex-start;
    padding-left: 10px;
`;

export const RouteWrapper: any = styled.div`
    margin: 30px 10px;

    label {
      font-weight: 700;
      line-height: 1.7em;
    }

    > div {
      display: flex;

      button {
        width: 50%;
        margin-left: 14px;
        background-color: ${props => props.theme.palette.textColor};
        color: white;
        padding: 15px;
        &:hover {
          background-color: ${props => props.theme.palette.primary};
        }
      }
    }
    input {
    }
`;

export const LocationContainer: any = styled.div`
padding: 10.5px;
p {
  }
`;

export const LocationInput: any = styled.div`
    position: relative;
    margin-right: 14px;
    width: 50%;
    input {
      padding: 6px 35px 6px 12px;
      width: 100%;
      height: 100%;
    }
    svg {
      opacity: .5;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 7px;
    }
`;

export const DirectionsPanel: any = styled.div`
    margin-top: 30px;
    table:not(.adp-placemark)
    {
        tr {
              td {
                padding: 8px 0;
                border-top-width: 1px;
                border-top-color: cdcdcd;
                line-height: 150%;
                &:first-child {
                  padding-left: 5px;
                  width: 10% !important;
                }
                &:nth-child(2) {
                  width: 5% !important;
                  font-weight: 500;
                  font-size: 14px;
                }
                &:nth-child(3) {
                  width: 70% !important;
                  font-weight: 500;
                }
                &:nth-child(4) {
                  width: 15% !important;
                }
          }
      }
    }
`;

export const catchmentArea: any = css`
`;
