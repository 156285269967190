import React, { FunctionComponent } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import ContentWrapper from "../../common/ContentWrapper";
import Footer from "../../common/Footer";

import MainContent from "../../content/MainContent";

const PageCourseT12: FunctionComponent = () =>
  (
    <ContentWrapper>
      <Grid>
        <Section>
          <MainContent />
        </Section>
      </Grid>
      <Footer />
       
    </ContentWrapper>
  );

PageCourseT12.displayName = "PageCourseT12";
export { PageCourseT12 };
