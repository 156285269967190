import React, { FunctionComponent } from "react";
import { IAsset, Img } from "src/common";

import * as styles from "./FullscreenImage.style";

const FullscreenImage: FunctionComponent<{
  img: IAsset[];
  subtitle: string;
}> = (props) => {
  return (
    <div css={styles.img}>
      <Img
        src={props.img}
        alt={props.subtitle}
      />
    </div>
  );
};

export default FullscreenImage;
