import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const ContentContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  font-weight: 300;
  @media (${BREAKPOINTS_DM.gold_1024}){
    flex-direction: row;
    padding: 50px 60px;
  }

  p, span, h1, h2, h3, h4 {
    font-weight: 300;
    margin-bottom: 21px;
  }

  h2 {
    color: #fff;
    font-size: 34px;
    
  }
  h1 {
    font-size: 34px;
    color: #fff;
  }

  > div >div:last-child {
    margin: 50px 0 0 0;
  }
`;

export const sideBarWrapper: any = css`
    width: 100%;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
      max-width: 224px;
    }
`;

export const h1: any = css`
  ${({ theme }) => theme?.textStyles.h1}
`;

export const imageInText: any = css`
  > div:first-of-type{
    float: left;
    margin: 0 20px 20px 0 ;
    max-width: 33%;
  }
`;
