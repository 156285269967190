import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  padding: 0 15px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 75%;
  }
`;

export const NavigationPage: any = styled.nav`
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    margin: 12px 0px 60px 0px;

    svg.olderNewsSvg {
      transform: rotate(90deg);
      padding: 2px 0px 0px 8px;
      margin-right: 10px;
    }

    svg.latestNewsSvg {
      transform: rotate(270deg);
      padding: 2px 8px 0px 0px;
      margin-left: 10px;
    }

    a.olderNews {
      padding: 0px 15px 6px 4px;
    }

    a.latestNews {
      padding: 0px 6px 6px 15px;
    }

    a {
      background-color: ${({ theme }) => theme.palette.white};
      border: 1px solid ${({ theme }) => theme.palette.green};
      color: ${({ theme }) => theme.palette.green};
      text-align: center;
      display: flex;

      > p {
        margin-top: 6px;
      }

      svg {
        fill: ${({ theme }) => theme.palette.green};
      }
    }
    @media (max-width: 500px)
    {
        a {
          padding: 4px 10px !important;
        }
    }
`;
