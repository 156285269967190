import React from "react";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import { css } from "styled-components";
import { SPageLayoutWrapper } from "../PageLanding";

const PageT7 = () => {
  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div>
        <ResponsiveSidebar />
      </div>
      <SPageLayoutWrapper>
        <div>
          <MainSidebarLeft />
        </div>
        <div
          css={css`
            width: 100%;
          `}
        >
          <MainAreaWrapper />
        </div>
      </SPageLayoutWrapper>
    </div>
  );
};

PageT7.displayName = "PageT7";
export { PageT7 };
