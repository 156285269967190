import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const ContentContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin: 134px auto 50px auto;
  padding: 0 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 234px auto 50px auto;
    padding: 0;
  }

  @media (${BREAKPOINTS_DM.gold_1024}){
    flex-direction: row;
  }

  p, span, h1, h2, h3, h4 {
    margin-bottom: 21px;
  }

  h2 {
    color: ${({ theme }) => theme?.palette.primary};
    font-size: 20px;
  }

  > div >div:last-child {
    margin: 50px 0 0 0;
  }
`;

export const sideBarWrapper: any = css`
    width: 100%;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
      max-width: 224px;
    }
`;

export const h1: any = css`
  ${({ theme }) => theme?.textStyles.h1}
`;

export const imageInText: any = css`
  > div:first-of-type{
    float: left;
    margin: 0 20px 20px 0 ;
    max-width: 33%;
  }
`;
