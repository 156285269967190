import React, { FC, useState } from "react";
import { ICourse } from "src/common/childTypes";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import CourseContent from "../../content/CourseContent";
import MainContent from "../../content/MainContent";

const PageCourseT24: FC = () => {
  const [course, setCourse] = useState<ICourse>(null);

  return (
    <>
      <Header />
      <MainContent>
        <CourseContent course={course} onSetCourse={setCourse} />
      </MainContent>
      <Footer />
       
    </>
  );
}


PageCourseT24.displayName = "PageCourseT24";
export { PageCourseT24 };
