import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const ContentContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  font-weight: 300;
  line-height: 1.5;
  color: #736654;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    flex-direction: row;
    padding: 100px 0 0 0;
  }
  a{
    word-break: break-word;
  }
  p,
  span,
  h1,
  h2,
  h3,
  h4 {
    font-weight: 300;
    margin-bottom: 21px;
    letter-spacing: 1px;
  }

  p,
  span {
    font-size: 14px;
  }

  h2 {
    font-size: 25px;
    span {
      color: #736654;
      font-size: 34px;
      font-weight: 700;
    }
  }
  h1 {
    color: #736654;
    font-size: 34px;
    font-weight: 700;
    margin-top: 75px;
  }

  ol,
  ul {
    margin: 0 0 0 20px;
    padding: 0;
    li {
      margin-bottom: 18px;
    }
  }

  > div > div:last-child {
    margin: 50px 0 0 0;
  }
  text {
    margin-bottom: 21px;
  }
`;

export const sideBarWrapper: any = css`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 224px;
  }
`;

export const imageInText: any = css`
  > div:first-of-type {
    float: left;
    margin: 0 20px 20px 0;
    max-width: 33%;
  }
`;

export const ImprintStartWrapper: any = styled.div`
  margin-bottom: 21px;
`;
