import React, { FC } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import ContactContent from "../../content/ContactContent";

const PageContactT24: FC = () => (
  <>
    <Header />
    <ContactContent />
    <Footer />
     
  </>
);

PageContactT24.displayName = "PageContactT24";
export { PageContactT24 };
