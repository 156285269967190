import React, { FC } from "react";

import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";

const PageMicroT14: FC = () => (
  <>
    <Header />
    <MainContent />
    <Footer />
     
  </>
);

PageMicroT14.displayName = "PageMicroT14";
export { PageMicroT14 };
