import React from "react";
import { css } from "styled-components";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import { SPageLayoutWrapper } from "../PageLanding";
import { CommonPageBrandMicroMainContent } from "src/common/brand-area/PageBrandMicro/CommonPageBrandMicroMainContent";

const PageBrandMicroT7 = () => (
    <div
        css={css`
            width: 100%;
        `}
    >
        <div>
            <ResponsiveSidebar />
        </div>

        <SPageLayoutWrapper>
            <div>
                <MainSidebarLeft />
            </div>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <CommonPageBrandMicroMainContent />
            </div>
        </SPageLayoutWrapper>
    </div>
);

PageBrandMicroT7.displayName = "PageBrandMicroT7";
export { PageBrandMicroT7 };
