import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";

import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PageBrandT23: FC = () => (
  <>
    <Header />
    <CommonPageBrandMainContent />
    <Footer />
  </>
);

PageBrandT23.displayName = "PageBrandT23";
export { PageBrandT23 };
