import { getRandomColor, IThemingColor, IAsset, IPage, ITextElement, Context } from "src/common";
import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";

import React, { FunctionComponent, useContext } from "react";

import { centeredContent } from "../../../constants/layout";
import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";

const PageBrandT5: FunctionComponent<any> = () => {
    const props = useContext(Context);
    const pageData: IPage = props?.PageBrand as any;
    let color = (pageData?.elements?.find((e) => e?.__typename === "ThemingColor") as IThemingColor)?.color;

    if (!color) {
        color = getRandomColor();
    }
    return (
        <>
            <div css={centeredContent}>
                <Header />
            </div>
            <CommonPageBrandMainContent />
            <div css={centeredContent}>
                <FooterT5 />
            </div>
        </>
    );
};
PageBrandT5.displayName = "PageBrandT5";
export { PageBrandT5 };
