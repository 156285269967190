import React, { Fragment, FunctionComponent, useContext } from "react";
import RichText from "../../../BaseComponents/RichText/RichText";
import { cutCorner } from "../../../constants/layout";
import * as styles from "./Imprint.style";
import { Context, IContext, ImprintStart, ITextElement } from "src/common";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import { ImprintNameAndAddressOfThePersonInCharge } from "src/common/components/ImprintNameAndAddressOfThePersonInCharge";
import { css } from "styled-components";
import { SPageLayoutWrapper } from "../PageLanding";

const PageImprintT7: FunctionComponent = () => {
  const slugProps = useContext<IContext>(Context);
  const pageImprint = slugProps?.PageImprint;
  const textElements = pageImprint?.elements?.filter(
    (element) => element?.__typename === "TextElement"
  ) as ITextElement[];

  React.useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.hash &&
      typeof document !== "undefined"
    ) {
      const searchTream = `${window.location.hash}`.replace("#", "");
      const xpath = `//span[contains(text(),'${searchTream}')]`;
      const matchingElement = document.evaluate(
        xpath,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      )?.singleNodeValue;
      if (matchingElement) {
        //@ts-ignore
        matchingElement.scrollIntoView();
      }
    }
  }, []);

  return (
    <>
      <ResponsiveSidebar />
      <SPageLayoutWrapper>
        <div>
          <MainSidebarLeft />
        </div>
        <div
          css={css`
            width: 100%;
          `}
        >
          <MainAreaWrapper>
            <div
              css={`
                ${cutCorner}${styles.wrapper}
              `}
            >
              <div>
                <div
                  css={`
                    ${styles.imprintRichtext}${styles.additionalInfos}
                  `}
                  style={{ marginBottom: 20 }}
                >
                  <h1>{textElements?.[0]?.headline}</h1>
                  <ImprintStart />
                  {textElements.map((e, i) => (
                    <Fragment key={`ey${i}`}>
                      {e?.text && (
                        <RichText
                          textContent={e?.text}
                          overwriteStyle={styles.imprintRichtext}
                        />
                      )}
                      {i === 0 && <ImprintNameAndAddressOfThePersonInCharge />}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </MainAreaWrapper>
        </div>
      </SPageLayoutWrapper>
    </>
  );
};

PageImprintT7.displayName = "PageImprintT7";
export { PageImprintT7 };
