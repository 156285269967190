import styled, { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const Wrapper: any = styled.div<{color: string}>`
    color: ${({ color }) => color ? color : "#222"};
    padding: 30px 0;
    min-height: 80vh;
`;

export const heading: any = css`
    font-size: 24px;
    font-family: "Arial", "Helvetica", sans-serif;
    font-weight: 600;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        font-size: 40px;
        margin-bottom: 10px;
    }
`;

export const subtitle: any = css`
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 30px;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        font-size: 40px;
    }
`;

export const flexWrapper: any = css`
    display: flex;
    flex-wrap: wrap;
`;

export const NewsItem: any = styled.div<{color: string}>`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    width: 100%;
    > * {
      width: 47%;
    }
    h3 {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    button {
      margin-top: 12px;
      border: solid ${({ color }) => color ? color : "#222"} 1px;
      color: ${({ color }) => color ? color : "#222"};
      padding: 3px 20px;
      font-size: 13px;
      &:hover {
        opacity: .7;
      }
    }
    div > img {
      height: 228px;
      width: 228px;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        width: 50%;
        margin-bottom: 30px;
        justify-content: space-between;
        align-items: flex-start;
        div > img  {
          height: 228px;
          width: 228px;
        }
        h3 {
          margin-top: 3px;
        }
        &:nth-child(odd) {
          padding-right: 15px;
        }
        &:nth-child(even) {
          padding-left: 15px;
        }
    }
`;

export const NextAndPrevPage: any = styled.nav<{color: string}>`
    margin-top: 40px;
    justify-content: space-between;
    display: flex;
    margin-bottom: 50px;
    > a {
      text-align: center;
      width: 115px;
      display: flex;
      align-items: center;
      border: solid ${({ color }) => color ? color : "#888888"} 1px;
      color: ${({ color }) => color ? color : "#888888"};
      padding: 4px 15px;
      cursor: pointer;
      &:hover {
        opacity: .6;
      }
      &:first-child {
        svg {
          margin-right: 8px;
        }
      }
      &:nth-child(2)
      {
        svg {
          margin-left: 8px;
        }
      }
      svg {
        display: none;
        width: 15px;
        opacity: .9;
      }
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        > a {
          text-align: left;
          width: auto;
          svg {
            display: block;
          }
        }
    }
`;

export const article: any = css`
    h1 {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    img {
      max-width: 500px;
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
    margin-bottom: 80px;
    min-height: 80vh;
`;

export const articleRichtext: any = css`
    strong p {
      margin-top: 20px;
    }
`;

export const NextAndPreviousArticleLinks: any = styled.div<{color: string}>`
    position: relative;
    margin-top: 40px;
    margin-bottom: 10px;
    width: 100%;
    left: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    a {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: ${({ color }) => color ? color : "#222"};
    }
    svg {
      margin: 0;
    }
    @media (${BREAKPOINTS_DM.platinum_1366})
    {
        padding: 0 10px;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        svg {
          margin: 0 7px;
        }
    }
`;
