import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
  margin-bottom: 70px;
  * {
    line-height: 140%;
  }
  @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-top: 50px;
        margin-left: 16px;
        width: 50%;
    }
  h1 {
    color: ${props => props.theme.palette.red};
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h2 {
    color: ${props => props.theme.palette.red};
    font-weight: 400;
    letter-spacing: .02em;
  }
  strong {
    font-weight: 600;
  }
`;

export const companyTitle: any = css`
  h1 {
    color: ${props => props.theme.palette.red};
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 30px;
  }
`;

export const columnsWrapper: any = css`
    display: flex;
    > div {
      width: 50%;
      &:first-child {
        padding-right: 30px;
      }
    }
`;

export const directionsDescription: any = css`
    h6 {
        color: ${props => props.theme.palette.red};
        font-size: 19px;
    }
`;

export const contact: any = css`
    margin-top: 20px;
`;

export const sidebarText: any = css`
    background-color: rgba(255,255,255,0.2);
    margin-top: 30px;
    padding: 10px 10px 15px;
    border-radius: 8px;
    max-width: 250px;
    br:first-child {
      margin-top: 0;
    }
    div > p:last-child {
      margin-bottom: 0;
    }
    strong p {
      margin-bottom: 0;
    }
`;

export const contactForm: any = css`
    margin-top: 70px;
    h3 {
        margin-bottom: 24px;
        color: ${(props) => props.theme.palette.red};
        font-weight: 600;
    }

    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 20px;
            margin-top: 0;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;


            &[data-attached] {
              display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;

        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const inputsWrapper: any = css`
    *:not(label) {
      padding: 3px 8px;
      width: 100%;
      margin-bottom: 20px;
    }
`;

export const submitWrapper: any = css`
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: flex-start;
    }
    button {
      max-width: 40%;
      width: 250px;
      background-color: ${props => props.theme.palette.red};
      color: white;
      padding: 12px;
      border-radius: 3px;
    }
`;

export const datenschutzAkzeptierenWrapper: any = css`
    display: flex;
    max-width: 50%;
`;

export const datenschutzAkzeptieren: any = css`
    font-size: 12px;
    margin-left: 5px;
    a {
      color: ${props => props.theme.palette.red};
    }
`;
