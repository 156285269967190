import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";

import React, { FunctionComponent } from "react";
import { css } from "styled-components";

import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import { SPageLayoutWrapper } from "../PageLanding";
import { TopBar } from "../../common/mainAreaWrapper/topBar/TopBar";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandT7: FunctionComponent = () => (
    <>
        <ResponsiveSidebar />
        <SPageLayoutWrapper>
            <div>
                <MainSidebarLeft />
            </div>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <TopBar />
                <Spacer bronze={16} />
                <CommonPageBrandMainContent />
            </div>
        </SPageLayoutWrapper>
    </>
);
PageBrandT7.displayName = "PageBrandT7";
export { PageBrandT7 };
