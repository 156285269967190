import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../constants/mediaquerys";

export const wrapper: any = css`
  max-width: ${props => props.theme.maxWidth}px;
  width: 100%;
  margin: 0 auto 70px auto;
  padding: 0 15px;
  @media (${BREAKPOINTS_DM.gold_1024})
  {
      padding: 0;
  }
`;

export const pagination: any = css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 40px 0;
    button {
      padding: 2px 10px;
      background-color: ${props => props.theme.palette.yellow};
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      p {
        color: white;
      }
      &:hover {
        background-color: black;
        p {
          color: ${props => props.theme.palette.yellow};
        }
        svg
        {
          fill: red;
        }
      }
      svg {
        transform: scale(.6);
        margin-left: 4px;
      }
    }
`;
