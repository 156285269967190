import React, { FunctionComponent } from "react";
import { RichText } from "src/common";
import * as styles from "./Confirmation.style";

const Confirmation = (props: {onClick: any, universalTexts: any}): any => {
    
    return (
        <div css={`${styles.formWrapper}${styles.confirmation}`} >
                <div css={styles.submitted}>
                    <h1>DANKE FÜR IHREN EINTRAG!</h1>
                    <p>Ihr Beitrag wurde entgegen genommen und wird in Kürze freigeschaltet.</p>
                        <button onClick={props.onClick}>
                            {props.universalTexts.gestbook__back ? <RichText textContent={props.universalTexts.gestbook__back}></RichText> : "Zum Gästebuch"}
                        </button>  
                </div>
            </div>
    );
};

export default Confirmation;
