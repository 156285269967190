import styled, { css } from 'styled-components';
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from '../../../constants/mediaquerys';

const generateCss = val => {
  return val ? `height: ${val}px;` : '';
};

const Default = css<any>`
  width: 100%;
  ${props => generateCss(props.bronze)};
`;

const Silver = css<any>`
  ${props => generateCss(props.silver)};
`;

const Gold = css<any>`
  ${props => generateCss(props.gold)};
`;

const Platinum = css<any>`
  ${props => generateCss(props.platinum)};
`;

const Diamond = css<any>`
  ${props => generateCss(props.diamond)};
`;

const SCol = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SCol;
