import { useRouter } from "next/router";
import React, { FC, useCallback } from "react";
import { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../../../../common/constants/mediaquerys";
import { useDealerSearchResult } from "../../../../../common/hooks/useDealerSearch";
import { centeredContent, flexWrapper } from "../../../constants/layout";
import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { DachseiteSearchContact } from "./search/DachseiteSearchContact";
import { SDealerItem } from "./search/styles/SDealerItem";
import { SDealerItemsContainer } from "./search/styles/SDealerItemsContainer";
import { FriendlyCaptcha } from "../../../../../common/components/friendly-capture";

const PageSearchT5: FC = () => {
  const { dealers, friendlyCaptchaToken } = useDealerSearchResult();
  const router = useRouter();

  const dealerRow = useCallback(
    (index) => {
      return (index + 1) % 2 === 0 ? (index + 1) / 2 : (index + 2) / 2;
    },
    [dealers]
  );

  const isEven = (index: number) => {
    return (index + 1) % 2 === 0;
  };

  const dealerIsGreen = useCallback(
    (dealerIndex: number) => {
      return (
        (isEven(dealerIndex) && !isEven(dealerRow(dealerIndex) - 1)) ||
        (!isEven(dealerIndex) && isEven(dealerRow(dealerIndex) - 1))
      );
    },
    [isEven, dealerRow]
  );

  return (
    <div css={centeredContent}>
      <Header OverwriteSearch={DachseiteSearchContact} />
      <div css={flexWrapper}>
        <div style={{ width: "calc(100% - 13.5px)" }}>
          <div
            css={css`
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                @media (${BREAKPOINTS_DM.gold_1024}) {
                  flex-direction: row;
                }
              `}
            >
              <div
                css={css`
                  width: 100%;
                `}
              >
                <div>
                  <div css={``}>
                    <div>
                      <div
                        css={css`
                          line-height: 24px;
                          background: #ee765e;
                          color: #fff;
                          font-size: 1.5rem;
                          padding: 27px 13.5px;
                          width: 100%;
                        `}
                      >
                        {dealers && dealers.length == 0 ? (
                          <>
                            In Ihrem gewünschten Gebiet befindet sich kein
                            passender Fachhändler. Kompetente Partner finden Sie
                            zusätzlich{" "}
                            <a
                              css={css`
                                font-weight: bold;
                                color: #008bc5;
                              `}
                              href="https://www.hausgeraete-ganzmacher.de"
                              target="_blank"
                            >
                              hier
                            </a>
                            .
                          </>
                        ) : (
                          <>
                            {router?.query?.search ? (
                                  <>
                                  {friendlyCaptchaToken ?
                                  <div>
                                    Suchergebnisse für Händler in:{" "}
                                    <strong>{router.query?.search ?? ""}</strong>
                                  </div>
                                  : <div>
                                    Bitte bestätigen Sie, dass Sie kein Roboter sind. Danach werden die Suchergebnisse angezeigt.
                                    <FriendlyCaptcha/>
                                  </div>
                                  }
                                    </>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {dealers?.length > 0 ? (
                    <SDealerItemsContainer>
                      {dealers?.map((o: any, index) => {
                        return (
                          <SDealerItem
                            isGreen={dealerIsGreen(index)}
                            target="_blank"
                            href={`${o.domain}`}
                            key={o.client_id}
                          >
                            <a href={o.domain} target="_blank">
                              {o.company_name}
                            </a>
                            <div>
                              <p>{o.street + " · " + o.zip + " " + o.city}</p>
                            </div>
                          </SDealerItem>
                        );
                      })}
                    </SDealerItemsContainer>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterT5 />
      </div>
    </div>
  );
};

PageSearchT5.displayName = "PageSearchT5";
export { PageSearchT5 };
