import styled from "styled-components";


const SShadowOverlay = styled.div`
    position: absolute;
    top: 0;
    opacity: 0.2;
    width: 100%;
    z-index: 1;
    height: 9px;
    transform: rotate(180deg);
    background: url(/images/gradient.png) repeat-x;
    &:last-child{
        bottom: 0;
        transform: rotate(0deg);
        top: calc(100% - 9px);
    }
`

export default SShadowOverlay;