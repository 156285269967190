
import React, { Fragment, FunctionComponent } from "react";

import { centeredContent, contentWrapper } from "../../../constants/layout";
import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import * as styles from "./pageRepairInquiry.style";
import { PageRepairForm } from "./RepairForm";

const PageRepairInquiryT6: FunctionComponent = () => (
    <Fragment>
        <Header/>
        <div css={styles.wrapper}>
            <div css={centeredContent}>
                <div css={contentWrapper}>
                    <PageRepairForm/>
                </div>
            </div>
        </div>
        <Footer showLogos={false}/>
    </Fragment>
);
PageRepairInquiryT6.displayName = "PageRepairInquiryT6"
export {PageRepairInquiryT6}