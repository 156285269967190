import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  position: relative;
  min-height: 80vh;
  font-weight: 300;

  margin: 134px auto 0 auto;
  padding: 0 10px;
  padding-bottom: 100px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 auto;
    padding: 0;
  }

  h1 {
    color: ${props => props.theme.palette.primary};
  }

  span {
    font-weight: 300;
  }
`;

export const OpenFormWrapper = styled.div`
  margin-top: 50px;

  button {
    padding: 0 25px;
    line-height: 45px;
    border: 1px solid ${props => props.theme.palette.textColor};
    color: ${props => props.theme.palette.white};
    background-color: ${props => props.theme.palette.textColor};
  }
`;

export const ContentContainer: any = styled.div`
  width: 100%;
`;

export const ContentWrapper: any = styled.div`
  width: 100%;
  display: flex;

  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const SideBarWrapper: any = styled.div`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 25%;
    padding-right: 21px;
  }
`;

export const ElementsWrapper: any = styled.div`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 75%;
  }
`;