import React, { Fragment, FC, useState } from "react";
import { IAsset } from "src/common";

import FullscreenImage from "../../../common/FullscreenImage";
import { RichText } from "../../../common/RichText";
import { Wrapper, HeadingWrapper, NewsContent, Images, ImageCaption } from "./NewsArticleItem.style";

interface IProps {
    headline: string;
    teaser: string;
    text: any;
    index: number;
    images: IAsset[];
}

const NewsArticleItem: FC<React.PropsWithChildren<IProps>> = (props) => {
    const [isActive, setIsActive] = useState(props?.index === 0);
    if (!props.headline || !props?.text) {
        return null;
    }
    const teaser = props?.teaser;
    // const teaser = props?.text && richToTeaser(props?.text);

    return (
        <Wrapper isActive={isActive}>
            <HeadingWrapper onClick={() => setIsActive(!isActive)}>
                <h3>{props.headline}</h3>
            </HeadingWrapper>

            {teaser && <p>{teaser}</p>}

            <NewsContent isActive={isActive}>
                <Images isActive={isActive}>
                    {props.images?.map((asset: IAsset, i: number) => {
                        return (
                            <Fragment key={`${i}${asset?.src}`}>
                                {asset && (
                                    <>
                                        <FullscreenImage img={[asset]} subtitle={asset.subtitle} />
                                        {asset.subtitle && <ImageCaption>{asset.subtitle}</ImageCaption>}
                                    </>
                                )}
                            </Fragment>
                        );
                    })}
                </Images>
                {props.text && <RichText textContent={props.text} />}
            </NewsContent>

            {isActive ? (
                <a onClick={() => setIsActive(!isActive)}>
                    <img src="/images/kw_icon_close_orange.png" />
                </a>
            ) : (
                <a onClick={() => setIsActive(!isActive)} data-open>
                    <img src="/images/kw_icon_arrow_right.png" />
                    mehr
                </a>
            )}
        </Wrapper>
    );
};

export default NewsArticleItem;
