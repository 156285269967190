import { css } from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const contactForm: any = css`
    h3 {
        margin-bottom: 14px;
        font-size: 20px;
        color: ${(props) => props.theme.primary};
    }

    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 15px;
            margin-top: 15px;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const inputsWrapper: any = css`
    *:not(label) {
      width: 100%;
      cursor: pointer;
    }
    label {
      font-weight: 300;
    }
    input, textarea {
      border: solid #c8c8c8 1px;
    }
`;

export const formFirstRow: any = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    > div {
      width: 48%;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: column;
        > div {
          width: 100%;
          &:first-child {
            margin-bottom: 14px;
          }
        }
    }
`;

export const submitWrapper: any = css`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: flex-start;
    > div {
      display: flex;
      align-items: flex-start;
    }
    label {
      margin-bottom: 24px;
      cursor: pointer;
      margin-right: 12px;
    }
    label a {
      color: ${props => props.theme.primary};
    }
    button {
      width: 40%;
      max-width: 250px;
      padding: 10px;
      color: white;
      background-color: ${props => props.theme.primary};
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: column;
        button {
          width: 100%;
          max-width: none;
        }
    }
`;

export const datenschutzAkzeptieren: any = css`
    font-size: 14px;
    margin-left: 5px;
    a {
      font-size: inherit;
      color: ${props => props.theme.primary};
    }
`;
