import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const companyInfoAndContactForm: any = css`
    background-color: ${colors.backgroundMint};
    * {
      color: white;
    }
    h1 {
      text-align: center;
      margin-bottom: 50px;
    }
`;

export const columnsWrapper: any = css`
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
      &:first-child {
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 12px;
      }
      &:nth-child(2) {
        padding-left: 16px;
      }
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        > div {
          width: 100%;
          padding-left: 0 !important;
          padding-right: 0 !important;
          &:first-child {
            align-items: flex-start;
          }
        }
    }
`;

export const openingHoursRichtext: any = css`
    span {
      white-space: nowrap;
    }
    table {
      margin: 0 0 10px auto;
    }
    tr {
      td:first-child {
        text-align: left;
      }
      td:nth-child(2) {
        padding-left: 30px;
        text-align: right;
      }
    }
`;

export const companyInfosWrapper: any = css`
    h3 {
      margin-bottom: 9px;
    }
`;

export const mapWrapper: any = css`
    background-color: ${colors.lightGray};
`;

export const map: any = css`
    width: 100%;
    height: 320px;
    background-color: #eeeeee;
    > div {
      width: 100%;
      height: 100%;
    }
`;

export const routeBerechnenWrapper: any = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 10px;
      color: white;
      background-color: ${props => props.theme.primary};
    }
    > * {
      width: 47%;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: column;
        > * {
          width: 100%;
        }
    }
`;

export const inputWrapper: any = css`
    margin: 60px 0 30px;
    label {
      font-weight: 300;
    }
`;

export const locationInputWrapper: any = css`
    position: relative;
    input {
        height: 41px;
        list-style: none;
        box-sizing: border-box;
        font-family: inherit;
        cursor: pointer;
        border-width: 1px;
        width: 100%;
        padding-right: 40px;
        padding-left: 8px;
    }
    svg {
      opacity: .4;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 7px;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-bottom: 12px;
    }
`;

export const routePanel: any = css`
    max-height: 80vh;
    overflow-y: auto;
`;

