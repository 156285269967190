import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  width: 100%;
  margin: auto;
  margin: 20px auto 50px auto;
  padding: 0 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 auto 50px auto;
    padding: 0;
    flex-basis: 100% !important;
  }
`;

export const NavigationPage: any = styled.nav`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  margin: 12px 0px 60px 0px;

  svg.olderNewsSvg {
    transform: rotate(90deg);
    padding: 2px 0px 0px 8px;
    margin-right: 10px;
  }

  svg.latestNewsSvg {
    transform: rotate(270deg);
    padding: 2px 8px 0px 0px;
    margin-left: 10px;
  }

  a.olderNews {
    padding: 0px 10px 6px 4px;
  }

  a.latestNews {
    padding: 0px 6px 6px 10px;
  }

  a {
    background-color: #1d1d1b;
    color: #cbbb89;
    text-align: center;
    border-radius: 4px;
    display: flex;
    &:hover {
      color: #fff;
    }

    p.button {
      margin: 6px 0 0;
      font-weight: 300;
    }
  }
  @media (max-width: 500px) {
    a {
      padding: 4px 10px !important;
    }
  }
`;
