import { css } from "styled-components";

import colors from "../../../constants/colors";

export const articleWrapper: any = css`
    * {
      text-align: center;
    }
`;

export const odd: any = css`
    background-color: ${colors.backgroundMint};
    * {
      color: white;
    }
    a, a * {
      color: ${props => props.theme.primary};
      &:hover {
        color: ${props => props.theme.palette.red};
        text-decoration: underline;
      }
    }
`;

export const richtextEven: any = css`
    h3, h4, h5 {
      color: ${props => props.theme.secondary};
      font-size: 15px !important;
      &:first-child {
        span {
          color: ${props => props.theme.secondary};
        }
        color: ${props => props.theme.secondary};
      }
    }
`;

export const even: any = css`
    background-color: ${colors.lightGray};
    * {
      color: black;
    }
    a, a *, h2, h3, h4, h5, h6 {
      color: ${props => props.theme.primary};
    }
`;

export const articleAssets: any = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    > div {
      width: 480px;
      margin: 18px 0;
      img {
        max-height: 300px !important;
      }
    }
`;

export const articleHeading: any = css`
    text-align: center;
`;

export const articleContent: any = css`
    br {
      margin-top: 12px;
    }
    h3 {
      font-size: 20px;
    }
    * {
      line-height: 140% !important;
    }
`;

export const assetWithSubtitle: any = css`
    background-color: white;
    p {
      padding: 10px;
      color: black;
    }
`;

export const nextAndPrevPage: any = css`
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    margin: 18px 0;
    a {
      background-color: ${props => props.theme.primary};
      color: white;
      padding: 4px 15px;
      text-align: center;
      &:first-child {
        margin-right: 7px;
      }
      &:nth-child(2) {
        margin-left: 7px;
      }
    }
    @media (max-width: 500px)
    {
        a {
          padding: 4px 10px !important;
        }
    }
`;
