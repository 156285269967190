import React, { FunctionComponent } from "react";

import Footer from "../../common/Footer";

import MainContent from "../../content/MainContent";

const PageMicroT21: FunctionComponent = () => (
  <>
    <MainContent/>
    <Footer />
     
  </>
);

PageMicroT21.displayName = "PageMicroT21";
export { PageMicroT21 };
