import { getRandomColor, IThemingColor, IPageBrandOverview, Context } from "src/common";
import { CommonPageBrandOverviewMainContent } from "src/common/brand-area/PageBrandOverview/CommonPageBrandOverviewMainContent";

import React, { FunctionComponent, useContext } from "react";

import { centeredContent } from "../../../constants/layout";
import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import Spacer from "../../../../../common/BaseComponents/Spacer/Spacer";

const PageBrandOverviewT5: FunctionComponent<any> = () => {
    const props = useContext(Context);
    const pageData: IPageBrandOverview = props?.PageBrandOverview as any;
    let color = (pageData?.elements?.find((e) => e?.__typename === "ThemingColor") as IThemingColor)?.color;
    if (!color) {
        color = getRandomColor();
    }
    return (
        <>
            <div css={centeredContent}>
                <Header />
            </div>
            <Spacer bronze={80} />

            <CommonPageBrandOverviewMainContent />
            <div css={centeredContent}>
                <FooterT5 />
            </div>
        </>
    );
};
PageBrandOverviewT5.displayName = "PageBrandOverviewT5";
export { PageBrandOverviewT5 };
