import { CommonPageBrandTopicMainContent } from "src/common/brand-area/PageBrandTopic/CommonPageBrandTopicMainContent";

import React, { FunctionComponent } from "react";

import { Banner } from "../../common/Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandTopicT1: FunctionComponent = () => (
    <>
        <MainNavigation />
        <Spacer bronze={80} />
        <CommonPageBrandTopicMainContent />
        <Banner />
        <Footer />
    </>
);

PageBrandTopicT1.displayName = "PageBrandTopicT1";
export { PageBrandTopicT1 };
