import * as React from "react";

import { Banner } from "../../common/Banner/Banner";
import BrandBannerVertical from "../../common/BrandBannerVertical/BrandBannerVertical";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import MainNavigation from "../../common/MainNavigation/MainNavigation";

const PageBrandOverviewOldT1 = () => 
  (
    <>
      <MainNavigation />
      <MainContent topComponent={BrandBannerVertical} />
      <Banner/>
      <Footer />
    </>
  );
PageBrandOverviewOldT1.displayName = "PageBrandOverviewOldT1";
export { PageBrandOverviewOldT1 };
