import React, { FC } from "react";
import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import ImprintContent from "../../content/ImprintContent";

const PageImprintT26: FC = () => (
  <>
    <Header disableLogo />
    <Grid>
      <Section>
        <ImprintContent />
      </Section>
    </Grid>
    <Footer />
     
  </>
);
PageImprintT26.displayName = "PageImprintT26";
export { PageImprintT26 };
