import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "src/common/constants/mediaquerys";

const Default = css`
  flex-direction: column;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

const Silver = css`
  flex-direction: row;
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SDealerSearchContainer = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
