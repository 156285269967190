import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import MainContent from "../../content/MainContent";

const PageT26: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent />
      </Section>
    </Grid>
    <Footer />
  </>
);

PageT26.displayName = "PageT26";
export { PageT26 };
