import React, { FunctionComponent } from "react";
import Header from "../../common/header/Header";
import { FooterT3 } from "../../common/footer/Footer";
import { Banner } from "../../common/Banner/Banner";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";
import Spacer from "../../../../../common/BaseComponents/Spacer/Spacer";
import { CommonPageBrandMicroMainContent } from "../../../../../common/brand-area/PageBrandMicro/CommonPageBrandMicroMainContent";

const PageBrandMicroT3: FunctionComponent = () => {
    return (
        <>
            <Banner />
            <PlanningDateModal />
            <Header />
            <Spacer bronze={80} silver={60} platinum={38} />
            <CommonPageBrandMicroMainContent />
            <FooterT3 />
        </>
    );
};

PageBrandMicroT3.displayName = "PageBrandMicroT3";
export { PageBrandMicroT3 };
