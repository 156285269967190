import React, { FunctionComponent } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

import MainContent from "../../content/MainContent";

const PageMicroT15: FunctionComponent = () => (
  <>
    <Header />
    <MainContent/>
    <Footer />
     
  </>
);

PageMicroT15.displayName = "PageMicroT15";
export { PageMicroT15 };
