import React, { Fragment, FC, useContext } from "react";
import {
  IContext,
  Context,
  getPage,
  ITextElement,
  getTextBlocks,
  IAsset,
} from "src/common";
import ImageWithAspectRatio from "src/templates/t9/BaseComponents/ImageWithAspectRatio";

import { Row, Col } from "../../../BaseComponents/Grid";
import Gallery from "../../common/Gallery";
import { RichText } from "../../common/RichText/";
import SubNavLeft from "../../common/SubNavLeft";
import { BorderBottom } from "../MainContent/MainContent.style";
import {
  Wrapper,
  sideBarWrapper,
  RightSideWrapper,
  Spacer,
} from "./GuestBookContent.style";
import GuestBookEntries from "./GuestBookEntries";

const GuestBookContent: FC = (): any => {
  const pageProps = useContext<IContext>(Context);

  const page = getPage(pageProps);
  const textBlocks = getTextBlocks(page);
  // ----------------------------------------- //

  // @ts-ignore
  const imgArray = page?.elements?.filter(
    (e) => e?.__typename === "Asset"
  ) as IAsset[];
  // @ts-ignore
  const slider = page?.elements?.filter(
    (e) => e?.__typename === "Slider"
  ) as any[];

  const navItem = pageProps?.PrimaryNavigation?.items?.find(
    (i) =>
      !!i?.subitems?.find((s) => s?.slug === pageProps.slug) ||
      i?.link?.[0]?.slug === pageProps.slug
  );
  // const vorname = universalTexts?.guestbook__firstname || "Vorname";
  // const nachname = universalTexts?.guestbook__lastname || "Nachname";
  // const email = universalTexts?.guestbook__email || "E-Mail";
  // const titel = universalTexts?.guestbook__title || "Titel";
  // const nachricht = universalTexts?.guestbook__message || "Nachricht";
  // const bewertungAbgeben = universalTexts?.gestbook__evaluation || "Bewertung abgeben";

  const showSlider =
    slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery;
  if (imgArray?.[0] || showSlider) {
    if (imgArray.length > 1 || showSlider) {
      if (slider) {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      } else {
        imageOrGallery = <Gallery imgArray={imgArray} />;
      }
    } else {
      imageOrGallery = (
        <ImageWithAspectRatio
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={342}
          ratio={50}
          quality={1080}
        />
      );
    }
  }

  return (
    <Wrapper>
      <Row>
        <Col md={9}>
          <RightSideWrapper>
            <Fragment>
              {imageOrGallery}
              {imageOrGallery ? <div style={{ margin: "20px 0" }} /> : null}
              {textBlocks?.map((tb: ITextElement, i: number) => (
                <Fragment key={`textblock${i}`}>
                  {tb.headline && (
                    <RichText>
                      <h1 className="title">{tb.headline}</h1>
                    </RichText>
                  )}
                  <RichText textContent={tb.text} />
                </Fragment>
              ))}
            </Fragment>

            <Spacer />
            <GuestBookEntries />
          </RightSideWrapper>
        </Col>
        <Col md={3}>
          {navItem && (
            <div css={sideBarWrapper}>
              <SubNavLeft />
            </div>
          )}
        </Col>
      </Row>
      <BorderBottom />
    </Wrapper>
  );
};

export default GuestBookContent;
