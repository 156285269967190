import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const ContentContainer: any = styled.div`
      max-width: ${({ theme }) => theme.maxWidth}px;
      width: 100%;
      padding: 0 20px;
      margin: 35px auto 20px 0;
      @media (${BREAKPOINTS_DM.gold_1024})
      {
          margin: 85px auto 50px auto;
          padding: 0;
      }
`;

export const ImageInBox: any = styled.div`
float: left;
 img {
     width: 300px;
     padding-right: 20px;
     margin-bottom: 20px;
 }
`;

export const ContentWrapper: any = styled.div`
    display: flex;
    flex-direction: column-reverse;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
    }
`;

export const SliderWrapper: any = styled.div`
    .slick-slide img {
        display: initial;
    }
`;

export const flexxer: any = css`
    display: flex;
    flex-direction: row;
`;


export const mainImageWrapper: any = css`
  width: 38%;
  height: auto;
`;

export const mainTextWrapper: any = css`
  width: calc(62% - 65px);
  margin-left: 65px;
`;

export const mainTextWrapperHidden: any = css`
  width: calc(62% - 65px);
  overflow-x: hidden;
  margin-left: 65px;
  margin-top: 30px;
`;

export const mainImage: any = css`
    max-width: 356px;
    max-height: 512px;
`;

export const sideContentWrapper: any = css`
    width: 100%;
    overflow: hidden;
    position: relative;
`;

export const showExcerpt: any = css`
    position: absolute;
    bottom: -5px;
    left: 0;
    cursor: pointer;
`;

export const image: any = css`
    width: 50px;
    height: 50px;
    margin-right: 10px;
`;

export const HeadlineWrapper: any = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
`;

export const h1: any = css`
    font-size: 24px;
    line-height: 42px;
    font-weight: bold;
    color: ${props => props.theme.palette.grey};
`;

export const richTextWrapper: any = css`
    width: 100%;

    max-width: -webkit-fill-available;
    margin: 13.5px 0;
    img{
      width: calc(50% - 7px);
      margin-right: 14px;
      margin-bottom: 6px;
    }
    p {
      width: auto;
    }
`;

export const textWrapper: any = css`
    width: 100%;
    margin: 13.5px 0;
    img {
      width: calc(50% - 7px);
    }
    p {
      width: auto;
      margin-bottom: 21px;
    }
    strong {
        font-weight: 700;
    }
    ul {
        list-style-position: inside;
        * {
        list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: disc;
        }
    }
    ol {
        list-style-position: inside;
        * {
        list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: decimal;
        }
    }
    em {
        font-style: italic;
    }
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 19px;
    }
    h6 {
        font-size: 18px;
    }
`;

export const imgWrapper: any = css`
    max-width: -webkit-fill-available;
    margin-left: 13.5px;
    margin-right: 20px;
    float: left;
    width: 36%;
`;

export const imageDescripton: any = css`
    background-color: #e6e7e9;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

export const clearFix: any = css`
  clear: both;
`;


