import { CommonPageBrandOverviewMainContent } from "src/common/brand-area/PageBrandOverview/CommonPageBrandOverviewMainContent";

import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PageBrandOverviewT26: FC = () => (
  <>
    <Header />
    <CommonPageBrandOverviewMainContent />
    <Footer />
  </>
);

PageBrandOverviewT26.displayName = "PageBrandOverviewT26";
export { PageBrandOverviewT26 };
