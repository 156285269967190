import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const HeadingWrapper: any = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  > h3 {
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #968a78;
    font-size: 25px;
    margin: 0 ;
  }
`;

export const NewsContent: any = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
  max-height: ${({ isActive }) => (isActive ? "auto" : "0")};
  margin-top: 21px;
  width: 100%;
  font-weight: 300;
  gap: 30px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    > div:first-child {
      flex-basis: 50%;
    }
  }

  ul {
    padding-left: 35px;
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: disc;
    }
  }
  strong ol li {
    font-weight: 700;
  }
  ul,
  ol {
    display: inline-block;
    text-align: left;
    li,
    span {
      text-align: left;
    }
  }
  ol {
    padding-left: 15px;
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: decimal;
    }
  }
  a {
    &,
    span {
      color: #d30032;
    }
    span {
      &::after {
        content: "";
        display: inline-block;
        background-image: url("/images/sprite-s82c5aa31b4.png");
        background-repeat: no-repeat;
        background-position: -20px -36px;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        transform: translateY(3px);
      }
    }
  }
  h1,
  h2:not(:first-child),
  h3,
  h4 {
    &,
    span {
      font-size: 14px;
      color: #968a78;
      font-weight: 700;
    }
  }
  h3,
  h4 {
    &,
    span {
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #968a78;
      font-size: 25px;
    }
  }

  .c-empty:first-child {
    display: none;
  }

  p {
    &,
    span {
      font-weight: 300;
      hyphens: none;
      overflow-wrap: break-word;
      /* word-break: break-all; */
    }
  }
`;

export const Images: any = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #7d7261 !important;
  margin-bottom: 20px;
  div {
    width: auto;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-basis: 50%;

    img {
      object-fit: cover;
    }
  }
  display: flex;
  flex-direction: column;
`;

export const ImageCaption: any = styled.p`
  margin-bottom: 10.5px;
  padding: 10.5px;
  background: #d0d2d3;
  font-size: 12px;
`;

export const Wrapper: any = styled.div<{ isActive: boolean }>`
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #7d7261 !important;

  svg {
    transform: scale(0.7);
    padding-left: 10px;
  }

  ${HeadingWrapper} {
  }

  > a {
    color: #968a78;
    font-weight: 300;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    img {
      display: block;
      margin-right: 10px;
    }
  }

  p:not(.c-empty) {
    font-weight: 300;
  }
`;
