import React, { Fragment, FunctionComponent, useContext } from "react";
import { Context, IContext } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import UniversalComponentRenderer from "../../../BaseComponents/UniversalCompoentRenderer/UniversalComponentRenderer";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import * as styles from "./PageBrandOverview.style";

const PageBrandOverviewOldT6: FunctionComponent = () => 
{
  const props = { ...useContext<IContext>(Context), ...useContext<IContext>(Context) };
  const elementsWithoutMainAsset = props.PageBrandOverview?.elements?.filter(element => element?.__typename !== "Asset");
  return (  
    <div id="PageBrandOverviewOldT6">
      <div>
        <Header />
      </div>
      <div>
        <UniversalComponentRenderer items={elementsWithoutMainAsset} />
      </div>
      <div css={styles.wrapper}>
        <div css={centeredContent}>
          <div css={contentWrapper}>
            <div css={styles.brands}>
              {props.AllBrand?.map((brand, i) => (
                <Fragment key={`brandf${i}`}>
                  {brand && (
                    <Img key={i} src={brand?.logo?.[0]?.src} quality={300} alt={brand?.name} />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer showLogos={false} />
    </div>
  );
};

PageBrandOverviewOldT6.displayName = "PageBrandOverviewOldT6";
export { PageBrandOverviewOldT6 };
