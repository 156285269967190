import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const ContentContainer: any = styled.div`
  p, span, h1, h2, h3, h4 {
    font-weight: 300;
  }

  h2 {
    color: ${({ theme }) => theme?.palette.green};
    font-size: 20px;
  }
`;
