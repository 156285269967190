import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Star } from "../RateStars.style";

export const GuestBookItemWrapper: any = styled.div`
  border-top: 1px solid #D0D2D3;
  margin-bottom: 42px;

  > p:first-child {
    padding-top: 10.5px;
    margin-bottom: 21px;

    &,
    span {
      font-size: 13px;
      font-weight: 300;
      line-height: 1.61538;
      color: #404040;
    }
  }

  > h3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10.5px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.green};

    [data-rate] {
      ${Star} {
        transform: scale(.8);
        margin: 0;
      }
    }
  }
`;

export const MessageWrapper: any = styled.div`
  background-color: #D0D2D3;
  padding: 10.5px;
  margin-bottom: 21px;

  h1,
  h2,
  h3,
  p,
  span {
    color: ${({ theme }) => theme.palette.textColor};
  }
`;
