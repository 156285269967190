import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const HeadingWrapper: any = styled.div`
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    > h3 {
      color: ${props => props.theme.palette.green};
      font-weight: 700;

      :after {
        display: inline-block;
        content: "";
        width: 16px;
        height: 11px;
        margin-left: 10px;
        background-image: url("/images/sprite-sa3ca3ca63e.png");
        background-repeat: no-repeat;
      }
    }
`;

export const NewsContent: any = styled.div<{ isActive: boolean; }>`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: auto;
    max-height: ${({ isActive }) => isActive ? "auto" : "0"};
    margin-top: 21px;
    width: 100%;

    @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
        align-items: flex-start;
    }

    ul {
        padding-left: 35px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: disc;
        }
    }
    strong ol li {
        font-weight: 700;
    }
    ul,
    ol {
        display: inline-block;
        text-align: left;
        li,
        span {
            text-align: left;
        }
    }
    ol {
        padding-left: 15px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: decimal;
        }
    }
    a {
        &,
        span {
            color: #d30032;
        }
        span {
            &::after {
                content: "";
                display: inline-block;
                background-image: url("/images/sprite-s82c5aa31b4.png");
                background-repeat: no-repeat;
                background-position: -20px -36px;
                width: 15px;
                height: 15px;
                margin-left: 5px;
                transform: translateY(3px);
            }
        }
    }
    h1,
    h2:not(:first-child),
    h3,
    h4 {
        &,
        span {
            font-size: 14px;
            color: ${props => props.theme.palette.green};
            font-weight: 700;
        }
    }
    h3,
    h4 {
        &,
        span {
            font-weight: 400;
            letter-spacing: 0.02em;
            color: ${props => props.theme.palette.green};
            font-size: 1.2rem;
        }
    }

    .c-empty:first-child {
      display: none;
    }
`;

export const Images: any = styled.div`
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
        margin-right: 20px;
        width: 230px;
    }

    display: flex;
    flex-direction: column;
`;

export const ImageCaption: any = styled.p`
    &:not(:last-of-type) {
      margin-bottom: -20px;
    }
    margin-bottom: 10.5px;
    padding: 10.5px;
    background: #D0D2D3;
    font-size: 12px;
`;

export const Wrapper: any = styled.div<{ isActive: boolean }>`
    margin-bottom: 40px;

    svg {
      transform: scale(0.7);
      padding-left: 10px;
    }

    ${HeadingWrapper} {
      h3 {
        :after {
          ${({ isActive }) => isActive && "display: none;"}
        }
      }
    }

    > a {
      color: #D0D2D3;
      :after {
        display: inline-block;
        content: "";
        width: 16px;
        height: 11px;
        margin-left: 10px;
        background-image: url("/images/sprite-sa3ca3ca63e.png");
        background-repeat: no-repeat;
        transform: rotate(180deg)
      }

      ${({ isActive }) => !isActive && "display: none;"}
    }
`;