import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FormWrapper: any = styled.div`
  padding: 10px;
  * {
    line-height: 140%;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(50% - 10px);
    margin-left: 10px;
  }
  h1 {
    ${({ theme }) => theme.textStyles.h1};
    line-height: 42px;
  }

  h2 {
    color: ${props => props.theme.palette.primary};
    font-weight: 400;
    letter-spacing: .02em;
    font-size: 21px;
    line-height: 21px;
  }
  strong {
    font-weight: 600;

  }
  p {
    font-weight: 400;
    span {
      font-weight: 400;
    }
  }
  table {
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-direction: inline-block;
      width: 100%;
    }
  }
  tr {
    flex-direction: column;
    display: flex;
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-direction: row;
    }
  }
  td {
    width: 100%;
    p {
      padding: 0;
    }

  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
  }
`;

export const CompanyInfo: any = styled.div`
  * {
    font-weight: 300;
  }

  [data-tel] {
    margin: 21px 0;
  }
`;

export const OfficeInfo: any = styled.div`

`;

export const directionsDescription: any = css`
  h6 {
      font-size: 19px;
  }
`;

export const contact: any = css`
  margin-top: 20px;
`;

export const SidebarText: any = styled.div`
  background-color: rgba(255,255,255,0.2);
  margin-top: 30px;
  padding: 10px 10px 15px;
  border-radius: 8px;
  max-width: 250px;
  br:first-child {
    margin-top: 0;
  }
  div > p:last-child {
    margin-bottom: 0;
  }
  strong p {
    margin-bottom: 0;
  }
`;

export const ContactForm: any = styled.div`
  margin-top: 50px;
  width: 100%;

  h3 {
    margin-bottom: 24px;
    color: ${props => props.theme.palette.primary};
    font-weight: 600;
    font-size: 18px;
  }

  label {
    font-weight: 300;
    line-height: 1.7em;
  }


`;

export const inputsWrapper: any = css`
  width: 100%;
  label, input, textarea {
    width: 100%;
    display: block;
  }
  input {
    height: 22px;
  }
  textarea {
    height: 200px;
  }

  label:not([for=Name]) {
    margin-top: 21px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    label[for="E-Mail"] {
      margin-top: 0;
    }

    label:not([for=Name]):not([for="E-Mail"]) {
      margin-top: 21px;
    }
  }
`;

export const fcWrapper: any = css`
    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 15px;
            margin-top: 15px;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const SubmitWrapper: any = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 21px;

  > div {
    width: 47%;
    display: flex;
    align-items: flex-start;

    > label > a {
      color: ${props => props.theme.palette.primary};
    }
  }

  button {
    display: block;
    width: 47%;
    height: 45px;
    line-height: 3;
    background-color: #1D1D1B;
    color: ${props => props.theme.palette.primary};
    border-radius: 0;
    &:hover {
      background-color: #1D1D1B;
    }
  }
`;

export const ImageWrapper: any = styled.div`
  img {
    width: 100%;
    max-height: 229px;
    object-fit: contain;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {

  }
`;

export const datenschutzAkzeptierenWrapper: any = css`
  display: flex;
  max-width: 50%;
`;

export const datenschutzAkzeptieren: any = css`
  font-size: 12px;
  margin-left: 5px;
  a {
    color: ${props => props.theme.palette.primary};
  }
`;
