import React, { FC, useState } from "react";
import { IAsset } from "src/common";

import FullscreenImage from "../../../common/FullscreenImage";
import { RichText } from "../../../common/RichText";
import * as styles from "./NewsArticleItem.style";
import { Wrapper, HeadingWrapper } from "./NewsArticleItem.style";
import { richToTeaser } from "../../../../../../common/utils/text/teaser";

interface IProps {
    headline: string;
    text: any;
    index: number;
    images: IAsset[];
}

const NewsArticleItem: FC<IProps> = (props) => {
    const [isActive, setIsActive] = useState(props?.index === 0);
    if (!props.headline || !props?.text) {
        return null;
    }

    const teaser = richToTeaser(props?.text, 100);
    const teaserIsHeading = props.text?.content?.[0]?.type == "heading";

    return (
        <Wrapper>
            <p>{props.text.content?.[0]?.children?.[0]?.text || ""}</p>

            <div
                css={`
                    ${styles.imgAndText}
                `}
            >
                <div css={styles.imgAndSubtitleWrapper}>
                    {props.images?.map((asset: IAsset, i: number) =>
                        isActive ? (
                            <div className="image" key={`${i}${asset?.src}`}>
                                {asset && (
                                    <>
                                        <FullscreenImage img={[asset]} subtitle={asset.title} />
                                        {asset.description && <p css={styles.subTitle}>{asset.description}</p>}
                                    </>
                                )}
                            </div>
                        ) : (
                            i === 0 && (
                                <div className="image" key={`${i}${asset?.src}`}>
                                    {asset && (
                                        <>
                                            <FullscreenImage img={[asset]} subtitle={asset.title} />
                                            {asset.description && <p css={styles.subTitle}>{asset.description}</p>}
                                        </>
                                    )}
                                </div>
                            )
                        )
                    )}
                </div>
                <div className="richTextWrapper">
                    <HeadingWrapper onClick={() => setIsActive(!isActive)}>
                        <h3>{props.headline}</h3>
                    </HeadingWrapper>
                    {isActive ? (
                        <>
                            <RichText css={styles.isActive} textContent={props?.text} />
                        </>
                    ) : (
                        <div css={styles.isActive}>{teaserIsHeading ? <h3>{teaser}</h3> : <p>{teaser}</p>}</div>
                    )}

                    <div onClick={() => setIsActive(!isActive)}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            {isActive ? (
                                <>
                                    <p className="bottom-button" style={{ color: "#d7e8c9" }}>
                                        SCHLIESSEN
                                    </p>
                                    <svg
                                        style={{
                                            transform: "rotate(180deg) scale(.7)",
                                            marginLeft: "10px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="34"
                                        height="34"
                                        viewBox="0 0 24 24"
                                        fill="#76B729"
                                    >
                                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                                    </svg>
                                </>
                            ) : (
                                <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="34"
                                        height="34"
                                        viewBox="0 0 512 512"
                                        fill="#76B729"
                                    >
                                        <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                                    </svg>
                                    <p className="bottom-button" style={{ color: "#d7e8c9" }}>
                                        mehr
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default NewsArticleItem;
