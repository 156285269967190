import React, { FC, useContext } from "react";
import { Context, DateFormatted, getPage, Img, IPageCourse, ITextElement } from "src/common";
import RichTextGeneral from "../../common/RichText";
import { Wrapper, CourseItemWrapper, CourseContentWrapper, CourseDateWrapper } from "./CourseContent.style";

const CourseContent: FC = () => {
  const props = useContext(Context);
  const page = getPage(props) as IPageCourse;
  const courses = page?.courses;
  return (
    <Wrapper>
      {courses?.map((course) => {
        const text = course.elements?.find(head => head?.__typename === "TextElement") as ITextElement;
        return (
          <CourseItemWrapper key={course.id}>
            <h3>{text.headline}</h3>
            <div>
              <CourseContentWrapper>
                <div data-content>
                  <RichTextGeneral textContent={text.text}>
                    {text.icon &&
                      <img data-image src={text.icon[0].src} />
                    }
                  </RichTextGeneral>
                </div>

              </CourseContentWrapper>
              <CourseDateWrapper>
                <h3><DateFormatted date={course.startDate} /></h3>
                <p>Beginn: <DateFormatted date={course.startDate} format="HH:mm"/> Uhr</p>
                <p>Ende: <DateFormatted date={course.endDate} format="HH:mm" />  Uhr</p>
                <p>Preis p.P.: {course.pricePerParticipantInEuro} €</p>
                <p>Freie Plätze: {course.maxParticipants}</p>
                <p>
                  Anmeldeschluss: <DateFormatted date={course.registrationDeadline} format="MM.DD.YYYY hh:mm" />
                </p>

                <a>öffnen</a>
              </CourseDateWrapper>
            </div>
          </CourseItemWrapper>
        );
      })}
    </Wrapper>
  );
};

export default CourseContent;
