import React, { FC } from "react";
 ;
import MainContent from "../../content/MainContent";

const PageCourseT21: FC = () => (
  <>
    <MainContent />
     
  </>
);

PageCourseT21.displayName = "PageCourseT21";
export { PageCourseT21 };
