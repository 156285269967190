import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";

const PageMicroT24: FC = () => (
  <>
    <Header />
    <MainContent />
    <Footer />
     
  </>
);

PageMicroT24.displayName = "PageMicroT24";
export { PageMicroT24 };
