import React from "react";

import { Banner } from "../../Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { CommonPageBrandMicroMainContent } from "src/common/brand-area/PageBrandMicro/CommonPageBrandMicroMainContent";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandMicroT4 = () => (
    <div id="PageT400">
        <div id="PageT4T41"></div>
        <div id="PageT4T42">
            <MainNavigation />
        </div>
        <div id="PageT4T43">
            <Spacer bronze={72} silver={48} />
            <CommonPageBrandMicroMainContent />
        </div>
        <div id="PageT4T44">
            <Banner />
        </div>
        <div id="footer">
            <Footer />
        </div>
    </div>
);

PageBrandMicroT4.displayName = "PageBrandMicroT4";
export { PageBrandMicroT4 };
