import React, { Fragment, FunctionComponent, useContext } from "react";
import {
  getRandomColor,
  IContext,
  Context,
  IThemingColor,
  IAsset,
  IPage,
  ITextElement,
  ISlider,
} from "src/common";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import ImageWithAspectRatio from "../../../../../common/BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import {
  flexItem100,
  flexItem50,
  centeredContent,
  flexWrapper,
} from "../../../constants/layout";
import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { FounderForm } from "../../common/FounderForm/FounderForm";
import Slider from "../../elements/Slider/Slider";
import * as styles from "./styles";

const PageT5: FunctionComponent<any> = () => {
  const props = useContext<IContext>(Context);

  const isFounderPage =
    props?.dealer?.dachseite &&
    ["gruender", "grunder", "Gründer"].includes(props?.Page?.slug);

  const pageData: IPage = props?.Page || (props?.PageBrandOverview as any);
  let color = (
    pageData?.elements?.find(
      (e) => e?.__typename === "ThemingColor"
    ) as IThemingColor
  )?.color;
  const textElements = pageData?.elements?.filter(
    (extra) => extra?.__typename === "TextElement"
  ) as ITextElement[];
  const asset = pageData?.elements?.find(
    (field, index) => field?.__typename === "Asset" && index === 0
  ) as IAsset;

  const slider = pageData?.elements?.find(
    (field, index) => field?.__typename === "Slider"
  ) as ISlider;
  const otherImages = pageData?.elements?.filter(
    (field, index) => field?.__typename === "Asset" && (index > 0 || !!slider)
  ) as IAsset[];
  if (!color) {
    color = getRandomColor();
  }
  const universlElements = pageData?.elements?.filter(
    (e) =>
      e?.__typename === "ContinuingPageGallery" ||
      e?.__typename === "PhotoGallery"
  ) as any[];
  return (
    <div css={centeredContent}>
      <Header />
      <div css={flexWrapper}>
        <Fragment>
          {slider ? (
            <Slider {...slider}></Slider>
          ) : (
            <>
              {asset && (
                <div
                  css={`
                    ${flexItem100}${styles.heroImage}
                  `}
                >
                  <ImageWithAspectRatio
                    src={asset?.src}
                    bronze={1 / 1}
                    silver={1 / 2}
                    alt={asset?.description || "bild"}
                    srcSetWidths={{
                      bronze: 720,
                      silver: 1440,
                      gold: 1920,
                      diamond: 3600,
                    }}
                  />
                  {asset.description && <p>{asset.description}</p>}
                </div>
              )}
            </>
          )}
          <div
            css={`
              ${flexItem50}${styles.contentBox}
            `}
            style={{ border: `solid 2px ${color}` }}
          >
            {textElements?.length > 0 && (
              <>
                {textElements[0]?.headline && (
                  <h2 style={{ color }}>{textElements[0]?.headline}</h2>
                )}
                <RichText
                  textContent={textElements[0]?.text}
                  overwriteStyle={styles.richText}
                ></RichText>
              </>
            )}
          </div>
          <div
            css={`
              ${flexItem50}${styles.iconBox}
            `}
            style={{ backgroundColor: color }}
          >
            {otherImages?.[0] && (
              <div css={styles.imageInBox}>
                {otherImages.map((f, i) => (
                  <Fragment key={`fi${i}`}>
                    <Img quality={700} src={f?.src} alt={f?.description} />
                    {f.description && (
                      <p className="subtitle">{f.description}</p>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
          {textElements?.map((textElement, i) => {
            if (textElement?.__typename === "TextElement") {
              if (i === 0) {
                return null;
              }

              if (!textElement?.text) {
                return null;
              }
              return (
                <Fragment key={i}>
                  <div
                    css={`
                      ${flexItem50}${styles.hiddenOnMobile}
                    `}
                    style={{ backgroundColor: color }}
                  >
                    {textElement?.icon?.[0] && (
                      <div css={styles.imageInBox}>
                        {textElement?.icon.map((f, i) => (
                          <Fragment key={`fi${i}`}>
                            <Img
                              quality={700}
                              src={f?.src}
                              alt={f.description}
                            />
                            {f.description && <p>{f.description}</p>}
                          </Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    css={`
                      ${flexItem50}${styles.articleContentBox}
                    `}
                    style={{ backgroundColor: color }}
                  >
                    {textElement?.text && (
                      <>
                        {textElement?.headline && (
                          <h2 style={{ color }}>{textElement?.headline}</h2>
                        )}
                        <RichText
                          textContent={textElement?.text}
                          overwriteStyle={styles.richText}
                        ></RichText>
                      </>
                    )}
                  </div>
                </Fragment>
              );
            }
          })}
          {(pageData as any)?.connectedArticles?.map((article, i) => {
            if (article?.__typename === "Article") {
              const articleAsset = article?.extras?.find(
                (extra) => extra?.__typename === "Asset"
              ) as IAsset;
              return (
                <Fragment key={i}>
                  <div
                    css={`
                      ${flexItem50}${styles.articleAssetBox}
                    `}
                    style={{ backgroundColor: color }}
                  >
                    {articleAsset?.src && (
                      <>
                        <Img
                          quality={700}
                          src={articleAsset?.src}
                          alt={articleAsset?.description}
                        />
                        {articleAsset.description && (
                          <p>{articleAsset.description}</p>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    css={`
                      ${flexItem50}${styles.articleContentBox}
                    `}
                    style={{ backgroundColor: color }}
                  >
                    {article?.headline && (
                      <h2 style={{ color }}>{article?.headline}</h2>
                    )}
                    {article?.mainText && (
                      <RichText
                        textContent={article?.mainText}
                        overwriteStyle={styles.richText}
                      ></RichText>
                    )}
                  </div>
                </Fragment>
              );
            }
          })}
          {universlElements &&
            universlElements.map((item, i) => {
              if (
                item?.items?.[0]?.link?.[0]?.__typename?.includes("Product")
              ) {
                return (
                  <div
                    key={`uE${i}`}
                    css={`
                      ${styles.productGallery}
                    `}
                  >
                    <UniversalComponentsRenderer items={[item]} />
                  </div>
                );
              } else {
                return (
                  <div
                    key={`uE${i}`}
                    css={`
                      ${flexItem100}${styles.contentBox}
                    `}
                    style={{ border: `solid 2px ${color}` }}
                  >
                    <UniversalComponentsRenderer items={[item]} />
                  </div>
                );
              }
            })}
          {isFounderPage && <FounderForm />}
        </Fragment>
        <FooterT5 />
      </div>
    </div>
  );
};
PageT5.displayName = "PageT5";
export { PageT5 };
