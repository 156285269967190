import { CommonPageBrandTopicMainContent } from "src/common/brand-area/PageBrandTopic/CommonPageBrandTopicMainContent";

import React, { FunctionComponent } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PageBrandTopicT26: FunctionComponent = () => (
  <>
    <Header />
    <CommonPageBrandTopicMainContent />
    <Footer />
  </>
);

PageBrandTopicT26.displayName = "PageBrandTopicT26";
export { PageBrandTopicT26 };
