import * as React from "react";

import BrandBannerVertical from "../../common/BrandBannerVertical/BrandBannerVertical";
import Footer from "../../common/Footer";
import FurtherAwards from "../../common/FurtherAwards";
import Header from "../../common/Header";

import MainContent from "../../content/MainContent";

const PageBrandOverviewT8 = () => 
  (
    <>
       
      <Header />
      <MainContent topComponent={BrandBannerVertical} />
      <FurtherAwards />
      <Footer />
    </>
  );
PageBrandOverviewT8.displayName = "PageBrandOverviewT8";
export { PageBrandOverviewT8 };
