import styled from "styled-components";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const Wrapper: any = styled.div``;

export const CourseItemWrapper: any = styled.div<{ primaryColor?: string }>`
  color: rgb(32, 33, 34);
  font-family: sans-serif;
  font-size: 14px;

  h3.color {
    color: ${(props) => props.primaryColor || "#000000"};
    font-size: 16px;
  }

  > div {
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-direction: row;
    }
  }
`;

export const CourseContentWrapper: any = styled.div<{ primaryColor?: string }>`
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 75%;
  }
  .imageWrapper {
    width: 33.3%;
    float: left;
    margin-right: 28px;
  }

  [data-image] {
    margin-bottom: 14px;
  }

  p {
    &,
    span {
      font-family: sans-serif;
      line-height: 160% !important;
    }
  }

  a {
    color: ${(props) => props.primaryColor || "#000000"};
  }
`;

export const CourseDateWrapper: any = styled.div<{ primaryColor?: string }>`
  width: 100%;
  margin-top: 14px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 25%;
    padding-left: 32px;
    margin-top: 0px;
  }
  h3,
  h3 > span {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  div.icon {
    width: 8px;
    margin-left: 5px;
    padding-top: 2px;
  }

  p {
    &,
    span {
      font-size: 14px;
      line-height: 17px;
    }
  }
  a {
    background-color: ${(props) => props.primaryColor || "#000000"};
    border: 1px solid ${(props) => props.primaryColor || "#000000"};
    color: #efefef;
    cursor: pointer;
    display: block;
    line-height: 14px;
    margin-top: 20px;
    padding: 5px 10px;
    &:hover {
      background-color: ${(props) => props.primaryColor || "#000000"};
    }
    width: 100%;
    text-align: center;
    align-content: center;
  }
`;

export const CourseImageWrapper: any = styled.div`
  width: 33.3%;
  margin-right: 34px;
  max-height: 290px;
`;
