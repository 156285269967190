import React, { Fragment, FunctionComponent } from "react";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { PageDateForm } from "./Form";
import * as styles from "./pageDateInquiry.style";

const PageDateInquiryT6: FunctionComponent = () => (
    <Fragment>
        <Header/>
        <div css={styles.wrapper}>
            <div css={centeredContent}>
                <div css={contentWrapper}>
                    <PageDateForm/>
                </div>
            </div>
        </div>
        <Footer showLogos={false}/>
    </Fragment>
);

PageDateInquiryT6.displayName = "PageDateInquiryT6"
export {PageDateInquiryT6}