import React, { Fragment } from "react";
import { ITextElement, RichText } from "src/common";
import { GuestbookStars } from "src/common/components/GuestbookStars/GuestbookStars";
import * as styles from "../../Guestbook.style";
import * as initialStyles from "./Initial.style";

const Initial = (props: {textBlocks: any, universalTexts: any, onClick: any, guestbookEntries: any, options: any}): any => {
    
    return (
        <Fragment>
            {props.textBlocks?.map((tb: ITextElement, i: number) => <Fragment key={`textblock${i}`}> 
                {tb.headline &&
                    <div css={initialStyles.headlineWrapper}>
                        <h1>
                            {tb.headline}
                        </h1>
                    </div>
                }
                <RichText overwriteStyle={initialStyles.richtextStyle} textContent={tb.text}></RichText>
            </Fragment>
            )}
            <div css={initialStyles.buttonWrapper}>
                <button onClick={props.onClick}>
                    {props.universalTexts?.guestbook_writeContribution ?
                        <RichText overwriteStyle={styles.labelRichtext} textContent={props.universalTexts?.guestbook_writeContribution}></RichText>
                        : "Beitrag verfassen"
                    }
                </button>
            </div>
            <div css={styles.contentWrapper}>
                <div className="entry">
                    {props.guestbookEntries?.map((entry, i) => {
                        return <div 
                            key={i} 
                            css={`${i % 2 === 1 ? initialStyles.odd : styles.even}`}>
                            <span className="info">
                                {entry.firstname} {entry.lastname} schrieb am {new Date(entry._meta.publishedAt).toLocaleDateString('de-DE', props.options)}:
                            </span>
                            <div className="title_stars_wrapper">
                                {entry.Evaluation === 0 || !entry.Evaluation ? 
                                    <h3>{entry.title}</h3>  
                                    : 
                                    <>
                                        <h3>
                                            {entry.title}
                                        </h3>
                                        <div className="title_stars_wrapper__stars">
                                            <GuestbookStars 
                                                changeable={false} 
                                                amountOfStars={entry.Evaluation}
                                                starSize={18}/>   
                                        </div>  
                                    </>
                                }
                                </div>
                            {entry.Message.content.map(message => {
                                return <p>{message.children?.[0].text}</p>
                            })}
                        </div>
                    })}
            </div>
        </div>
    </Fragment>
    );
};

export default Initial;
