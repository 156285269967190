import { useRouter } from "next/router";
import { FC, useContext } from "react";
import { useDealerSearchResult } from "src/common/hooks/useDealerSearch";
import Search from "../../common/Search/Search";
import {
  SSearchContent,
  SSearchContentBelowHero,
  SSearchContentDealerItem,
  SSearchContentDealersList,
  SSearchContentDetailsLink,
  SSearchContentHero,
  SSearchContentHeroLogo,
  SSearchContentHeroTitle,
  SSearchContentNoDealers,
} from "./SearchContent.style";
import { FriendlyCaptcha } from "../../../../../common/components/friendly-capture";
import { css } from "styled-components";
import { Context } from "../../../../../common";

const SearchContent: FC = () => {
  const { dealers, friendlyCaptchaToken, isLoading } = useDealerSearchResult();
  const { query } = useRouter();
  const props = useContext(Context);
  return (
    <SSearchContent>
      <SSearchContentHero>
        <SSearchContentHeroLogo>
          {props.CompanyInformationPublic.logo?.[0]?.src && (
            <img src={props.CompanyInformationPublic.logo?.[0]?.src} />
          )}
        </SSearchContentHeroLogo>
        <SSearchContentHeroTitle>
          <h1>IN IHRER NÄHE</h1>
        </SSearchContentHeroTitle>
      </SSearchContentHero>
      <SSearchContentBelowHero>
        <div></div>
        <p>
          Geben Sie Ihren Ort oder Ihre Postleitzahl ein, um die drei nächsten
          Küchenhäuser in Ihrer Umgebung zu finden.
        </p>
      </SSearchContentBelowHero>
      <Search isContact />

      {query?.search ? (
        <>
          {friendlyCaptchaToken ? (
            <>
              {isLoading || !dealers || dealers?.length === 0 ? (
                <SSearchContentNoDealers>
                  {isLoading ? (
                    <h2>Einen Moment bitte ...</h2>
                  ) : (
                    <>
                      <h2>Keine Ergebnisse gefunden</h2>
                      <p>
                        In Ihrem gewünschten Gebiet befindet sich kein passender
                        Fachhändler. Kompetente Partner finden Sie zusätzlich{" "}
                        <a href="">hier.</a>
                      </p>
                    </>
                  )}
                </SSearchContentNoDealers>
              ) : dealers ? (
                <SSearchContentDealersList>
                  {dealers.map((dealer) => (
                    <SSearchContentDealerItem key={dealer.company_id}>
                      <p>Gute Küchen</p>
                      <h3>
                        <a href={dealer.domain} target="_blank">
                          {dealer.company_name}
                        </a>
                      </h3>
                      <h3>
                        {dealer.street}, {dealer.zip} {dealer.city}
                      </h3>
                      <SSearchContentDetailsLink
                        href={dealer.domain}
                        target="_blank"
                      >
                        DETAILS
                      </SSearchContentDetailsLink>
                    </SSearchContentDealerItem>
                  ))}
                </SSearchContentDealersList>
              ) : null}
            </>
          ) : (
            <div
              css={css`
                padding: 24px;
                padding-top: 64px;
              `}
            >
              Bitte bestätigen Sie, dass Sie kein Roboter sind. Danach werden
              die Suchergebnisse angezeigt.
              <FriendlyCaptcha />
            </div>
          )}
        </>
      ) : null}
    </SSearchContent>
  );
};

export default SearchContent;
