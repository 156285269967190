import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Context, ITextElement, IAsset } from "src/common";
import {
  getContactCompanyNamePrefix,
  getContactCompanyNameSuffix,
} from "../../../../../common/components/ImprintStart";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import Spacer from "../../../BaseComponents/Spacer/Spacer";
import { centeredContent } from "../../../constants/layout";
import { Banner } from "../../common/Banner/Banner";
import { FooterT3 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";

import * as styles from "./Contact.page.style";
import { Wrapper } from "./Contact.page.style";
import { ContactForm } from "./contactForm/ContactForm";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";
import { getOpeningHoursContent } from "../../../../../common/utils/getOpeningHoursContent";

interface ILocation {
  lat: number;
  lng: number;
}

const PageContactT3: FunctionComponent = () => {
  const pageProps = useContext(Context);
  const palette = pageProps?.Settings;
  const [inputUserLocation, setInputUserLocation] = useState("");
  const [companyLocation, setCompanyLocation] = useState<ILocation>(null);
  const [areDirectionsVisible, setAreDirectionsVisible] = useState(true);
  const contactPage = pageProps?.PageContact;
  const universalTexts = pageProps?.UniversalTexts;
  const companyInformationPublic = pageProps?.CompanyInformationPublic;
  const mapElementId = "map";
  const routePanelId = "routePanel";
  const googleApiKey =
    process.env.NEXT_PUBLIC_MAPSAPIKEY || process.env.MAPSAPIKEY;

    const openingHoursContent = getOpeningHoursContent(false);


  const textElements = contactPage?.elements?.filter(
    (e) => e?.__typename === "TextElement"
  ) as ITextElement[];
  const asset = contactPage?.elements?.find(
    (e) => e?.__typename === "Asset"
  ) as IAsset;

  useEffect(() => {
    setCompanyLocation({
      lat: contactPage?.location?.latitude,
      lng: contactPage?.location?.longitude,
    });
  }, []);

  useEffect(() => {
    if (!companyLocation) {
      return;
    }

    // @ts-ignore
    if (window?.google) {
      setMap();
      return;
    }

    const s = document?.createElement("script");
    s.type = "text/javascript";
    s.src = `https://maps.google.com/maps/api/js?key=${googleApiKey}`;

    const x = document?.getElementsByTagName("script")[0];
    x?.parentNode?.insertBefore(s, x);
    s?.addEventListener("load", setMap);

    return () => s?.removeEventListener("load", setMap);
  }, [companyLocation]);

  const getRouteDescriptions = (): void => {
    document.getElementById(routePanelId).innerHTML = null;

    // @ts-ignore
    const windowGoogleMap = window?.google?.maps;
    const directionsService = new windowGoogleMap.DirectionsService();
    const directionsRenderer = new windowGoogleMap.DirectionsRenderer();

    const map = new windowGoogleMap.Map(document.getElementById(mapElementId));

    directionsRenderer.setMap(map);
    directionsRenderer.setPanel(document.getElementById(routePanelId));

    const request = {
      origin: inputUserLocation,
      destination: `${companyLocation.lat},${companyLocation.lng}`,
      travelMode: "DRIVING",
    };

    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        directionsRenderer.setDirections(result);
      } else {
        setMap();
      }
    });
  };

  const setMap = (): void => {
    if (!companyLocation) {
      return;
    }
    // @ts-ignore
    const windowGoogleMap = window?.google?.maps;

    const mapId = document.getElementById(mapElementId);
    const map = new windowGoogleMap.Map(mapId, {
      zoom: contactPage?.location?.zoom || 12,
      center: companyLocation,
      disableDefaultUI: true,
    });
    new windowGoogleMap.Marker({ position: companyLocation, map });
  };

  const getUserLocation = (): void => {
    if (navigator?.geolocation) {
      navigator?.geolocation.getCurrentPosition(
        (position) => {
          setInputUserLocation(
            `${position?.coords?.latitude},${position?.coords?.longitude}`
          );
        },
        (error) => {
          console.info("Standort konnte nicht abgerufen werden: ", error);
        }
      );
    } else {
      console.info("Standort konnte nicht abgerufen werden");
    }
  };

  return (
    <Fragment>
      <Banner />
      <Header />
      <div css={styles.map} id={mapElementId} />
      <div
        css={`
          ${centeredContent}
        `}
      >
        <Wrapper color={palette.themeTextColor}>
          <div css={styles.companyInfosAndContactForm}>
            <div css={styles.companyInfosWrapper}>
              <div css={styles.addressWrapper}>
                {(companyInformationPublic?.companyNameOverwriteContact ||
                  companyInformationPublic?.companyName) && (
                  <h2>
                    {getContactCompanyNamePrefix()}
                  </h2>
                )}
                {companyInformationPublic?.street && (
                  <p>{companyInformationPublic.street}</p>
                )}
                {companyInformationPublic?.postcode &&
                  companyInformationPublic.place && (
                    <p>
                      {companyInformationPublic.postcode}{" "}
                      {companyInformationPublic.place}
                    </p>
                  )}
                <br />
                {companyInformationPublic?.phone && (
                  <p>Tel: {companyInformationPublic.phone}</p>
                )}
                {companyInformationPublic?.fax && (
                  <p>Fax: {companyInformationPublic.fax}</p>
                )}
              </div>
              <div>
                <p className="openinghours" css={styles.openingHoursHeading}>Öffnungszeiten</p>
                {openingHoursContent && (
                  <RichText
                    textContent={openingHoursContent}
                    overwriteStyle={styles.openingHoursRichtext}
                  ></RichText>
                )}
                <div>
                  <br />
                  {textElements?.[0] && (
                    <RichText
                      textContent={textElements?.[0]?.text}
                      overwriteStyle={styles.openingHoursRichtext}
                    >
                      <h1>{textElements?.[0]?.headline}</h1>
                    </RichText>
                  )}
                  {textElements?.[1] && (
                    <RichText
                      textContent={textElements?.[1]?.text}
                      overwriteStyle={styles.openingHoursRichtext}
                    >
                      <h1>{textElements?.[1]?.headline}</h1>
                    </RichText>
                  )}
                </div>
                {asset?.src && (
                  <>
                    <Spacer bronze={20} gold={40} />
                    <div>
                      <Img
                        src={asset?.src}
                        quality={400}
                        alt={asset.description}
                      ></Img>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              <ContactForm />
            </div>
          </div>
          {areDirectionsVisible && (
            <div css={styles.contentArea_directionsPanel} className="direction-panel">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h6>{universalTexts?.StartAddress || "Startadresse"}</h6>
                <svg
                  css={styles.closeDirectionsPanelSvg}
                  onClick={() => setAreDirectionsVisible(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                </svg>
              </div>
              <div css={styles.routeBerechnenWrapper}>
                <form css={styles.buttonLocationInputWrapper} onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  console.log(` e`, e);
                }}>
                  <div css={styles.locationInputWrapper}>
                    <input
                      type="text"
                      name={"UserLocation"}
                      placeholder={
                        universalTexts?.yourLocation || "Ihr Standort"
                      }
                      value={inputUserLocation}
                      onChange={(event) =>
                        setInputUserLocation(event.target.value)
                      }
                    />
                    <svg
                      onClick={getUserLocation}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 11h-2.051c-.469-4.725-4.224-8.48-8.949-8.95v-2.05h-2v2.05c-4.725.47-8.48 4.225-8.949 8.95h-2.051v2h2.051c.469 4.725 4.224 8.48 8.949 8.95v2.05h2v-2.05c4.725-.469 8.48-4.225 8.949-8.95h2.051v-2zm-11 8.931v-3.931h-2v3.931c-3.611-.454-6.478-3.32-6.931-6.931h3.931v-2h-3.931c.453-3.611 3.32-6.477 6.931-6.931v3.931h2v-3.931c3.611.454 6.478 3.319 6.931 6.931h-3.931v2h3.931c-.453 3.611-3.32 6.477-6.931 6.931zm1-7.931c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z" />
                    </svg>
                  </div>
                  <button className="notheme" onClick={getRouteDescriptions} type="submit" name={"submit"}>
                    {universalTexts?.CalculateRoute || "Route berechnen"}
                  </button>
                </form>
                {/* <div>
                  <br/>
                  {textElements?.[0] && (
                    <RichText textContent={textElements?.[0]?.text}>
                      <h1>{textElements?.[0]?.headline}</h1>
                    </RichText>
                  )}
                  {textElements?.[1] && (
                    <RichText textContent={textElements?.[1]?.text}>
                      <h1>{textElements?.[1]?.headline}</h1>
                    </RichText>
                  )}
                </div> */}
              </div>
              <div css={styles.directionsPanel} id={routePanelId} />
            </div>
          )}
        </Wrapper>
        <PlanningDateModal />
      </div>
      <FooterT3 />
    </Fragment>
  );
};

PageContactT3.displayName = "PageContactT3";
export { PageContactT3 };
