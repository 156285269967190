import React from "react";
import { flexItem100, centeredContent, flexWrapper } from "../../../constants/layout";
import Header from "../../common/header/Header";
import { FooterT5 } from "../../common/footer/Footer";

const PageGuestbookT5 = () => {
    return (
        <div css={centeredContent}>
            <Header />
            <div css={flexWrapper}>
                <div
                    css={`
                        ${flexItem100}$
                    `}
                >
                    <h1>This page is not supported by this template</h1>
                </div>
                <FooterT5 />
            </div>
        </div>
    );
};
PageGuestbookT5.displayName = "PageGuestbookT5";
export { PageGuestbookT5 };
