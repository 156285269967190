import * as React from "react";

import { Banner } from "../../common/Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import MainNavigation from "../../common/MainNavigation/MainNavigation";

const PageT1 = () => 
  (
    <>
      <div>
        <Banner />
      </div>
      <div>
        <MainNavigation />
        <MainContent />
        <Footer />
      </div>
    </>
  );
PageT1.displayName = "PageT1";
export { PageT1 };
