import styled from "styled-components";

export const SDealerItemsContainer = styled.div`
  display: flex;
  margin-top: 13.5px;
  gap: 13.5px;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: 1;
  width: 100%;
`;
