import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import NewsContent from "../../content/NewsContent";

const PageNewsT8: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <NewsContent />
      </Section>
    </Grid>
    <Footer />
     
  </>
);

PageNewsT8.displayName = "PageNewsT8";
export { PageNewsT8 };

