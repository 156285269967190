import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const productGallery: any = css`
  width: 100%;
`;

export const heroImage: any = css`
  padding: 0;
  img {
    width: 100%;
    height: auto;
  }
`;

export const imageInBox: any = css`
  padding: 0;
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
  img {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 1000px;
  }
  p:not(:last-child) {
    margin-bottom: 50px;
  }
`;

export const contentBox: any = css`
  h2 {
    font-size: 24px;
    line-height: 120% !important;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
`;

export const richText: any = css`
  margin-bottom: 10px;
  position: relative;
  iframe {
    width: 100%;
  }
  br {
    margin-top: 10px;
  }
  h3,
  h4 {
    color: ${colors.blue};
    font-size: 20px;
    line-height: 120% !important;
  }
  ul {
    margin-left: calc(1em + 2px);
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: disc;
    }
  }
  ol {
    margin-left: calc(2rem + 2px);
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: decimal;
    }
  }
`;

export const iconBox: any = css`
  img {
    width: 100%;
    height: auto !important;
    object-fit: contain !important;
  }
`;

export const articleAssetBox: any = css`
  img {
    width: 100%;
    height: auto;
  }
`;

export const articleContentBox: any = css`
  * {
    color: white !important;
  }

  h2,
  h3,
  h4 {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }

  h2 {
    margin-bottom: 1rem;
  }
  a {
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const hiddenOnMobile: any = css`
  display: block;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    /* display: none; */
  }
`;
