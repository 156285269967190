import styled from "styled-components";

export const SSearchContentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid black;

  h1 {
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 3.9px;
    color: #736654;
  }
`;

export const SSearchContentResultsInput = styled.div`
  border-bottom: 1px solid black;
  padding: 20px;

  h3 {
    font-weight: 300;
    padding: 0;
    margin: 0;
    font-size: 25px;
    line-height: 28px;
    color: #736654;
    padding-bottom: 10px;
  }

  p {
    margin-top: 20px;
    font-weight: 300;
  }

  form {
    position: relative;
    border: 1px solid #7d7261;

    input {
      width: 100%;
      height: 70px;
      font-size: 30px;
      padding-left: 20px;
      border: none;
    }

    input[type="submit"] {
      position: absolute;
      background: url(https://www.kuechengilde.de/images/icon/kg_icon_search.png)
        no-repeat;
      top: 10px;
      right: 10px;
      width: 55px;
      height: 51px;
      color: transparent;
    }
  }
`;

export const SSearchContentFooter = styled.div`
  ul {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 40px;
    li {
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 300;
      display: table-cell;
      background: url(https://www.kuechengilde.de/images/icon/kg_icon_link_arrow.jpg)
        no-repeat;
      background-position-y: center;
      padding-left: 25px;
      margin-top: 0px;
      height: 40px;
      max-height: 40px;
      vertical-align: middle;
      margin-right: 30px;
      display: flex;
      align-items: center;

      a {
        font-weight: 300;
        align-items: center;
        color: #736654;

        svg {
          fill: #706452;
        }
      }
    }
  }
`;

export const SSearchContentNoResults = styled.div`
  margin-top: 40px;
  h3 {
    padding: 0;
    margin: 0;
    font-size: 25px;
    line-height: 28px;
    color: #736654;
    font-weight: 300;
  }

  a {
    background: url(https://www.kuechengilde.de/images/icon/kg_icon_link_arrow.jpg)
      no-repeat;

    cursor: pointer;
    font-weight: 300;
    display: inline-block;
    height: 30px;
    padding-top: 5px;
    padding-left: 25px;
    margin-top: 10px;
  }
`;

export const SSearchContentResult = styled.div`
  border-bottom: 1px solid black;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 300;

  a {
    background: url(https://www.kuechengilde.de/images/icon/kg_icon_link_arrow.jpg)
      no-repeat;

    cursor: pointer;
    font-weight: 300;
    display: inline-block;
    height: 30px;
    padding-top: 5px;
    padding-left: 25px;
    font-size: 14px;
  }

  h3 {
    font-weight: 400;
    padding: 0;
    margin: 0;
    font-size: 25px;
    line-height: 28px;
    color: #736654;
    display: flex;
    flex-direction: column;
    font-weight: 300;
    margin-top: 14px;

    a {
      text-decoration: none;
      background: none;
      height: unset;
      padding: 0;
      font-size: 25px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h4 {
    font-weight: 300;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const SSearchContent: any = styled.div``;
