import React, { FunctionComponent, useContext } from "react";
import {  Context, ITextElement, IAsset, ImprintStart, Img, getCurrentNavItem } from "src/common";
import { ImprintNameAndAddressOfThePersonInCharge } from "src/common/components/ImprintNameAndAddressOfThePersonInCharge";

import RichText from "../../../BaseComponents/RichText/RichText";
import { centeredContent } from "../../../constants/layout";
import { Banner } from "../../common/Banner/Banner";
import { FooterT3 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { ImgGallery } from "../../common/imgGallery/ImgGallery";
import LandingImage from "../../common/landingImage/LandingImage";
import * as styles from "./Slug.style";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";

const PageImprintT3: FunctionComponent = () => 
{
  React.useEffect(() => 
  {
    if(typeof window !== "undefined" && window.location.hash && typeof document !== "undefined") 
    {
      const searchTream = `${window.location.hash}`.replace("#", "");
      const xpath = `//span[contains(text(),'${searchTream}')]`;
      const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)?.singleNodeValue;
      if(matchingElement) 
      {
        // @ts-ignore
        matchingElement.scrollIntoView();
      }
    }
  }, []);
  const pageProps = useContext(Context);
  const pageContent = pageProps?.PageImprint;
  const images = pageContent.elements?.filter(item => item?.__typename === "Asset") as IAsset[];

  const textElements = pageContent?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[];

  let currentNavItem = getCurrentNavItem(pageProps?.PrimaryNavigation?.items, pageProps?.slug);

  if(!currentNavItem) 
  {
    for(const navItem of pageProps?.PrimaryNavigation?.items) 
    {
      if(navItem?.subitems?.length > 0) 
      {
        for(const subNavItem of navItem.subitems) 
        {
          if(subNavItem?.slug === pageProps?.slug) 
          {
            currentNavItem = navItem;
            break;
          }
        }
      }
    }
  }
  const palette = pageProps?.Settings;
  return (
    <div css={styles.wrapper} >
      {pageContent?.imageFixedBackground && images?.length > 0 && (
        <LandingImage img={images?.[0]?.src} />
      )}
      <Banner />
      <PlanningDateModal />
      <Header />
      <styles.PageContentWrapper backgroundColor={palette.themeBackgroundColor} primaryColor={palette.themeColorPrimary} textColor={palette.themeTextColor}>
        {pageContent?.imageFixedBackground && (
          <div css={centeredContent}>
            <div css={styles.flexWrapper}>
              <div>
                {currentNavItem?.label && <h1 css={styles.heading}>{currentNavItem.label}</h1>}
                {textElements?.[0]?.headline && <h2 css={styles.heading}>{textElements?.[0]?.headline}</h2>}
                <div css={styles.mainText}>
                  <ImprintStart />
                  <h2></h2>
                  <h2></h2>
                  {textElements.map((textElement, indexTextElement) => 
                    <React.Fragment key={`indexTextElement${indexTextElement}`}>
                      {textElement?.icon?.map((i, index) => 
                      {
                        if(i) 
                        {
                          return <Img key={`i${index}`} quality={1020} src={[i]} alt={i.title}></Img>;
                        }
                        return null;
                      })}
                      {textElement?.text && <RichText fragment textContent={textElement?.text} />}
                      {indexTextElement === 0 && <ImprintNameAndAddressOfThePersonInCharge />}
                    </React.Fragment>)}
                </div>
              </div>
            </div>
          </div>
        )}
        {!pageContent?.imageFixedBackground && (
          <div css={centeredContent}>
            {images?.length === 1 && (
              <img css={styles.mainImageNotFixed} src={images?.[0]?.src} alt="" />
            )}
            {images?.length > 1 && (
              <ImgGallery
                images={images.map(i => 
                  ({
                    src: i?.[0]?.src,
                    subtitle: i.subtitle
                  }))}
              />
            )}
            <div>
              {textElements?.[0]?.headline && <h1 css={styles.headingImageNotFixed}>{textElements?.[0]?.headline}</h1>}
              <div css={styles.mainText}>
                <ImprintStart />
                <br></br>
                {textElements.map((textElement, indexTextElement) => 
                  <React.Fragment key={`indexTextElement${indexTextElement}`}>
                    {textElement?.icon?.map((i, index) => 
                    {
                      if(i) 
                      {
                        return <Img key={`i${index}`} quality={1020} src={[i]} alt={i.title}></Img>;
                      }
                      return null;
                    })}
                    {textElement?.text && <RichText fragment textContent={textElement?.text} />}
                    {indexTextElement === 0 && <ImprintNameAndAddressOfThePersonInCharge />}
                  </React.Fragment>)}
              </div>
            </div>
          </div>
        )}
      </styles.PageContentWrapper>
      <FooterT3 />
    </div>
  );
};
PageImprintT3.displayName = "PageImprintT3";
export { PageImprintT3 };
