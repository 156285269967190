import React, { FC } from "react";
import { Context, IContext } from "src/common";
import { Wrapper }   from "./ServiceNumber.style";

const ServiceNumber: FC<any> = (props) => {
    const p = React.useContext<IContext>(Context);
    if(!p.CompanyInformationPublic?.phone)
    {
        return null;
    }

    return (
        <Wrapper>
            <span> 
                {(!!p.CompanyInformationPublic?.serviceNumber) ? 
                p.CompanyInformationPublic?.serviceNumber 
                : 
                p.CompanyInformationPublic?.phone }
            </span>
            <span>unsere Servicenummer</span>
        </Wrapper>
    );
};

export default ServiceNumber;