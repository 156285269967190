import * as React from "react";

import { Banner } from "../../common/Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import MainNavigation from "../../common/MainNavigation/MainNavigation";

const PageBrandOldT1 = () => 
  (
    <>
      <div>
        <Banner />
      </div>
      <div>
        <MainNavigation />
        <MainContent />
        <Footer />
      </div>
    </>
  );
PageBrandOldT1.displayName = "PageBrandOldT1";
export { PageBrandOldT1 };
