import React, { FC, useContext, useState } from "react";
import { Context, IAsset, getIfNewsShouldBeDisplayByDate, INews } from "src/common";

import { Container } from "../../common/Grid";
import NewsArticleItem from "./NewsArticleItem";
import { Wrapper } from "./NewsContent.style";
import LinkBannerTeaser from "../../common/LinkBannerTeaser/LinkBannerTeaser";

const NewsContent: FC<any> = () => {
    const p = useContext(Context);
    const [currentPage, setCurrentPage] = useState<number>(1);

    if (!p.PageNews?.NewsArticles) {
        return null;
    }

    const news = p.PageNews;
    const newsArticles = news?.NewsArticles?.filter((article) =>
        getIfNewsShouldBeDisplayByDate({
            startDate: article?.startDate,
            endDate: article?.endDate,
        })
    ).filter((article) => article !== null);

    const newsCustomArticles = news?.NewsArticlesCustom?.filter((article) =>
        getIfNewsShouldBeDisplayByDate({
            startDate: article?.startDate,
            endDate: article?.endDate,
        })
    ).filter((article) => article !== null);

    const articles = [...newsCustomArticles, ...newsArticles];

    const itemsPerPage = 10;

    const amountOfPages = Math.ceil(articles.length / itemsPerPage);
    const articlesToRender: INews[] = articles.slice((currentPage - 1) * itemsPerPage, itemsPerPage);
    // for (let i = (currentPage - 1) * itemsPerPage; i < (currentPage - 1) * itemsPerPage + itemsPerPage; i++) {
    //   articlesToRender.push(articles[i]);
    // }

    return (
        <Container>
            <Wrapper>
                {articlesToRender &&
                    articlesToRender.map(
                        (article, index) =>
                            article && (
                                <NewsArticleItem
                                    key={`newsArticleItem${index}`}
                                    index={index}
                                    headline={article?.headline}
                                    text={article?.text}
                                    teaser={article?.teaser}
                                    images={
                                        (article?.extras?.filter((e) => e?.__typename === "Asset") as
                                            | IAsset[]
                                            | undefined) ?? []
                                    }
                                />
                            )
                    )}
            </Wrapper>
            <LinkBannerTeaser />
        </Container>
    );
};

export default NewsContent;
