import React, { FC, useState } from "react";
import { ICourse } from "src/common/childTypes";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";

const PageCourseT23: FC = () => 
{
  const [course, setCourse] = useState<ICourse>(null);

  return (
    <>
      <Header />
      <Grid>
        <Section>
          <MainContent coursePage course={course} onSetCourse={setCourse}/>
        </Section>
      </Grid>
      <Footer />
       
    </>
  );
};

PageCourseT23.displayName = "PageCourseT23";
export { PageCourseT23 };
