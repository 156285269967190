import React, { FC, useContext } from "react";
import { Context, DateFormatted, getPage, IAsset, Img, IPageCourse, ITextElement } from "src/common";
import { ICourse } from "src/common/childTypes";

import { Section } from "../../common/Grid";
import { Wrapper, CourseItemWrapper, CourseImageWrapper, CourseDateWrapper } from "./CourseContent.style";
import CourseForm from "./CourseForm";

const CourseContent: FC<{ course: ICourse; onSetCourse: (ICourse) => void }> = ({ course, onSetCourse }) => 
{
  const props = useContext(Context);
  const page = getPage(props) as IPageCourse;
  const courses = page?.courses;
  return (
    <Section>
      <>
        { course
          ? <CourseForm course={course} onSetCourse={onSetCourse}/>
          : <Wrapper>
            {courses?.map((currentCourse) => 
            {
              const text = currentCourse.elements?.find(head => head?.__typename === "TextElement") as ITextElement;
              const icon = currentCourse.elements?.find(head => head?.__typename === "Asset") as IAsset;

              return (
                <CourseItemWrapper key={currentCourse.id}>
                  <a onClick={() => onSetCourse(currentCourse)}>
                    <CourseImageWrapper>
                      {icon &&
                        <div data-image>
                          <Img src={[icon]}/>
                        </div>
                      }
                    </CourseImageWrapper>

                    <CourseDateWrapper>
                      <h3 className="title">{text.headline}</h3>
                      <h2 className="title-2">Termin: <DateFormatted date={currentCourse.startDate} /> <DateFormatted date={currentCourse.startDate} format="HH:mm"/> Uhr</h2>
                      <p>Kurzbeschreibung</p>
                    </CourseDateWrapper>
                  
                  </a>
                </CourseItemWrapper>
              );
            })}
          </Wrapper>
        }
      </>
    </Section>
  );
};

export default CourseContent;
