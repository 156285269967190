import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
    margin-bottom: 40px;
`;

export const headingWrapper: any = css`
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const h3: any = css`
    color: ${props => props.theme.palette.yellow};
    font-weight: 400;
    text-transform: uppercase;
    font-size: 15px;
`;

export const svg: any = css`
    transform: scale(.7);
    padding-left: 10px;
`;

export const isActive: any = css`
    h4 {
        span {
            font-weight: bold;
        }
    }
    overflow: hidden;
    height: auto;
    max-height: 2000px;
    transition: max-height 5s ease-out;
`;

export const isNotActive: any = css`
    h4 {
        span {
            font-weight: bold;
        }
    }
    overflow: hidden;
    height: auto;
    max-height: 0;
    transition: max-height 1s ease;
`;

export const imgAndText: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        align-items: flex-start;
    }
    h2, h3{
        &,span{
            color: #fed061;
            font-weight: 400;
            font-size: 18px;
        }
    }
    strong{
        &,span{
            font-weight: 400;
        }
    }
    p > a, a{
        &,span{
            color: #fed061;
            font-size: 14px;
        }
    }
    p > a.download {
        color: ${props => props.theme.palette.yellow};
        &::after {
            content: "";
            display: inline-block;
            background-image: url("/images/sprite-s82c5aa31b4.png");
            background-repeat: no-repeat;
            background-position: -20px -36px;
            width: 15px;
            height: 15px;
            margin-left: 5px;
            transform: translateY(3px);
        }
    }
`;

export const imgAndSubtitleWrapper: any = css`
    @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-right: 20px;
    }
`;

export const imageRow: any = css`
   display: flex;
   min-width: 33%;
   flex-direction: column;
   > div {
    margin-bottom: 20px;
   }
    img {
        max-width: 320px;
    }
`;

export const subTitle: any = css`
    background-color: #E6E7E9;
    padding: 10px;
    border-radius: 0 0 8px 8px;
    font-size: 12px;
    text-align: center;
`;
