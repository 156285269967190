import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const GuestBookEntriesWrapper: any = styled.div`
  position: relative;
  margin-top: 24px;

  border-top: 1px solid

  h1 {
    color: ${props => props.theme.palette.green};
  }

  span {
    font-weight: 300;
  }
`;