import React, { FunctionComponent } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header/Header";


import { CommonPageBrandMicroMainContent } from "src/common/brand-area/PageBrandMicro/CommonPageBrandMicroMainContent";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandMicroT11: FunctionComponent = () => (
    <div>
        <Header />
        <Grid>
            <Section>
                <Spacer bronze={100} silver={86} />
                <CommonPageBrandMicroMainContent />
            </Section>
        </Grid>
        <Footer />
    </div>
);

PageBrandMicroT11.displayName = "PageBrandMicroT11";
export { PageBrandMicroT11 };
