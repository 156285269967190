import React, { Fragment, FC, useContext, useState } from "react";
import {
  IContext,
  Context,
  getPage,
  ITextElement,
  getTextBlocks,
  IAsset,
} from "src/common";
import { Row, Col } from "src/templates/t9/BaseComponents/Grid";
import ImageWithAspectRatio from "src/templates/t9/BaseComponents/ImageWithAspectRatio";

import Gallery from "../../common/Gallery";
import { RichText } from "../../common/RichText/";
import universalTexts from "../../common/UniversalText";
import { eState } from "./Constants";
import { Wrapper } from "./GuestBookContent.style";
import GuestBookEntries from "./GuestBookEntries";

const GuestBookContent: FC = (): any => {
  const pageProps = useContext<IContext>(Context);

  const page = getPage(pageProps);
  const textBlocks = getTextBlocks(page);
  // ----------------------------------------- //
  const UniversalTexts = universalTexts(pageProps?.UniversalTexts);

  // @ts-ignore
  const imgArray = page?.elements?.filter(
    (e) => e?.__typename === "Asset"
  ) as IAsset[];
  // @ts-ignore
  const slider = page?.elements?.filter(
    (e) => e?.__typename === "Slider"
  ) as any[];
  const [state, setState] = useState<eState>(eState.initial);

  // const vorname = universalTexts?.guestbook__firstname || "Vorname";
  // const nachname = universalTexts?.guestbook__lastname || "Nachname";
  // const email = universalTexts?.guestbook__email || "E-Mail";
  // const titel = universalTexts?.guestbook__title || "Titel";
  // const nachricht = universalTexts?.guestbook__message || "Nachricht";
  // const bewertungAbgeben = universalTexts?.gestbook__evaluation || "Bewertung abgeben";

  const showSlider =
    slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery;
  if (imgArray?.[0] || showSlider) {
    if (imgArray.length > 1 || showSlider) {
      if (slider) {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      } else {
        imageOrGallery = <Gallery imgArray={imgArray} />;
      }
    } else {
      imageOrGallery = (
        <ImageWithAspectRatio
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={342}
          ratio={50}
          quality={1080}
        />
      );
    }
  }

  return (
    <Wrapper>
      <Row>
        <Col md={9}>
          {state === eState.initial && (
            <Fragment>
              {imageOrGallery}
              {imageOrGallery ? <div style={{ margin: "20px 0" }} /> : null}
              {textBlocks?.map((tb: ITextElement, i: number) => (
                <Fragment key={`textblock${i}`}>
                  {tb.headline && (
                    <RichText>
                      <h1>{tb.headline}</h1>
                    </RichText>
                  )}
                  <RichText textContent={tb.text} />
                </Fragment>
              ))}
            </Fragment>
          )}

          <GuestBookEntries />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default GuestBookContent;
