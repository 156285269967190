import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { IAsset } from "src/common";
import {FullscreenImage} from "src/common/components/FullscreenImage/FullscreenImage";

import RichText from "../../../BaseComponents/RichText/RichText";
import * as styles from "./NewsArticleItem.style";

interface IProps 
{
  headline: string;
  text: any;
  images: IAsset[];
  active?: boolean;
}

export const NewsArticleItem: FunctionComponent<IProps> = props => 
{
  const [isActive, setIsActive] = useState(false);
  useEffect(() => 
  {
    if(props.active) 
    {
      setIsActive(true);
    }
  }, []);

  if(!props.headline || !props.text) 
  {
    return null;
  }

  return (
    <div css={styles.wrapper}>
      <div
        css={styles.headingWrapper}
        onClick={() => setIsActive(!isActive)}
      >
        <h3 css={styles.h3}>{props.headline}</h3>
        <svg
          css={styles.svg}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
        </svg>
      </div>
      <div
        css={`
                    ${styles.imgAndText} ${isActive
      ? styles.isActive
      : styles.isNotActive}
                `}
      >
        <div css={styles.imageRow}>
          {props.images?.map((asset: IAsset, ind) => 
            (
              <div css={styles.imgAndSubtitleWrapper} key={`img-new-articles-item-${ind}`}>
                <FullscreenImage
                  img={asset && [asset]}
                  subtitle={asset?.title}
                />
                {asset?.title ? (
                  <p css={styles.subTitle}>{asset.title}</p>
                ) : null}
              </div>
            ))}
        </div>
        <RichText
          textContent={props.text}
          css={isActive ? styles.isActive : styles.isNotActive}
        ></RichText>
      </div>
      <div
        css={`${styles.headingWrapper} ${isActive? styles.isActive : styles.isNotActive}`}
        onClick={() => setIsActive(!isActive)}
      >
        <p>SCHLIESSEN</p>
        <svg
          css={styles.svg}
          style={{ transform: "rotate(180deg) scale(.7)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
        </svg>
      </div>
    </div>
  );
};
