import React, { Fragment, FC, useState } from "react";
import { IAsset } from "src/common";
import { richToTeaser } from "../../../../../../common/utils/text/teaser";

import FullscreenImage from "../../../common/FullscreenImage";
import { RichText } from "../../../common/RichText";
import {
  Wrapper,
  HeadingWrapper,
  NewsContent,
  Images,
  ImageCaption,
} from "./NewsArticleItem.style";

interface IProps {
  headline: string;
  text: any;
  index: number;
  images: IAsset[];
}

const NewsArticleItem: FC<IProps> = (props) => {
  const [isActive, setIsActive] = useState(props?.index === 0);
  if (!props.headline || !props?.text) {
    return null;
  }
  const teaser = props?.text && richToTeaser(props?.text);
  return (
    <Wrapper isActive={isActive}>
      <HeadingWrapper onClick={() => setIsActive(!isActive)}>
        <h3>{props.headline}</h3>
      </HeadingWrapper>

      {teaser ? <p>{teaser}</p> : null}

      <NewsContent isActive={isActive}>
        <RichText textContent={props?.text} />
        <Images>
          {props.images?.map((asset: IAsset, i: number) => {
            return (
              <Fragment key={`${i}${asset?.src}`}>
                {asset && (
                  <>
                    <FullscreenImage
                      img={[asset]}
                      subtitle={asset.description}
                    />
                    {asset.description && (
                      <ImageCaption>{asset.description}</ImageCaption>
                    )}
                  </>
                )}
              </Fragment>
            );
          })}
        </Images>
      </NewsContent>

      {isActive ? (
        <a onClick={() => setIsActive(!isActive)}>
          <img src="/images/kg_icon_link_close.jpeg" />
          schließen
        </a>
      ) : (
        <a onClick={() => setIsActive(!isActive)} data-open>
          <img src="/images/kg_icon_link_arrow.jpeg" />
          mehr
        </a>
      )}
    </Wrapper>
  );
};

export default NewsArticleItem;
