import React, { FC } from "react";
import { IGuestbookEntry, DateFormatted } from "src/common";
import RichTextGeneral from "../../../common/RichText";
import RateStars from "../RateStars";
import { GuestBookItemWrapper, MessageWrapper } from "./GuestBookItem.style";

const GuestBookItem: FC<{ item: IGuestbookEntry }> = ({ item }) => {
  return (
    <GuestBookItemWrapper>
      <p>
        {item.firstname} {item.lastname} schrieb am{" "}
        <DateFormatted date={item.date} />
      </p>
      <h3>
        {item.title}
        <RateStars selected={item.Evaluation} fixed />
      </h3>
      {item.Message?.content ? (
        <MessageWrapper>
          <RichTextGeneral textContent={item.Message} />
        </MessageWrapper>
      ) : null}
      {item.commentOfDealer?.content ? (
        <MessageWrapper>
          <em>Kommentar:</em>
          <RichTextGeneral textContent={item.commentOfDealer} />
        </MessageWrapper>
      ) : null}
    </GuestBookItemWrapper>
  );
};

export default GuestBookItem;
