import { RichText } from "src/common";
import React from "react";
import * as styles from "./../../PageGuestbookT4.style";
import * as previewStyles from "./Preview.style";
import { GuestbookStars } from "src/common/components/GuestbookStars/GuestbookStars";
import { css } from "styled-components";


const Preview = (props: {universalTexts: any, formData: any, goToGuestbookSubmit: any, goBackToEditSubmit: any}): any => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <>
            <div css={previewStyles.previewWrapper}>
                <div css={`${styles.contentWrapper}${previewStyles.preview__floatLeft}`}>
                    {props.universalTexts?.guestbook__preview ?
                        <RichText overwriteStyle={css`${styles.labelRichtext} ${styles.heading}`} textContent={props.universalTexts?.guestbook__preview}></RichText>
                        : "Vorschau ansehen"
                    }
                    <div className="entry">
                        <hr/>
                        <p>
                            {props.formData.alias} schrieb am {new Date().toLocaleDateString('de-DE', options)}:
                        </p>
                        {props.formData.sterne === 0? 
                            <h3>{props.formData.titel}</h3>  
                            :
                            <div className="title_stars_wrapper">
                                <h3>{props.formData.titel}</h3>
                                <GuestbookStars changeable={false} starSize={16} amountOfStars={props.formData.sterne}></GuestbookStars>
                            </div>
                        }
                        {props.formData.nachricht}
                    </div>
                    <div css={styles.submitWrapper}>
                        <button onClick={props.goToGuestbookSubmit} type="submit">{props.universalTexts?.Submit || "Absenden"}</button>
                        <button onClick={props.goBackToEditSubmit}>Bearbeiten</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export { Preview };
