import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Row, Col } from "../../../BaseComponents/Grid";

export const FormWrapper: any = styled.div`
  span {
    font-weight: 300;
  }
  p {
    margin: 10.5px 0 0;
  }
  ${Row} {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  ${Col} {
    input:not([type=checkbox]), textarea {
      padding: 1px 2px;
      width: 100%;
      font-size: 15;
      font-weight: 300;
    }
  }
  
  margin: 0px 0 10px;
  input {
    line-height: 20px;
  }
  
  div.input {
    width: 100%;
  }

  div.input-right {
    width: 100%;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    div.input {
      width: 98%;
    }
  
    div.input-right {
      width: 98%;
      margin-left: auto;
    }
  }
`;

export const LabelRichtext: any = styled.label`
  display: flex;
  span.secondary {
    margin: 10.5px 0 0 5px;
    color: #000;
  }
  * {
    line-height: 21px;
    font-weight: 300;
  }
  
`;

export const AcceptPrivacyWrapper: any = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  line-height: 42px;

  label {
    margin-left: 5px;
  }
  label, a {
    font-size: 13px;
  }
  a {
    color: #92c2d6 ;
  }
`;

export const Star: any = styled.svg`
  margin-right: 12px;
  cursor: pointer;
  transform: scale(1.2);
  transform-origin: top left;
  margin-top: 3px;
`;

export const SubmitWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 21px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
  }

  div.button-right {
    width: 100%;
    margin-top: 10px;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      margin-left: 21px;
      margin-top: 0;
    }
  }

  button {
    width: 100%;
    border-radius: 0;
    margin-top: 0;
    padding: 12px;
    font-weight: 300;

    @media (${BREAKPOINTS_DM.silver_768}) {
    }

    &[type=submit] {
      background-color: #92c2d6 ;
      color: white;
      &:hover {
        background-color: #706f6f;
      }
    }

    &[type=button] {
      p, span {
        margin: 0;
        line-height: 15px;
      }
      background-color: #AAAAAA;
      color: ${props => props.theme.palette.white};
      &:hover {
        background-color: #706f6f;
      }
      @media (${BREAKPOINTS_DM.silver_768}) {
        width: 50%;
      }
    }
  }
`;

export const H1: any = styled.div`
  margin-bottom: 10.5px;
  span.title {
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.68px;
    line-height: 38px;
    padding: 0 10px 0 0;
    text-transform: uppercase;
  }
`;

export const InputsRow: any = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
