import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const formWrapper: any = css`
  background-color: #f1f1f1;
  padding: 0px 10px 100px 10px;

    @media (${BREAKPOINTS_DM.silver_768})
    {
      display: grid;
      width: auto;
      padding: 0px 70px 80px 70px;
    }
`;

export const submitted: any = css`
  padding: 0 6%;
  font-family: Helvetica,Arial,sans-serif;
  text-align: center;

   h1 {
    margin-bottom: 15px;
    padding-top: 60px;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 1.6
   }

   > p {
    margin-bottom: 2rem;
    font-size: 1em;
   }

   button {
      color: white;
      text-align: center;
    }

    @media (${BREAKPOINTS_DM.silver_768})
      {
        h1 {
          margin-top: 2%;
        }
      }
`;


export const confirmation: any = css`
  background: ${props => props.theme.palette.backgroundMint};
  color: white;
  h1 {
    font-weight: 400;
    font-size: 1.875rem;
  }
`;