import React, { FunctionComponent, useContext, useState } from "react";
import { IContext, Context, IAsset, ITextElement, getIfNewsShouldBeDisplayByDate, getCurrentNavItem } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import Spacer from "../../../BaseComponents/Spacer/Spacer";
import { centeredContent } from "../../../constants/layout";
import { Banner } from "../../common/Banner/Banner";
import { FooterT3 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { CenteredContent } from "../../common/MainContent/Slug.style";
import { TeaserGallery } from "../../common/teaserGallery/TeaserGallery";
import * as styles from "./Aktuelles.page.style";
import { Wrapper, NewsItem, NextAndPrevPage, NextAndPreviousArticleLinks } from "./Aktuelles.page.style";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";
import { richToTeaser } from "../../../../../common/utils/text/teaser";

const PageNewsT3: FunctionComponent = () => 
{
  const pageProps = useContext<IContext>(Context);
  const palette = pageProps?.Settings;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const currentNavItem = getCurrentNavItem(
    pageProps?.PrimaryNavigation?.items,
    pageProps?.slug
  );
  const textElements = pageProps?.PageNews?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[];

  const itemsPerPage = 8;

  const newsPage = pageProps?.PageNews;
  // const subTitleItem = newsPage.elements?.find(item => item?.__typename === "TextElement");
  const newsArticles = newsPage.NewsArticles?.filter(article =>
    getIfNewsShouldBeDisplayByDate({
      startDate: article?.startDate,
      endDate: article?.endDate
    })
  ) || [];

  const newsCustomArticles = newsPage.NewsArticlesCustom.filter(article =>
    getIfNewsShouldBeDisplayByDate({
      startDate: article?.startDate,
      endDate: article?.endDate
    })
  ) || [];

  const articles = [...newsCustomArticles, ...newsArticles];

  const articlesToRender = [];
  const amountOfPages = Math.ceil(articles.length / itemsPerPage);

  for(let i = (currentPage - 1) * itemsPerPage; i < (currentPage - 1) * itemsPerPage + itemsPerPage; i++) 
  {
    articlesToRender.push(articles[i]);
  }

  if(pageProps?.currentItemId) 
  {
    let itemBefore;
    let itemAfter;

    const currentItem = articles?.find((item, i) => 
    {
      if(item.id === pageProps?.currentItemId) 
      {
        if(i !== 0) 
        {
          itemBefore = articles[i - 1];
        }

        if(i !== articles.length - 1) 
        {
          itemAfter = articles[i + 1];
        }

        return true;
      }

      return false;
    });

    const asset = currentItem?.extras?.find(item => item?.__typename === "Asset") as IAsset;



    return (
      <>
        <Header />
        <PlanningDateModal />
        <Banner />
        <div css={`${centeredContent}`}>
          <NextAndPreviousArticleLinks color={palette.themeColorPrimary}>
            <div>
              {itemBefore && (
                <a href={`/${newsPage.slug}/${itemBefore.id}`}>
                  <svg
                    style={{ transform: "rotate(180deg)" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                  </svg>
                  vorheriges
                </a>
              )}
            </div>
            <div>
              {itemAfter && (
                <a href={`/${newsPage.slug}/${itemAfter.id}`}>
                      nächstes
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"> 
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                  </svg>
                </a>
              )}
            </div>
          </NextAndPreviousArticleLinks>
          <Wrapper color={palette.themeTextColor}>
            <div css={styles.article}>
              {currentItem?.headline && <h1>{currentItem?.headline}</h1>}
              {(currentItem as any)?.teaser ? <>
                <p>{(currentItem as any).teaser}</p>
                <Spacer bronze={20}  />
              </> : null}
              {asset?.src && (
                <img src={asset?.src} alt={asset?.title} title={asset?.title} />
              )}
              {currentItem?.text && (
                <RichText
                  textContent={currentItem.text}
                  overwriteStyle={styles.articleRichtext}
                ></RichText>
              )}
            </div>
          </Wrapper>
        </div>
        <FooterT3 />
      </>
    );
  }

  return (
    <>
      <Header />
      <Banner />
      <div css={centeredContent}>
        <Wrapper color={palette.themeTextColor}>
          {textElements?.[0]?.headline && <h1 css={styles.heading}>{textElements?.[0].headline}</h1>}
          <RichText css={"h2 { span { font-size: 30px; }}"}
            textContent={textElements?.[0]?.text}
          ></RichText>
          <Spacer bronze={20} silver={30} />
          <div css={styles.flexWrapper}>
            {articlesToRender.map((newsItem, i) => 
            {
              if(!newsItem) 
              {
                return null;
        
              }
              
              const asset = newsItem?.extras?.find(item => item?.__typename === "Asset") as IAsset;
              let subtitle = (newsItem as any)?.teaser || ( newsItem?.text && richToTeaser(newsItem?.text, 200) );
 
              return (
                <NewsItem key={i} color={palette.themeTextColor}>
                  {asset?.src && (<Img src={asset?.src} quality={228} alt={asset?.title} />)}
                  <div>
                    {newsItem.headline && <h3>{newsItem.headline}</h3>}
                    {newsItem.text && <h4>{subtitle}</h4>}
                    {newsItem.id && (
                      <a href={`/${newsPage.slug}/${newsItem.id}`}>
                        <button>Mehr anzeigen</button>
                      </a>
                    )}
                  </div>
                </NewsItem>
              );
            })}
          </div>
          <NextAndPrevPage color={palette.themeColorPrimary}>
            {currentPage < amountOfPages && (
              <a
                onClick={e => 
                {
                  e.preventDefault();
                  setCurrentPage(currentPage + 1);
                }}
                href={"#"}
              >
                <svg
                  style={{ transform: "rotate(180deg)" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                </svg>
                Ältere Nachrichten
              </a>
            )}
            <a
              onClick={e => 
              {
                e.preventDefault();
                setCurrentPage(currentPage - 1);
              }}
              style={currentPage > 1 ? { visibility: "visible" } : { visibility: "hidden" }}
              href={"#"}
            >
              Neuere Nachrichten
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
              </svg>
            </a>
          </NextAndPrevPage>
        </Wrapper>
        {currentNavItem && (
          <CenteredContent mt={50} textColor={palette.themeTextColor}>
            <TeaserGallery
              isTwoColumnsLayout={false}
              items={currentNavItem.subitems as any[]}
            />
          </CenteredContent>
        )}
      </div>
      <FooterT3 />
    </>
  );
};
PageNewsT3.displayName = "PageNewsT3";
export { PageNewsT3 };
