import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
  margin: 70px 0 50px;

  @media (${BREAKPOINTS_DM.silver_768})
  {
      margin-right: 16px;
      width: 50%;
      margin-top: 0;
      padding: 0 10px;
  }

  @media (${BREAKPOINTS_DM.gold_1024})
  {
      padding: 0;
  }
`;

export const map: any = css`
    width: 100%;
    height: 300px;
`;

export const serviceNumber: any = css`
    margin-bottom: 50px;
    align-items: flex-start;
    padding-left: 10px;
    background-color: ${props => props.theme.palette.yellow};
`;

export const routeBerechnenWrapper: any = css`
    margin-top: 5px;
    display: flex;
    button {
      width: 50%;
      margin-left: 14px;
      background-color: ${colors.yellow};
      border-radius: 5px;
      color: white;
      padding: 15px;
      &:hover {
        background-color: black;
      }
    }
`;

export const locationInputWrapper: any = css`
    position: relative;
    margin-right: 14px;
    width: 50%;
    input {
      padding: 6px 35px 6px 12px;
      width: 100%;
      height: 100%;
    }
    svg {
      opacity: .5;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 7px;
    }
`;

export const directionsPanel: any = css`
    margin-top: 30px;
    background-color: #FFD533;
    table:not(.adp-placemark)
    {
        tr {
              td {
                padding: 8px 0;
                border-top-width: 1px;
                border-top-color: ${colors.orange} !important;
                line-height: 150%;
                &:first-child {
                  padding-left: 5px;
                  width: 10% !important;
                }
                &:nth-child(2) {
                  width: 5% !important;
                  font-weight: 500;
                  font-size: 14px;
                }
                &:nth-child(3) {
                  width: 70% !important;
                  font-weight: 500;
                }
                &:nth-child(4) {
                  width: 15% !important;
                }
          }
      }
    }
`;

export const catchmentArea: any = css`
    margin-top: 20px;
    strong {
      > p {
        margin-top: 20px;
      }
    }
`;
