import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
  align-items: center;
  background-color: ${props => props.theme.palette.red};
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  padding: 12px 10px;
  display: flex;
  flex-wrap: wrap;
  span {
    font-size: 12px;
    text-transform: uppercase;
    color: white;
    white-space: nowrap;
  }
`;

export const number: any = css`
  font-size: 29px !important;
  line-height: .85;
  margin-right: 20px;
`;
