import { CommonPageBrandTopicMainContent } from "src/common/brand-area/PageBrandTopic/CommonPageBrandTopicMainContent";

import React, { FunctionComponent } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PageBrandTopicT25: FunctionComponent = () => (
  <>
    <Header />
    <CommonPageBrandTopicMainContent />
    <Footer />
  </>
);

PageBrandTopicT25.displayName = "PageBrandTopicT25";
export { PageBrandTopicT25 };
