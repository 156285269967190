import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const imageWrapper: any = css`
    margin: 0 5% 25px 5%;

    @media (${BREAKPOINTS_DM.silver_768})
      {
        margin: 0 20% 25px 20%;
      }
    
`;

export const imageGalleryWrapper: any = css`
   // TODO what of we have multiple pictures?
`;

export const HeadlineWrapper: any = styled.div<{color: string}>`
    h1 {
      color: ${({ color }) => color ? color : "#222"};
      font-size: 24px;
      font-weight:600;
      text-transform: uppercase;
      margin: 0 0 10px 5%;

      @media (${BREAKPOINTS_DM.silver_768})
      {
        margin: 0 0 10px 20%;
      }
    }
`;

export const TextWrapper: any = styled.div<{textColor: string}>`
  color: ${({ textColor }) => textColor ? textColor : "#222"};
`;

export const ContentWrapper: any = styled.div<{textColor: string; primary: string; background: string; colorIsland: string}>`
    display: flex;
    margin: 0px 5%;
    font-style: italic;
    flex-direction: column;

    @media (${BREAKPOINTS_DM.silver_768})
    {
      margin: 0px 20%;
      flex-direction: row;
    }

    .entry {
      @media (${BREAKPOINTS_DM.silver_768})
      {
        margin-right: 28px;
      }
      color: ${({ textColor }) => textColor ? textColor : "#222"};
      h3 {
        color: ${({ primary }) => primary ? primary : "#222"};
        font-size: 20px;
        font-weight:200;
        text-transform: uppercase;
        margin: 28px 0 32px 0;
      }

      div {
        padding-top: 20px;
      }

      hr:not(:first-child){
        margin-top: 56px;
      }

      hr {
        margin: 14px 0;
        border-top: 1px solid ${({ primary }) => primary ? primary : "#222"};};
      }

      p {
        margin-bottom: 1rem;
      }

      .title_stars_wrapper {
        display: flex;
        display: column;
        justify-content: space-between;

        p {
          padding-top: 22px;
        }
    }

    }

    .write_posting_wrapper {
      background-color: ${({ background }) => background ? background : "#a5a5a5"};
      height: min-content;
      padding: 14px; // 14px 35px 14px 14px
      color: ${({ colorIsland }) => colorIsland ? colorIsland : "white"};
      margin-top: 10px;

      > p {
        font-style: italic;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      button {
        width: 100%;
        padding: 6px 0;
        border: 2px solid ${({ colorIsland }) => colorIsland ? colorIsland : "white"};
        color: ${({ colorIsland }) => colorIsland ? colorIsland : "white"};
      }

      @media (${BREAKPOINTS_DM.silver_768})
      {
        > p {
          width: 164px;
          text-align: left;
        }
      }
    }
`;

