import React, { FunctionComponent, useEffect, useState, Fragment } from "react";

import * as styles from "./FullscreenImage.style";
import { IAsset, Img } from "src/common";

interface IProps
{
    img: IAsset[];
    subtitle: string;
}

const FullscreenImage: FunctionComponent<IProps> = props =>
{
    const [isActive, setIsActive] = useState(false);

    return (
        <div css={styles.img}>
            <Img src={props.img} alt={props.subtitle} onClick={() => setIsActive(true)}/>
            <div css={`${styles.wrapper} ${isActive ? styles.wrapperActive : styles.wrapper} ` } onClick={() => setIsActive(false)}>
                <div css={styles.fullScreenWrapper} style={isActive ? { display: "block" } : { display: "none" }}>
                    <img src={props.img?.[0]?.src} css={styles.imgFullScreen} alt={"image"}/>
                    <p>{props.subtitle}</p>
                    <svg css={styles.svg} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                </div>
            </div>
        </div>
    );
};

export default FullscreenImage;
