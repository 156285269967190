import React from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { css } from "styled-components";

import Contact from "../../../components/common/contact/Contact";
import { Footer } from "../../../components/common/footer/Footer";
import MainNavigation from "../../../components/common/MainNavigation/MainNavigation";
import Map from "../../../components/common/map/Map";
import { centeredContent } from "../../../constants/layout";

export const contactPageContent: any = css`
    display: block;
    strong { 
        font-weight: 600;
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
        display: flex;
    }
`;

const PageContactT1 = () => 
  (
    <>
             
      <MainNavigation />
      <div css={centeredContent}>
        <div css={contactPageContent}>
          <Map />
          <Contact />
        </div>
        <Footer />
      </div>
    </>
  );

PageContactT1.displayName = "PageContactT1";
export { PageContactT1 };
