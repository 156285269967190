import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
export const CourseFormWrapper: any = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  padding-bottom: 60px;
  color: #666;
  h3 {
    margin-bottom: 34px;
    font-size: 18px;
    font-weight: 600;
  }

  h2 {
    margin-top: 68px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 22px;
    line-height: 1.375;

    span {
    }
  }
  form {
    margin-top: 20px;
    .submit-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    [type=submit] {
      background-color: ${({ theme }) => theme.palette.textColor};
      padding: .5rem;
      font-size: 15px;
      line-height: 30px;
      letter-spacing: 1px;

      border-radius: 0;
      color: white;
      width: 49%;
    }

    [type=button] {
      background-color: rgb(230,124,25);
      padding: .5rem;
      font-size: 15px;
      line-height: 30px;
      letter-spacing: 1px;
      border-radius: 0;
      color: white;
      width: 49%;
      &:hover {
        background-color: ${({ theme }) => theme.palette.textColor};
      }
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      .submit-wrapper {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
`;

export const CourseItemWrapper: any = styled.div`
  > div {
    display: flex;
  }

  > a {
    display: block;
    background-color: #92c2d6;
    color: #efefef;
    border: 1px solid #92c2d6;
    line-height: 3;
    padding: 0 10px;
    margin-top: 20px;
  }

`;

export const CourseContentWrapper: any = styled.div`
  width: 66.7%;

  p {
    margin-bottom: 34px;
    &, span {
      line-height: 34px;
    }
  }

  a {
    color: #92c2d6;
  }

`;

export const InputWrapper: any = styled.div<{ sm?: number; md?: number}>`
  font-weight: normal;
  margin-bottom: 17px;

  ${({ sm }) => sm && `
    width: calc(100% / 12 * ${sm} - 10px);
  `}

  label {
    display: block;
    line-height: 1.4em;
    font-weight: 300;
  }

  input:not([type=checkbox]) {
    display: block;
    border-top-style: inherit;
    border-top-width: 0;
    border: 1px solid ${({ theme }) => theme.palette.textColor};
    padding: 2px 5px;
    width: 100%;
  }

  select {
    display: inline-block;
    font-size: 100%;
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    ${({ md }) => md && `
      width: calc(100% / 12 * ${md} - 10px);
    `}
  }

`;

export const CourseDateWrapper: any = styled.div`
  width: 25%;
  margin: auto 0 auto auto;

  h3 {
    &, span {
      font-size: 22px;
      color: ${({ theme }) => theme.palette.primary};
    }
    margin: 0;
  }
  span {
    margin: 0;
    line-height: 1.5;
  }
`;
