import { Context } from "src/common";

import React, { useContext, FC } from "react";

import {
    BrandAreaComponentRouter,
    IBrandAreaComponentRouterElement,
} from "../component-router/BrandAreaComponentRouter";
import { IGenPageMicro } from "../../types_dealer_gen";

export const CommonPageBrandMicroMainContent: FC<any> = () => {
    const c = useContext(Context);
    const page = c?.PageMicro as IGenPageMicro;
    
    if (!c || !page) {
        return null;
    }
    const elements = [
        {
            __typename: "automaticallyBackToOverviewLinkMobile",
            id: "automaticallyBackToOverviewLinkMobile",
        },
        {
            __typename: "automaticallyOverviewLinkWithDropdown",
            id: "backToOverviewLink",
        },
        {
            __typename: "microPageFrame",
            id: "microPageFrame",
            src: page?.externalLinkAddress,
        }
    ] as IBrandAreaComponentRouterElement[];


    return (
        <div className="brand-area brand-area-micro">
            <BrandAreaComponentRouter elements={elements} />
        </div>
    );
};
