import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.ul`
  display: none;
  position: relative;
  right: -15px;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 85px;

  border-top: 1px solid ${props => props.theme.palette.black50};
  li {
    border-bottom: 1px solid ${props => props.theme.palette.black50};
    a {
      display: block;
      padding-top: 4.25px;
      padding-bottom: 5.25px;
      background-image: linear-gradient(to left,#d0d2d3 0,#fff 95%);
      line-height: 21px;
      font-weight: 300;
    }
    a:hover {
      color: ${props => props.theme.palette.green};
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}){
    display: block;
  }

  .active {
    color: ${props => props.theme.palette.green} !important;
  }
`;
