import React, { FunctionComponent } from "react";
import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import FurtherAwards from "../../common/FurtherAwards/FurtherAwards";
import Header from "../../common/Header/Header";

import MainContent from "../../content/MainContent";

const PageCourseT8: FunctionComponent = () =>
(
  <div>
    <Header />
    <Grid>
      <Section>
        <MainContent />
        <FurtherAwards />
      </Section>
    </Grid>
    <Footer />
     
  </div>
);

PageCourseT8.displayName = "PageCourseT8";
export { PageCourseT8 };
