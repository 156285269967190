import { CommonPageBrandOverviewMainContent } from "src/common/brand-area/PageBrandOverview/CommonPageBrandOverviewMainContent";

import * as React from "react";

import { Banner } from "../../common/Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandOverviewT1: React.FunctionComponent = () => (
    <>
        <MainNavigation />
        <Spacer bronze={80} />
        <CommonPageBrandOverviewMainContent />
        <Banner />
        <Footer />
    </>
);
PageBrandOverviewT1.displayName = "PageBrandOverviewT1";
export { PageBrandOverviewT1 };
