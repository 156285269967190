import React, { FunctionComponent } from "react";
import Header from "../../common/header/Header";
import { FooterT3 } from "../../common/footer/Footer";
import { Banner } from "../../common/Banner/Banner";

import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";
import { CommonPageBrandTopicMainContent } from "src/common/brand-area/PageBrandTopic/CommonPageBrandTopicMainContent";
import Spacer from "../../../../../common/BaseComponents/Spacer/Spacer";

const PageBrandTopicT3: FunctionComponent = () => {
  return (
    <>
      <Banner />
      <PlanningDateModal />
      <Header />
      <Spacer bronze={80} silver={60} platinum={38} />
      <CommonPageBrandTopicMainContent />
      <FooterT3 />
    </>
  );
};

PageBrandTopicT3.displayName = "PageBrandTopicT3";
export { PageBrandTopicT3 };
