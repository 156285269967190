import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0 auto 68px auto;
  padding: 0 17px;
`;

export const CourseItemWrapper: any = styled.div`
  cursor: pointer;
  h3 {
    margin-bottom: 34px;
    font-family: "Roboto Slab";
  }
`;

export const CourseContentWrapper: any = styled.div`
  width: 66.7%;

  p {
    margin-bottom: 34px;
    &, span {
      font-family: "Roboto Slab";
      line-height: 34px;
      font-weight: normal;
    }
  }

  a {
    color: #80C7DC;
  }

`;

export const CourseImageWrapper: any = styled.div`
  width: 33.3%;
  margin-right: 34px;
  max-height: 290px;

`;

export const CourseDateWrapper: any = styled.div`
  width: 33.3%;
  h3.title {
    font-family: "Open Sans", sans-serif;
    color: rgb(85, 85, 85);
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    margin: 20px 0 10px;
    &:hover {
      color: #adadad;
    }
  }

  h2.title-2 {
    color: rgb(170, 170, 170);
    font-family: "Open Sans", sans-serif;
    font-size: 11.7px;
    font-weight: 600;
    line-height: 16.38px;
  }

  p {
    color: rgb(102, 102, 102);
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    margin: 10px 0;
  }
`;

export const FurtherAwardsWrapper: any = styled.div`
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0 300px 0 0;
  }
`;
