import React, { FC } from "react";

import Contact from "../../common/Contact";
import { Container, Row, Col, Section, Divider } from "../../common/Grid";
import Map from "../../common/Map";

import { ContentContainer, Wrapper } from "./ContactContent.style";

const ContactContent: FC = () => (
  <Wrapper>
    <Container>
      <Section>
        <ContentContainer>
          <Map />
          <Contact />
        </ContentContainer>
        <Divider />
      </Section>
    </Container>
  </Wrapper>
);

export default ContactContent;