import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";

const PageT23: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent />
      </Section>
    </Grid>
    <Footer />
     
  </>
);

PageT23.displayName = "PageT23";
export { PageT23 };
