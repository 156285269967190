import { CommonPageBrandOverviewMainContent } from "src/common/brand-area/PageBrandOverview/CommonPageBrandOverviewMainContent";

import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PageBrandOverviewT23: FC = () => (
  <>
    <Header />
    <CommonPageBrandOverviewMainContent />
    <Footer />
  </>
);

PageBrandOverviewT23.displayName = "PageBrandOverviewT23";
export { PageBrandOverviewT23 };
