import React, { FunctionComponent } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header/Header";

import MainContent from "../../content/MainContent";

const PageMicroT25: FunctionComponent = () =>
  (
    <div>
      <Header />
      <MainContent />
      <Footer />
       
    </div>
  );

PageMicroT25.displayName = "PageMicroT25";
export { PageMicroT25 };
