import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { IContext, Context, getPage, ITextElement, getTextBlocks } from "src/common";

import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import { centeredContent } from "../../../constants/layout";
import { Footer } from "../../common/footer/Footer";
import { Gallery } from "../../common/gallery/Gallery";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import RichText from "../../common/RichText/RichText";
import SRichTextGeneral from "../../common/RichText/Styles/SRichTextGeneral";

import * as styles from "./Guestbook.style";

enum eState 
  {
  initial,
  write,
  preview
}

const PageGuestbookT1 = (): any => 
{
  const p = React.useContext<IContext>(Context);
  const pageProps = p;

  const page = getPage(p);
  const textBlocks = getTextBlocks(page);
  // ----------------------------------------- //
  const universalTexts = pageProps?.UniversalTexts;
  const footer = pageProps?.Footer;
  // @ts-ignore
  const imgArray = page?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const slider = page?.elements?.filter((e) => e?.__typename === "Slider") as any[];
  const [state, setState] = useState<eState>(eState.initial);
  const [selectedStars, setSelectedStars] = useState<number>(0);
  const [hoveredStars, setHoveredStars] = useState<number>(0);

  const { register, handleSubmit, errors } = useForm();

  let fullStarsToRender: number;

  if(selectedStars >= hoveredStars) 
  {
    fullStarsToRender = selectedStars;
  }
  else 
  {
    fullStarsToRender = hoveredStars;
  }

  const halfStarsToRender = 5 - fullStarsToRender;

  const onSubmit = (data): void => 
  {
    console.info({
      eMail: data.email,
      title: data.titel,
      lastname: data.nachname,
      firstname: data.vorname,
      message: data.nachricht,
      evaluation: selectedStars + 1,
      alias: data.alias
    });

    if(errors) 
    {
      console.info("errors", errors);
    }

    setState(eState.preview);
  };

  // const vorname = universalTexts?.guestbook__firstname || "Vorname";
  // const nachname = universalTexts?.guestbook__lastname || "Nachname";
  // const email = universalTexts?.guestbook__email || "E-Mail";
  // const titel = universalTexts?.guestbook__title || "Titel";
  // const nachricht = universalTexts?.guestbook__message || "Nachricht";
  // const bewertungAbgeben = universalTexts?.gestbook__evaluation || "Bewertung abgeben";

  const stars: React.ReactElement[] = [];

  for(let i = 0; i < fullStarsToRender; i++) 
  {
    stars.push(
      <svg
        onMouseLeave={() => setHoveredStars(0)}
        onClick={() => setSelectedStars(i + 1)}
        css={styles.star}
        key={i}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
      </svg>
    );
  }
  for(let i = 0; i < halfStarsToRender; i++) 
  {
    stars.push(
      <Fragment key={`star${i}`}>
        <svg
          onMouseEnter={() => setHoveredStars(fullStarsToRender + i + 1)}
          onMouseLeave={() => setHoveredStars(0)}
          css={styles.star}
          key={fullStarsToRender + i}
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15.668 8.626l8.332 1.159-6.065 5.874 1.48 8.341-7.416-3.997-7.416 3.997 1.481-8.341-6.064-5.874 8.331-1.159 3.668-7.626 3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z" />
        </svg>
      </Fragment>
    );
  }

  const showSlider = slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery;
  if(imgArray?.[0] || showSlider) 
  {
    if(imgArray.length > 1 || showSlider) 
    {
      if(slider) 
      {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      }
      else 
      {
        imageOrGallery = <Gallery imgArray={[imgArray?.[0]]} />;
      }
    }
    else 
    {
      imageOrGallery = (
        <ImageWithAspectRatio
          alt={imgArray?.[0]?.title}
          src={[imgArray?.[0]]}
          height={342}
          ratio={50}
          quality={1080}
        />
      );
    }
  }

  let content: React.ReactElement;
  if(state === eState.initial) 
  {
    content = (
      <Fragment>
        <>
          {imageOrGallery}
          {imageOrGallery ? <div style={{ margin: "20px 0" }} /> : null}
        </>
        {textBlocks?.map((tb: ITextElement, i: number) => <Fragment key={`textblock${i}`}>
          {tb.headline &&
            <div css={SRichTextGeneral}>
              <h1 >
                {tb.headline}
              </h1>
            </div>
          }
          <RichText overwriteStyle={SRichTextGeneral} textContent={tb.text}></RichText>
        </Fragment>
        )}
        <button onClick={() => setState(eState.write)}>
          {universalTexts?.guestbook_writeContribution ?
            <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts?.guestbook_writeContribution}></RichText>
            : "Beitrag verfassen"
          }
        </button>
      </Fragment>
    );
  }
  else if(state === eState.write) 
  {
    content = (
      <div css={styles.formWrapper}>
        <h1 css={styles.heading}>
          {universalTexts?.guestbook_writeContribution ?
            <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts?.guestbook_writeContribution}></RichText>
            : "Beitrag verfassen"
          }
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div css={styles.formRow}>
              <div>
                <label htmlFor={"vorname"}>
                  {universalTexts.guestbook__firstname ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__firstname}></RichText> : "Vorname"}
                </label>
                <input type="text" id={"vorname"} name="vorname" ref={register} />
              </div>
              <div>
                <label htmlFor={"nachname"}>
                  {universalTexts.guestbook__lastname ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__lastname}></RichText> : "Nachname"}
                </label>
                <input type="text" id={"nachname"} name="nachname" ref={register} />
              </div>
            </div>
            <div css={styles.formRow}>
              <div>
                <label htmlFor={"alias"} style={{ height: "31px" }}>
                  <RichText overwriteStyle={styles.labelRichtext}>{"Alias"}</RichText>
                </label>
                <input required={true} type="text" id={"alias"} name="alias" ref={register} />
              </div>
              <div>
                <label htmlFor={"email"}>
                  {universalTexts.guestbook__email ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__email}></RichText> : "E-Mail"}
                </label>
                <input required={true} type="email" id={"email"} name="email" ref={register} />
              </div>
            </div>
            <div css={`${styles.formRow}${styles.formRowOneChild}`} style={{ marginTop: 30 }}>
              <label htmlFor={"titel"}>
                {universalTexts.guestbook__title ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__title}></RichText> : "Titel"}
              </label>
              <input required={true} type="text" id={"titel"} name="titel" ref={register} />
            </div>
            <div css={styles.formRow} style={{ display: "block" }}>
              <label htmlFor={"nachricht"}>
                {universalTexts.guestbook__message ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__message}></RichText> : "Nachricht"}
              </label>
              <textarea rows={8} required={true} name="nachricht" id={"nachricht"} ref={register} />
            </div>
            <div css={styles.formRow} style={{ display: "block" }}>
              <label htmlFor={"bewertungAbgeben"}>
                {universalTexts.gestbook__evaluation ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.gestbook__evaluation}></RichText> : "Bewertung abgeben"}
              </label>
              <p>{stars}</p>
            </div>
          </div>
          <div css={styles.acceptPrivacyWrapper}>
            <input
              required={true}
              type="checkbox"
              placeholder="Ich akzeptiere die Datenschutzerklärung"
              name="checkbox"
              id="checkbox"
              ref={register({ required: true })}
            />
            <label htmlFor={"checkbox"}>
                            Ich akzeptiere die{" "}
              <a target="_blank" rel="noreferrer" href={`/${pageProps?.PageImprint?.slug || ""}`}> Datenschutzerklärung</a>
            </label>
          </div>
          <div css={styles.submitWrapper}>
            <button type="submit">{universalTexts?.Submit || "Absenden"}</button>
            <button onClick={() => setState(eState.initial)} type="submit">
              {universalTexts.guestbook__cancel ? <RichText overwriteStyle={SRichTextGeneral} textContent={universalTexts.guestbook__cancel}></RichText> : "Abbrechen"}
            </button>
          </div>
        </form>
      </div>
    );
  }
  else 
  {
    content = (
      <Fragment>
        <button>
          {universalTexts.gestbook__back ? <RichText overwriteStyle={SRichTextGeneral} textContent={universalTexts.gestbook__back}></RichText> : "Zurück"}
        </button>
      </Fragment>
    );
  }

  return (
    <>
       
      <MainNavigation />
      <div css={styles.wrapper}>
        <div css={centeredContent}>{content}</div>
      </div>
      <Footer />
    </>
  );
};
PageGuestbookT1.displayName = "PageGuestbookT1";
export { PageGuestbookT1 };
