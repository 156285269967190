import styled from "styled-components";

export const SSearchContent = styled.div``;

export const SSearchContentHero = styled.div`
  background-color: #92c2d6;
  height: 210px;
  margin-top: 100px;
  display: flex;
  flex-basis: 1;
`;

export const SSearchContentBelowHero = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-basis: 1;

  div {
    width: 50%;
  }

  p {
    width: 50%;
    font-family: "sofia_pro_extralightregular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    box-sizing: border-box;
  }
`;

export const SSearchContentHeroLogo = styled.div`
  position: relative;
  width: 50%;

  img {
    height: 150px;
    position: absolute;
    top: 0;
    left: 40px;
  }
`;

export const SSearchContentHeroTitle = styled.div`
  position: relative;
  display: flex;
  width: 50%;

  h1 {
    font-family: "FFSuperGroteskWebMedium", sans-serif;
    color: white;
    font-size: 29px;
    letter-spacing: 2px;
    position: absolute;
    bottom: 12px;
    left: 0;
    margin-bottom: 0;
  }
`;

export const SSearchContentFooter = styled.div`
  border-top: 14px solid #92c2d6;
  width: calc(100% - 50px);
  margin: auto;
  margin-top: 60px;
  padding-top: 30px;
  margin-bottom: 30px;

  ul {
    li {
      font-family: "FFSuperGroteskWebMedium", sans-serif;
      background: url(https://www.gk-gute-kuechen.de/images/icon/gk_icon_arrow_blue.png)
        no-repeat;
      color: #85b2c3;
      font-size: 29px;
      font-size: 17px;
      margin-top: 0px;
      margin-right: 20px;
      padding-top: 0px;
      padding-left: 28px;
      display: inline-flex;
      align-items: center;

      a {
        font-size: 29px;
        font-size: 17px;
      }
    }
  }
`;

export const SSearchContentNoDealers = styled.div`
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: auto;
  margin-top: 60px;

  h2 {
    font-family: "FFSuperGroteskWebMedium", sans-serif;
    font-size: 23px;
    line-height: 25px;
    letter-spacing: 1px;
    letter-spacing: 2px;
    font-weight: 600;
  }

  p {
    font-family: "FFSuperGroteskWebRegular", sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 200;

    a {
      color: #337ab7;
      text-decoration: none;
    }
  }
`;

export const SSearchContentDealersList = styled.div`
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: -30px;
`;

export const SSearchContentDealerItem = styled.div`
  border: 14px solid #92c2d6;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    width: 100%;
    margin: 0 auto;
    font-family: "FFSuperGroteskWebRegular", sans-serif;
    font-weight: 200;
    letter-spacing: 1px;
    font-size: 14px;
  }

  h3 > a,
  h3 {
    font-family: "FFSuperGroteskWebMedium", sans-serif;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 1px;
    letter-spacing: 2px;
    font-weight: 600;

    a {
      :hover {
        text-decoration: underline;
      }
    }
  }
`;
export const SSearchContentDetailsLink = styled.a`
  background: url(https://www.gk-gute-kuechen.de/images/icon/gk_icon_arrow_blue.png)
    no-repeat;
  color: #85b2c3;
  padding-left: 28px;
  font-size: 16px;
  font-weight: 200;
`;
