import React, { useContext } from "react";
import { Context } from "src/common";
import { MicroWrapper } from "./Micro.style";

const Micro: React.FC<any> = () => {
  const props = useContext(Context);
  return (
    <MicroWrapper>
      <iframe src={props?.PageMicro?.externalLinkAddress} />
    </MicroWrapper>
  );
};

export default Micro;