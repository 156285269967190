import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import FurtherAwards from "../../common/FurtherAwards";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";

const PageBrandT8: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent />
      </Section>
    </Grid>
    <FurtherAwards/>
    <Footer />
     
  </>
);

PageBrandT8.displayName = "PageBrandT8";
export { PageBrandT8 };
