import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const listing: any = css`
  display: flex;
  flex-direction: column;
`;

export const connectedArticle: any = css`
  cursor: pointer;
  margin: 10px 0 50px;
  width: 100%;
  > div > div {
    float: left;
  }
  > div {
    display: flow-root;
  }
  > div > span {
    display: flow-root;
    strong{
      font-weight: bold;
    }
  }
  h2 {
    color: ${(props) => props.theme.primary};
    font-size: 30px;
    text-transform: uppercase;
  }
  h2 {
    margin-bottom: 1rem;
  }
  h3 {
    color: ${(props) => props.theme.primary};
    font-size: 20px;
    margin-bottom: 13.5px;
  }
  iframe {
    max-width: 100%;
  }
`;

export const connectedArticleWithBorder: any = css`
  padding: 0 !important;
  &:before {
    border-bottom-color: ${(props) => props.theme.secondary};
  }
  > div {
    padding: 27px;
    width: 100%;
    border: solid 1px ${(props) => props.theme.secondary};
  }
`;
