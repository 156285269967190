import React, { Fragment, FunctionComponent, useContext } from "react";
import { Context, IContext, IAsset } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import UniversalComponentRenderer from "../../../BaseComponents/UniversalCompoentRenderer/UniversalComponentRenderer";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import { smallHeading } from "../../../constants/textStyles";
import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import * as styles from "./PageBrand.style";

const PageBrandOldT6: FunctionComponent = () => 
{
  const props = { ...useContext<IContext>(Context), ...useContext<IContext>(Context) };
  const elementsWithoutMainAsset = props.PageBrand?.elements?.filter(element => element?.__typename !== "Asset");

  return (
    <Fragment>
      <Header />
      <UniversalComponentRenderer items={elementsWithoutMainAsset} />
      {props.PageBrand?.connectedArticles?.map((article, i) => 
      {
        if(!article) 
        {
          return null;
        }

        const assets = article?.extras?.filter(extra => extra?.__typename === "Asset") as IAsset[];

        return (
          <div css={`${styles.articleWrapper}${i % 2 === 1 ? styles.odd : styles.even}`} key={i}>
            <div css={centeredContent}>
              <div css={contentWrapper}>
                <div css={`${null}${styles.articleContent}`}>
                  {article?.headline && (
                    <h3 css={`${smallHeading}${styles.articleHeading}`}>{article?.headline}</h3>
                  )}
                  {article?.mainText && <RichText textContent={article?.mainText} />}
                </div>
                <div css={`${null}${styles.articleAssets}`}>
                  {assets?.map((asset, j) => (
                    <div key={j} title={asset.description} css={styles.assetWithSubtitle}>
                      <Img quality={500} src={asset?.src} alt={asset.description} />
                      {asset.description && <p>{asset.description}</p>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {props.PageBrand?.footnote && (
        <div css={`${styles.footnote}${props.PageBrand?.connectedArticles?.length % 2 === 0 ? styles.footnoteOdd : null}`}>
          <div css={contentWrapper}>
            <div css={centeredContent}>
              <RichText overwriteStyle={styles.footnoteRichtext} textContent={props.PageBrand.footnote} />
            </div>
          </div>
        </div>
      )}
      <Footer showLogos={false} />
    </Fragment>
  );
};

PageBrandOldT6.displayName = "PageBrandOldT6";
export { PageBrandOldT6 };
