import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Col } from "../../../BaseComponents/Grid";

export const FormWrapper: any = styled.div`
  margin-bottom: 50px;
  * {
    line-height: 140%;
  }
  h1 {
    font-size: 34px;
    padding: 0 10px 0 0px;
    color: #85b2c3;
    line-height: 38px;
    letter-spacing: 0.68px;
    font-weight: 700;
  }

  div > h2 {
    font-size: 34px;
    padding: 0 10px 0 0px;
    color: #85b2c3;
    line-height: 38px;
    letter-spacing: 0.68px;
    font-weight: 700;
  }
  strong {
    font-weight: 600;
  }
`;

export const CompanyInfo: any = styled.div`
  * {
    font-weight: 300;
  }

  [data-tel] {
    margin-top: 21px;
  }
`;

export const OfficeInfo: any = styled.div`
  span { 
    color: #85b2c3;
  }
`;

export const companyTitle: any = css`
  h1 {
    color: #85b2c3;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 30px;
  }
`;

export const directionsDescription: any = css`
  h6 {
      color: #85b2c3;
      font-size: 19px;
  }
`;

export const contact: any = css`
  margin-top: 20px;
`;

export const SidebarText: any = styled.div`
  background-color: rgba(255,255,255,0.2);
  margin-top: 30px;
  padding: 10px 10px 15px;
  border-radius: 8px;
  max-width: 250px;
  br:first-child {
    margin-top: 0;
  }
  div > p:last-child {
    margin-bottom: 0;
  }
  strong p {
    margin-bottom: 0;
  }
`;

export const ContactForm: any = styled.div`
  margin-top: 70px;
  width: 100%;

  * {
    font-weight: 300;
  }

  h3 {
    font-size: 34px;
    padding: 0 10px 0 0px;
    color: #85b2c3;
    line-height: 38px;
    letter-spacing: 0.68px;
    font-weight: 700;
  }
`;

export const inputsWrapper: any = css`
  label, input, textarea {
    width: 100%;
    display: block;
  }
  input, textarea {
    margin-bottom: 20px;
  }
`;

export const fcWrapper: any = css`
    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 15px;
            margin-top: 15px;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const SubmitWrapper: any = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  > div {
    width: 50%;
    display: flex;
    align-items: flex-start;

    > label > a {
      font-size: 12px;
      color: #85b2c3;
      &:hover {
        color: #fff;
      }
    }
  }

  button {
    display: block;
    width: 100%;
    line-height: 3;
    font-weight: 300;
    background-color: #85b2c3;
    color: #EFEFEF;
    border-radius: 0;
    &:hover {
      background-color: #706f6f;
    }
  }
  div.button {
    width: 50%;
    padding-left: 10px;
  }
`;

export const datenschutzAkzeptierenWrapper: any = css`
  display: flex;
  max-width: 50%;
`;

export const datenschutzAkzeptieren: any = css`
  font-size: 12px;
  margin-left: 5px;
  a {
    color: #85b2c3;
  }
`;

export const InputsContent: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Input: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 47.5%;
  }
`;

export const InfoWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    flex-direction: row;
  }
`;
