import React from "react";
import { RichText } from "src/common";
import { GuestbookStars } from "src/common/components/GuestbookStars/GuestbookStars";
import * as styles from "../../Guestbook.style";
import * as previewStyles from "./Preview.style";


const Preview = (props: {universalTexts: any, formData: any, goToGuestbookSubmit: any, goBackToEditSubmit: any, options: any}): any => {
    
    return (
        <div css={previewStyles.preview}>
            <h1 css={styles.heading}>
            {props.universalTexts?.guestbook__preview ?
                <RichText textContent={props.universalTexts?.guestbook__preview}></RichText>
                : "Vorschau ansehen"
            }
            </h1>

            <div css={styles.contentWrapper}>
                <div className="entry">
                    <div css={`${styles.even}`}>
                        <span className="info">
                            {props.formData.alias} schrieb am {new Date().toLocaleDateString('de-DE', props.options)}:
                        </span>
                        {props.formData.sterne === 0 ?
                                <h3>{props.formData.titel}</h3>  
                                : 
                                <div className="title_stars_wrapper">
                                    <h3>
                                        {props.formData.titel}
                                    </h3>
                                    <div className="title_stars_wrapper__stars">
                                        <GuestbookStars
                                            changeable={false} 
                                            amountOfStars={props.formData.sterne}
                                            starSize={18}/>   
                                    </div>
                                </div>
                            }
                        <p className="message">{props.formData.nachricht}</p>
                    </div>
                </div>
            </div>

            <div css={styles.submitWrapper} style={{textAlign: "center"}}>
                <button onClick={props.goToGuestbookSubmit} type="submit">{props.universalTexts?.Submit || "Absenden"}</button>
                <button onClick={props.goBackToEditSubmit}>Bearbeiten</button>
            </div>
        </div>
    );
};

export default Preview;
