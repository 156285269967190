import * as React from "react";

import { Banner } from "../../common/Banner/Banner";
import { Footer } from "../../common/footer/Footer";

import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { CommonPageBrandMicroMainContent } from "src/common/brand-area/PageBrandMicro/CommonPageBrandMicroMainContent";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandMicroT1 = () => (
    <>
        <div>
            <Banner />
        </div>
        <div>
            <MainNavigation />
            <Spacer bronze={24} gold={40} />
            <CommonPageBrandMicroMainContent />
            <Footer />
        </div>
    </>
);

PageBrandMicroT1.displayName = "PageBrandMicroT1";
export { PageBrandMicroT1 };
