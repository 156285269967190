import React, { FC, useContext } from "react";
import styled, { css } from "styled-components";
import { IContext, Context } from "../../../../../common";
import { BREAKPOINTS_DM } from "../../../../../common/constants/mediaquerys";
import { useDealerSearchResult } from "../../../../../common/hooks/useDealerSearch";
import { GEDK_LINES } from "../../../../../common/utils/lineShorts";
import { DachseiteSearch } from "../../common/DachseiteSearch/DachseiteSearch";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import { SectionWrapper } from "../../content/LandingContent/LandingContent.style";
import Hero from "../../content/MainContent/Hero";
import { useRouter } from "next/router";
import { FriendlyCaptcha } from "../../../../../common/components/friendly-capture";

const SContentContainer: any = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 0 30px;
`;

const PageSearchT23: FC = () => {
  const props = useContext<IContext>(Context);
  const router = useRouter();
  const query = router.query;

  const { dealers, friendlyCaptchaToken, isLoading } = useDealerSearchResult();
  return (
    <>
      <Header />
      <SectionWrapper>
        <div
          css={css`
            img {
              height: 300px !important;
            }
          `}
        >
          <Hero page={props.PageContact} />
        </div>
        <div>
          <SContentContainer>
            <div
              css={css`
                width: 100%;
                min-height: calc(100vh - 510px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  @media (${BREAKPOINTS_DM.gold_1024}) {
                    flex-direction: row;
                  }
                `}
              >
                <div
                  css={css`
                    width: 100%;
                    @media (${BREAKPOINTS_DM.gold_1024}) {
                      width: 33%;
                    }
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                  `}
                >
                  <DachseiteSearch></DachseiteSearch>
                </div>
                <div
                  css={css`
                    width: 100%;
                    @media (${BREAKPOINTS_DM.silver_768}) {
                      width: 67%;
                    }
                  `}
                >
                  <div>
                    {!friendlyCaptchaToken && query.search && !isLoading ? (
                      <div
                        css={css`
                          padding-top: 10px;
                        `}
                      >
                        Bitte bestätigen Sie, dass Sie kein Roboter sind. Danach
                        werden die Suchergebnisse angezeigt.
                        <FriendlyCaptcha />
                      </div>
                    ) : null}
                    <div css={`
                    margin-top: 2em;
                    @media (${BREAKPOINTS_DM.silver_768}) {
                      margin-top: 0px;
                    }
                    `}>
                      {!isLoading && dealers && dealers.length == 0 ? (
                        <div>
                          <h3
                            css={css`
                              font-weight: bold;
                              font-size: 20px;
                              line-height: 24px;
                              padding: 10px 0px;
                              padding-top: 0px;
                              color: white;
                            `}
                          >
                            Keine Ergebnisse gefunden
                          </h3>
                          <div
                            css={css`
                              line-height: 24px;
                            `}
                          >
                            In Ihrem gewünschten Gebiet befindet sich kein
                            passender Fachhändler. Kompetente Partner finden Sie
                            zusätzlich{" "}
                            <a
                              css={css`
                                font-weight: bold;
                                color: white;
                              `}
                              href="https://www.kuechenkompass.com"
                              target="_blank"
                            >
                              hier
                            </a>
                            .
                          </div>
                        </div>
                      ) : (
                        <div>{isLoading && <p>Einen Moment bitte ...</p>}</div>
                      )}
                    </div>
                    {dealers?.map((o: any, i: number) => {
                      return (
                        <a target="_blank" href={`${o.domain}`} key={i}>
                          <div
                            key={o.caisy_org_id || o.phnummer}
                            css={`
                              margin-bottom: 40px;
                            `}
                          >
                            <div>
                              <div css={``}>
                                <span>
                                  {GEDK_LINES.find(
                                    (l) =>
                                      l.short ==
                                      props.CompanyInformationPrivate.line
                                  ).name ?? ""}
                                </span>
                                <div>
                                  <h2
                                    css={css`
                                      color: white;
                                      font-size: 30px;
                                      font-size: 29px;
                                      line-height: 35px;
                                      padding: 15px 0px 10px 0px;
                                      :hover {
                                        text-decoration: underline;
                                      }
                                    `}
                                  >
                                    {o.company_name}
                                  </h2>
                                </div>
                                <div>
                                  <p>
                                    {o.street + " · " + o.zip + " " + o.city}
                                  </p>
                                  <p>&gt; Details</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    }) ?? null}
                  </div>
                </div>
              </div>
              <div />
            </div>
          </SContentContainer>
        </div>
      </SectionWrapper>
      <Footer />
       
    </>
  );
};

PageSearchT23.displayName = "PageSearchT23";
export { PageSearchT23 };
