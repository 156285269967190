import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  position: relative;
  min-height: 80vh;
  font-weight: 300;

  margin: 20px auto 50px auto;
  padding: 0 10px;
  padding-bottom: 100px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 auto 50px auto;
    padding: 300px 0 0 0;

  }

  h1 {
    color: ${props => props.theme.palette.primary};
  }

  span {
    font-weight: 300;
  }
`;

export const OpenFormWrapper = styled.div`
  margin-top: 50px;

  button {
    padding: 0 25px;
    line-height: 45px;
    background-color: #1D1D1B;
    color: ${props => props.theme.palette.primary};
    border-radius: 0;
    &:hover {
      background-color: #1D1D1B;
    }
  }
`;

export const ContentWrapper: any = styled.div`
  width: 100%;
  display: flex;


  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const BoxedContent: any = styled.div`
  ${({ theme }) => theme.box}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &,
    span {

    }
    margin-bottom: 36px;
  }

  h1,
  h2,
  h3 {
    &,
    span {
      text-transform: uppercase;
      font-weight: 100;
      font-size: 24px;
    }
  }

  span {
    font-weight: 400;
  }
  p:not(.c-empty) {
    font-weight: 400;
    margin-bottom: 20px;
  }

  margin-bottom: 21px;
`;

export const SideBarWrapper: any = styled.div`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 25%;
    padding-left: 21px;
  }
`;

export const ElementsWrapper: any = styled.div`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 75%;
  }
`;