import * as React from "react";
import { FunctionComponent, useState, useContext } from "react";
import { Context, IAsset } from "src/common";

import { extrasMapper } from "../../utils/extrasMapper/extrasMapper";
import * as styles from "./AccordeonContent.style";
import { NewsArticleItem } from "./newsArticleItem/NewsArticleItem";

export const AccordeonContent: FunctionComponent = () => 
{
  const props = useContext(Context);

  // Set Articles per Page
  const articlesPerPage = 8;
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(articlesPerPage);

  const forward = (): void => 
  {
    if(startPoint + articlesPerPage >= newsArticles.length) 
    {
      return;
    }
    setStartPoint(startPoint + articlesPerPage);
    setEndPoint(endPoint + articlesPerPage);
  };

  const back = (): void => 
  {
    if(startPoint - articlesPerPage < 0) 
    {
      return;
    }

    setStartPoint(startPoint - articlesPerPage);
    setEndPoint(endPoint - articlesPerPage);
  };

  const articles = props?.PageNews?.NewsArticles || [];
  const customArticles = props?.PageNews?.NewsArticlesCustom || [];

  const newsArticles = [...customArticles, ...articles].map((article, index) => 
  {
    const imgArray: IAsset[] = article?.extras?.filter(e => e?.__typename === "Asset") as IAsset[]; 
    return (
      <NewsArticleItem
        active={index === startPoint}
        headline={article?.headline}
        text={article?.text}
        images={imgArray}
        key={index}
      />
    );
  });

  return (
    <div css={styles.wrapper}>
      {newsArticles?.slice(startPoint, endPoint)}
      <div css={styles.pagination}>
              
        {endPoint < newsArticles?.length ? (
          <button onClick={() => { forward(); window.scrollTo({ top: 0 }); }}>
            <svg
              style={{
                transform: "rotate(180deg) scale(.6)",
                marginRight: "4px",
                marginLeft: "0"
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            </svg>
            <p>Ältere Nachrichten</p>
          </button>
        ) : (
          <p />
        )}
        { startPoint > 0 ? (
          <button onClick={() => { back(); window.scrollTo({ top: 0 }); }}>
            <p>Neuere Nachrichten</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            </svg>
          </button>
        ) : (
          <p />
        )}
      </div>
    </div>
  );
};
