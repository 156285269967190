import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const MicroWrapper: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  h1 {
    color: rgb(60, 60, 59);
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.48px;
    line-height: 42px;
    margin-bottom: 21px;
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 100vh;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 auto 90px;
  }
`;
