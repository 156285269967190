import React, { FC } from "react";
import { Grid, Section } from "../../../BaseComponents/Grid";
import Header from "../../common/Header";
import SearchContent from "../../content/SearchContent/SearchContent";
import Footer from "../../common/Footer";


const PageSearchT26: FC = () => (
  <>
    <Header disableLogo />
    <Grid>
      <Section>
        <SearchContent />
      </Section>
    </Grid>
    <Footer />
    </>
);
PageSearchT26.displayName = "PageSearchT26";
export { PageSearchT26 };
