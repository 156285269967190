import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../../../../../../common/constants/mediaquerys";

const CSSGreen = css`
  background: #69c0ac;
  color: #fff;

  a {
    color: #fff;
  }

  p {
    font-weight: 300;
    color: #fff;
  }
`;

export const SDealerItem = styled.div<any>`
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(50% - 13.5px / 2);
  }
  border: 2px solid #69c0ac;
  padding: 27px;
  display: flex;
  flex-direction: column;
  gap: 13.5px;

  a {
    text-decoration: underline;
    color: #69c0ac;
    font-size: 1.33333rem;
    :hover {
      text-decoration: none;
    }
  }

  p {
    font-weight: 300;
    color: #3c3c3b;
  }

  ${({ isGreen }) => isGreen && CSSGreen}
`;
