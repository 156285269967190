import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";
import NewsContent from "../../content/NewsContent";

const PageNewsT25: FC = () => (
  <>
    <Header />
    <MainContent>
      <NewsContent />
    </MainContent>
    <Footer />
     
  </>
);

PageNewsT25.displayName = "PageNewsT25";
export { PageNewsT25 };

