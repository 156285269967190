import React, { FunctionComponent } from "react";
import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";

const PageBrandT0: FunctionComponent = () => {
    return (
        <>
            <CommonPageBrandMainContent />
        </>
    );
};

PageBrandT0.displayName = "PageBrandT0";
export { PageBrandT0 };
