import { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../../../../common/constants/mediaquerys";

export default css`
  .inputsWrapper{
    *:not(label) {
      width: 100%;
      cursor: pointer;
    }
    label {
      font-weight: 300;
      cursor: pointer;
    }
    input, textarea {
      border: solid #c8c8c8 1px;
    }
    textarea {
      margin-bottom: 14px;
    }
  }
  .twoColumnsRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    flex-direction: column;
    > div {
      width: 100%;
      &:first-child {
        margin-bottom: 14px;
      }
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
    flex-direction: row;
    > div {
      width: 48%;
      &:first-child {
        margin-bottom: 0;
      }
    }
    }
  }
  .threeColumns{
    > div {
          width: 100%;
          &:first-child, &:nth-child(2) {
            margin-bottom: 14px;
          }
        }
    @media (${BREAKPOINTS_DM.silver_768})
    {
      > div {
        width: 31%;
        &:first-child, &:nth-child(2) {
            margin-bottom: 0;
          }
      }
    }
  }
  .submitWrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: flex-start;
    > div {
      display: flex;
      align-items: flex-start;
    }
    label {
      margin-bottom: 24px;
      cursor: pointer;
      margin-right: 12px;
    }
    label a {
      color: #008bc5;
    }
    button {
      width: 40%;
      max-width: 250px;
      padding: 10px;
      color: white;
      background-color: #008bc5;
    }
    /* @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: column;
        button {
          width: 100%;
          max-width: none;
        }
    } */
  }
  .datenschutzAkzeptieren{
    font-size: 12px;
    margin-left: 5px;
    a {
      font-size: inherit;
      color: #A8A9AB;
    }
  }
`;

