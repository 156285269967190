import React from "react";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import { Banner } from "../../Banner/Banner";


const PageBrandOldT4 = () => {
    return (
        <div id="PageBrandOldT400">
            <div id="PageBrandOldT4T41">
                 
            </div>
            <div id="PageBrandOldT4T42">
                <MainNavigation />
            </div>
            <div id="PageBrandOldT4T43">
                <MainContent />
            </div>
            <div id="PageBrandOldT4T44">
                <Banner />
            </div>
            <div id="footer">
                <Footer />
            </div>
        </div>
    );
};

PageBrandOldT4.displayName = "PageBrandOldT4";
export { PageBrandOldT4 };
