import React, { FunctionComponent } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import ContentWrapper from "../../common/ContentWrapper";
import Footer from "../../common/Footer";

import MainContent from "../../content/MainContent";

const PageMicroT12: FunctionComponent = () => (
  <>
    <ContentWrapper>
      <Grid>
        <Section>
          <MainContent />
        </Section>
      </Grid>
      <Footer />
       
    </ContentWrapper>
  </>
);

PageMicroT12.displayName = "PageMicroT12";
export { PageMicroT12 };
