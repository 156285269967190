import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  margin-bottom: 30px;
  svg {
    transform: scale(0.7);
    padding-left: 10px;
  }

  p {
    font-weight: 300;
  }
`;

export const HeadingWrapper: any = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  > h3 {
    color: #fff;
    font-weight: 100;
    font-size: 34px;
    letter-spacing: 0.68px;
    line-height: 38px;
  }
`;

export const isActive: any = css`
  overflow: hidden;
  height: auto;
  max-height: 2000px;
  transition: max-height 5s ease-out, opacity 5s ease-out;
  ul {
    padding-left: 35px;
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: disc;
    }
  }
  strong ol li {
    font-weight: 700;
  }
  ul,
  ol {
    display: inline-block;
    text-align: left;
    li,
    span {
      text-align: left;
    }
  }
  ol {
    padding-left: 15px;
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: decimal;
    }
  }
  a {
    &,
    span {
      color: #fff;
    }
    span {
      &::after {
        content: "";
        display: inline-block;
        background-image: url("/images/sprite-s82c5aa31b4.png");
        background-repeat: no-repeat;
        background-position: -20px -36px;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        transform: translateY(3px);
      }
    }
  }
  h1,
  h2,
  h3,
  h4 {
    &,
    span {
      font-size: 12px;
      color: #d7e8c9;
      font-weight: 700 !important;
    }
  }
  h3,
  h4 {
    &,
    span {
      font-weight: 400;
      letter-spacing: 0.02em;
      color: #d7e8c9;
      font-size: 1.2rem;
    }
  }
  p {
    font-weight: 300;
    color: #d7e8c9;
    span {
      font-weight: 300;
      font-size: 15px;
    }
  }
  p.bottom-button {
    color: #d7e8c9;
    font-size: 15px;
    font-weight: 100;
    line-height: 34px;
  }

  h5 {
    &,
    strong {
      color: #d7e8c9;
    }
  }
`;

export const isNotActive: any = css`
  overflow: hidden;
  height: auto;
  max-height: 80px;
  transition: max-height 1s ease;
  h1,
  h2,
  h3,
  h4 {
    & {
      font-size: 12px;
      line-height: 25px;
      color: #d7e8c9 !important;
      font-weight: 700 !important;
    }
  }
  h3,
  h4 {
    &,
    span {
      font-weight: 400;
      letter-spacing: 0.02em;
      color: #fff;
      font-size: 1.2rem;
    }
  }
  p,
  h5 {
    opacity: 0;
  }
  p.bottom-button {
    color: #76b729;
    font-size: 15px;
    font-weight: 100;
    line-height: 34px;
  }
`;

export const imgAndText: any = css`
  display: flex;
  flex-direction: column;

  .richTextWrapper {
    padding-top: 25px;

    > div:nth-child(2) {
      margin-bottom: 20px;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    align-items: flex-start;

    .richTextWrapper {
      width: 65%;
      border-top: 2px solid white;
    }
  }
`;

export const imgAndSubtitleWrapper: any = css`
  .image {
    border-top: 2px solid white;
    padding-top: 30px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-right: 20px;
    width: 35%;
    max-width: 300px;

    .image {
      border-top: none;
    }
  }

  display: flex;
  flex-direction: column;
`;

export const subTitle: any = css`
  &:not(:last-of-type) {
    margin-bottom: -20px;
  }
  background-color: rgb(112, 111, 111);
  padding: 10px;
  font-size: 12px;
  text-align: center;
  margin-bottom: 20px;
`;
