import React, { FunctionComponent, useContext, Fragment } from "react";

import { cutCorner } from "../../../../constants/layout";
import * as styles from "./GuestbookEntries.style";
import { IContext, Context, IGuestbookEntry, RichText } from "src/common";

export const GuestbookEntries: FunctionComponent = () => {
    const slugProps = useContext<IContext>(Context);
    const guestbookEntries = slugProps?.AllGuestbookEntry;
    const _guestbookEntries = guestbookEntries.map(node => {
        if (node?._meta?.publishedAt) {
            (node as any).datePublished = new Date(node.date || node._meta.publishedAt);
        }
        return node
    })

    return (
        <Fragment>
            {_guestbookEntries?.sort((a: any, b: any) => b.datePublished - a.datePublished).map((node: IGuestbookEntry, i) => {
                let name: string;
                let datePublished: Date;
                const stars: React.ReactElement[] = [];
                let dateOptions: any;

                if (node?._meta?.publishedAt) {
                    datePublished = new Date(node.date || node._meta.publishedAt);
                    dateOptions = {
                        month: "long",
                        day: "numeric",
                        year: "numeric"
                    };
                }

                if (!node?.firstname && !node.lastname) {
                    name = "Unbekannt";
                }
                else {
                    name = `${node.firstname} ${node.lastname}`;
                }

                for (let j = 0; j < node?.Evaluation; j++) {
                    stars.push(<svg key={j} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z" /></svg>);
                }

                return (
                    <div key={i} css={`${cutCorner}${styles.guestbookEntry}`}>
                        <div>
                            <p css={styles.datePublished}>
                                {name} bewertete
                                {datePublished && <span> am {datePublished.toLocaleDateString("de-DE", dateOptions)}</span>}
                            </p>
                            <div css={styles.headlineWrapper}>
                                {node?.title && <h2 css={styles.title}>{node.title}</h2>}
                                {stars && <div>{stars}</div>}
                            </div>
                            {node?.Message && <RichText fragment textContent={node.Message} />}
                            {node?.commentOfDealer && (
                                <div css={styles.comment}>
                                    <h4 style={{ marginTop: 10 }} css={styles.title}>Kommentar</h4>
                                    <RichText fragment textContent={node.commentOfDealer} />
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </Fragment>
    );
};
