import React, { FC, useContext } from "react";

import {
  IContext,
  Context,
  getPage,
  ITextElement,
  ISidebarText,
  IAsset,
} from "src/common";
import ImageWithAspectRatio from "src/templates/t9/BaseComponents/ImageWithAspectRatio";

import Gallery from "../../common/Gallery";
import { Container, Section, Divider } from "../../common/Grid";
import Hero from "../../common/Hero";
import SubNavRight from "../../common/SubNavRight";
import TextBoxRight from "../../common/TextBoxRight";
import {
  Wrapper,
  ContentContainer,
  ContentWrapper,
  SideBarWrapper,
} from "./GuestBookContent.style";

const GuestBookContent: FC = (): any => {
  const pageProps = useContext<IContext>(Context);

  const page = getPage(pageProps);
  // ----------------------------------------- //

  //@ts-ignore
  const imgArray = page?.elements?.filter(
    (e) => e?.__typename === "Asset"
  ) as IAsset[];
  //@ts-ignore
  const slider = page?.elements?.filter(
    (e) => e?.__typename === "Slider"
  ) as any[];
  //@ts-ignore
  const headText = page?.elements?.find(
    (e) => e?.__typename === "TextElement"
  ) as ITextElement;

  // const vorname = universalTexts?.guestbook__firstname || "Vorname";
  // const nachname = universalTexts?.guestbook__lastname || "Nachname";
  // const email = universalTexts?.guestbook__email || "E-Mail";
  // const titel = universalTexts?.guestbook__title || "Titel";
  // const nachricht = universalTexts?.guestbook__message || "Nachricht";
  // const bewertungAbgeben = universalTexts?.gestbook__evaluation || "Bewertung abgeben";

  const showSlider =
    slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery;
  if (imgArray?.[0] || showSlider) {
    if (imgArray.length > 1 || showSlider) {
      if (slider) {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      } else {
        imageOrGallery = <Gallery imgArray={imgArray} />;
      }
    } else {
      imageOrGallery = (
        <ImageWithAspectRatio
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={342}
          ratio={50}
          quality={1080}
        />
      );
    }
  }

  const navItem = pageProps?.PrimaryNavigation?.items?.find(
    (i) =>
      !!i?.subitems?.find((s) => s?.slug === pageProps.slug) ||
      i?.link?.[0]?.slug === pageProps.slug
  );
  const sideBarText = page?.extras?.find(
    (e) => e?.__typename === "SidebarText"
  ) as ISidebarText;

  return (
    <Wrapper>
      <Container>
        <Hero page={page} />
        <Section>
          <ContentContainer>
            {headText && <h1>{headText.headline}</h1>}
            <ContentWrapper>
              {(navItem || sideBarText) && (
                <SideBarWrapper>
                  <SubNavRight />
                  <TextBoxRight text={sideBarText?.text} />
                </SideBarWrapper>
              )}
            </ContentWrapper>
            <Divider />
          </ContentContainer>
        </Section>
      </Container>
    </Wrapper>
  );
};

export default GuestBookContent;
