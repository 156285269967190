import { css } from "styled-components";

import colors from "../../../constants/colors";

export const imprintWrapper: any = css`
border: 2px solid ${colors.blue};
h2>span, h2, h1, h1>span {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 12px;
  color: ${colors.blue};
}
`;

export const imprintRichtext: any = css`
h3 {
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 8px;
  color: ${colors.blue};
}
a {
  color: ${colors.blue};
}
strong p {
  margin-top: 12px;
  display: block;
}
p {
  margin-bottom: 10px;
  display: block;
}
a {
  display: inline-block;
}
ul {
  padding-left: 35px;
  list-style-position: inside;
  * {
    list-style-position: inside;
  }
  li {
    display: list-item;
    list-style: disc;
  }
}
ol {
  padding-left: 35px;
  list-style-position: inside;
  * {
    list-style-position: inside;
  }
  li {
    display: list-item;
    list-style: decimal;
  }
}
`;
