import React from "react";

import { Banner } from "../../Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import MainNavigation from "../../common/MainNavigation/MainNavigation";


const PageMicroT4 = () => 
  (
    <div id="PageT400">
      <div id="PageT4T41">
         
      </div>
      <div id="PageT4T42">
        <MainNavigation />
      </div>
      <div id="PageT4T43">
        <MainContent micro />
      </div>
      <div id="PageT4T44">
        <Banner />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );

PageMicroT4.displayName = "PageMicroT4";
export { PageMicroT4 };
