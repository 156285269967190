import { CommonPageBrandOverviewMainContent } from "src/common/brand-area/PageBrandOverview/CommonPageBrandOverviewMainContent";

import React, { FunctionComponent } from "react";

import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandOverviewT6: FunctionComponent = () => (
    <div id="PageBrandOverviewT6">
        <div>
            <Header noHeadComponent />
        </div>
        <Spacer bronze={80} />
        <CommonPageBrandOverviewMainContent />
        <Footer showLogos={false} />
    </div>
);

PageBrandOverviewT6.displayName = "PageBrandOverviewT6";
export { PageBrandOverviewT6 };
