import React from "react";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import { Banner } from "../../Banner/Banner";

const PageImprintT4 = () => {
    return (
        <div>
            <div>
                <MainNavigation />
            </div>
            <MainContent>
            </MainContent>
            <Banner />
            <div>
                <Footer />
            </div>
        </div>
    );
};

PageImprintT4.displayName = "PageImprintT4";
export { PageImprintT4 };
