import styled from "styled-components";

export const Wrapper: any = styled.div`
  width: 100%
`;

export const CourseItemWrapper: any = styled.div`
  color: #968a78;
  h3 {
  }

  > div {
    display: flex;
  }
`;

export const CourseContentWrapper: any = styled.div`
  width: 75%;

  [data-image] {
    width: calc(33.3% - 16px);
    margin: 0 21px 10px 16px;
    height: 130px;
    float: left;
    object-fit: contain;
  }

  [data-content] {
    p {
      &, span {
        font-family: helvetica,sans-serif;
        font-size: 15px;
      }
      width: unset;
    }

    a {
      color: #80C7DC;
    }
  }
`;

export const CourseDateWrapper: any = styled.div`
  width: 25%;
  padding-left: 32px;
  h3 {
    margin-bottom: 4px;
  }

  p {
    &, span {
      font-size: 14px;
      line-height: 17px;
    }
  }
  a {
    display: block;
    background-color: ${({theme}) => theme.palette.bgLight};
    border: 1px solid ${({theme}) => theme.palette.bgLight};
    line-height: 3;
    padding: 0 10px;
    margin-top: 20px;
  }
`;
