import React, { FC, useContext } from "react";
import { Context, DateFormatted, getPage, Img, IPageCourse, ITextElement } from "src/common";
import { ICourse } from "src/common/childTypes";

import { RichTextGeneral } from "../../common/RichText";
import { Wrapper, CourseItemWrapper, CourseContentWrapper, CourseDateWrapper, CourseImageWrapper } from "./CourseContent.style";
import CourseForm from "./CourseForm";

const CourseContent: FC<{ course: ICourse; onSetCourse: (ICourse) => void }> = ({ course, onSetCourse }) => 
{
  const props = useContext(Context);
  const page = getPage(props) as IPageCourse;
  const courses = page?.courses;
  return (
    
    <Wrapper>
      { course
        ? <CourseForm course={course} onSetCourse={onSetCourse}/>
        : <>
          {courses?.map((currentCourse) => 
          {
            const text = currentCourse.elements?.find(head => head?.__typename === "TextElement") as ITextElement;
            return (
              <CourseItemWrapper key={currentCourse.id}>
                <h3>{text.headline}</h3>
                <div>
                  <CourseContentWrapper>
                    {text.icon &&
                  <div data-image>
                    <Img src={text.icon}/>
                  </div>}

                    <div data-content>
                      <RichTextGeneral textContent={text.text} />
                    </div>

                  </CourseContentWrapper>
                  <CourseDateWrapper>
                    <h3><DateFormatted date={currentCourse.startDate} /></h3>
                    <p>Beginn: <DateFormatted date={currentCourse.startDate} format="HH:mm"/> Uhr</p>
                    <p>Ende: <DateFormatted date={currentCourse.endDate} format="HH:mm" />  Uhr</p>
                    <p>Preis p.P.: {currentCourse.pricePerParticipantInEuro} €</p>
                    <p>Freie Plätze: {currentCourse.maxParticipants}</p>
                    <p>
                  Anmeldeschluss: <DateFormatted date={currentCourse.registrationDeadline} format="MM.DD.YYYY hh:mm" />
                    </p>

                    <a onClick={() => onSetCourse(currentCourse)}>Anmelden</a>
                  </CourseDateWrapper>
                </div>
              </CourseItemWrapper>
            );
          })}
        </>
      }
    </Wrapper>

  );
};

export default CourseContent;
