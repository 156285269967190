import React, { FC } from "react";
import { Grid, Section } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import ContactContent from "../../content/ContactContent";

const PageContactT9: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <ContactContent />
      </Section>
    </Grid>
    <Banner />
    <Footer />
     
  </>
);

PageContactT9.displayName = "PageContactT9";
export { PageContactT9 };
