import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
    padding: 0 !important;
    > div {
        border: solid 1px ${(props) => props.theme.secondary};
        padding: 27px;
    }
    h1 {
        font-size: 30px;
        margin-bottom: 18px;
        color: ${(props) => props.theme.primary};
    }
`;

export const contactInfoWrapper: any = css`
    padding: 0 !important;
    h1 {
        font-size: 30px;
        margin-bottom: 18px;
        color: ${(props) => props.theme.primary};
    }
`;

export const twoColumnsWrapper: any = css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
        width: 47%;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        > div {
            width: 100%;
        }
    }
`;

export const openingHoursHeading: any = css`
    font-size: 20px;
    margin: 18px 0 3px;
    color: ${(props) => props.theme.primary};
`;

export const openingHoursRichtext: any = css`
    h3 {
        font-size: 20px;
        margin: 18px 0 3px;
        color: ${(props) => props.theme.primary};
    }
    table {
        width: 100%;
        td:last-child {
            text-align: right;
        }
    }
    ol,
    ul {
        padding-left: 0 !important;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        margin-bottom: 18px;
    }
`;

export const mapWrapper: any = css`
    width: 100%;
    height: 350px;
    background-color: #eeeeee;
    margin-top: 40px;
    > div {
        width: 100%;
        height: 100%;
    }
`;

export const routeBerechnenWrapper: any = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
        padding: 10px;
        color: white;
        background-color: ${(props) => props.theme.primary};
    }
    > * {
        width: 47%;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: column;
        > * {
            width: 100%;
        }
    }
`;

export const inputWrapper: any = css`
    margin: 30px 0;
    label {
        font-weight: 300;
    }
`;

export const locationInputWrapper: any = css`
    position: relative;
    input {
        height: 41px;
        list-style: none;
        box-sizing: border-box;
        font-family: inherit;
        cursor: pointer;
        border-width: 1px;
        width: 100%;
        padding-right: 40px;
        padding-left: 8px;
    }
    svg {
        opacity: 0.4;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 7px;
    }
`;

export const routePanel: any = css`
    max-height: 50vh;
    overflow-y: auto;
`;
