import React, { useContext, useState, FunctionComponent } from "react";
import { Context, IAsset, getIfNewsShouldBeDisplayByDate, INews } from "src/common";

import * as styles from "./AccordeonContent.style";
import { NewsArticleItem } from "./NewsArticleItem/NewsArticleItem";

export const AccordeonContent: FunctionComponent<any> = () => 
{
  const p = useContext(Context);
  const [currentPage, setCurrentPage] = useState<number>(1);

  if(!p.PageNews?.NewsArticles) 
  {
    return null;
  }

  const news = p.PageNews;
  const newsArticles = news?.NewsArticles?.filter((article) =>
    getIfNewsShouldBeDisplayByDate({ startDate: article?.startDate, endDate: article?.endDate })
  ).filter((article) => article !== null) || [];

  const customArticles = news?.NewsArticlesCustom?.filter((article) =>
    getIfNewsShouldBeDisplayByDate({ startDate: article?.startDate, endDate: article?.endDate })
  ).filter((article) => article !== null) || [];

  const articles = [...customArticles, ...newsArticles];

  const itemsPerPage = 10;

  const articlesToRender: INews[] = [];
  const amountOfPages = Math.ceil(articles.length / itemsPerPage);

  for(let i = (currentPage - 1) * itemsPerPage; i < (currentPage - 1) * itemsPerPage + itemsPerPage; i++) 
  {
    articlesToRender.push(articles[i]);
  }

  const NewsArticles = articlesToRender.map((Article, index) => 
  {
    if(!Article) 
    {
      return null;
    }
    return (
      <section key={`newsArticleItem${index}`} id={Article?.id}>
        <NewsArticleItem
          headline={Article?.headline}
          index={index}
          text={Article?.text}
          images={(Article?.extras?.filter(e => e?.__typename === "Asset") as | IAsset[] | undefined) ?? []}
        />
      </section>
    );
  });

  return (
    <div css={styles.wrapper}>
      {NewsArticles}
      <div css={styles.flexItem100} style={{ padding: 0 }}>
        <nav css={styles.nextAndPrevPage}>
          {currentPage < amountOfPages && (
            <a
              className="olderNews"
              onClick={(e) => 
              {
                setCurrentPage(currentPage + 1);
              }}
              href={"#"}
            >
              <svg
                className="olderNewsSvg"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
              </svg>
              <p>
                    Ältere Nachrichten
              </p>
            </a>
          )}
          <a
            className="latestNews"
            onClick={(e) => 
            {
              setCurrentPage(currentPage - 1);
            }}
            style={currentPage > 1 ? { visibility: "visible" } : { visibility: "hidden" }}
            href={"#"}
          >
            <p>
                Neuere Nachrichten
            </p>
            <svg
              className="latestNewsSvg"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg>
          </a>
        </nav>
      </div>
    </div>
  );
};
