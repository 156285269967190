import React, { FC } from "react";
import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";
import NewsContent from "../../content/NewsContent";

const PageNewsT23: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent news/>
        <NewsContent />
      </Section>
    </Grid>
    <Footer />
     
  </>
);

PageNewsT23.displayName = "PageNewsT23";
export { PageNewsT23 };

