import { css } from "styled-components";

import { BREAKPOINTS_DM } from "./mediaquerys";
import { theme } from "./themes";

export const centeredContent: any = css`
    max-width: ${theme.maxWidth}px;
    width: 100%;
    margin: 90px auto 0 auto;
    padding: 0 10px;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        padding: 0;
    }
`;
export const flexItem50: any = css`
    width: calc(50% - 13.5px);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: 100%;
    }
`;