import { CommonPageBrandOverviewMainContent } from "src/common/brand-area/PageBrandOverview/CommonPageBrandOverviewMainContent";

import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PageBrandOverviewT25: FC = () => (
  <>
    <Header />
    <CommonPageBrandOverviewMainContent />
    <Footer />
  </>
);

PageBrandOverviewT25.displayName = "PageBrandOverviewT25";
export { PageBrandOverviewT25 };
