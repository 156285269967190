import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";

import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PageBrandT24: FC = () => (
  <>
    <Header />
    <CommonPageBrandMainContent />
    <Footer />
  </>
);

PageBrandT24.displayName = "PageBrandT24";
export { PageBrandT24 };
