import React, { FunctionComponent, useContext, useEffect, useState, Fragment } from "react";
import RichText from "../../../BaseComponents/RichText/RichText";
import * as styles from "./Contact.style";
import { ContactForm } from "./contactForm/ContactForm";
import { IContext, Context } from "src/common";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";
import { SPageLayoutWrapper } from "../PageLanding";
import { css } from "styled-components";
import { getOpeningHoursContent } from "../../../../../common/utils/getOpeningHoursContent";

interface ILocation {
    lat: number;
    lng: number;
}

const PageContactT7: FunctionComponent = () => {
    const slugProps = useContext<IContext>(Context);

    const [inputUserLocation, setInputUserLocation] = useState("");
    const [companyLocation, setCompanyLocation] = useState<ILocation>(null);

    const companyInformationPublic = slugProps?.CompanyInformationPublic;
    const contactPage = slugProps?.PageContact;
    const universalTexts = slugProps?.UniversalTexts;

    const mapElementId = "map";
    const routePanelId = "routePanel";
    const googleApiKey = process.env.NEXT_PUBLIC_MAPSAPIKEY || process.env.MAPSAPIKEY;

    const openingHoursContent = getOpeningHoursContent();

    const companyInformation = slugProps?.CompanyInformationPublic;
    const openingHours = companyInformation?.openingHours;
    const gmbOpeningHours = companyInformation?.gmbOpeningHours;

    const fallbackOpeningHours = !gmbOpeningHours?.periods && openingHours;

    useEffect(() => {
        setCompanyLocation({
            lat: contactPage.location.latitude,
            lng: contactPage.location.longitude,
        });
    }, []);

    useEffect(() => {
        if (!companyLocation) {
            return;
        }

        // @ts-ignore
        if (window.google) {
            setMap();
            return;
        }

        const s = document.createElement("script");
        s.type = "text/javascript";
        s.src = `https://maps.google.com/maps/api/js?key=${googleApiKey}`;

        const x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);

        s.addEventListener("load", setMap);

        return () => s.removeEventListener("load", setMap);
    }, [companyLocation]);

    const getRouteDescriptions = (): void => {
        document.getElementById(routePanelId).innerHTML = null;

        // @ts-ignore
        const windowGoogleMap = window.google.maps;
        const directionsService = new windowGoogleMap.DirectionsService();
        const directionsRenderer = new windowGoogleMap.DirectionsRenderer();

        const map = new windowGoogleMap.Map(document.getElementById(mapElementId));

        directionsRenderer.setMap(map);
        directionsRenderer.setPanel(document.getElementById(routePanelId));

        const request = {
            origin: inputUserLocation,
            destination: `${companyLocation.lat},${companyLocation.lng}`,
            travelMode: "DRIVING",
        };

        directionsService.route(request, (result, status) => {
            if (status === "OK") {
                directionsRenderer.setDirections(result);
            } else {
                setMap();
            }
        });
    };

    const setMap = (): void => {
        if (!companyLocation) {
            return;
        }

        // @ts-ignore
        const windowGoogleMap = window.google.maps;

        const mapId = document.getElementById(mapElementId);
        const map = new windowGoogleMap.Map(mapId, {
            zoom: contactPage?.location?.zoom || 12,
            center: companyLocation,
        });
        new windowGoogleMap.Marker({ position: companyLocation, map });
    };

    const getUserLocation = (): void => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setInputUserLocation(`${position.coords.latitude},${position.coords.longitude}`);
                },
                (error) => {
                    console.info("Standort konnte nicht abgerufen werden: ", error);
                }
            );
        } else {
            console.info("Standort konnte nicht abgerufen werden");
        }
    };

    if (!contactPage?.location?.latitude || !contactPage?.location?.longitude) {
        return null;
    }

    const ContactInfo = (
        <div css={styles.contactInfoWrapper}>
            {(companyInformationPublic?.companyName || companyInformationPublic?.companyNameOverwriteContact) && (
                <h1>{getContactCompanyNameShort()}</h1>
            )}
            <div css={styles.twoColumnsWrapper}>
                <div>
                    {companyInformationPublic?.street && <p>{companyInformationPublic.street}</p>}
                    {companyInformationPublic?.postcode && companyInformationPublic.place && (
                        <p>
                            {companyInformationPublic.postcode} {companyInformationPublic.place}
                        </p>
                    )}
                    <br />
                    {companyInformationPublic?.phone && <p>Tel: {companyInformationPublic.phone}</p>}
                    {companyInformationPublic?.fax && <p>Fax: {companyInformationPublic.fax}</p>}
                    {fallbackOpeningHours && <h3 css={styles.openingHoursHeading}>Öffnungszeiten</h3>}
                    {openingHoursContent && (
                        <RichText
                            textContent={openingHoursContent}
                            overwriteStyle={styles.openingHoursRichtext}
                        ></RichText>
                    )}
                </div>
                <ContactForm />
            </div>
            <div css={styles.mapWrapper}>
                <div id={mapElementId} />
            </div>
            <div
                css={`
                    ${null}
                `}
            >
                <div css={styles.inputWrapper}>
                    <label htmlFor="routeInput">Startadresse</label>
                    <div css={styles.routeBerechnenWrapper}>
                        <div css={styles.locationInputWrapper}>
                            <input
                                id={"routeInput"}
                                type="text"
                                name={"UserLocation"}
                                placeholder={universalTexts?.yourLocation || "Ihr Standort"}
                                value={inputUserLocation}
                                onChange={(event) => setInputUserLocation(event.target.value)}
                            />
                            <svg
                                onClick={getUserLocation}
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M24 11h-2.051c-.469-4.725-4.224-8.48-8.949-8.95v-2.05h-2v2.05c-4.725.47-8.48 4.225-8.949 8.95h-2.051v2h2.051c.469 4.725 4.224 8.48 8.949 8.95v2.05h2v-2.05c4.725-.469 8.48-4.225 8.949-8.95h2.051v-2zm-11 8.931v-3.931h-2v3.931c-3.611-.454-6.478-3.32-6.931-6.931h3.931v-2h-3.931c.453-3.611 3.32-6.477 6.931-6.931v3.931h2v-3.931c3.611.454 6.478 3.319 6.931 6.931h-3.931v2h3.931c-.453 3.611-3.32 6.477-6.931 6.931zm1-7.931c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z" />
                            </svg>
                        </div>
                        <button onClick={getRouteDescriptions} name={"submit"}>
                            {universalTexts?.CalculateRoute || "Route berechnen"}
                        </button>
                    </div>
                </div>
                <div id={routePanelId} css={styles.routePanel} />
            </div>
        </div>
    );

    return (
        <Fragment>
            <ResponsiveSidebar />
            <SPageLayoutWrapper>
                <div>
                    <MainSidebarLeft />
                </div>
                <div
                    css={css`
                        width: 100%;
                    `}
                >
                    <MainAreaWrapper topChildren={ContactInfo} />
                </div>
            </SPageLayoutWrapper>
        </Fragment>
    );
};
PageContactT7.displayName = "PageContactT7";
export { PageContactT7 };
