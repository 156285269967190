import React, { FC, useEffect } from "react";

 ;
import { Banner } from "../../common/Banner/Banner";
import Header from "../../common/header/Header";
import { FooterT3 } from "../../common/footer/Footer";
import MainContent from "../../common/MainContent";
import { CourseContent } from "../../common/CourseContent/CourseContent";
import { css } from "styled-components";
import { useRouter } from "next/router";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";

const PageCourseT3: FC = () => {
  const router = useRouter();
  const courseId = router?.query?.course as string;

  console.log(` router?.query?.course`, router?.query?.course);
  useEffect(() => {
    console.log(`useEff router?.query?.course`, router?.query?.course);
  }, [!!router?.query?.course]);

  return (
    <>
      <Banner />
      <Header />
      <PlanningDateModal />
      <div
        css={css`
          > div > div {
            padding: 30px 0 0px;
          }
        `}
      >
        {!courseId && <MainContent />}
      </div>

      <CourseContent courseId={courseId} />

      <FooterT3 />
    </>
  );
};

PageCourseT3.displayName = "PageCourseT3";
export { PageCourseT3 };
