import React, { useState, useContext, Fragment } from "react";
import { Context, getPage, RichText, ITextElement, IAsset,  } from "src/common";
import {FullscreenImage} from "src/common/components/FullscreenImage/FullscreenImage";
import { css } from "styled-components";

import ImageWithAspectRatio from "../../../../BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import SRichTextGeneral from "../../../../BaseComponents/RichText/Styles/SRichTextGeneral";
import Articles from "../../../Articles/Articles";
import * as styles from "../MainContent.style";

export const OneColumn = (props) => 
{
  const p = useContext(Context);
  const page = getPage(p);
  // @ts-ignore
  const textElements = page?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[];
  // @ts-ignore
  const assetElements = page?.elements?.filter(e => e?.__typename === "Asset" && !!(e as IAsset)) as IAsset[];
  const [isExpanded, setExpanded] = useState(false);
  return (
    <div css={(css`margin-bottom: 20px;`) as any}>
      {assetElements?.map((e, i) => (
        <Fragment key={i}>
          {e && (
            <div css={(css`${styles.mainImageWrapper};margin-bottom: 20px;`) as any}>
              <FullscreenImage
                img={[e]}
                subtitle={e?.description}
              />
            </div>
          )}
        </Fragment>
      ))}
      <div css={(css`margin-top: 30px;`) as any}>
        {textElements?.map((e, i) => (
          <div key={i}>
            <RichText textContent={e?.text} overwriteStyle={SRichTextGeneral} />
          </div>
        ))}
        <Articles />
        {props.bottom ?? null}
      </div>
    </div>
  );
};
