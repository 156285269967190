import React, { FunctionComponent } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header/Header";

import MainContent from "../../content/MainContent";

const PageMicroT26: FunctionComponent = () =>
  (
    <div>
      <Header />
      <Grid>
        <Section>
          <MainContent />
        </Section>
      </Grid>
      <Footer />
       
    </div>
  );

PageMicroT26.displayName = "PageMicroT26";
export { PageMicroT26 };
