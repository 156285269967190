import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
    padding-bottom: 100px;
    min-height: 80vh;
`;

export const formWrapper: any = css`
    display: grid;
    margin-top: 60px;
    margin: 10px 20px 0 20px;

    @media (${BREAKPOINTS_DM.gold_1024}) {
        margin: 10px auto 20px auto;
        max-width: 940px;
    }

    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 20px;
            margin-top: 0;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const formRow: any = css`
    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
      margin-bottom: 10px;
    }
    label {
      display: block;
      cursor: pointer;
    }

    div.optional{
        display:flex;
        width: 100%;

        > span {
          margin-left: 4px;
          color: ${props => props.theme.palette.productBg1};
        }
    }

    input, select, textarea {
      width: 100%;
      height: 22px;
      padding: 3px 2px;
      display: block;
      border: 1px solid rgba(0,0,0,.3);
      //: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)) 1px;
      margin-bottom: 11px;
      &::placeholder {
        font-size: 13px;
      }
    }
    textarea {
      height: 100px;
      resize: vertical;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-bottom: 10px;
        flex-direction: row;
        justify-content: space-between;

        > div {
          width: 49%;
          margin-bottom: 0;
        }

        .formRow__first {
            margin-top: 11px;
        }
    }
`;

export const formRowOneChild: any = css`
    display: block;
`;

export const labelRichtext: any = css`
  p span {
    color: ${({ theme }) => theme.palette.yellow};
  }
  strong p {
    color: ${props => props.theme.palette.productBg1};
    font-weight: 400;
  }
`;

export const richtextStyle: any = css`
    margin: 0 20px 0 20px;

    > div {
        margin: 0;
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        margin: 0 auto;
        max-width: 940px;
    }
`;

export const acceptPrivacyWrapper: any = css`
    display: flex;
    align-items: center;
    margin-top: 30px;
    input {
      margin-right: 8px;
    }
    label, a {
      font-size: 13px;
    }
    a {
      span { 
        color: ${props => props.theme.palette.yellow};
      }
    }
`;

export const submitWrapper: any = css`
    flex-direction: column;

    @media (${BREAKPOINTS_DM.silver_768})
    {
      flex-direction: row;
      flex-wrap: wrap;
    }

    button {
      margin-top: 50px;
      font-size: 14px;
      border-radius:4px;
      background-color: ${(props: any) => props.theme.palette.yellow}; //wouldnt this be better? #947294; instead of #d4c1d4;
      color: white;

      &:hover {
        background-color: black;
        color: ${(props: any) => props.theme.palette.yellow};
        p {
            color: ${(props: any) => props.theme.palette.yellow};
        }
      }

      > div {
        max-width: unset;
        p {
            color: white;
        }
      }

      &:first-child {
        width: 100%;
        padding: 6px 0;
        margin-right: 0;
        
        @media (${BREAKPOINTS_DM.silver_768})
        {
          width: unset;
          margin-right: 20px;
          padding: 6px 15%;
        }

        @media (${BREAKPOINTS_DM.gold_1024})
        {
          padding: 6px 20%;
        }
      }

      &:nth-child(2) {
        margin-top: 15px;
        padding: 6px 0;
        width: 100%;

        &:hover {
          opacity: 0.6;
        }

        > div {
          margin-left: 0;
          margin-right: 0;
        }

        @media (${BREAKPOINTS_DM.silver_768})
        {
          width: unset;
          padding: 6px 6%;
          margin-top: 50px;
        }

        @media (${BREAKPOINTS_DM.gold_1024})
        {
          padding: 6px 10%;
        }
      }
    }
    label, a {
      cursor: pointer;
      font-size: 12px;
    }
    input {
      margin-right: 3px;
    }
    a {
      color: ${props => props.theme.palette.productPrime};
    }
`;

export const heading: any = css`
    p {
      margin: 80px 0 10px 0;
      letter-spacing: .02em;
      span { 
        color: ${(props: any) => props.theme.palette.yellow}; 
      }
    }
    span {
      font-size: 28px;
      color: ${(props: any) => props.theme.palette.yellow}; 
      line-height: 1.4;
      font-family: Helvetica,Arial,sans-serif;
    }
`;

export const headlineWrapper: any = css`
    h1 {
      font-size: 24px;
      font-weight:700;
      margin: 50px 20px 21px 20px;
      color: #3c3c3b;

        @media (${BREAKPOINTS_DM.gold_1024})
        {
            margin: 40px auto 20px auto;
            max-width: 940px;
        }
    }

    .headline_wrapper__confirmation {
        margin-top: 60px;
        margin-left: 0;
    }

`;

export const contentWrapper: any = css`
    display: flex;
    margin: 0 20px 0 20px;
    font-style: normal;
    flex-direction: column;

    @media (${BREAKPOINTS_DM.gold_1024})
    {
        margin: 0 auto 0 auto;
        max-width: 940px;
    }

    .entry {
      @media (${BREAKPOINTS_DM.silver_768})
      {
        margin-right: 28px;
      }
      
      h3 {
        color: ${(props: any) => props.theme.palette.yellow};
        font-size: 16px;
        font-weight: 400;
        letter-spacing: .02em;
        text-transform: uppercase;
        margin: 15px 0 10px 0;
      }

      div {
        align-items: center;
      }

      hr:not(:first-child){
        margin-top: 56px;
      }

      hr {
        margin: 14px 0;
        border: 0;
        border-top: .5px solid ${(props: any) => props.theme.palette.lightGrey};
      }

      p {
        margin-bottom: 1rem;
      }

      .title_stars_wrapper {
        display: flex;
        display: column;
        justify-content: space-between;

        p {
          padding-top: 22px;
        }
    }

    }

    .write_posting_wrapper {
        background-color:${(props: any) => props.theme.palette.yellow}; 
        width: fit-content;
        padding: 0 1em;
        border-radius:4px;
        margin: 20px 0;
        line-height: 3;

        button span {
            color: white;
        }

        &:hover {
            background-color: black;
            button span {
                color: ${(props: any) => props.theme.palette.yellow};  
            }
        }
    } 
`;

export const labelAndError: any = css`
    display: flex;
    justify-content: flex-start;

    .err-msg {
      margin-left: 5px;
      color: ${(props: any) => props.theme.palette.yellow};
    }
`;