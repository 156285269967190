import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const preview_wrapper: any = css`
  color: black;
  display: flex;
  margin: 6% 6% 0 6%;
  font-style: italic;
  flex-direction: column;

    @media (${BREAKPOINTS_DM.silver_768})
    {
      margin: 6% 24% 0 4%;
    }

    @media (${BREAKPOINTS_DM.gold_1024})
    {
      margin: 6% 30% 0 20%;
    }

    .entry {
      
      h3 {
        color: ${(props: any) => props.theme.palette.guestbookPrimary};
        font-size: 20px;
        font-weight: 200;
        text-transform: uppercase;
        margin: 28px 0 32px 0;
      }

      div {
        padding-top: 20px;
      }

      p {
        margin-bottom: 1rem;
      }

      hr {
        border-top: 1px solid ${(props: any) => props.theme.palette.guestbookPrimary};
      }
    }

    .title_stars_wrapper {
      display: flex;
      display: column;
      justify-content: space-between;

      p {
        padding-top: 22px;
      }

    }

`;

