import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const CourseFormWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0px auto;
  padding: 60px 17px;

  h3 {
    margin-bottom: 34px;
    font-family: "Roboto Slab";
    font-size: 18px;
    font-weight: 600;
  }

  h2 {
    color: rgb(102, 102, 102);
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    margin: 5px 0 10px;

    span {
      font-family: "Nunito Sans";
    }
  }
  form {
    width: 100%;
    padding: 0 15px;
    [type=submit] {
      font-family: "Nunito Sans",Helvetica,Arial,sans-serif;
      background-color: transparent;
      text-transform: uppercase;
      padding: .5rem;
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1px;

      border-radius: 0;
      color: inherit;
      border: 1px solid #575756;
      width: 50%;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    flex-direction: row;
    form {
      width: 75%;
    }
  }
`;

export const CourseItemWrapper: any = styled.div`


  > div {
    display: flex;
  }

  > a {
    display: block;
    background-color: #80C7DC;
    color: #efefef;
    border: 1px solid #80C7DC;
    line-height: 3;
    padding: 0 10px;
    margin-top: 20px;
  }

`;

export const CourseContentWrapper: any = styled.div`
  width: 66.7%;

  p {
    margin-bottom: 34px;
    &, span {
      font-family: "Roboto Slab";
      line-height: 34px;
    }
  }

  a {
    color: #80C7DC;
  }

`;

export const InputWrapper: any = styled.div<{ sm?: number; md?: number}>`
  font-family: "Roboto Slab", sans-serif;
  font-weight: normal;
  margin-bottom: 17px;

  ${({ sm }) => sm && `
    width: calc(100% / 12 * ${sm} - 10px);
  `}
  label {
    display: block;
    line-height: 1em;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 400;
  }

  input:not([type=checkbox]) {
    display: block;
    border-top-style: inherit;
    border-top-width: 0;
    border: 1px solid #000;
    line-height: 2;
    min-height: 28px;
    padding: 0 5px;
    width: 100%;
  }

  select {
    display: inline-block;
    font-family: "Roboto Slab", sans-serif;
    font-size: 100%;
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: #bcbcbc;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    ${({ md }) => md && `
      width: calc(100% / 12 * ${md} - 10px);
    `}
  }

`;

export const CourseDateWrapper: any = styled.div`
  width: 100%;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  h3 {
    font-family: "Roboto Slab";
    margin: 0;
  }
  span {
    font-family: "Roboto Slab";
    margin: 0;
    line-height: 1.5;
  }
  span.title-1 {
    color: rgb(102, 102, 102);
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    margin: 5px 0 10px;
  }
  p.back-btn {
    color: rgb(173, 173, 173);
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.8px;
    &:hover {
      color: #a5ccdd;
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 25%;
    padding: 0px 15px;
  }
`;
