import { useRouter } from "next/router";
import { FC, useContext, useEffect, useState } from "react";
import { Context, getPage } from "src/common";
import {
  useDealerSearch,
  useDealerSearchResult,
} from "src/common/hooks/useDealerSearch";
import { Container, Section } from "../../common/Grid";
import Hero from "../../common/Hero";
import { HeroWrapper } from "../MainContent/MainContent.style";
import {
  SSearchContent,
  SSearchContentFooter,
  SSearchContentNoResults,
  SSearchContentResult,
  SSearchContentResultsInput,
  SSearchContentTitle,
} from "./SearchContent.style";
import { FriendlyCaptcha } from "../../../../../common/components/friendly-capture";
import { css } from "styled-components";

const SearchContent: FC = () => {
  const { query } = useRouter();
  const { dealers, friendlyCaptchaToken, isLoading } = useDealerSearchResult();
  const { triggerSearch } = useDealerSearch();
  const p = useContext(Context);
  const page = getPage(p);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!query?.search) return;

    setInputValue(query.search as string);
  }, [query]);

  return (
    <SSearchContent>
      <Container>
        <Section>
          <HeroWrapper>
            <Hero page={page} />
          </HeroWrapper>
          <SSearchContentTitle>
            <h1>KONTAKT</h1>
          </SSearchContentTitle>
          <SSearchContentResultsInput>
            <h3>Planer der Küchengilde in Ihrer Nähe</h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                triggerSearch((e.target as any)?.elements?.[0]?.value);
              }}
            >
              <input
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
              />
              <input type="submit" value="" />
            </form>
            {!friendlyCaptchaToken && query?.search ? null : (
              <p>
                Geben Sie Ihren Ort oder Ihre Postleitzahl ein, um die drei
                nächsten Küchenhäuser in Ihrer Umgebung zu finden!
              </p>
            )}

            {query?.search ? (
              <>
                {friendlyCaptchaToken ? (
                  <>
                    {!dealers || dealers.length === 0 ? (
                      <SSearchContentNoResults>
                        {isLoading ? (
                          <h3>Einen moment bitte ...</h3>
                        ) : (
                          <>
                            <h3>Keine Ergebnisse gefunden</h3>
                            <p>
                              In Ihrem gewünschten Gebiet befindet sich kein
                              passender Fachhändler. Kompetente Partner finden
                              Sie zusätzlich <a href=""> hier.</a>
                            </p>
                          </>
                        )}
                      </SSearchContentNoResults>
                    ) : (
                      <>
                        {dealers?.map((dealer) => {
                          return (
                            <SSearchContentResult key={dealer.client_id}>
                              <h4>KÜCHENGILDE</h4>
                              <h3>
                                <a href={dealer.domain} target="_blank">
                                  {dealer.company_name}
                                </a>
                                {dealer.street} · {dealer.zip} {dealer.city}
                              </h3>
                              <a href={dealer.domain} target="_blank">
                                Details
                              </a>
                            </SSearchContentResult>
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <div
                    css={css`
                      padding-top: 24px;
                    `}
                  >
                    Bitte bestätigen Sie, dass Sie kein Roboter sind. Danach
                    werden die Suchergebnisse angezeigt.
                    <FriendlyCaptcha />
                  </div>
                )}
              </>
            ) : null}
          </SSearchContentResultsInput>

          <SSearchContentFooter>
            <ul>
              <li>
                <a href="">IMPRESSUM</a>
              </li>
              <li>
                <a href="">DATENSCHUTZ</a>
              </li>
              <li>
                <a href="">
                  <svg
                    style={{ width: 18, height: 18, verticalAlign: "-13%" }}
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 58 58"
                  >
                    <title>flogo-HexRBG-Wht-58</title>
                    <path
                      className="cls-1"
                      d="M53.85,0H3.15A3.15,3.15,0,0,0,0,3.15v50.7A3.15,3.15,0,0,0,3.15,57h27.3V35H23V26.33h7.41V20c0-7.37,4.49-11.38,11.06-11.38A62.15,62.15,0,0,1,48.15,9v7.69H43.61c-3.57,0-4.26,1.69-4.26,4.18v5.5H47.9L46.79,35H39.35V57h14.5A3.15,3.15,0,0,0,57,53.85V3.15A3.15,3.15,0,0,0,53.85,0Z"
                    ></path>
                  </svg>{" "}
                  FACEBOOK
                </a>
              </li>
            </ul>
          </SSearchContentFooter>
        </Section>
      </Container>
    </SSearchContent>
  );
};

export default SearchContent;
