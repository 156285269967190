import styled from "styled-components";

import { theme } from "../../../constants/themes";

export const PageMicroWrapper: any = styled.div`
    max-width: ${theme.maxWidth}px;
    width: 92%;
    margin: 0 auto;
    position: relative;
`;

export const ContentWrapper: any = styled.div`
margin-bottom: 15px;
display: flex;
align-items: stretch;
flex-wrap: wrap;
margin-right: -13.5px;

.brand-area-micro {
  width: 100%;
}

> * {
  margin-bottom: 13.5px;
};
`;
