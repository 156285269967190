import React, { useContext, FunctionComponent, Fragment } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { Context, ISidebarText, getPage, ITextElement, IAsset } from "src/common";
import ImageWithAspectRatio from "src/common/BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import { handleFormSubmit } from "src/common/utils/formSubmit";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import { Col, Row } from "../../../BaseComponents/Grid";
import RichText, { RichTextGeneral } from "../RichText";
import * as styles from "./Contact.style";
import { FormWrapper, CompanyInfo, OfficeInfo, SidebarText, ContactForm, SubmitWrapper, InputsContent, Input, InfoWrapper } from "./Contact.style";
import { getOpeningHoursContent } from "../../../../../common/utils/getOpeningHoursContent";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

const Contact: FunctionComponent = () => 
{
  const props = useContext(Context);
  const page = getPage(props);
  const {
    register,
    handleSubmit,
    errors,
    reset,
  } = useForm();
  const openingHoursContent = getOpeningHoursContent();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [success, setSuccess] = React.useState(false);

  const onSubmit = async (data): Promise<any> => 
  {
    // Kontaktformular: d-26922068a30343d98a725c3fdaa663da
    // Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
    // Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441

    const fcValue = (document.querySelector(".frc-captcha-solution") as HTMLInputElement)?.value;

    let title: string | undefined;
    if(typeof window !== "undefined") 
    {
      title = document.title;
    }
    await handleFormSubmit({
      fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
      ...data,
      line: props?.CompanyInformationPrivate?.line,
      subject: `Neue Anfrage über Ihre Homepage ${data?.Name ? `von ${data?.Name} ` : ""}${title ? `- ${title}` : ""}`,
      dealerMail: props?.CompanyInformationPublic?.email,
      templateId: "d-26922068a30343d98a725c3fdaa663da"
    });

    reset();
    setSuccess(true);
  };

  const name = props.UniversalTexts?.name || "Name";
  const eMail = props.UniversalTexts?.eMail || "E-Mail";
  const message = props.UniversalTexts?.message || "Nachricht";

  const sidebarText = props.PageContact?.extras?.find(
    extraItem => extraItem?.__typename === "SidebarText"
  ) as ISidebarText;

  // @ts-ignore
  const imgArray = page?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const textElements = page?.elements?.filter(item => item?.__typename === "TextElement") as ITextElement[];

  textElements?.map(textElement => 
  {
    if(textElement?.icon) 
    {
      textElement?.icon?.map(icon => imgArray.push(icon as any));
    }
  });

  return (
    <FormWrapper>
      <h1>{getContactCompanyNameShort()}</h1>
      <InfoWrapper>
        <Col sm={6}>
          <CompanyInfo>
            <h2>
              {props.CompanyInformationPublic?.managingDirector}
            </h2>
            <p>{props.CompanyInformationPublic?.street}</p>
            <p>
              {props.CompanyInformationPublic?.postcode + " "}{" "}
              {props.CompanyInformationPublic?.place}
            </p>
            {props.CompanyInformationPublic?.phone && (
              <p data-tel>Tel: {props.CompanyInformationPublic?.phone}</p>
            )}
            {props.CompanyInformationPublic?.fax && (
              <p data-fax>Fax: {props.CompanyInformationPublic?.fax}</p>
            )}
          </CompanyInfo >
        </Col>

        <Col sm={6}>
          <OfficeInfo>
            {openingHoursContent && (
              <RichTextGeneral
                textContent={
                  openingHoursContent
                }
              />
            )}

          </OfficeInfo>

          <div>
            {(props.PageContact?.elements?.filter(
              e => e?.__typename === "TextElement"
            ) as ITextElement[])?.map((t, i) => (
              <Fragment key={i}>
                {i === 1 ? (
                  <RichText
                    textContent={t.text}
                  >
                    <h1>{t.headline}</h1>
                  </RichText>
                ) : (
                  i > 1 && (
                    <RichText
                      textContent={t.text}
                    >
                      <h1>{t.headline}</h1>
                    </RichText>
                  )
                )}
              </Fragment>
            ))}
          </div>

          {imgArray?.map((imgItem, index) => (
            <div key={`imageContact${index}`} >
              <ImageWithAspectRatio
                alt={imgItem?.title}
                src={imgItem?.src}
                height={342}
                ratio={78}
                quality={720}
              />
            </div>
          ))}
          {sidebarText?.text && (
            <SidebarText>
              <RichText textContent={sidebarText?.text}/>
            </SidebarText>
          )}
        </Col>

      </InfoWrapper>

      <Row>
        <ContactForm>
          {props?.UniversalTexts?.contactForm && (
            <h3>{props?.UniversalTexts?.contactForm}</h3>
          )}
          {success ? (
            <div>
              <h1>DANKE!</h1>
              Wir werden uns sobald wie möglich bei Ihnen melden.
            </div>
          )

            :
            <form onSubmit={handleSubmit(onSubmit)}>
              <div css={styles.inputsWrapper}>
                <InputsContent>
                  <Input>
                    <label htmlFor={"Name"}>{name}</label>
                    <input
                      required={true}
                      type="text"
                      name="Name"
                      ref={register({
                        options: { required: true, unique: true },
                        min: 12
                      })}
                    />
                  </Input>
                    
                  <Input>
                    <label htmlFor={"E-Mail"}>{eMail}</label>
                    <input
                      required={true}
                      type="email"
                      name="E-Mail"
                      ref={register({
                        options: { required: true, unique: true },
                        min: 4
                      })}
                    />
                  </Input>
                </InputsContent>
                  
                <div>
                  <label htmlFor={"Nachricht"}>{message}</label>
                  <textarea
                    rows={8}
                    required={true}
                    name="Nachricht"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              <div css={styles.fcWrapper}>
                <FriendlyCaptcha />
              </div>
              <SubmitWrapper>
                <div>
                  <input
                    required={true}
                    type="checkbox"
                    placeholder="Ich akzeptiere die Datenschutzerklärung"
                    name="checkbox"
                    ref={register({ required: true })}
                  />
                  <label
                    htmlFor={"checkbox"}
                    css={styles.datenschutzAkzeptieren}
                  >
                    Ich akzeptiere die <a href={`/${props.PageImprint?.slug || ""}`}>Datenschutzerklärung</a>
                  </label>
                </div>
                <div className="button">
                  <button type="submit">
                    {props?.UniversalTexts?.Submit || "Absenden"}
                  </button>
                </div>
                
              </SubmitWrapper>

            </form>}
        </ContactForm>
      </Row>
    </FormWrapper>
  );
};

export default Contact;
