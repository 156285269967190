import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";

const PageBrandOverviewOldT11: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent pageBrand/>
      </Section>
    </Grid>
    <Footer />
     
  </>
);

PageBrandOverviewOldT11.displayName = "PageBrandOverviewOldT11";
export { PageBrandOverviewOldT11 };
