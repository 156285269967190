import { CommonPageBrandTopicMainContent } from "src/common/brand-area/PageBrandTopic/CommonPageBrandTopicMainContent";

import React, { FunctionComponent } from "react";

import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandTopicT6: FunctionComponent = () => (
    <>
        <div>
            <Header noHeadComponent />
        </div>
        <Spacer bronze={80} />
        <CommonPageBrandTopicMainContent />
        <Footer showLogos={false} />
    </>
);

PageBrandTopicT6.displayName = "PageBrandTopicT6";
export { PageBrandTopicT6 };
