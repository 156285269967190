import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { css } from "styled-components";

export const wrapper: any = css`
    position: relative;
    padding-top: 50px;
    padding-bottom: 100px;
    min-height: 80vh;
    button {
      margin-top: 30px;
      border: none;
      padding: 8px 25px;
      color: white;
      background-color: ${props => props.theme.palette.red};
      &:hover {
        opacity: .7;
      }
    }
`;

export const mainText: any = css`
    h1 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 20px;
    }
`;

export const formWrapper: any = css`
    max-width: 800px;
`;

export const heading: any = css`
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
`;

export const formRow: any = css`
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
      margin-bottom: 10px;
    }
    label {
      display: block;
      cursor: pointer;
    }
    input, select, textarea {
      width: 100%;
      height: 29px;
      padding: 3px 2px;
      display: block;
      border: solid black 1px;
      &::placeholder {
        font-size: 13px;
      }
    }
    textarea {
      height: 100px;
      resize: vertical;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-bottom: 10px;
        flex-direction: row;
        justify-content: space-between;
        > div {
          width: 49%;
          margin-bottom: 0;
        }
    }
`;

export const formRowOneChild: any = css`
    display: block;
`;

export const labelRichtext: any = css`
    strong p {
      color: #e6e7e9;
      font-weight: 400;
    }
`;

export const acceptPrivacyWrapper: any = css`
    display: flex;
    align-items: center;
    margin-top: 30px;
    input {
      margin-right: 8px;
    }
    label, a {
      font-size: 13px;
    }
    a {
      color: ${props => props.theme.palette.red};
    }
`;

export const star: any = css`
    margin-right: 12px;
    cursor: pointer;
    transform: scale(1.2);
    transform-origin: top left;
    margin-top: 3px;
`;

export const submitWrapper: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768})
    {
      flex-direction: row;
    }
    button {
      padding: 12px 100px;
      border-radius: 5px;
      width: 100%;
      margin-top: 15px;
      @media (${BREAKPOINTS_DM.silver_768})
      {
        width: unset;
        margin-top: 30px;
      }
      &:first-child {
        margin-top: 30px;
        margin-right: 20px;
        background-color: ${props => props.theme.palette.red};
        color: white;
        &:hover {
          background-color:  ${props => props.theme.palette.black};
          color: ${props => props.theme.palette.red};
          opacity: 1;
        }
      }
      &:nth-child(2) {
        background-color: rgba(255,255,255,0.6);
        color: white;
        padding: 10px 60px;
        &:hover {
          background-color: #7a7a7a;
          color: ${props => props.theme.palette.red};
          opacity: 1;
        }
      }
    }
    label, a {
      cursor: pointer;
      font-size: 12px;
    }
    input {
      margin-right: 3px;
    }
    a {
      color: ${props => props.theme.palette.red};
    }
`;
