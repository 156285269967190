import React, { FC } from "react";
import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import ContactContent from "../../content/ContactContent";

const PageContactT23: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <ContactContent />
      </Section>
    </Grid>
    <Footer />
     
  </>
);

PageContactT23.displayName = "PageContactT23";
export { PageContactT23 };
