import { useRouter } from "next/router";
import React, { FC, useContext } from "react";
import styled, { css } from "styled-components";
import { IContext, Context } from "../../../../../common";
import { BREAKPOINTS_DM } from "../../../../../common/constants/mediaquerys";
import { useDealerSearchResult } from "../../../../../common/hooks/useDealerSearch";
import { GEDK_LINES } from "../../../../../common/utils/lineShorts";
import { DachseiteSearch } from "../../common/DachseiteSearch/DachseiteSearch";
import Footer from "../../common/Footer";
import { Container, Divider, Section } from "../../common/Grid";
import Header from "../../common/Header";
import Hero from "../../common/Hero";
 ;
import { FriendlyCaptcha } from "../../../../../common/components/friendly-capture";

const SContentContainer: any = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 0 30px;
`;

const PageSearchT24: FC = () => {
  const props = useContext<IContext>(Context);
  const router = useRouter();
  const query = router.query;
  const { dealers, friendlyCaptchaToken, isLoading } = useDealerSearchResult();
  return (
    <>
      <Header />
      <Container>
        <div
          css={css`
            img {
              height: 300px !important;
            }
          `}
        >
          <Hero page={props.PageContact} isDealerSearchPage={true}/>
        </div>
        <Section>
          <SContentContainer>
            <div
              css={css`
                width: 100%;
                min-height: calc(100vh - 510px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  @media (${BREAKPOINTS_DM.gold_1024}) {
                    flex-direction: row;
                  }
                `}
              >
                <div
                  css={css`
                    width: 100%;
                    @media (${BREAKPOINTS_DM.gold_1024}) {
                      width: 33%;
                    }
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                  `}
                >
                  <DachseiteSearch></DachseiteSearch>
                </div>
                <div
                  css={css`
                    width: 100%;
                    @media (${BREAKPOINTS_DM.silver_768}) {
                      width: 67%;
                    }
                  `}
                >
                  <div>
                    {query.search && !isLoading && !friendlyCaptchaToken ? (
                       <div css={css``}>
                       Bitte bestätigen Sie, dass Sie kein Roboter sind. Danach werden
                       die Suchergebnisse angezeigt.
                       <FriendlyCaptcha />
                     </div>
                    ) : null}
                     <div>{isLoading && <p>Einen Moment bitte ...</p>}</div>
                    <div css={``}>
                      {dealers && dealers.length == 0 ? (
                        <div>
                          <h3
                            css={css`
                              font-weight: bold;
                              font-size: 20px;
                              line-height: 24px;
                              padding: 10px 0px;
                              padding-top: 0px;
                              color: #f9b000;
                            `}
                          >
                            Keine Ergebnisse gefunden
                          </h3>
                          <div
                            css={css`
                              line-height: 24px;
                            `}
                          >
                            In Ihrem gewünschten Gebiet befindet sich kein
                            passender Fachhändler. Kompetente Partner finden Sie
                            zusätzlich{" "}
                            <a
                              css={css`
                                font-weight: bold;
                                color: #f9b000;
                              `}
                              href="https://www.kuechenkompass.com"
                              target="_blank"
                            >
                              hier
                            </a>
                            .
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    {(!isLoading && dealers) ? dealers.map((o: any) => {
                      return (
                        <a target="_blank" href={`${o.domain}`}>
                          <div
                            key={o.caisy_org_id || o.phnummer}
                            css={`
                              margin-bottom: 40px;
                            `}
                          >
                            <div>
                              <div css={``}>
                                <span>
                                  <strong>
                                    {GEDK_LINES.find(
                                      (l) =>
                                        l.short ==
                                        props.CompanyInformationPrivate.line
                                    ).name ?? ""}
                                  </strong>
                                </span>
                                <div>
                                  <h2
                                    css={css`
                                      color: #f9b000;
                                      font-size: 30px;
                                      font-size: 29px;
                                      line-height: 35px;
                                      padding: 15px 0px 10px 0px;
                                    `}
                                  >
                                    {o.company_name}
                                  </h2>
                                </div>
                                <div>
                                  <p>
                                    {o.street + " · " + o.zip + " " + o.city}
                                  </p>
                                  <p>&gt; Details</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    }) : null}
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          </SContentContainer>
        </Section>
      </Container>
      <Footer />
    </>
  );
};

PageSearchT24.displayName = "PageSearchT24";
export { PageSearchT24 };
