import { css } from "styled-components";

export const articleWrapper: any = css`
  padding: 0 !important;
  > div {
    border: solid 1px ${(props) => props.theme.secondary};
    padding: 27px;
  }
`;

export const articleImage: any = css`
  img {
    width: 100%;
    max-width: 400px;
    height: auto;
  }
`;

export const articleContent: any = css`
  br {
    margin-top: 1rem;
  }
  h2 {
    margin-top: 1.6rem;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.primary};
  }
`;
export const articleText: any = css`
  h2,
  h2 > span {
    margin-top: 1.6rem;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }
  h3,
  h3 > span {
    margin-top: 1.4rem;
    font-size: 1.4rem;
    margin-bottom: 0.7rem;
  }
  h4,
  h4 > span {
    margin-top: 1.2rem;
    font-size: 1.2rem;
    margin-bottom: 0.6rem;
  }
  h3, h4{
    :first-child{
      margin-top: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &,
    span {
      color: #54aa9e;
    }
  }
  h5,
  h6 {
    &,
    span {
      color: #54aa9e;
    }
    font-size: 15px;
  }
`;

export const nextAndPrevPage: any = css`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  margin: 12px 0;
  a {
    background-color: ${(props) => props.theme.primary};
    color: white;
    padding: 4px 15px;
    text-align: center;
    &:first-child {
      margin-right: 7px;
    }
    &:nth-child(2) {
      margin-left: 7px;
    }
  }
  @media (max-width: 500px) {
    a {
      padding: 4px 10px !important;
    }
  }
`;
