import { CommonPageBrandTopicMainContent } from "src/common/brand-area/PageBrandTopic/CommonPageBrandTopicMainContent";

import React, { FunctionComponent } from "react";

import { Banner } from "../../Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import Spacer from "../../../../../common/BaseComponents/Spacer/Spacer";

const PageBrandTopicT4: FunctionComponent = () => (
    <>
        <MainNavigation />
        <Spacer bronze={80} />
        <CommonPageBrandTopicMainContent />
        <Banner />
        <Footer />
    </>
);

PageBrandTopicT4.displayName = "PageBrandTopicT4";
export { PageBrandTopicT4 };
