import React, { FC } from "react";
import { IGuestbookEntry, DateFormatted } from "src/common";
import RichTextGeneral from "../../../common/RichText";
import RateStars from "../RateStars";

import { GuestBookItemWrapper, MessageWrapper } from "./GuestBookItem.style";

const GuestBookItem: FC<{ item: IGuestbookEntry }> = ({ item }) => (
  <GuestBookItemWrapper>
    <p>{item.firstname} {item.lastname} schrieb am <DateFormatted date={item.date} /></p>
    <h3>
      {item.title}
      <RateStars
        selected={item.Evaluation}
        fixed
      />
    </h3>
    <MessageWrapper>
      <RichTextGeneral textContent={item.Message.content} />
    </MessageWrapper>
  </GuestBookItemWrapper>
);

export default GuestBookItem;
