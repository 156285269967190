import React, { Fragment, FunctionComponent, useContext, useEffect, useRef } from "react";
import { IBrand, Context } from "src/common";
import { css } from "styled-components";

import Img from "../../../BaseComponents/Img/Img";
import SBrandBannerVertical from "./Styles/SBrandBannerVertical";
import SShadowOverlay from "./Styles/SShadowOverlay";

const BrandBannerVertical: FunctionComponent = () => 
{
  const props = useContext(Context);
  const allBrands = props.AllBrand;
  if(allBrands.length <= 1) 
  {
    return null;
  }
  const companies = allBrands.map((company, i) => 
  {
    if(!company?.logo) 
    {
      return null;
    }
    return (
      <a key={i}>
        <Img
          src={company?.logo}
          lazyload={false}
          placeholderHeight={"31px"}
          quality={200}
          alt={company?.name || ""}
        ></Img>
      </a>
    );
  });
  const allCompanies: any[] = [];
  for(const i of Array(8).keys()) 
  {
    allCompanies.push(<div key={`aa${i}`}> {companies} </div>);
  }
  return (
    <SBrandBannerVertical amount={allBrands.length}>
      <div
        css={css`
                    opacity: 0;
                    width: 100%;
                    max-width: 712px;
                    pointer-events: none;
                    position: absolute;
                `}
      >
        <div> {companies} </div>
      </div>
      <SShadowOverlay />
      <div className="container">
        <div className="marquee">{allCompanies}</div>
        <div className="marquee">{allCompanies}</div>
      </div>
      <SShadowOverlay />
    </SBrandBannerVertical>
  );
};

export default BrandBannerVertical;
