import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
    position: relative;
    padding-bottom: 100px;
    min-height: 80vh;
    font-weight: 300;
    padding: 50px 11px;

    h1 {
      color: ${props => props.theme.palette.green};
    }

    span {
      font-weight: 300;
    }
`;

export const OpenFormWrapper = styled.div`
  margin-top: 50px;

  button {
    padding: 0 25px;
    line-height: 45px;
    border: 1px solid ${props => props.theme.palette.green};
    color: ${props => props.theme.palette.white};
    background-color: ${props => props.theme.palette.green};


  }
`;
