import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const previewWrapper: any = css`

    @media (${BREAKPOINTS_DM.gold_1024})
    {
        max-width: 950px;
        margin: 0 auto;
        padding: 20px 40px 20px 0;
        h3 {
            margin-top: 0;
        }
    }
`;

export const preview__floatLeft: any = css`
    @media (${BREAKPOINTS_DM.silver_768})
    {
        float: left;
        position: relative;
        left: 10px;

        button {
            padding: 10px 13px !important; // TODO fix this without important
            width: fit-content;
        }
    }

    @media (${BREAKPOINTS_DM.gold_1024})
    {
        position: relative;
        left: auto;
    }
`;