import React from "react";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import { Banner } from "../../Banner/Banner";


const PageT4 = () => {
    return (
        <div id="PageT400">
            
            <div id="PageT4T42">
                <MainNavigation />
            </div>
            <div id="PageT4T43">
                <MainContent />
            </div>
            <div id="PageT4T44">
                <Banner />
            </div>
            <div id="footer">
                <Footer />
            </div>
        </div>
    );
};

PageT4.displayName = "PageT4";
export { PageT4 };
