import React, { FunctionComponent, useState } from "react";
import { ICourse } from "src/common/childTypes";

import Footer from "../../common/Footer";
import FurtherAwards from "../../common/FurtherAwards";
import Header from "../../common/Header";

import CourseContent from "../../content/CourseContent";
import { FurtherAwardsWrapper } from "../../content/CourseContent/CourseContent.style";
import MainContent from "../../content/MainContent";

const PageCourseT15: FunctionComponent = () => 
{
  const [course, setCourse] = useState<ICourse>(null);
  return (
    <>
      <Header />
      {!course && <MainContent course/>}
      <CourseContent course={course} onSetCourse={setCourse} />
      <FurtherAwardsWrapper>
        <FurtherAwards />
      </FurtherAwardsWrapper>
      <Footer />
       
    </>
  );
};

PageCourseT15.displayName = "PageCourseT15";
export { PageCourseT15 };
