import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "src/common/constants/mediaquerys";

const Default = css`
  display: block;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.3em;
  padding: 0.5em 0;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SDealerSearchBody = styled.label<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
