import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";

import React, { FC } from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PageBrandT26: FC = () => (
  <>
    <Header />
    <CommonPageBrandMainContent />
    <Footer />
  </>
);

PageBrandT26.displayName = "PageBrandT26";
export { PageBrandT26 };
