import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
export const CourseFormWrapper: any = styled.div<{ primaryColor?: string }>`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 117px auto;
  padding: 60px 17px;
  
  .error{
    color: red;
  }

  a.back-btn {
    color: ${(props) => props.primaryColor || "#000000"};
    cursor: pointer;
    text-align: end;
    width: 100%;
    display: block;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }

  h3 {
    margin-bottom: 34px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;
  }

  h2, h2>span {
    margin-top: 68px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 22px;
    line-height: 1.375;

  }
  form {
    margin-top: 34px;

    [type="submit"] {
      background-color: ${(props) => props.primaryColor || "#000000"};
      border: 1px solid ${(props) => props.primaryColor || "#000000"};
      border-radius: 0;
      color: #fff;
      display: flex;
      font-family: "Poppins", Helvetica, Arial, sans-serif;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 14px;
      padding: 5px 10px;
      text-transform: uppercase;
      width: fit-content;
      div.icon {
        width: 8px;
        margin-left: 5px;
      }
      &:hover {
        background-color: #000;
      }
    }
  }
`;

export const CourseItemWrapper: any = styled.div`
  > div {
    display: flex;
  }

  > a {
    display: block;
    background-color: #80c7dc;
    color: #efefef;
    border: 1px solid #80c7dc;
    line-height: 3;
    padding: 0 10px;
    margin-top: 20px;
  }
`;

export const CourseContentWrapper: any = styled.div`
  width: 66.7%;

  p {
    margin-bottom: 34px;
    &,
    span {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      line-height: 34px;
    }
  }

  a {
    color: #80c7dc;
  }
`;

export const InputWrapper: any = styled.div<{
  sm?: number;
  md?: number;
  primaryColor?: string;
}>`
  font-family: "Open Sans", Helvetica, Arial, sans-serif, sans-serif;
  font-weight: normal;
  margin-bottom: 17px;

  ${({ sm }) =>
    sm &&
    `
    width: calc(100% / 12 * ${sm} - 10px);
  `}
  label {
    display: block;
    font-family: "Open Sans", Helvetica, Arial, sans-serif, sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
    margin-bottom: 5px;
  }

  input:not([type="checkbox"]) {
    display: block;
    border-top-style: inherit;
    border-top-width: 0;
    border: 1px solid #000;
    line-height: 2;
    min-height: 28px;
    padding: 0 5px;
    width: 100%;
  }

  select {
    display: inline-block;
    font-family: "Open Sans", Helvetica, Arial, sans-serif, sans-serif;
    font-size: 100%;
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: ${(props) => props.primaryColor || "#000000"};
    font-family: "Open Sans", Helvetica, Arial, sans-serif, sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
    margin-bottom: 5px;
    &:hover {
      color: #bcbcbc;
    }
  }

  span {
    font-family: "Open Sans", Helvetica, Arial, sans-serif, sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
    margin-bottom: 5px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    ${({ md }) =>
      md &&
      `
      width: calc(100% / 12 * ${md} - 10px);
    `}
  }
`;

export const CourseDateWrapper: any = styled.div`
  h3 {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    margin: 0;
  }
  span {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    margin: 0;
    line-height: 1.5;
  }
`;
