import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: none;
  li {
    background-color: ${props => props.theme.palette.bgLight};
    border-radius: 10px;
    margin-bottom: 3px;
    a {
      display: block;
      padding: 10px;
      color: black;
    }
    a:hover {
      color: ${props => props.theme.palette.green};
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}){
    display: block;
  }

  .active {
    color: ${props => props.theme.palette.green} !important;
  }
`;
