import * as React from "react";

import { Banner } from "../../common/Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import MainNavigation from "../../common/MainNavigation/MainNavigation";


const PageMicroT1 = () => 
  (
    <>
      <div>
        <Banner />
      </div>
      <div>
         
        <MainNavigation />
        <MainContent micro />
        <Footer />
      </div>
    </>
  );
PageMicroT1.displayName = "PageMicroT1";
export { PageMicroT1 };
