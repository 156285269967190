import React, { FunctionComponent, useContext } from "react";
import { Context, IPage, IArticle, Img, IAsset, IPageBrand } from "src/common";
import { css } from "styled-components";

import RichText from "../../BaseComponents/RichText/RichText";
import SRichTextGeneral from "../../BaseComponents/RichText/Styles/SRichTextGeneral";
import * as styles from "./Articles.style";

const Articles: FunctionComponent = () => 
{
  const props = useContext(Context);
  const filteredArticles = (((props?.Page || props.PageBrand) as any) as IPageBrand)?.connectedArticles?.filter(
        article => article?.__typename === "Article"
    );

  if(!filteredArticles || filteredArticles.length === 0) 
  {
    return null;
  }

  const articles = filteredArticles?.map((item, index) => 
  {
    const article = item as IArticle;
    const imgArray = article?.extras?.filter(
            e => e?.__typename === "Asset" && !!(e as IAsset)
        ) as IAsset[];
    return (
      <div key={index} css={css`${SRichTextGeneral};${styles.Article}` as any}>
        <h3 css={styles.h3}>{article?.headline}</h3>
        {imgArray && imgArray.length > 2 ? (
          <div css={css`display: flex;` as any}>
            {imgArray?.map((image, i) =>
              image ? (
                <Img
                  key={i}
                  css={imgArray && imgArray.length > 2 ? styles.img3orMore : styles.img}
                  quality={720}
                  src={[image]}
                  alt={image.title || "newsImage"}
                />
              ) : null
            )}
          </div>
        ) : (
          <>
            {imgArray?.map((image, i) =>
              image ? (
                <Img
                  key={i}
                  css={imgArray && imgArray.length > 2 ? styles.img3orMore : styles.img}
                  quality={720}
                  src={[image]}
                  alt={image.title || "newsImage"}
                />
              ) : null
            )}
          </>
        )}
        <RichText textContent={article?.mainText} fragment></RichText>
      </div>
    );
  });

  return (
    <div css={styles.wrapper}>
      {articles}
      {props?.Page?.footnote ? (
        <RichText
          textContent={props?.Page?.footnote}
          css={styles.footnote}
          overwriteStyle={SRichTextGeneral}
        ></RichText>
      ) : null}
    </div>
  );
};

export default Articles;
