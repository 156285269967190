import React, { FunctionComponent, useEffect, useState } from "react";
import RichText from "../RichText/RichText";
import { ServiceNumber } from "../ServiceNumber/ServiceNumber";
import * as styles from "./Map.style";
import {Context, ITextElement } from "src/common";
import { useContext } from "react";

interface ILocation
{
    lat: number;
    lng: number;
}


const Map: FunctionComponent<any> = () =>
{
    const props = useContext(Context);
    const [companyLocation, setCompanyLocation] = useState<ILocation>(null);
    const [inputUserLocation, setInputUserLocation] = useState("");
    const googleApiKey = process.env.NEXT_PUBLIC_MAPSAPIKEY || process.env.MAPSAPIKEY;
    const mapElementId = "map";
    const routePanelId = "routePanel";

    const setMap = (): void =>
    {
        if(!companyLocation)
        {
            return;
        }

        // @ts-ignore
        const windowGoogleMap = window.google.maps;

        const mapId = document.getElementById(mapElementId);
        const map = new windowGoogleMap.Map(mapId, {
            zoom: props.PageContact?.location?.zoom || 12,
            center: companyLocation
        });
        new windowGoogleMap.Marker({ position: companyLocation, map });
    };

    const getRouteDescriptions = (): void =>
    {
        document.getElementById(routePanelId).innerHTML = null;

        // @ts-ignore
        const windowGoogleMap = window.google.maps;

        const directionsService = new windowGoogleMap.DirectionsService();
        const directionsRenderer = new windowGoogleMap.DirectionsRenderer();

        const map = new windowGoogleMap.Map(document.getElementById(mapElementId));

        directionsRenderer.setMap(map);
        directionsRenderer.setPanel(document.getElementById(routePanelId));

        const request = {
            origin: inputUserLocation,
            destination: `${companyLocation.lat},${companyLocation.lng}`,
            travelMode: "DRIVING"
        };

        directionsService.route(request, (result, status) =>
        {
            if(status === "OK")
            {
                directionsRenderer.setDirections(result);
            }
            else
            {
                setMap();
            }
        });
    };

    const getUserLocation = (): void =>
    {
        if(navigator.geolocation)
        {
            navigator.geolocation.getCurrentPosition(position =>
            {
                setInputUserLocation(`${position.coords.latitude},${position.coords.longitude}`);
            }, error =>
            {
                console.warn("Standort konnte nicht abgerufen werden: ", error);
            });
        }
        else
        {
            console.warn("Standort konnte nicht abgerufen werden");
        }
    };

    useEffect(() =>
    {
        setCompanyLocation({
            lat: props.PageContact?.location.latitude,
            lng: props.PageContact?.location.longitude,
        });
    }, []);

    useEffect(() =>
    {
        if(!companyLocation)
        {
            return;
        }

        // @ts-ignore
        if(window.google)
        {
            setMap();
            return;
        }

        const s = document.createElement("script");
        s.type = "text/javascript";
        s.src = `https://maps.google.com/maps/api/js?key=${googleApiKey}`;

        const x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);

        s.addEventListener("load", setMap);

        return () => s.removeEventListener("load", setMap);
    }, [companyLocation]);

    const textElements = (props.PageContact?.elements?.filter(e => e?.__typename === "TextElement")) as ITextElement[];
    return (
        <div css={styles.wrapper}>
            <div css={styles.map} id={mapElementId}/>
            <ServiceNumber customStyle={styles.serviceNumber} companyInformationPublic={props.CompanyInformationPublic}/>
            <div>
                <p>{props.UniversalTexts?.StartAddress || "Startadresse"}</p>
                <div css={styles.routeBerechnenWrapper}>
                    <div css={styles.locationInputWrapper}>
                        <input
                            type="text"
                            name={"UserLocation"}
                            placeholder={props.UniversalTexts?.yourLocation || "Ihr Standort"}
                            value={inputUserLocation}
                            onChange={event => setInputUserLocation(event.target.value)}
                        />
                        <svg onClick={getUserLocation} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M24 11h-2.051c-.469-4.725-4.224-8.48-8.949-8.95v-2.05h-2v2.05c-4.725.47-8.48 4.225-8.949 8.95h-2.051v2h2.051c.469 4.725 4.224 8.48 8.949 8.95v2.05h2v-2.05c4.725-.469 8.48-4.225 8.949-8.95h2.051v-2zm-11 8.931v-3.931h-2v3.931c-3.611-.454-6.478-3.32-6.931-6.931h3.931v-2h-3.931c.453-3.611 3.32-6.477 6.931-6.931v3.931h2v-3.931c3.611.454 6.478 3.319 6.931 6.931h-3.931v2h3.931c-.453 3.611-3.32 6.477-6.931 6.931zm1-7.931c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z"/>
                        </svg>
                    </div>
                    <button onClick={getRouteDescriptions} name={"submit"}>
                        {props.UniversalTexts?.CalculateRoute || "Route berechnen"}
                    </button>
                </div>
            </div>
            <div css={styles.directionsPanel} id={routePanelId}/>
            {textElements?.[0] &&
                <RichText textContent={textElements?.[0]?.text} overwriteStyle={styles.catchmentArea} >
                    <h1>{textElements?.[0]?.headline}</h1>
                </RichText>
            }
        </div>
    );
};

export default Map;
