import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const Default = css``;

const Silver = css`
    display: flex;
    justify-content: space-between;

    > {
        :first-child {
            width: 25%;
        }

        :last-child {
            width: 75%;
            padding-right: 0px;
        }
    }
`;

const Gold = css``;

const Platinum = css`
`;

const Diamond = css``;

export const SCommonPageBrandTopicMainContentTwoColumnsWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
