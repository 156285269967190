import React, { FunctionComponent, Fragment, useContext, FC } from "react";
import { Context, getPage, ITextElement, ISidebarText, IAsset, Img, IPhotoGallery } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";
import { css } from "styled-components";

import Articles from "../../common/Articles";
import Micro from "../../common/Micro/Micro";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral, SRichTextGeneral } from "../../common/RichText";
import SubNavRight from "../../common/SubNavRight";
import TextBoxRight from "../../common/TextBoxRight";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import { ContentContainer, ContentWrapper, UniversaElementWrapper, ImageWrapper, TextItem , RichTextWrapper} from "./MainContent.style";

const Text: FC<{ element: ITextElement; firstElement?: boolean }> = ({ element, firstElement }) => 
  (
    <TextItem firstElement={firstElement} imageWithText={element?.text && element?.icon.length > 0 && true}>
      <h1>{element?.headline}</h1>
      {element?.icon.length > 0 && (
        <ImageWrapper>
          {element?.icon?.map((i, index) => 
          {
            if(i?.src) 
            {
              return (
                <>
                  {firstElement ?
                    (
                      <Fragment key={`i${index}`}>
                        <Img
                          quality={1020}
                          src={[i]}
                          contain
                          alt={i.title}
                        >{i.title && element?.text && <p css={styles.figcaption}>{i.title}</p>}</Img>
                      </Fragment>
                    ) : (
                      <div className="image" key={`i${i}`}>
                        <Img
                          quality={1020}
                          src={[i]}
                          contain
                          alt={i.title}
                        >{i.title && element?.text && <p css={styles.figcaption}>{i.title}</p>}</Img>
                      </div>
                    )
                  }
                </>
              );
            }
            return null;
          })}
        </ImageWrapper>
      )}
      {element?.text && <RichTextWrapper onlyText={element?.icon.length === 0 && true}><RichTextGeneral fragment textContent={element?.text} /></RichTextWrapper>}
    </TextItem>
  );

const MainContent: FunctionComponent<any> = (props) => 
{
  const p = useContext(Context);
  const page = getPage(p);
  const sideBarText = page?.extras?.find((e) => e?.__typename === "SidebarText") as ISidebarText;

  // @ts-ignore
  const mixedElements = (page?.elements?.filter(
    (e) => e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery" || e?.__typename === "TextElement" || e?.__typename === "Asset"
  ) as any[])?.reduce((elements, e) => 
  {
    if(e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery" || e?.__typename === "Asset") 
    {
      elements.push(e);
    }
    else if(e?.__typename === "TextElement") 
    {
      elements.push(e);
    }

    return elements;
  }, []);

  const connectedArticles = (page as any)?.connectedArticles;
  const navItem = p?.PrimaryNavigation?.items?.find(
    (i) => !!i?.subitems?.find((s) => s?.slug === p.slug) || i?.link?.[0]?.slug === p.slug
  );
  const TopComponent = props.topComponent;  

  const firstTextElement = mixedElements.find(element => element.__typename === "TextElement");
  return (
    <>
      <ContentContainer>
        <ContentWrapper>
          <Hero topComponent={TopComponent} />
          {p?.PageMicro?.externalLinkAddress && (
            <Micro />
          )}
          {mixedElements &&
            mixedElements.map((e, i) => 
            {
              const imageElement = TopComponent && e?.__typename === "Asset" && e as IAsset;
              const universlElement = (e?.__typename === "ContinuingPageGallery") && e as ITextElement;
              const textElement = (e?.__typename === "TextElement") && e as ITextElement;
              const photoGallery = e?.__typename === "PhotoGallery" && e as IPhotoGallery;
              return (
                <Fragment key={`mixedElements${i}`}>
                  {imageElement &&
                    <div
                      css={
                        css`
                          ${SRichTextGeneral}
                          ${styles.imageInText}
                        ` as any
                      }>
                      <Img
                        alt={imageElement?.title}
                        src={[imageElement]}
                        quality={1080}
                      />
                    </div>
                  }

                  {textElement &&
                    <Text element={e} firstElement={textElement === firstTextElement}/>
                  }

                  {universlElement &&
                    <UniversaElementWrapper>
                      <UniversalComponentsRenderer items={[universlElement]}>
                        {({ item }) => <ProductItem item={item} />}
                      </UniversalComponentsRenderer>
                      <Spacer bronze={20} silver={30}></Spacer>
                    </UniversaElementWrapper>
                  }

                  {photoGallery &&
                    <UniversaElementWrapper>
                      <PhotoGallery item={photoGallery} />
                      <Spacer bronze={20} silver={30}></Spacer>
                    </UniversaElementWrapper>
                  }
                </Fragment>
              );
            })}
          <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
        </ContentWrapper>

        {(navItem || sideBarText) && (
          <div css={styles.sideBarWrapper}>
            <SubNavRight />
            <TextBoxRight text={sideBarText?.text} />
          </div>
        )}
      </ContentContainer>
      {props.children}
    </>
  );
};

export default MainContent;
