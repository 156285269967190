import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const contentContainer: any = css`
      display: flex;
      flex-direction: column;
      max-width: ${({ theme }) => theme.maxWidth}px;
      width: 100%;
      margin: 50px auto 50px auto;
      padding: 0 10px;
      @media (${BREAKPOINTS_DM.gold_1024})
      {
          flex-direction: row;
          padding: 0 15px;
      }

      p, span, h1, h2, h3, h4 {
        min-height: 1em;
      }
      h2 {
          color: #D30032;
          font-size: 20px; 
      }
      > div >div:last-child {
          margin: 50px 0 0 0;
      }
`;

export const contentWrapper: any = css`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const sideBarWrapper: any = css`
    width: 100%;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
      max-width: 224px;
      padding-left: 20px;
    }
`;

export const h1: any = css`
      ${({ theme }) => theme?.textStyles.h1Red}
`;
export const imageInText: any = css`
    > div:first-of-type{
        float: left;
        margin: 0 20px 20px 0 ;
        max-width: 33%;
    }
`;
