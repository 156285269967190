import { Context } from "src/common";

import React, { useContext, FC } from "react";

import {  IGenPageBrandMain } from "../../__generated/root/types";
import {
  BrandAreaComponentRouter,
  IBrandAreaComponentRouterElement,
} from "../component-router/BrandAreaComponentRouter";

export const CommonPageBrandMainContent: FC<any> = () => 
{
  const c = useContext(Context);
  const page = c?.PageBrand as unknown as IGenPageBrandMain;

  if(!c || !page) 
  {
    return null;
  }

  // const brandSubtopicPages = page?.subtopics || [];

  // here always at top (index 0) 50% link to overview page + 
  // at index 1 is the 50% dropdown 
  // at index 2 the first component
  // at index 3 is the header
  // ...rest starts from index 4

  const rawElements = page.elements || [];
  const hideInNavigationPlus= (page as any)?.hideInNavigationPlus || false;
  
  const elementsInserted = [
    ...(hideInNavigationPlus ? []: [
      {
        __typename: "automaticallyBackToOverviewLinkMobile",
        id: "automaticallyBackToOverviewLinkMobile",
      }, 
      {
        __typename: "automaticallyOverviewLinkWithDropdown",
        id: "backToOverviewLink",
      },
    ]),
    ...rawElements.slice(0, 1),
    {
      __typename: "automaticallyIntegratedHeader",
      id: "header",
    },
    ...rawElements.slice(1),
  ];

  return (
    <div className="brand-area brand-area-overview">
      <BrandAreaComponentRouter
        elements={elementsInserted as IBrandAreaComponentRouterElement[]}
      />
    </div>
  );
};
