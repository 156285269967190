import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
    background-color: white;
    * {
        text-align: center;
    }
`;

export const imprintRichtext: any = css`
    h2 {
        margin-top: 14px;
        font-size: 30px;
        color: ${props => props.theme.primary};
    }
    h3 {
        margin-top: 10px;
        font-size: 24px;
        color: ${props => props.theme.primary};
    }
    strong p {
        margin-top: 12px;
        display: block;
    }
    p {
        margin-bottom: 10px;
        display: block;
    }
    a {
        display: inline-block;
        color: ${props => props.theme.primary};
    }
    ul {
        padding-left: 35px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: disc;
        }
    }
    strong ol li {
        font-weight: 700;
    }
    ul,
    ol {
        display: inline-block;
        text-align: left;
        li,
        span {
            text-align: left;
        }
    }
    ol {
        padding-left: 15px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: decimal;
        }
    }
`;

export const additionalInfos: any = css`
    p {
        margin-bottom: 0;
    }
`;
