import React, { FC, useState } from "react";
import { ICourse } from "src/common/childTypes";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import CourseContent from "../../content/CourseContent";
import MainContent from "../../content/MainContent";

const PageCourseT11: FC = () => 
{
  const [course, setCourse] = useState<ICourse>(null);
  return (
    <>
      <Header />
      <Grid>
        <Section>
          {!course && <MainContent />}
          <CourseContent course={course} onSetCourse={setCourse}/>
        </Section>
      </Grid>

      <Banner />
      <Footer />
       
    </>
  );
};

PageCourseT11.displayName = "PageCourseT11";
export { PageCourseT11 };
