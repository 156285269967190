import { CommonPageBrandMainContent } from "src/common/brand-area/PageBrand/CommonPageBrandMainContent";

import * as React from "react";

import { Banner } from "../../common/Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";

const PageBrandT1: React.FunctionComponent = () => (
    <>
        <div>
            <Banner />
        </div>
        <div>
            <MainNavigation />
            <Spacer bronze={80} />
            <CommonPageBrandMainContent />
            <Footer />
        </div>
    </>
);
PageBrandT1.displayName = "PageBrandT1";
export { PageBrandT1 };
