import React from "react";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { Footer } from "../../common/footer/Footer";
import { MainContent } from "../../common/MainContent/MainContent";
import { Banner } from "../../Banner/Banner";


const PageBrandOverviewOldT4 = () => {
    return (
        <div id="PageBrandOverviewOldT400">
            <div id="PageBrandOverviewOldT4T41">
                 
            </div>
            <div id="PageBrandOverviewOldT4T42">
                <MainNavigation />
            </div>
            <div id="PageBrandOverviewOldT4T43">
                <MainContent />
            </div>
            <div id="PageBrandOverviewOldT4T44">
                <Banner />
            </div>
            <div id="footer">
                <Footer />
            </div>
        </div>
    );
};

PageBrandOverviewOldT4.displayName = "PageBrandOverviewOldT4";
export { PageBrandOverviewOldT4 };
