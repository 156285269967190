import React, { FC } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import GuestBookContent from "../../content/GuestBookContent";

const PageGuestbookT25: FC = () => (
  <>
    <Header />
    <GuestBookContent />
    <Footer />
     
  </>
);

PageGuestbookT25.displayName = "PageGuestbookT25";
export { PageGuestbookT25 };
