import React from "react";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";

import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import { css } from "styled-components";
import { SPageLayoutWrapper } from "../PageLanding";

const PageBrandOverviewOldT7 = (props) => (
    <>
        <ResponsiveSidebar />
        <SPageLayoutWrapper>
      <div>
        <MainSidebarLeft />
      </div>
      <div
        css={css`
          width: 100%;
        `}
      >
        <MainAreaWrapper />
      </div>
    </SPageLayoutWrapper>
    </>
);

PageBrandOverviewOldT7.displayName = "PageBrandOverviewOldT7";
export { PageBrandOverviewOldT7 };
