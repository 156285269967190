import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const heroImage: any = css`
    padding: 0;
    img {
        width: 100%;
        height: auto;
    }
`;

export const contentBox: any = css`
    h2 {
        font-size: 24px;
        line-height: 120% !important;
        text-transform: uppercase;
    }
`;

export const richText: any = css`
    margin-bottom: 10px;
    position: relative;
    iframe {
        width: 100%;
    }
    br {
        margin-top: 10px;
    }
    h3,
    h4 {
        color: ${colors.blue};
        font-size: 20px;
        line-height: 120% !important;
    }
    ul {
        margin-left: calc(1em + 2px);
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: disc;
        }
    }
    ol {
        margin-left: calc(2rem + 2px);
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: decimal;
        }
    }
`;

export const iconBox: any = css`
    img {
        width: 100%;
        height: auto !important;
        object-fit: contain !important;
    }
`;

export const articleAssetBox: any = css`
    img {
        width: 100%;
        height: auto;
    }
`;

export const articleContentBox: any = css`
    * {
        color: white !important;
    }
    h2,
    h3,
    h4 {
        font-size: 20px;
    }
    a {
        font-weight: 700;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const hiddenOnMobile: any = css`
    display: block;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        /* display: none; */
    }
`;
