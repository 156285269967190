import * as React from "react";
import { FunctionComponent } from "react";

import * as styles from "./ServiceNumber.style";
import { ICompanyInformationPublic } from "src/common";

interface IProps
{
    companyInformationPublic: ICompanyInformationPublic;
    customStyle?: any;
}

export const ServiceNumber: FunctionComponent<IProps> = props =>
{
    if(!props.companyInformationPublic?.phone)
    {
        return null;
    }

    return (
        <div css={`${styles.wrapper} ${props.customStyle} `}>
            <span css={styles.number}>{(!!props.companyInformationPublic?.serviceNumber) ? 
                props.companyInformationPublic?.serviceNumber 
                : 
                props.companyInformationPublic?.phone }</span>
            <span>unsere Servicenummer</span>
        </div>
    );
};
