import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Col } from "../../../BaseComponents/Grid";

export const FormWrapper: any = styled.div`
  * {
    line-height: 140%;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
      margin-top: 50px;
  }
  h1 {
    ${({ theme }) => theme.textStyles.h1};
  }

  h2 {
    color: ${props => props.theme.palette.red};
    font-weight: 400;
    letter-spacing: .02em;
  }
  strong {
    font-weight: 600;
  }
`;

export const CompanyInfo: any = styled.div`
  * {
    font-weight: 300;
  }

  [data-tel] {
    margin-top: 21px;
  }
`;

export const OfficeInfo: any = styled.div`

`;

export const companyTitle: any = css`
  h1 {
    color: ${props => props.theme.palette.red};
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 30px;
  }
`;

export const directionsDescription: any = css`
  h6 {
      color: ${props => props.theme.palette.red};
      font-size: 19px;
  }
`;

export const contact: any = css`
  margin-top: 20px;
`;

export const SidebarText: any = styled.div`
  background-color: rgba(255,255,255,0.2);
  margin-top: 30px;
  padding: 10px 10px 15px;
  border-radius: 8px;
  max-width: 250px;
  br:first-child {
    margin-top: 0;
  }
  div > p:last-child {
    margin-bottom: 0;
  }
  strong p {
    margin-bottom: 0;
  }
`;

export const ContactForm: any = styled.div`
  margin-top: 70px;
  width: 100%;

  * {
    font-weight: 300;
  }

  h3 {
    margin-bottom: 24px;
    color: ${props => props.theme.palette.green};
    font-weight: 600;
  }
`;

export const inputsWrapper: any = css`
  width: 100%;
  label, input, textarea {
    width: 100%;
    display: block;
  }

  ${Col} {
    margin-bottom: 21px;
  }
`;

export const SubmitWrapper: any = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: 50%;
    display: flex;
    align-items: flex-start;

    > label > a {
      color: ${props => props.theme.palette.green};
    }
  }

  button {
    display: block;
    width: 50%;
    line-height: 3;
    background-color: ${props => props.theme.palette.green};
    color: white;
    border-radius: 0;
  }
`;

export const datenschutzAkzeptierenWrapper: any = css`
  display: flex;
  max-width: 50%;
`;

export const datenschutzAkzeptieren: any = css`
  font-size: 12px;
  margin-left: 5px;
  a {
    color: ${props => props.theme.palette.red};
  }
`;
