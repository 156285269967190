import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../constants/mediaquerys";

export const wrapper: any = css``;

export const h3: any = css`
    font-size: 15px;
    color: ${props => props.theme.palette.yellow};
    font-weight: 500;
    margin-bottom: 10px;
    @media (${BREAKPOINTS_DM.silver_768}) {
        min-width: calc(100% - 200px);
    }
    text-transform: uppercase;
`;

export const img3orMore: any = css`
    height: auto;
    padding: 0;
    margin: 10px 0;
    @media (${BREAKPOINTS_DM.silver_768}) {
        width: 33%;
        height: 114px;
        margin-right: 20px;
    }
`;

export const img: any = css`
    height: auto;
    float: left;
    padding: 0;
    margin: 10px 0;
    @media (${BREAKPOINTS_DM.silver_768}) {
        width: 160px;
        height: 114px;
        margin-right: 20px;
    }
`;

export const footnote: any = css`
    margin: 40px 0 20px 0;
`;
export const Article: any = css`
    max-width: 100%;
    min-height: 140px;
    p.c-empty{
        display: block;
    }
    iframe {
        margin: 20px 0;
    }
    margin: 50px 0 21px 0;
    > div.c-frame {
        width: 100%;
        overflow-x: scroll;
    }
`;
