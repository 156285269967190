import { css } from "styled-components";

import colors from "../../../constants/colors";

export const articleWrapper: any = css`
    * {
      text-align: center;
    }
`;

export const odd: any = css`
    background-color: ${colors.backgroundMint};
    * {
      color: white;
    }
    a, a * {
      color: ${props => props.theme.primary};
      &:hover {
        color: ${props => props.theme.palette.red};
        text-decoration: underline;
      }
    }
`;

export const even: any = css`
    background-color: ${colors.lightGray};
    * {
      color: black;
    }
    a, a *, h2, h3, h4, h5, h6 {
      color: ${props => props.theme.primary};
    }
`;

export const articleAssets: any = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    > div {
      max-width: 400px;
      margin: 18px 0;
      img {
        max-height: 300px !important;
      }
    }
`;

export const articleHeading: any = css`
    text-align: center;
`;

export const articleContent: any = css`
    br {
      margin-top: 12px;
    }
    h3, h4, h5, h6 {
      font-size: 20px;
      color: ${props => props.theme.primary};
    }
    * {
      line-height: 140% !important;
    }
    ol, ul {
      display: inline-block;
      li {
        text-align: left;
      }
    }
`;

export const assetWithSubtitle: any = css`
    background-color: white;
    p {
      padding: 10px;
      color: black;
    }
`;

export const footnote: any = css`
    background-color: ${colors.backgroundMint};
`;

export const footnoteOdd: any = css`
    background-color: ${colors.lightGray};
`;

export const footnoteRichtext: any = css`
  * {
    text-align: center;
  }
  a, a * {
    color: ${props => props.theme.primary};
    &:hover {
      color: ${props => props.theme.palette.red};
      text-decoration: underline;
    }
  }
`;
