import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import BrandBannerVertical from "../../common/BrandBannerVertical";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";

const PageBrandOverviewT9: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent topComponent={BrandBannerVertical} />
      </Section>
    </Grid>
    <Banner />
    <Footer />
     
  </>
);

PageBrandOverviewT9.displayName = "PageBrandOverviewT9";
export { PageBrandOverviewT9 };
