import React, { FunctionComponent, Fragment } from "react";
import { cutCorner  } from "../../../constants/layout";
import { PageDateForm } from "./Form";
import * as styles from "./PageDateInquiry.style";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { SPageLayoutWrapper } from "../PageLanding";
import { css } from "styled-components";

const PageDateInquiryT7: FunctionComponent = () => (
  <Fragment>
    <ResponsiveSidebar />
    <SPageLayoutWrapper>
      <div>
        <MainSidebarLeft />
      </div>
      <div
        css={css`
          width: 100%;
        `}
      >
        <MainAreaWrapper skipFirstText>
          <div
            css={`
              ${cutCorner}${styles.wrapper}
            `}
          >
            <div>
              <PageDateForm />
            </div>
          </div>
        </MainAreaWrapper>
      </div>
    </SPageLayoutWrapper>
  </Fragment>
);
PageDateInquiryT7.displayName = "PageDateInquiryT7";
export { PageDateInquiryT7 };
