import React, { Fragment, FunctionComponent, useContext, useState } from "react";
import { Context, IContext, getIfNewsShouldBeDisplayByDate, INews, IAsset } from "src/common";
import {FullscreenImage} from "src/common/components/FullscreenImage/FullscreenImage";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import UniversalComponentRenderer from "../../../BaseComponents/UniversalCompoentRenderer/UniversalComponentRenderer";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import { smallHeading } from "../../../constants/textStyles";
import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import * as styles from "./pageNews.style";
// import { ImageLightbox } from "src/common/components/ImageLightbox";

const PageNewsT6: FunctionComponent = () => 
{
  const props = useContext(Context);

  const elementsWithoutMainAsset = props.PageNews?.elements?.filter((element) => element?.__typename !== "Asset" && !!element?.__typename);

  // const [imgLightbox, setImgLightbox] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // TODO: Comment in for filtering by date
  /* let articles = pageNews.NewsArticles?.filter(article => (
        getIfNewsShouldBeDisplayByDate({ startDate: article?.startDate, endDate: article?.endDate }))
    );*/

  // TODO: Comment out
  const newsArticles = props.PageNews?.NewsArticles?.filter((article) => article !== null) || [];
  const newsCustomArticles = props.PageNews?.NewsArticlesCustom?.filter((article) => article !== null) || [];

  const articles = [...newsCustomArticles, ...newsArticles];

  const itemsPerPage = 10;

  const articlesToRender: INews[] = [];
  const amountOfPages = Math.ceil(articles?.length / itemsPerPage);

  for(let i = (currentPage - 1) * itemsPerPage; i < (currentPage - 1) * itemsPerPage + itemsPerPage; i++) 
  {
    articlesToRender.push(articles[i]);
  }

  return (
    <Fragment>
      <Header />
      {/* <ImageLightbox closeLightbox={() => setImgLightbox(null)} img={imgLightbox} /> */}
      <UniversalComponentRenderer items={elementsWithoutMainAsset} />
      <div id={"articles"}>
        {articlesToRender?.map((article, i) => 
        {
          if(!article) 
          {
            return null;
          }

          const assets = article?.extras?.filter((extra) => extra?.__typename === "Asset") as IAsset[];

          return (
            <div
              css={`${styles.articleWrapper}${i % 2 === 1 ? styles.odd : styles.even}`}
              key={`${i}article`}
            >
              <div css={centeredContent}>
                <div css={contentWrapper}>
                  <div css={`${null}${styles.articleContent}`}>
                    {article?.headline && (
                      <h3 css={`${smallHeading}${styles.articleHeading}`}>
                        {article?.headline}
                      </h3>
                    )}
                    {article?.text && (
                      <RichText
                        overwriteStyle={i % 2 === 0 ? styles.richtextEven : null}
                        textContent={article?.text}
                      />
                    )}
                  </div>
                  <div css={`${null}${styles.articleAssets}`}>
                    {assets?.map((asset, j) => (
                      <Fragment key={j}>
                        {asset && (
                          <div title={asset.title} css={styles.assetWithSubtitle}>
                            <FullscreenImage
                              quality={480}
                              img={[asset]}
                              subtitle={asset.description}
                            ></FullscreenImage>
                            {asset.description && <p>{asset.description}</p>}
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div css={centeredContent}>
        <nav css={styles.nextAndPrevPage}>
          {currentPage > 1 && (
            <a onClick={() => setCurrentPage(currentPage - 1)} href={"#articles"}>
              Neuere Nachrichten »
            </a>
          )}
          {currentPage < amountOfPages && (
            <a onClick={() => setCurrentPage(currentPage + 1)} href={"#articles"}>
              « Ältere Nachrichten
            </a>
          )}
        </nav>
      </div>
      <Footer showLogos={false} />
    </Fragment>
  );
};
PageNewsT6.displayName = "PageNewsT6";
export { PageNewsT6 };
