import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import ImprintContent from "../../content/ImprintContent";

const PageImprintT8: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <ImprintContent/>
      </Section>
    </Grid>
    <Banner />
    <Footer />
     
  </>
);
PageImprintT8.displayName="PageImprintT8";
export { PageImprintT8 };
