import React, { FC, useState } from "react";
import { ICourse } from "src/common/childTypes";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import CourseContent from "../../content/CourseContent";
import MainContent from "../../content/MainContent";

const PageCourseT26: FC = () => 
{
  const [course, setCourse] = useState<ICourse>(null);

  return (
    <>
      <Header />
      <Grid>
        <Section>
          <MainContent coursePage>
            <CourseContent course={course} onSetCourse={setCourse} />
          </MainContent>
        </Section>
      </Grid>
      <Footer />
       
    </>
  );
};

PageCourseT26.displayName = "PageCourseT26";
export { PageCourseT26 };
