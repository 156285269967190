import React, { FunctionComponent } from "react";
import { cutCorner } from "../../../constants/layout";
import { PageRepairForm } from "./RepairForm";
import * as styles from "./PageRepairInquiry.style";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import { css } from "styled-components";
import { SPageLayoutWrapper } from "../PageLanding";

const PageRepairInquiryT7: FunctionComponent = (props) => {
  return(
  <>
    <ResponsiveSidebar />
    <SPageLayoutWrapper>
      <div>
        <MainSidebarLeft />
      </div>
      <div
        css={css`
          width: 100%;
        `}
      >
        <MainAreaWrapper skipFirstText>
          <div
            css={`
              ${cutCorner}${styles.wrapper}
            `}
          >
            <div>
              <PageRepairForm />
            </div>
          </div>
        </MainAreaWrapper>
      </div>
    </SPageLayoutWrapper>
  </>
)};

PageRepairInquiryT7.displayName = "PageRepairInquiryT7";
export { PageRepairInquiryT7 };
