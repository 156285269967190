import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
    background-color: rgba(0, 0, 0, 0.81);
    position: fixed;
    z-index: 9999999999999;
    top: 0;
    left: 0;
    opacity: 0;
    height: 0;
    width: 0;
    transition: opacity 0.6s ease, height 0s linear 0.6s, width 0s linear 0.6s;
`;

export const wrapperActive: any = css`
    opacity: 1;
    height: 100vh;
    width: 100vw !important;
    transition: opacity 0.6s ease, height 0s linear 0s, width 0s linear 0s;
    img{
        width: 100% !important;
    }
`;

export const fullScreenWrapper: any = css`
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    max-width: 800px;
    width: calc(100% - 30px);
    p {
        height: 30px;
        padding-top: 10px;
        width: 100%;
        color: rgba(255, 255, 255, 0.8);
    }
`;

export const imgFullScreen: any = css`
    width: 100%;
    height: 100%;
    border: 4px solid white;
`;

export const svg: any = css`
    fill: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    &:hover {
        fill: rgba(255, 255, 255, 1);
    }
`;

export const img: any = css`
    :not(:first-child) {
        margin-top: 20px;
        span {
            margin-top: 20px;
        }
    }
    height: auto;
    cursor: pointer;
    span {
        display: inline-block;
        width: 230px;
    }
    > div,
    img {
        width: 230px;
    }
`;
