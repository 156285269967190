import { css } from "styled-components";

import colors from "../../../../constants/colors";

export const guestbookEntry: any = css`
  padding: 0;
  > div {
    border: solid 1px ${props => props.theme.secondary};
    padding: 27px;
  }
`;

export const datePublished: any = css`
    color: #3f3f3e;
`;

export const headlineWrapper: any = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    svg {
      width: 24px;
      margin-left: 2px;
    }
`;

export const title: any = css`
    color: ${props => props.theme.primary};
    font-size: 20px;
`;

export const comment: any = css`
    h4 {
      margin: 12px 0 5px;
    }
    p {
      width: 100%;
      padding: 10px;
      background-color: ${colors.lightMint};
    }
`;
