import React, { FunctionComponent, useContext, Fragment } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { Context, ContactPublicInformation } from "src/common";
import * as styles from "./PageContact.style";

import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { centeredContent } from "../../../constants/layout";
import { contactPageContent } from "./PageContact.style";
import { Footer } from "../../common/footer/Footer";
import { CustomGMap } from "../../common/CustomGMap";
import { css } from "styled-components";
import { handleFormSubmit } from "../../../../../common/utils/formSubmit";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

const PageContactT4: FunctionComponent = () => {
    const props = useContext(Context);

    const universalTexts = props?.UniversalTexts;

    const { register, handleSubmit, errors, reset } = useForm();
    const [success, setSuccess] = React.useState(false)

    const onSubmit = (data): void => {
        //Kontaktformular: d-26922068a30343d98a725c3fdaa663da
        //Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6 
        //Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441 

        const fcValue = (document.querySelector(".frc-captcha-solution") as HTMLInputElement)?.value;

        let title: string | undefined = undefined;
        if (typeof window !== 'undefined') {
            title = document.title
        }
        handleFormSubmit({
            fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
            ...data,
      line: props?.CompanyInformationPrivate?.line,
      subject: `Neue Anfrage über Ihre Homepage ${data?.Name ? `von ${data?.Name} ` : ''}${title ? `- ${title}` : ''}`,
            dealerMail: props?.CompanyInformationPublic?.email,
            templateId: 'd-26922068a30343d98a725c3fdaa663da'
        }).then(r => {
            reset()
            setSuccess(true)
        })
    };

    const name = universalTexts?.name || "Name";
    const eMail = universalTexts?.eMail || "E-Mail";
    const message = universalTexts?.message || "Nachricht";
    return (
        <div id="PageContactT4">
             
            <div id="PageContactMainNavigationT4">
                <MainNavigation />
            </div>
            <div css={centeredContent}>
                <div css={contactPageContent}>
                    <CustomGMap />
                    <div css={styles.wrapper}>
                        <div css={styles.wrapperPublicInfo}>
                            <ContactPublicInformation />
                        </div>
                        <div css={styles.contactForm}>
                            <h3>KONTAKTFORMULAR</h3>
                            {success ?
                    <form>
                        <h1>DANKE!</h1>
                        Wir werden uns sobald wie möglich bei Ihnen melden.
                    </form>
                    : <form onSubmit={handleSubmit(onSubmit)}>
                                <div css={styles.inputsWrapper}>
                                    <label htmlFor={"Name"}>{name}</label>
                                    <input
                                        required={true}
                                        type="text"
                                        placeholder={""}
                                        name="Name"
                                        ref={register({
                                            options: {
                                                required: true,
                                                unique: true,
                                            },
                                            min: 3,
                                        })}
                                    />
                                    <label htmlFor={"E-Mail"}>{eMail}</label>
                                    <input
                                        required={true}
                                        type="email"
                                        placeholder={""}
                                        name="E-Mail"
                                        ref={register({
                                            options: {
                                                required: true,
                                                unique: true,
                                            },
                                            min: 4,
                                        })}
                                    />
                                    <label htmlFor={"Nachricht"}>{message}</label>
                                    <textarea
                                        rows={8}
                                        required={true}
                                        name="Nachricht"
                                        placeholder={""}
                                        ref={register({ required: true })}
                                    />
                                </div>
                                <FriendlyCaptcha />
                                <div css={styles.submitWrapper}>
                                    <div>
                                        <input
                                            css={css`
                                                position: absolute;
                                                opacity: 0;
                                                pointer-events: none;
                                            `}
                                            type="privacy"
                                            placeholder="Ich akzeptiere die Datenschutzerklärung"
                                            name="privacy"
                                        />
                                        <input
                                            required={true}
                                            type="checkbox"
                                            placeholder="Ich akzeptiere die Datenschutzerklärung" //TODO: Place const final Strings in external file? Language support?
                                            name="checkbox"
                                            ref={register({ required: true })}
                                        />
                                        <label htmlFor={"checkbox"} css={styles.datenschutzAkzeptieren}>
                                            Ich akzeptiere die{" "}
                                            <a target="_blank" href={`/${props?.PageImprint?.slug}`}> Datenschutzerklärung</a>
                                        </label>
                                    </div>
                                    <button type="submit">{universalTexts?.Submit || "Absenden"}</button>
                                </div>
                            </form> }
                        </div>
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};
PageContactT4.displayName = "PageContactT4";
export { PageContactT4 };
