import React, { FC } from "react";
import { Row, Col } from "src/templates/t9/BaseComponents/Grid";

import Contact from "../../common/Contact";
import Map from "../../common/Map";
import { BorderBottom } from "../MainContent/MainContent.style";
import { ContentContainer, MapWrapper, ContactWrapper } from "./ContactContent.style";

const ContactContent: FC = () => (
  <ContentContainer>
    <Row>
      <MapWrapper>
        <Map />
      </MapWrapper>
      <ContactWrapper>
        <Contact />
      </ContactWrapper>
    </Row>
    <BorderBottom/>
  </ContentContainer>
);

export default ContactContent;
