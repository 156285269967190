import styled, { css } from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const wrapper: any = css`
  margin-bottom: 70px;
  * {
    line-height: 140%;
  }
  @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-left: 16px;
        width: 50%;
    }
`;

export const sidebarText: any = css`
    background-color: rgba(255,255,255,0.2);
    margin-top: 30px;
    padding: 10px 10px 15px;
    border-radius: 8px;
    max-width: 250px;
    br:first-child {
      margin-top: 0;
    }
    p {
      margin-bottom: 20px;
    }
    div > p:last-child {
      margin-bottom: 0;
    }
    strong p {
      margin-bottom: 0;
    }
`;

export const contactForm: any = css`
    margin-top: 70px;
    h3 {
        margin-bottom: 24px;
        font-weight: 600;
    }

    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 20px;
            margin-top: 0;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }

    @media (${BREAKPOINTS_DM.gold_1024}) {
        margin-top: 0;
    }
`;

export const inputsWrapper: any = css`
    *:not(label) {
      padding: 3px 8px;
      width: 100%;
      margin-bottom: 20px;
    }
`;

export const SubmitWrapper: any = styled.div<{color: string}>`
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: flex-start;
    }
    button {
      color: ${({ color }) => color ? color : "#222"};
      max-width: 40%;
      width: 200px;
      padding: 3px 10px;
      border-radius: 3px;
      border: solid 1px ${({ color }) => color ? color : "#222"};
    }
`;

export const datenschutzAkzeptieren: any = css`
    font-size: 12px;
    margin-left: 5px;
    a {
      font-size: inherit;
      color: ${colors.gray};
    }
`;
