import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";
import { SRichTextGeneral } from "../../common/RichText";

export const ContentContainer: any = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: 85px auto 0px auto;
    padding: 0 10px;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        flex-direction: row;
        padding: 0 15px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      span {
        color:  ${({ theme }) => theme?.palette?.green} !important;
      }
    }

    h1,
    h2,
    h3 {
        &,
        span {
            font-weight: 400;
        }
    }
    h1 {
        &,
        span {
            font-weight: 700;
            font-size: 1.6rem;
        }
    }

    [data-gallery] {
      [data-caption] {
        color: transparent;
      }
    }
`;

export const ContentWrapper: any = styled.div`
    display: flex;
    flex-direction: column;

    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: 75%;
    }
`;

export const sideBarWrapper: any = css`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 224px;
    padding-left: 20px;
  }
`;

export const imageInText: any = css`
  > div:first-of-type {
    height: auto;
    > img {
        height: auto;
    }
    float: left;
    margin: 0 20px 20px 0;
  }
`;

export const figcaption: any = css`
  text-align: center;
  background-color: rgb(208, 210, 211);
  padding: 10px 10px;
  margin: 0 0 10px;
`;

export const UniversaElementWrapper: any = styled.div`
  // margin-top: 75px;
  .caption-description {
    display: none;
  }
  .slick-prev,
  .slick-next {
    z-index: 9;
    background-color: rgba(255,255,255,.5) !important;
    width: 30px !important;
    height: 30px !important;
    :before {
      opacity: 1;
      display: inline-block;
      content: "" !important;
      background-image: url(/images/sprite-sa3ca3ca63e.png);
      background-repeat: no-repeat;
      background-position: 0 -172px;
      height: 18px;
      width: 12px;
      color: transparent !important;
    }

    :hover {
      background-color: rgba(255,255,255,.5);
    }
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
    :before {
      transform: rotate(180deg);
    }
  }

  .slick-slide{
    padding-top: 6px;
    padding-right: 6px;
    &:not(.slick-current) {
      opacity: .5;
    }
  }

  [data-gallery-slider] {
    .slick-slide {
      div > div > div {
        img { 
          height: 378px;
        }
      }
    }
  }
  .slick-track {
    div > div > div {
      img { 
        height: 70px;
      }
    }
  }

  [data-gallery-comparison] {
    [data-image-left],
    [data-image-right] {
      :after {
        color: ${({ theme }) => theme?.palette?.green};
      }
    }

    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: 690px;
      [data-image-comparison-item] {
        width: 690px;
        [data-image-left],
        [data-image-right] {
          :before {
            width: 690px;
          }
        }
      }
    }
  }
`;

export const TextArrayContainer: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left:-10.5px;
`;

export const TextItem: any = styled.div<{ firstElement: boolean; imageWithText: boolean}>`
  ${({ imageWithText }) => imageWithText && SRichTextGeneral};
  width: 100%;
  padding: 0 21px 0 7px;
  margin: 0 0 ${({ firstElement }) => firstElement ? "75px" : "42px"};

  .image {
    margin: ${({ imageWithText }) => imageWithText ? "0" : "0 20px 20px 0"};
    width: ${({ imageWithText }) => imageWithText ? "100%" : "30%"};
    ${({ imageWithText }) => imageWithText && `
      div {
        img { 
          height: auto;
        }
      }
    `};
  }
  .image:last-child {
    margin-right: ${({ imageWithText }) => imageWithText ? "0" : "auto"};
  }
 
  h1 {
    font-size: ${({ firstElement }) => firstElement ? "21px" : "15px"};
    * {
      color:  ${({ theme }) => theme?.palette?.green} !important;
      font-weight: 300;
    }
  }

  a {
    display: inline-block;
    margin-bottom: 10.5px;
  }
`;

export const ImageWrapper: any = styled.div`
  height: auto;
  float: left;
  margin: 0 20px 20px 0;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    flex-wrap: wrap;
    
  }
`;

export const RichTextWrapper: any = styled.div<{onlyText: boolean}>`
  ${SRichTextGeneral}
  ${({ onlyText }) => onlyText && `
    p {
      margin: 0 0 21px;
    }
  `}

  li {
    list-style-position: outside;
  }
`;
