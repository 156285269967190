import React, { FunctionComponent } from "react";

import { CommonPageBrandMicroMainContent } from "../../../../../common/brand-area/PageBrandMicro/CommonPageBrandMicroMainContent";

const PageMicroT0: FunctionComponent = () => (
    <>
        <CommonPageBrandMicroMainContent />
    </>
);

PageMicroT0.displayName = "PageMicroT0";
export { PageMicroT0 };
