import React, { FunctionComponent, useContext, Fragment } from "react";
import { cutCorner } from "../../../constants/layout";
import * as styles from "./PageSearch.style";
import { IContext, Context } from "src/common";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { useRouter } from "next/router";
import { GEDK_LINES } from "../../../../../common/utils/lineShorts";
import { useDealerSearchResult } from "../../../../../common/hooks/useDealerSearch";
import { css } from "styled-components";
import { SPageLayoutWrapper } from "../PageLanding";
import { FriendlyCaptcha } from "../../../../../common/components/friendly-capture";

const PageSearchT7: FunctionComponent = () => {
  const props = useContext<IContext>(Context);
  const router = useRouter();
  const { dealers, friendlyCaptchaToken, isLoading } = useDealerSearchResult();

  return (
    <Fragment>
      <ResponsiveSidebar />
      <SPageLayoutWrapper>
        <div>
          <MainSidebarLeft />
        </div>
        <div
          css={css`
            width: 100%;
          `}
        >
          <MainAreaWrapper>
            <div>
              <div
                css={`
                  background: #54aa9e;
                  color: #fff;
                  ${styles.connectedArticle}${styles.connectedArticleWithBorder}${cutCorner}
                `}
              >
                {isLoading ? (
                  <div> Einen moment bitte ... </div>
                ) : (
                  <>
                    {dealers && dealers.length == 0 ? (
                      <div>
                        In Ihrem gewünschten Gebiet befindet sich kein passender
                        Fachhändler. Kompetente Partner finden Sie zusätzlich{" "}
                        <a
                          css={css`
                            color: #d40046;
                            font-weight: bold;
                            color: ${props.Settings.themeColorPrimary};
                          `}
                          href="https://www.hausgeraete-ganzmacher.de"
                          target="_blank"
                        >
                          hier
                        </a>
                        .
                      </div>
                    ) : (
                      <>
                        {friendlyCaptchaToken ? (
                          <div>
                            Suchergebnisse für Händler in:{" "}
                            <strong>{router.query?.search ?? ""}</strong>
                          </div>
                        ) : (
                          <div>
                            Bitte bestätigen Sie, dass Sie kein Roboter sind.
                            Danach werden die Suchergebnisse angezeigt.
                            <FriendlyCaptcha />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              {dealers?.map((o: any, i: number) => {
                return (
                  <a
                    target="_blank"
                    href={`${o.domain}`}
                    key={`${i}_` + o.caisy_org_id || o.phnummer}
                  >
                    <div
                      css={`
                        ${styles.connectedArticle}${styles.connectedArticleWithBorder}${cutCorner}
                      `}
                    >
                      <div>
                        <div
                          css={`
                            ${styles.listing}
                          `}
                        >
                          <span>
                            <strong>
                              {GEDK_LINES.find(
                                (l) =>
                                  l.short ==
                                  props.CompanyInformationPrivate.line
                              ).name ?? ""}
                            </strong>
                          </span>
                          <div>
                            <h3>{o.company_name}</h3>
                          </div>
                          <div>
                            <p>{o.street + " · " + o.zip + " " + o.city}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              }) ?? null}
            </div>
          </MainAreaWrapper>
        </div>
      </SPageLayoutWrapper>
    </Fragment>
  );
};
PageSearchT7.displayName = "PageSearchT7";
export { PageSearchT7 };
