import React, { FunctionComponent } from "react";
import Header from "../../common/header/Header";
import { FooterT3 } from "../../common/footer/Footer";
import { Banner } from "../../common/Banner/Banner";

import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";
import { CommonPageBrandOverviewMainContent } from "src/common/brand-area/PageBrandOverview/CommonPageBrandOverviewMainContent";
import Spacer from "../../../../../common/BaseComponents/Spacer/Spacer";

const PageBrandOverviewT3: FunctionComponent = () => {
    return (
        <>
            <Banner />
            <PlanningDateModal />
            <Header />
            <Spacer bronze={80} silver={60} platinum={38} />
            <CommonPageBrandOverviewMainContent />
            <FooterT3 />
        </>
    );
};

PageBrandOverviewT3.displayName = "PageBrandOverviewT3";
export { PageBrandOverviewT3 };
