import React, { Fragment, FunctionComponent, useContext } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { IContext, Context } from "src/common";
import { css } from "styled-components";
import { handleFormSubmit } from "../../../../../../common/utils/formSubmit";
import * as styles from "./RepairForm.style";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

export const PageRepairForm: FunctionComponent = () => {
    const slugProps = useContext<IContext>(Context);

    const { register, handleSubmit, errors, reset } = useForm();
    const [success, setSuccess] = React.useState(false)
    const footer = slugProps?.Footer;
    const universalTexts = slugProps?.UniversalTexts;


    const onSubmit = (data): void => {
        //Kontaktformular: d-26922068a30343d98a725c3fdaa663da
        //Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6 
        //Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441 
        //Produktanfrage: d-ee2129a87d2d4e70bbcf21f1bff6491e

        const fcValue = (document.querySelector('.frc-captcha-solution') as HTMLInputElement)?.value;


        let title: string | undefined = undefined;
        if (typeof window !== 'undefined') {
            title = document.title
        }

        handleFormSubmit({
            fcValue: !!fcValue ? fcValue : sessionStorage.getItem('fcaptcha'),
            ...data,
      line: slugProps?.CompanyInformationPrivate?.line,
      subject: `Reparaturanfrage ${data?.Name ? `von ${data?.Name} ` : ''}${title ? `- ${title}` : ''}`,
            dealerMail: slugProps?.CompanyInformationPublic?.email,
            templateId: 'd-82dc22fc5dee48db9e177c0140b37441',
            // cc: [{ email: "serviceseite@gedk.de" }]
        }).then(r => {
            reset()
            setSuccess(true)
        })
    };
    // Liebe Kollegen,
    // ein Interessent hat eine Reparaturanfrage gestellt.

    // Vorname: {{ vorname }}
    // Nachname: {{ nachname }}
    // E-Mail: {{ email }}
    // Adresse(Straße + Nummer): {{ adresse }}
    // Ort: {{ ort }}
    // PLZ: {{ plz }}
    // Land: {{ land }}
    // Telefonnummer: {{ tel }}

    // Terminwunsch:
    // Datum: {{ date }}
    // Uhrzeit(von): {{ uhrzeitVon }}
    // Uhrzeit(bis): {{ uhrzeitBis }}

    // Hersteller: {{ hersteller }}
    // Gerätetyp: {{ geraetetyp }}
    // Gerätetypnummer: {{ geraetetypnummer }}
    // Problembeschreibung: {{ problem }}
    // Weitere Hinweise: {{ weitereHinweise }}

    // Bitte nehmen Sie rasch Kontakt zu Ihrem Interessenten auf.
    // Viel Erfolg und Grüße aus Berlin.

    const dateOptions = [];
    const today = new Date();

    for (let i = 1; i < 9; i++) {
        const newDate = new Date();
        newDate.setDate(today.getDate() + i);
        dateOptions.push(
            <option key={i} value={newDate.toLocaleDateString()}>
                {newDate.toLocaleDateString()}
            </option>
        );
    }

    const timeOptions: React.ReactElement[] = [];
    const timeOptions2: React.ReactElement[] = [];

    for (let j = 8; j < 22; j++) {
        let value;
        let value2;

        if (j < 10) {
            value = `0${j}:00`;
            value2 = `0${j}:30`;
        }
        else {
            value = `${j}:00`;
            value2 = `${j}:30`;
        }

        timeOptions.push(
            <Fragment key={j}>
                <option value={value}>{value}</option>
                <option value={value2}>{value2}</option>
            </Fragment>
        );

        timeOptions2.push(
            <Fragment key={j + 999}>
                {j !== 8 && <option value={value}>{value}</option>}
                <option value={value2}>{value2}</option>
            </Fragment>
        );
    }

    const brandOptions = [];

    for (const brand of slugProps?.AllBrand) {
        brandOptions.push(
            <option key={brand?.id} value={brand?.name}>
                {brand?.name}
            </option>
        );
    }

    const deviceTypeOptions = [];

    if (slugProps?.AllDeviceType?.length > 0) {
        let k = 0;

        for (const deviceType of slugProps.AllDeviceType) {
            deviceTypeOptions.push(
                <option key={k} value={deviceType.name}>
                    {deviceType.name}
                </option>
            );
            k++;
        }
    }

    const vorname = "*Vorname";
    const nachname = "*Nachname";
    const email = "*E-Mail";
    const adresse = "*Adresse (Straße + Nummer)";
    const ort = "*Ort";
    const plz = "*PLZ";
    const land = "Land";
    const tel = "*Telefonnummer";
    const date = "Datum";
    const uhrzeitVon = "Uhrzeit (von)";
    const uhrzeitBis = "Uhrzeit (bis)";
    const hersteller = "Hersteller";
    const geraetetyp = "Gerätetyp";
    const geraetetypnummer = "Gerätetypnummer";
    const problem = "Problembeschreibung";
    const weitereHinweise = "Weitere Hinweise";
    return (
        <div css={styles.fromWrapper}>
            {success ?
                <form>
                    <h1>DANKE!</h1>
                        Wir werden uns sobald wie möglich bei Ihnen melden.
                    </form>
                : <form onSubmit={handleSubmit(onSubmit)}>
                    <div css={styles.inputsWrapper}>

                        <div css={styles.twoColumnsRow}>
                            <div>
                                <label htmlFor={"vorname"}>{vorname}</label>
                                <input required={true} type="text" name="vorname" id={"vorname"} ref={register({ required: true })} />
                            </div>
                            <div>
                                <label htmlFor={"nachname"}>{nachname}</label>
                                <input required={true} type="text" name="nachname" id={"nachname"} ref={register({ required: true })} />
                            </div>
                        </div>

                        <div css={styles.twoColumnsRow}>
                            <div>
                                <label htmlFor={"email"}>{email}</label>
                                <input required={true} type="email" id={"email"} name="email" ref={register({ required: true, min: 4 })} />
                            </div>
                            <div>
                                <label htmlFor={"adresse"}>{adresse}</label>
                                <input required={true} type="text" name="adresse" id={"adresse"} ref={register({ required: true })} />
                            </div>
                        </div>

                        <div css={styles.twoColumnsRow}>
                            <div>
                                <label htmlFor={"ort"}>{ort}</label>
                                <input required={true} type="text" name="ort" id={"ort"} ref={register({ required: true })} />
                            </div>
                            <div>
                                <label htmlFor={"plz"}>{plz}</label>
                                <input required={true} type="text" name="plz" id={"plz"} ref={register({ required: true })} />
                            </div>
                        </div>

                        <div css={styles.twoColumnsRow}>
                            <div>
                                <label htmlFor={"land"}>{land}</label>
                                <select name="land" id="land" ref={register()}>
                                    <option value="deutschland">Deutschland</option>
                                    <option value="oesterreich">Österreich</option>
                                    <option value="schweiz">Schweiz</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"tel"}>{tel}</label>
                                <input required={true} type="text" name="tel" id={"tel"} ref={register({ required: true })} />
                            </div>
                        </div>

                        <div css={`${styles.twoColumnsRow}${styles.threeColumns}`}>
                            <div>
                                <label htmlFor={"date"}>{date}</label>
                                <select name="date" id="date" ref={register()}>
                                    {dateOptions}
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"uhrzeitVon"}>{uhrzeitVon}</label>
                                <select name="uhrzeitVon" id="uhrzeitVon" ref={register()}>
                                    {timeOptions}
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"uhrzeitBis"}>{uhrzeitBis}</label>
                                <select name="uhrzeitBis" id="uhrzeitBis" ref={register()}>
                                    {timeOptions2}
                                </select>
                            </div>
                        </div>

                        <div css={styles.twoColumnsRow}>
                            <div>
                                <label htmlFor={"hersteller"}>{hersteller}</label>
                                <select name="hersteller" id="hersteller" ref={register()}>
                                    {brandOptions}
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"geraetetyp"}>{geraetetyp}</label>
                                <select name="geraetetyp" id="geraetetyp" ref={register()}>
                                    {deviceTypeOptions}
                                </select>
                            </div>
                        </div>

                        <div css={styles.twoColumnsRow}>
                            <div>
                                <label htmlFor={"geraetetypnummer"}>{geraetetypnummer}</label>
                                <input type="text" name="geraetetypnummer" id={"geraetetypnummer"} ref={register()} />
                            </div>
                        </div>

                        <label htmlFor={"problem"}>{problem}</label>
                        <textarea rows={8} name="problem" id={"problem"} ref={register()} />

                        <label htmlFor={"weitereHinweise"}>{weitereHinweise}</label>
                        <textarea rows={8} name="weitereHinweise" id={"weitereHinweise"} ref={register()} />
                    </div>
                    <div css={styles.fcWrapper}>
                        <FriendlyCaptcha />
                    </div>
                    <div css={styles.submitWrapper}>
                        <div>
                            <input
                                css={css`
                                    position: absolute;
                                    opacity: 0;
                                    pointer-events: none;
                                `}
                                type="privacy"
                                placeholder="Ich akzeptiere die Datenschutzerklärung"
                                name="privacy"
                            />
                            <input
                                required={true}
                                type="checkbox"
                                placeholder="Ich akzeptiere die Datenschutzerklärung"
                                name="checkbox"
                                id={"checkbox"}
                                ref={register({ required: true })}
                            />
                            <label htmlFor={"checkbox"} css={styles.datenschutzAkzeptieren}>
                                Ich akzeptiere die <a target="_blank" href={`/${slugProps?.PageImprint?.slug}`}> Datenschutzerklärung</a>
                            </label>
                        </div>
                        <button type="submit">{universalTexts?.Submit || "Absenden"}</button>
                    </div>
                </form>}
        </div>
    );
};
