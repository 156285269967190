import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 11px;
  background-color: #92c2d6;
  position: fixed;
  bottom: 0;
  z-index: 70;
  height: 30px;
  box-sizing: content-box;
  box-shadow: 2px 2px 5px rgba(50,50,50,.5);

  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: fit-content;
    bottom: initial;
    right: 0;
    top: 112px;
    height: initial;
  }

  a {
    color: ${props => props.theme.palette.white};
  }

  @media (${BREAKPOINTS_DM.gold_1024}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    min-height: 120px;
  }
`;
