import styled from "styled-components";

export const Wrapper: any = styled.div`
  position: relative;
  right: -15px;
  width: 100%;
  box-sizing: border-box;
  padding: 10.5px;
  margin-top: 85px;
  background-color: #D0D2D3;

  h6 {
    margin-top: 21px;
    font-weight: 700;
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

