import Link from "next/link";
import { useRouter } from "next/router";
import React, { createRef, FC, useContext, useState } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { Context, DateFormatted } from "src/common";
import { ICourse } from "src/common/childTypes";
import { courseSubmit } from "../../../../../../common/utils/courseSubmit";
import dynamic from "next/dynamic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const WidgetInstance = dynamic(
  () => import("friendly-challenge/widget").then((mod) => mod.WidgetInstance),
  { ssr: false }
);

import {
  CourseFormWrapper,
  InputWrapper,
  CourseDateWrapper,
} from "./CourseForm.style";
import Spacer from "../../../../BaseComponents/Spacer/Spacer";
import dayjs from "dayjs";
import { getContactCompanyNameShort } from "../../../../../../common/components/ImprintStart";

export const CourseForm: FC<{ course: ICourse }> = ({ course }) => {
  const pageProps = useContext(Context);
  const primaryColor: any = pageProps?.Settings?.themeColorPrimary;
  const router = useRouter();
  const [numberOfPersons, setNumberOfPersons] = useState(1);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<any>(undefined);
  const { register, handleSubmit, errors, reset } = useForm();
  const captchaWidgetRef = createRef<any>();

  if (!course) return null;

  return (
    <CourseFormWrapper primaryColor={primaryColor} key={course.id}>
      <Link
        href={`${router.asPath}`.split("?")[0]}
        shallow={true}
        passHref={true}
      >
        <a className="back-btn">Zurück zur Übersicht </a>
      </Link>
      <WidgetInstance
        data-lang="de"
        data-start="auto"
        data-sitekey="FCMTG4TENAJLK1P0"
      />
      <h3>{course.nameInNavigation}</h3>
      <CourseDateWrapper>
        <div>
          <b>
            <DateFormatted date={course.startDate} />
          </b>
          {((course.endDate &&
            dayjs(course.startDate).date() !== dayjs(course.endDate).date()) ||
            dayjs(course.startDate).month() !== dayjs(course.endDate).month() ||
            dayjs(course.startDate).year() !==
              dayjs(course.endDate).year()) && (
            <b>
              - <DateFormatted date={course.endDate} />
            </b>
          )}
        </div>
        <span>
          Beginn: <DateFormatted date={course.startDate} format="HH:mm" /> Uhr
        </span>
        &nbsp;|&nbsp;
        <span>
          Ende: <DateFormatted date={course.endDate} format="HH:mm" /> Uhr
        </span>
        &nbsp;|&nbsp;
        <span>Preis p.P.: {course.pricePerParticipantInEuro} &euro;</span>
        &nbsp;|&nbsp;
        <span>Freie Plätze: {course.maxParticipants}</span>&nbsp;|&nbsp;
        <span>
          Anmeldeschluss:{" "}
          <DateFormatted
            date={course.registrationDeadline}
            format="DD.MM.YYYY HH:mm"
          />{" "}
          Uhr
        </span>
      </CourseDateWrapper>

      {success ? (
        <>
          <h2>
            Ihre Anmeldung war erfolgreich!<br></br>
            Wir freuen uns auf ein schönes Erlebnis mit Ihnen.
          </h2>
        </>
      ) : (
        <>
          <h2>
            Hiermit melde ich mich verbindlich zur Veranstaltung &quot;
            <span>{course.nameInNavigation}</span>&quot; an:
          </h2>
          <form
            className="withfc" 
            onSubmit={handleSubmit((e) => {
              const fcValue = (
                document.querySelector(".frc-captcha-solution") as any
              )?.value;
              courseSubmit(
                {
                  ...e,
                  fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
                  caisyProjectId: pageProps?.dealer?.caisyProjectId,
                },
                course
              ).then((r) => {
                if (r && r.errorMsg) {
                  setError(r.errorMsg);
                } else if (r.success) {
                  setSuccess(true);
                  setError(undefined);
                } else {
                  setError(undefined);
                }
              });
            })}
          >
            <InputWrapper>
              <label htmlFor="firstname">Vorname</label>
              <input
                id="firstname"
                name="firstname"
                minLength={2}
                type="text"
                placeholder="Vorname"
                required
                ref={register({ required: true })}
              />
            </InputWrapper>

            <InputWrapper>
              <label htmlFor="lastname">Nachname</label>
              <input
                id="lastname"
                name="lastname"
                minLength={2}
                type="text"
                placeholder="Nachname"
                required
                ref={register({ required: true })}
              />
            </InputWrapper>

            <InputWrapper>
              <label htmlFor="street">Straße</label>
              <input
                id="street"
                name="street"
                minLength={2}
                type="text"
                placeholder="Straße"
                required
                ref={register({ required: true })}
              />
            </InputWrapper>

            <InputWrapper>
              <div>
                <InputWrapper md={4}>
                  <label htmlFor="zipcode">PLZ / Ort</label>
                  <input
                    id="zipcode"
                    name="zipcode"
                    minLength={2}
                    type="text"
                    placeholder="PLZ"
                    required
                    ref={register({ required: true })}
                  />
                </InputWrapper>

                <InputWrapper md={8}>
                  <label htmlFor="city">&nbsp;</label>
                  <input
                    id="city"
                    name="city"
                    minLength={2}
                    type="text"
                    placeholder="Ort"
                    required
                    ref={register({ required: true })}
                  />
                </InputWrapper>
              </div>
            </InputWrapper>

            <InputWrapper>
              <div>
                <InputWrapper md={6}>
                  <label htmlFor="telephone">Telefon</label>
                  <input
                    id="telephone"
                    type="text"
                    name="telephone"
                    placeholder="Telefon"
                    ref={register({ required: false })}
                  />
                </InputWrapper>

                <InputWrapper md={6}>
                  <label htmlFor="email">E-Mail</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    ref={register({ required: false })}
                  />
                </InputWrapper>
              </div>
            </InputWrapper>

            <InputWrapper>
              <span>Ich möchte insgesamt&nbsp;</span>
              <select
                id="num_participants"
                name="num_participants"
                size={1}
                defaultValue={1}
                ref={register({ required: false })}
                onChange={(e) => {
                  if (e.target.value) {
                    setNumberOfPersons(
                      typeof e.target.value == "string"
                        ? parseInt(e.target.value)
                        : e.target.value
                    );
                  }
                }}
              >
                {Array.apply(
                  null,
                  Array(
                    course?.maxParticipants &&
                      !isNaN(course?.maxParticipants) &&
                      course?.maxParticipants < 30
                      ? course?.maxParticipants
                      : 30
                  )
                ).map((_, i) => {
                  if (i === 0) {
                    return (
                      <option key={i} value={i + 1}>
                        {i + 1 + " Person"}
                      </option>
                    );
                  }
                  return (
                    <option key={i} value={i + 1}>
                      {i + 1 + " Personen"}
                    </option>
                  );
                })}
              </select>
              &nbsp;<span>anmelden.</span>
            </InputWrapper>

            <InputWrapper>
              <span>
                Betrag:{" "}
                {course.pricePerParticipantInEuro * (numberOfPersons || 1)}{" "}
                &euro;
              </span>
            </InputWrapper>

            <InputWrapper>
              <input
                type="checkbox"
                id="want_contacting"
                name="want_contacting"
                ref={register({ required: false })}
              />
              &nbsp;
              <span>Über weitere Angebote möchte ich in Zukunft&nbsp;</span>
              <select
                name="contacting"
                id="contacting"
                ref={register({ required: false })}
              >
                <option value="email">per Email</option>
                <option value="telephone">per Telefon</option>
                <option value="telefax">per Fax</option>
              </select>
              &nbsp;<span>informiert werden.</span>
            </InputWrapper>

            <InputWrapper>
              <input
                type="checkbox"
                ref={register({ required: true })}
                value="Akzeptiert"
                name="Betrag"
                required
              />
              &nbsp;
              <span>
                Der Betrag wird nach Rechnungsstellung innerhalb von 8 Tagen
                überwiesen.
              </span>
            </InputWrapper>

            <InputWrapper>
              <input
                ref={register({ required: true })}
                type="checkbox"
                id="privacy_acknowledgement"
                value="true"
                name="privacy_acknowledgement"
                required
              />
              &nbsp;<span>Die</span>{" "}
              <a target="_blank" href={`/${pageProps.PageImprint?.slug}`}>
                AGB und die Datenschutzerklärung
              </a>
              &nbsp;{" "}
              <span>
                von {getContactCompanyNameShort()} habe ich
                gelesen und erkenne diese für verbindlich an. Preise verstehen
                sich inkl. der gesetzlichen MwSt.
              </span>
            </InputWrapper>
            <div
              ref={captchaWidgetRef}
              className="frc-captcha"
              data-lang="de"
              data-start="auto"
              data-sitekey="FCMTG4TENAJLK1P0"
            ></div>
            <Spacer bronze={20}></Spacer>
            {error && (
              <>
                <Spacer bronze={20}></Spacer>
                <h3 className="error">Fehler: {error}</h3>
                <Spacer bronze={20}></Spacer>
              </>
            )}
            <button type="submit">
              Absenden{" "}
              <div className="icon">
                <FontAwesomeIcon icon={faChevronRight as any} />{" "}
              </div>{" "}
            </button>
          </form>
        </>
      )}
    </CourseFormWrapper>
  );
};
