import React, { FunctionComponent } from "react";

import { Banner } from "../../common/Banner/Banner";
import { FooterT3 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import MainContent from "../../common/MainContent";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";

const PageMicroT3: FunctionComponent = () => 
  (
    <>
      <Banner />
      <PlanningDateModal />
      <Header />
      <MainContent micro />
      <FooterT3 />
    </>
  );

PageMicroT3.displayName = "PageMicroT3";
export { PageMicroT3 };
