import React, { useContext, FunctionComponent, Fragment } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import {
  Context,
  ISidebarText,
  getPage,
  ITextElement,
  IAsset,
} from "src/common";
import ImageWithAspectRatio from "src/common/BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import { css } from "styled-components";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import { handleFormSubmit } from "../../../../../common/utils/formSubmit";
import { flexItem100, flexItem50 } from "../../../constants/layout";
import RichText from "../RichText/RichText";
import * as styles from "./Contact.style";
import { getOpeningHoursContent } from "../../../../../common/utils/getOpeningHoursContent";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

const Contact: FunctionComponent = () => {
  const props = useContext(Context);
  const page = getPage(props);
  const { register, handleSubmit, errors, reset } = useForm();
  const [success, setSuccess] = React.useState(false);
  const openingHoursContent = getOpeningHoursContent();

  const onSubmit = (data): void => {
    // Kontaktformular: d-26922068a30343d98a725c3fdaa663da
    // Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
    // Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441

    const fcValue = (document.querySelector(".frc-captcha-solution") as HTMLInputElement)?.value;


    let title: string | undefined;
    if (typeof window !== "undefined") {
      title = document.title;
    }
    handleFormSubmit({
      fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
      ...data,
      line: props?.CompanyInformationPrivate?.line,
      subject: `Neue Anfrage über Ihre Homepage ${
        data?.Name ? `von ${data?.Name} ` : ""
      }${title ? `- ${title}` : ""}`,
      dealerMail: props?.CompanyInformationPublic?.email,
      templateId: "d-26922068a30343d98a725c3fdaa663da",
    }).then((r) => {
      reset();
      setSuccess(true);
    });
  };

  const name = props.UniversalTexts?.name || "Name";
  const eMail = props.UniversalTexts?.eMail || "E-Mail";
  const message = props.UniversalTexts?.message || "Nachricht";

  const sidebarText = props.PageContact?.extras?.find(
    (extraItem) => extraItem?.__typename === "SidebarText"
  ) as ISidebarText;

  // @ts-ignore
  const imgArray = page?.elements?.filter(
    (e) => e?.__typename === "Asset"
  ) as IAsset[];
  // @ts-ignore
  const textElements = page?.elements?.filter(
    (item) => item?.__typename === "TextElement"
  ) as ITextElement[];

  textElements?.map((textElement) => {
    if (textElement?.icon) {
      textElement?.icon?.map((icon) => {
        imgArray.push(icon as any);
      });
    }
  });

  return (
    <div
      css={css`
        ${styles.wrapper};
      `}
    >
      <div css={flexItem100}>
        <h1>{getContactCompanyNameShort()}</h1>
        <div
          css={css`
            display: flex;
            flex-direction: collumn;
            justify-content: space-between;
            td > p {
              min-width: 20px;
            }
          `}
        >
          <div
            css={css`
              width: 50%;
            `}
          >
            <h2>{props.CompanyInformationPublic?.managingDirector}</h2>
            <p>{props.CompanyInformationPublic?.street}</p>
            <p>
              {props.CompanyInformationPublic?.postcode + " "}{" "}
              {props.CompanyInformationPublic?.place}
            </p>
            <br />
            {props.CompanyInformationPublic?.phone && (
              <p>Tel: {props.CompanyInformationPublic?.phone}</p>
            )}
            {props.CompanyInformationPublic?.fax && (
              <p>Fax: {props.CompanyInformationPublic?.fax}</p>
            )}
          </div>
          <div
            css={css`
              width: 50%;
              table {
                width: 100%;
              }
              td {
                width: auto;
              }
              p {
                min-height: 18px;
              }
              td,
              p,
              span {
                height: 18px;
              }
              td:nth-child(2),
              td:nth-child(3) {
                text-align: right;
              }
            `}
          >
            {openingHoursContent && (
              <RichText
                textContent={openingHoursContent}
              />
            )}
          </div>
        </div>
      </div>
      <div
        css={css`
          ${flexItem50};
          ${styles.columnsWrapper}
        `}
      >
        <div>
          {(
            props.PageContact?.elements?.filter(
              (e) => e?.__typename === "TextElement"
            ) as ITextElement[]
          )?.map((t, i) => (
            <Fragment key={i}>
              {i === 1 ? (
                <RichText
                  overwriteStyle={styles.directionsDescription}
                  textContent={t.text}
                >
                  <h1>{t.headline}</h1>
                </RichText>
              ) : (
                i > 1 && (
                  <RichText
                    overwriteStyle={styles.contact}
                    textContent={t.text}
                  >
                    <h1>{t.headline}</h1>
                  </RichText>
                )
              )}
            </Fragment>
          ))}
        </div>
        {imgArray?.map((imgItem, index) => (
          <div key={`imageContact${index}`}>
            <ImageWithAspectRatio
              alt={imgItem?.title}
              src={imgItem?.src}
              height={342}
              ratio={50}
              quality={720}
            />
          </div>
        ))}
        {sidebarText?.text && (
          <div>
            <RichText
              overwriteStyle={styles.sidebarText}
              textContent={sidebarText?.text}
            />
          </div>
        )}
      </div>
      <div css={styles.contactForm}>
        {props?.UniversalTexts?.contactForm && (
          <h3>{props?.UniversalTexts?.contactForm}</h3>
        )}
        {success ? (
          <form>
            <h1>DANKE!</h1>
            Wir werden uns sobald wie möglich bei Ihnen melden.
          </form>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div css={styles.inputsWrapper}>
              <label htmlFor={"Name"}>{name}</label>
              <input
                required={true}
                type="text"
                name="Name"
                ref={register({
                  options: { required: true, unique: true },
                  min: 12,
                })}
              />
              <label htmlFor={"E-Mail"}>{eMail}</label>
              <input
                required={true}
                type="email"
                name="E-Mail"
                ref={register({
                  options: { required: true, unique: true },
                  min: 4,
                })}
              />
              <label htmlFor={"Nachricht"}>{message}</label>
              <textarea
                rows={8}
                required={true}
                name="Nachricht"
                ref={register({ required: true })}
              />
            </div>
            <FriendlyCaptcha />
            <div css={styles.submitWrapper}>
              <div>
                <input
                  css={css`
                    position: absolute;
                    opacity: 0;
                    pointer-events: none;
                  `}
                  type="privacy"
                  placeholder="Ich akzeptiere die Datenschutzerklärung"
                  name="privacy"
                />
                <input
                  required={true}
                  type="checkbox"
                  placeholder="Ich akzeptiere die Datenschutzerklärung"
                  name="checkbox"
                  ref={register({ required: true })}
                />
                <label htmlFor={"checkbox"} css={styles.datenschutzAkzeptieren}>
                  Ich akzeptiere die{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/${props.PageImprint?.slug || ""}`}
                  >
                    {" "}
                    Datenschutzerklärung
                  </a>
                </label>
              </div>
              <button type="submit">
                {props?.UniversalTexts?.Submit || "Absenden"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;
