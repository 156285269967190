import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  width: 100%;
  margin: auto;
  margin: 20px auto 50px auto;
  padding: 0 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 auto 50px auto;
    padding: 0;
  }

  /* > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > a {
      align-self: center;
    }
  } */
`;

