import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Row, Col } from "../../../BaseComponents/Grid";

export const Wrapper: any = styled.div`
    ${Col} {
      padding-left: 0;
      padding-right: 0;
    }
    ${Row} {
      margin-bottom: 50px;
      ${Col} {
        padding-left: 0;
        padding-right: 0;
      }
    }
    position: relative;
    padding-bottom: 100px;
    min-height: 80vh;
    font-weight: 300;
    padding: 50px 11px;
    max-width: 1100px;

    h1 {
      color: ${props => props.theme.palette.white};
      font-size: 20px;
      font-weight: 100;
      line-height: 35px;
      letter-spacing: 0.4px;
      margin: 0 0 7.5px;
    }
    p {
      margin-bottom: 21px;
      span {
        font-weight: 300;
        font-size: 15px;
        line-height: 21px;
      }
    }
    span {
      font-weight: 300;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      padding: 50px 60px;
    }
`;

export const OpenFormWrapper = styled.div`

  button {
    padding: 0 15px;
    line-height: 45px;
    border: 1px solid #76B729;
    p { 
      margin-bottom: 0;
    }
    color: ${props => props.theme.palette.white};
    background-color: #76B729;
    &:hover {
      background-color: #706f6f;
      border: 1px solid #706f6f;
    }

  }
`;

export const sideBarWrapper: any = css`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 229px;
  }
`;

export const RightSideWrapper: any = styled.div`
  padding-left: 5px;
`;
