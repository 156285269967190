import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const MicroWrapper: any = styled.div`
  background-color: ${props => props.theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  h1 {
    color: rgb(255, 255, 255);
    font-family: Exo, Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.6px;
    line-height :48px;
    margin: 72px 0 129px;
  }
  iframe {
    width: 100%;
    height: 100vh;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 13.5px 90px 0;
  }
`;
