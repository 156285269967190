import React, { FC } from "react";

import { Grid, Section } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import MainContent from "../../content/MainContent";

const PageT9: FC = () => (
  <>
    <Header />
    <Grid>
      <Section>
        <MainContent />
      </Section>
    </Grid>
    <Banner />
    <Footer />
     
  </>
);

PageT9.displayName = "PageT9";
export { PageT9 };
