import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import colors from "../../../constants/colors";

export const Wrapper: any = styled.div`
    position: relative;
    padding-bottom: 100px;
    min-height: 80vh;
    font-weight: 300;
    h1 {
      color: #383838;
      font-weight: 700;;
    };

    span {
      font-weight: 300;
    };

    .SRichText {
      margin: 0;
    }
`;

export const OpenFormWrapper = styled.div`
  button {
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: auto;
    line-height: 15px;
    border: 1px solid ${props => props.theme.palette.green};
    color: ${props => props.theme.palette.white};
    background-color: ${props => props.theme.palette.green};
    p {
      padding: 6px 18px;
      margin: 0;
      span {
        white-space: nowrap;
        font-size: 12px;
        color: ${props => props.theme.palette.white};
      }
    }
  }
`;

export const Content: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 79%;
    margin: auto;
  }
`;

export const Button: any = styled.div`
  margin: -40px 0 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: fit-content;
  line-height: 15px;
  border: 1px solid ${props => props.theme.palette.green};
  color: ${props => props.theme.palette.white};
  background-color: ${props => props.theme.palette.green};
  padding: 6px 18px;
  p {
    margin: 0;
    white-space: nowrap;
    color: ${props => props.theme.palette.white};
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
  }
  &:hover {
    background: ${colors.light_green};
    border: 1px solid ${colors.light_green};
  }
`;
