import React, { useState } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { IContext, Context, getPage, getTextBlocks, RichText, IAsset } from "src/common";

import { GuestbookStars } from "../../../../../common/components/GuestbookStars/GuestbookStars";
import { Footer } from "../../common/footer/Footer";
import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { Confirmation } from "./components/Confirmation";
import { InitialGuestbookPage } from "./components/InitialGuestbookPage";
import { Preview } from "./components/Preview";
import * as styles from "./PageGuestbookT4.style";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

enum eState 
  {
  initial,
  write,
  preview,
  submit
}

const PageGuestbookT4 = (): any => 
{
  const p = React.useContext<IContext>(Context);

  const pageProps = p;
  const page = getPage(p);

  const textBlocks = getTextBlocks(page);
  const bannerImage = (page?.elements as IAsset[]).filter(e => e?.__typename === "Asset");
  // ----------------------------------------- //
  const universalTexts = pageProps?.UniversalTexts;
  const guestbookEntries = pageProps?.AllGuestbookEntry;

  const [state, setState] = useState<eState>(eState.initial);
  const [selectedStars, setSelectedStars] = useState<number>(0);
  const {
    register, handleSubmit, errors, setValue 
  } = useForm({ mode: "onChange" });
  const [formData, setFormData] = React.useState({ 
    vorname: "",
    nachname: "",
    alias: "",
    email: "",
    titel: "",
    nachricht: "",
    sterne: 0,
    checkbox: false
  });

  const updateSelectedStars = (newStarsSelected: number) => 
  {
    setSelectedStars(newStarsSelected);
  };

  const onSubmit = (data): void => 
  {
    const fcValue = (document.querySelector(".frc-captcha-solution") as HTMLInputElement)?.value;

    console.log(data);
    setFormData({
      fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
      ...data,
      checkbox: true,
      sterne: selectedStars
    });
    setState(eState.preview);
  };

  const capitalize = (string) => 
    string.charAt(0).toUpperCase() + string.slice(1);

  const displayErrorMessage = (dataName: string) => 
  {
    if(!errors[dataName]) 
    {
      return;
    }

    const errorType = errors[dataName].type;
    let errorMessage;

    if(errorType === "required") 
    {
      if(dataName === "checkbox") 
      {
        errorMessage = "Bitte Datenschutzbestimmungen akzeptieren.";
      }
      else 
      {
        errorMessage = "Bitte " + capitalize(dataName) + " angeben.";
      }
    }
    else if(errorType === "pattern" && dataName === "email") 
    {
      errorMessage = "Bitte gültige E-Mail angeben.";
    }
    else if(errorType === "minLength") 
    {
      if(dataName === "nachricht") 
      {
        errorMessage = "Mindestens 4 Zeichen.";
      }
      else 
      {
        errorMessage = "Mindestens 2 Zeichen.";
      }
    }
        
    return errors[dataName] && (
      <div className="err-msg">{errorMessage}</div>
    );
  };

  const goToGuestbookSubmit = () => 
  {
    setState(eState.submit);
    setFormData({ 
      vorname: "",
      nachname: "",
      alias: "",
      email: "",
      titel: "",
      nachricht: "",
      sterne: 0,
      checkbox: false
    });
    setSelectedStars(0);
  };

  const goBackToEditSubmit = () => 
  {
    setState(eState.write);
  };

  React.useEffect(() => 
  {
    for(const name in formData) 
    {
      setValue(name, formData[name]);
    }
  }, [state]);
    
  let content: React.ReactElement;
  if(state === eState.initial) 
  {
    content = (
      <InitialGuestbookPage universalTexts={universalTexts} textBlocks={textBlocks} bannerImage={bannerImage} guestbookEntries={guestbookEntries} onClick={() => setState(eState.write)}/>
    );
  }
  else if(state === eState.write) 
  {
    content = (
      <div css={styles.formWrapper}>
        <h1 css={styles.heading}>
          {universalTexts?.guestbook_writeContribution ?
            <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts?.guestbook_writeContribution}></RichText>
            : "Beitrag verfassen"
          }
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div css={styles.formRow}>
              <div className="formRow__first">
                <div css={styles.labelAndError}>
                  <label className="optional" htmlFor={"vorname"}>
                    <div className="optional">  
                      {universalTexts.guestbook__firstname ? <RichText textContent={universalTexts.guestbook__firstname}></RichText> : "Vorname"}
                      <span> (optional, nicht öffentlich)</span>
                    </div>
                  </label>
                  {displayErrorMessage("vorname")}
                </div>
                <input type="text" id={"vorname"} name="vorname" ref={register({ minLength: 2 })} />
              </div>
              <div className="formRow__first">
                <div css={styles.labelAndError}>
                  <label className="optional" htmlFor={"nachname"}>
                    <div className="optional">
                      {universalTexts.guestbook__lastname ? <RichText textContent={universalTexts.guestbook__lastname}></RichText> : "Nachname"}
                      <span> (optional, nicht öffentlich)</span>
                    </div>
                  </label>
                  {displayErrorMessage("nachname")}
                </div>
                <input type="text" id={"nachname"} name="nachname" ref={register({ minLength: 2 })} />
              </div>
            </div>
            <div css={styles.formRow}>
              <div>
                <div css={styles.labelAndError}>
                  <label htmlFor={"alias"}>
                    <RichText>{"Alias"}</RichText>
                  </label>
                  {displayErrorMessage("alias")}
                </div>
                <input type="text" id={"alias"} name="alias" ref={register({ required: true, minLength: 2 })} />
              </div>
              <div>
                <div css={styles.labelAndError}>
                  <label className="optional" htmlFor={"email"}>
                    <div className="optional">
                      {universalTexts.guestbook__email ? <RichText textContent={universalTexts.guestbook__email}></RichText> : "E-Mail"}
                      <span> (nicht öffentlich)</span>
                    </div>
                  </label>
                  {displayErrorMessage("email")}
                </div>
                <input 
                  type="email" 
                  id={"email"} 
                  name="email" 
                  ref={register({ 
                    required: true, 
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
                  })
                  }/>
              </div>
            </div>
            <div css={`${styles.formRow}${styles.formRowOneChild}`}>
              <div css={styles.labelAndError}>
                <label htmlFor={"titel"}>
                  {universalTexts.guestbook__title ? <RichText textContent={universalTexts.guestbook__title}></RichText> : "Titel"}
                </label>
                {displayErrorMessage("titel")}
              </div>
              <input type="text" id={"titel"} name="titel" ref={register({ required: true })} />
            </div>
            <div css={styles.formRow} style={{ display: "block" }}>
              <div css={styles.labelAndError}>
                <label htmlFor={"nachricht"}>
                  {universalTexts.guestbook__message ? <RichText textContent={universalTexts.guestbook__message}></RichText> : "Nachricht"}
                </label>
                {displayErrorMessage("nachricht")}
              </div>
              <textarea rows={8} name="nachricht" id={"nachricht"} ref={register({ required: true, minLength: 4 })} />
            </div>
            <div css={styles.formRow} style={{ display: "block" }}>
              <label htmlFor={"bewertungAbgeben"}>
                {universalTexts.gestbook__evaluation ? <RichText textContent={universalTexts.gestbook__evaluation}></RichText> : "Bewertung abgeben"}
              </label>
              <GuestbookStars changeable amountOfStars={selectedStars} onChange={updateSelectedStars}></GuestbookStars>
            </div>
          </div>
          <div css={styles.acceptPrivacyWrapper}>
            <input
              type="checkbox"
              placeholder="Ich akzeptiere die Datenschutzerklärung"
              name="checkbox"
              id="checkbox"
              ref={register({ required: true })}
            />
            <div css={styles.labelAndError}>
              <label htmlFor={"checkbox"}>
                                Ich akzeptiere die{" "}
                <a href={`/${pageProps?.PageImprint?.slug || ""}`}> <span>Datenschutzerklärung</span></a>
              </label>
            </div>
          </div>
          <div css={styles.labelAndError} style={{ marginTop: "20px" }}>
            {displayErrorMessage("checkbox")}
          </div>
          <FriendlyCaptcha />
          <div css={styles.submitWrapper}>
            <button type="submit">
              {universalTexts?.guestbook__preview ?
                <RichText textContent={universalTexts?.guestbook__preview}></RichText>
                : "Vorschau ansehen"
              }
            </button>
            <button onClick={() => setState(eState.initial)} type="reset">
              {universalTexts.guestbook__cancel ? <RichText overwriteStyle={styles.richtextStyle} textContent={universalTexts.guestbook__cancel}></RichText> : "Abbrechen"}
            </button>
          </div>
        </form>
      </div>
    );
  }
  else if(state === eState.preview) 
  {
    content = (
      <Preview universalTexts={universalTexts} formData={formData} goToGuestbookSubmit={() => goToGuestbookSubmit()} goBackToEditSubmit={() => goBackToEditSubmit()}/>
    );
  }
  else 
  {
    content = (
      <Confirmation universalTexts={universalTexts} onClick={() => setState(eState.initial)}/>
    );
  }

  return (
    <>
      <div id="PageGuestbookT4T42">
        <MainNavigation />
      </div>
      <div css={styles.wrapper}>
        <div>{content}</div>
      </div>
      <Footer />
    </>
  );
};

PageGuestbookT4.displayName = "PageGuestbookT4";
export { PageGuestbookT4 };
