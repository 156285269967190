import React, { FC } from "react";
import { Context, getPage, IContext } from "src/common";

import GuestBookItem from "./GuestBookItem";
import { GuestBookEntriesWrapper } from "./GuestBookEntries.style";

const GuestBookEntries: FC = () => {
  const props = React.useContext<IContext>(Context);
  const page = getPage(props);
  const { AllGuestbookEntry } = props;

  return (
    <GuestBookEntriesWrapper>
      {
        AllGuestbookEntry?.map((item, i) => (
          <GuestBookItem
            key={`guest_book_${i}`}
            item={item}
          />
        ))
      }
    </GuestBookEntriesWrapper>
  )
};

export default GuestBookEntries;
