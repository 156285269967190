import React, { FC } from "react";
import { Row, Col } from "src/templates/t9/BaseComponents/Grid";

import Contact from "../../common/Contact";
import Map from "../../common/Map";
import { ContentContainer } from "./ContactContent.style";

const ContactContent: FC = () => (
  <ContentContainer>
    <Row>
      <Col md={6}>
        <Map />
      </Col>
      <Col md={6}>
        <Contact />
      </Col>
    </Row>
  </ContentContainer>
);

export default ContactContent;
