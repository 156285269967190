import { css } from "styled-components";

export const buttonWrapper: any = css`
    display: flex;
    justify-content: center;
    margin: 60px 0;
`;

export const headlineWrapper: any = css`
    background-color: ${props => props.theme.palette.guestbookGreen};
    padding: 80px 50px 50px 50px;

    h1 {
      color: white;
      font-size: 30px;
      font-weight:400;
      text-transform: uppercase;
    }
`;

export const odd: any = css`
    display:flex;
    flex-direction: column;
    align-items: center;

    background-color: ${(props: any) => props.theme.palette.backgroundMint};
    padding: 5% 10%;

    * {
      color: white;
    }
    a, a * {
      color: ${props => props.theme.primary};
      &:hover {
        color: ${props => props.theme.palette.red};
        text-decoration: underline;
      }
    }
`;

export const richtextStyle: any = css`
    background-color: ${props => props.theme.palette.guestbookGreen};
    color: white;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
`;
