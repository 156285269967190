import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
export const CourseFormWrapper: any = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 117px auto;
  padding: 60px 17px;

  p.back-btn {
    color: #80C7DC;
    cursor: pointer;
    text-align: end;
    width: 100%;
    &:hover {
      color: #EFEFEF;
      text-decoration: underline;
    }
  }

  h3 {
    color: rgb(128, 199, 220);
    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 16px;
  }

  h2 {
    color: rgb(239, 239, 239);
    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 16px;

    span {
      fcolor: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      margin-bottom: inherit;
    }
  }
  form {

    [type=submit] {
      align-items: flex-start;
      background-color: rgb(128, 199, 220);
      border: 1px solid rgb(128, 199, 220);
      color: rgb(239, 239, 239);
      cursor: pointer;
      font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 300;
      height: 44px;
      line-height: 42px;
      text-align: center;
      white-space: nowrap;
      width: 330.25px;
      &:hover {
        color: #008288;
      }
    }
  }
`;

export const CourseItemWrapper: any = styled.div`


  > div {
    display: flex;
  }

  > a {
    display: block;
    background-color: #80C7DC;
    color: #efefef;
    border: 1px solid #80C7DC;
    line-height: 3;
    padding: 0 10px;
    margin-top: 20px;
  }

`;

export const CourseContentWrapper: any = styled.div`
  width: 66.7%;

  p {
    margin-bottom: 34px;
    &, span {
      font-family: "Roboto Slab";
      line-height: 34px;
    }
  }

  a {
    color: #80C7DC;
  }

`;

export const InputWrapper: any = styled.div<{ sm?: number; md?: number}>`
  color: rgb(239, 239, 239);
  font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 17px;

  ${({ sm }) => sm && `
    width: calc(100% / 12 * ${sm} - 10px);
  `}
  label {
    color: rgb(239, 239, 239);
    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  input:not([type=checkbox]) {
    display: block;
    border-top-style: inherit;
    border-top-width: 0;
    border: 1px solid #000;
    line-height: 2;
    min-height: 28px;
    padding: 0 5px;
    width: 100%;
  }

  select {
    display: inline-block;
    font-family: "Roboto Slab", sans-serif;
    font-size: 100%;
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: #80C7DC;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    ${({ md }) => md && `
      width: calc(100% / 12 * ${md} - 10px);
    `}
  }

`;

export const CourseDateWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-left: 32px;
  margin-left: auto;
  margin-bottom: 21px;
  h3 {
    margin-bottom: 4px;
  }
  
  b.principal > span{ 
    color: rgb(128, 199, 220);
    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }

  span {
    color: rgb(239, 239, 239);
    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  p {
    &, span {
      font-size: 14px;
      line-height: 17px;
    }
  }
  a {
    background-color: #80C7DC;
    border: 1px solid #80C7DC;
    color: #efefef;
    cursor: pointer;
    display: inline-block;
    line-height: 2;
    margin-top: 20px;
    padding: 0 10px;
    text-align: center;
    width: 100%;
  }
`;
