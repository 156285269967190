import styled from "styled-components";
import { BREAKPOINTS, BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Section } from "../../common/Grid";

export const Wrapper: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
  }

  h2 {
    color: ${({ theme }) => theme?.palette.primary};
    font-size: 20px;
    line-height: 1.75;
  }

  ${Section} {
    display: block;
    padding-top: 300px;
  }
`;

export const ContentContainer: any = styled.div`
  margin-bottom: 60px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
  }
`;
