import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { IContext, Context, getPage, getTextBlocks, RichText, IAsset, getCurrentNavItem } from "src/common";

import { GuestbookStars } from "../../../../../common/components/GuestbookStars/GuestbookStars";
import { FooterT3 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { CenteredContent } from "../../common/MainContent/Slug.style";
import { TeaserGallery } from "../../common/teaserGallery/TeaserGallery";
import { Initial } from "./Initial";
import * as styles from "./PageGuestbookT3.style";
import { FormWrapper } from "./PageGuestbookT3.style";
import { Preview } from "./Preview";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

enum eState 
  {
  initial,
  write,
  preview,
  submit
}

const PageGuestbookT3 = (): any => 
{
  const p = React.useContext<IContext>(Context);
  const palette = p?.Settings;


  const pageProps = p;
  const currentNavItem = getCurrentNavItem(
    pageProps?.PrimaryNavigation?.items,
    pageProps?.slug
  );
  const page = getPage(p);

  const textBlocks = getTextBlocks(page);
  const bannerImage = (page?.elements as IAsset[]).filter(e => e?.__typename === "Asset");
  // ----------------------------------------- //
  const universalTexts = pageProps?.UniversalTexts;
  const guestbookEntries = pageProps?.AllGuestbookEntry;

  const [state, setState] = useState<eState>(eState.initial);
  const [selectedStars, setSelectedStars] = useState<number>(0);
  const {
    register, handleSubmit, errors, setValue 
  } = useForm({ mode: "onChange" });
  const options = {
    weekday: "long", year: "numeric", month: "long", day: "numeric" 
  };
  const [formData, setFormData] = React.useState({ 
    vorname: "",
    nachname: "",
    alias: "",
    email: "",
    titel: "",
    nachricht: "",
    sterne: 0,
    checkbox: false
  });

  const updateSelectedStars = (newStarsSelected: number): void => 
  {
    setSelectedStars(newStarsSelected);
  };

  const onSubmit = (data): void => 
  {
    const fcValue = (document.querySelector(".frc-captcha-solution") as HTMLInputElement)?.value;

    console.log(data);
    setFormData({
      fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
      ...data,
      checkbox: true,
      sterne: selectedStars
    });
    setState(eState.preview);
  };

  const capitalize = (string: string): string => 
    string.charAt(0).toUpperCase() + string.slice(1);

  const displayErrorMessage: any = (dataName: string) => 
  {
    if(!errors[dataName]) 
    {
      return;
    }

    const errorType = errors[dataName].type;
    let errorMessage;

    if(errorType === "required") 
    {
      if(dataName === "checkbox") 
      {
        errorMessage = "Bitte Datenschutzbestimmungen akzeptieren.";
      }
      else 
      {
        errorMessage = "Bitte " + capitalize(dataName) + " angeben.";
      }
    }
    else if(errorType === "pattern" && dataName === "email") 
    {
      errorMessage = "Bitte gültige E-Mail angeben.";
    }
    else if(errorType === "minLength") 
    {
      if(dataName === "nachricht") 
      {
        errorMessage = "Mindestens 4 Zeichen.";
      }
      else 
      {
        errorMessage = "Mindestens 2 Zeichen.";
      }
    }
        
    return errors[dataName] && (
      <div className="err-msg">{errorMessage}</div>
    );
  };

  const goToGuestbookSubmit: any = () => 
  {
    setState(eState.submit);
    setFormData({ 
      vorname: "",
      nachname: "",
      alias: "",
      email: "",
      titel: "",
      nachricht: "",
      sterne: 0,
      checkbox: false
    });
    setSelectedStars(0);
  };

  const goBackToEditSubmit: any = () => 
  {
    setState(eState.write);
  };

  useEffect(() => 
  {
    for(const name in formData) 
    {
      setValue(name, formData[name]);
    }
  }, [state, setValue, formData]);
    
  let content: React.ReactElement;
  if(state === eState.initial) 
  {
    content = (
      <Initial 
        universalTexts={universalTexts} 
        onClick={() => setState(eState.write)} 
        textBlocks={textBlocks} 
        bannerImage={bannerImage} 
        guestbookEntries={guestbookEntries} 
        options={options}
      />
    );
  }
  else if(state === eState.write) 
  {
    content = (
      <FormWrapper color={palette.themeTextColor}> 
        <h1 css={styles.heading}>
          {universalTexts?.guestbook_writeContribution ?
            <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts?.guestbook_writeContribution}></RichText>
            : "Beitrag verfassen"
          }
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div css={styles.formRow}>
              <div>
                <div css={styles.labelAndError}>
                  <label htmlFor={"vorname"}>
                    {universalTexts.guestbook__firstname ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__firstname}></RichText> : "Vorname"}
                  </label>
                  {displayErrorMessage("vorname")}
                </div>
                <input type="text" id={"vorname"} name="vorname" ref={register({ minLength: 2 })} />
              </div>
              <div>
                <div css={styles.labelAndError}>
                  <label htmlFor={"nachname"}>
                    {universalTexts.guestbook__lastname ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__lastname}></RichText> : "Nachname"}
                  </label>
                  {displayErrorMessage("nachname")}
                </div>
                <input type="text" id={"nachname"} name="nachname" ref={register({ minLength: 2 })} />
              </div>
            </div>
            <div css={styles.formRow}>
              <div>
                <div css={styles.labelAndError}>
                  <label htmlFor={"alias"}>
                    <RichText overwriteStyle={styles.labelRichtext}>{"Alias"}</RichText>
                  </label>
                  {displayErrorMessage("alias")}
                </div>
                <input type="text" id={"alias"} name="alias" ref={register({ required: true, minLength: 2 })} />
              </div>
              <div>
                <div css={styles.labelAndError}>
                  <label htmlFor={"email"}>
                    {universalTexts.guestbook__email ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__email}></RichText> : "E-Mail"}
                  </label>
                  {displayErrorMessage("email")}
                </div>
                <input 
                  type="email" 
                  id={"email"} 
                  name="email" 
                  ref={register({ 
                    required: true, 
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
                  })
                  }/>
              </div>
            </div>
            <div css={`${styles.formRow}${styles.formRowOneChild}`}>
              <div css={styles.labelAndError}>
                <label htmlFor={"titel"}>
                  {universalTexts.guestbook__title ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__title}></RichText> : "Titel"}
                </label>
                {displayErrorMessage("titel")}
              </div>
              <input type="text" id={"titel"} name="titel" ref={register({ required: true })} />
            </div>
            <div css={styles.formRow} style={{ display: "block" }}>
              <div css={styles.labelAndError}>
                <label htmlFor={"nachricht"}>
                  {universalTexts.guestbook__message ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.guestbook__message}></RichText> : "Nachricht"}
                </label>
                {displayErrorMessage("nachricht")}
              </div>
              <textarea rows={8} name="nachricht" id={"nachricht"} ref={register({ required: true, minLength: 4 })} />
            </div>
            <div css={styles.formRow} style={{ display: "block" }}>
              <label htmlFor={"bewertungAbgeben"}>
                {universalTexts.gestbook__evaluation ? <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts.gestbook__evaluation}></RichText> : "Bewertung abgeben"}
              </label>
              <GuestbookStars changeable amountOfStars={selectedStars} onChange={updateSelectedStars}></GuestbookStars>
            </div>
          </div>
          <div css={styles.acceptPrivacyWrapper}>
            <input
              type="checkbox"
              placeholder="Ich akzeptiere die Datenschutzerklärung"
              name="checkbox"
              id="checkbox"
              ref={register({ required: true })}
            />
            <div css={styles.labelAndError}>
              <label htmlFor={"checkbox"}>
                                Ich akzeptiere die{" "}
                <a href={`/${pageProps?.PageImprint?.slug || ""}`}> <span>Datenschutzerklärung</span></a>
              </label>
            </div>
          </div>
          <div css={styles.labelAndError} style={{ marginTop: "20px" }}>
            {displayErrorMessage("checkbox")}
          </div>
          <div css={styles.fcWrapper}>
            <FriendlyCaptcha />
          </div>
          <div css={styles.submitWrapper}>
            <button type="submit">
              {universalTexts?.guestbook__preview ?
                <RichText overwriteStyle={styles.labelRichtext} textContent={universalTexts?.guestbook__preview}></RichText>
                : "Vorschau ansehen"
              }
            </button>
            <button onClick={() => setState(eState.initial)} type="reset">
              {universalTexts.guestbook__cancel ? <RichText overwriteStyle={styles.richtextStyle} textContent={universalTexts.guestbook__cancel}></RichText> : "Abbrechen"}
            </button>
          </div>
        </form>
      </FormWrapper>
    );
  }
  else if(state === eState.preview) 
  {
    content = (
      <Preview 
        universalTexts={universalTexts} 
        formData={formData} 
        goToGuestbookSubmit={() => goToGuestbookSubmit()} 
        goBackToEditSubmit={() => goBackToEditSubmit()}
        options={options}
      />
    );
  }
  else 
  {
    content = (
      <div css={styles.submitted}>
        <h1>Danke für Ihren Eintrag!</h1>
        <p>Ihr Beitrag wurde entgegen genommen und wird in Kürze freigeschaltet.</p>
        <button onClick={() => setState(eState.initial)}>
          {universalTexts.gestbook__back ? <RichText overwriteStyle={styles.richtextStyle} textContent={universalTexts.gestbook__back}></RichText> : "Zum Gästebuch"}
        </button>
      </div>
    );
  }

  return (
    <>
      <Header />
      <PlanningDateModal />
      <div css={styles.wrapper}>
        <div>{content}</div>
      </div>
      {currentNavItem && (
          <CenteredContent mt={50} textColor={palette.themeTextColor}>
            <TeaserGallery
              isTwoColumnsLayout={false}
              items={currentNavItem.subitems as any[]}
            />
          </CenteredContent>
        )}
      <FooterT3/>
    </>
  );
};
PageGuestbookT3.displayName = "PageGuestbookT3";
export { PageGuestbookT3 };
