import React, { FC, useContext, useState } from "react";
import { Context, IAsset, getIfNewsShouldBeDisplayByDate, INews } from "src/common";

import NewsArticleItem from "./NewsArticleItem";
import { Wrapper, NavigationPage } from "./NewsContent.style";

const NewsContent: FC<any> = () => {
  const p = useContext(Context);
  const [currentPage, setCurrentPage] = useState<number>(1);

  if (!p.PageNews?.NewsArticles) {
    return null;
  }

  const news = p.PageNews;
  const newsArticles = news?.NewsArticles?.filter((article) =>
    getIfNewsShouldBeDisplayByDate({ startDate: article?.startDate, endDate: article?.endDate })
  ).filter((article) => article !== null) || [];
 
  const newsCustomArticles = news?.NewsArticlesCustom?.filter((article) =>
    getIfNewsShouldBeDisplayByDate({ startDate: article?.startDate, endDate: article?.endDate })
  ).filter((article) => article !== null) || [];

  const articles = [...newsCustomArticles, ...newsArticles];

  const itemsPerPage = 10;

  const amountOfPages = Math.ceil(articles.length / itemsPerPage);
  const articlesToRender: INews[] = articles.slice((currentPage - 1) * itemsPerPage, itemsPerPage);
  // for (let i = (currentPage - 1) * itemsPerPage; i < (currentPage - 1) * itemsPerPage + itemsPerPage; i++) {
  //   articlesToRender.push(articles[i]);
  // }

  return (
    <Wrapper>
      {articlesToRender && articlesToRender.map((article, index) => article && (
        <NewsArticleItem
          key={`newsArticleItem${index}`}
          index={index}
          headline={article?.headline}
          text={article?.text}
          images={
            (article?.extras?.filter(e => e?.__typename === "Asset") as
              | IAsset[]
              | undefined) ?? []
          }
        />
      ))}

      <NavigationPage>
        {currentPage < amountOfPages && (
          <a
            className="olderNews"
            onClick={() => setCurrentPage(currentPage + 1)}
            href={"#"}
          >
            <svg
              className="olderNewsSvg"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg>
            <p>Ältere Nachrichten</p>
          </a>
        )}
        <a
          className="latestNews"
          onClick={() => setCurrentPage(currentPage - 1)}
          style={currentPage > 1 ? { visibility: "visible" } : { visibility: "hidden" }}
          href={"#"}
        >
          <p>Neuere Nachrichten </p>
          <svg
            className="latestNewsSvg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
          </svg>
        </a>
      </NavigationPage>
    </Wrapper>
  );
};

export default NewsContent;
