import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const nachOben: any = css`
    margin: 30px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    &:hover{
      color: ${props => props.theme.palette.yellow};
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        display: none;
    }
   svg {
      transform: rotate(180deg) scale(.65);
      fill: red;
      margin-left: 7px;
   }
`;
