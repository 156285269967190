import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const heroImage: any = css`
    padding: 0;
    img {
      width: 100%;
      height: auto;
    }
`;

export const contentBox: any = css`
    h2 {
      font-size: 24px;
      line-height: 120% !important;
    }
`;

export const richText: any = css`
    margin-bottom: 10px;
    br {
      margin-top: 10px;
    }
    h3, h4 {
      color: ${colors.blue};
      font-size: 20px;
      line-height: 120% !important;
    }
`;

export const iconBox: any = css`
    img {
      width: 100%;
      height: auto;
    }
`;

export const articleAssetBox: any = css`
    img {
      width: 100%;
      height: auto;
    }
`;

export const articleContentBox: any = css`
    * {
      color: white !important;
    }
    h2, h3, h4 {
      font-size: 20px;
    }
    a {
      font-weight: 700;
      &:hover {
        text-decoration: underline;
      }
    }
`;

export const hiddenOnMobile: any = css`
    display: block;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        /* display: none; */
    }
`;
