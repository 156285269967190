import React, { FunctionComponent } from "react";
import Header from "../../common/header/Header";
import { FooterT3 } from "../../common/footer/Footer";
import MainContent from "../../common/MainContent";
import { Banner } from "../../common/Banner/Banner";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";

const PageT3: FunctionComponent = () => {
    return (
        <>
            <Banner />
            <PlanningDateModal />
            <Header />
            <MainContent />
            <FooterT3 />
        </>
    );
};

PageT3.displayName = "PageT3";
export { PageT3 };
