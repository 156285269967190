import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useState,
} from "react";
import {
  Context,
  getIfNewsShouldBeDisplayByDate,
  INews,
  IAsset,
} from "src/common";
import { css } from "styled-components";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import { cutCorner } from "../../../constants/layout";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";
import * as styles from "./News.style";
import { SPageLayoutWrapper } from "../PageLanding";
const PageNewsT7: FunctionComponent = () => {
  const slugProps = useContext(Context);

  const pageNews = slugProps.PageNews;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const newsArticles =
    pageNews.NewsArticles?.filter((article) =>
      getIfNewsShouldBeDisplayByDate({
        startDate: article?.startDate,
        endDate: article?.endDate,
      })
    ).filter((article) => article !== null) || [];

  const newsCustomArticles =
    pageNews.NewsArticlesCustom?.filter((article) =>
      getIfNewsShouldBeDisplayByDate({
        startDate: article?.startDate,
        endDate: article?.endDate,
      })
    ).filter((article) => article !== null) || [];

  const articles = [...newsCustomArticles, ...newsArticles];

  const itemsPerPage = 8;

  const articlesToRender: INews[] = [];
  const amountOfPages = Math.ceil(articles?.length / itemsPerPage);

  for (
    let i = (currentPage - 1) * itemsPerPage;
    i < (currentPage - 1) * itemsPerPage + itemsPerPage;
    i++
  ) {
    articlesToRender.push(articles[i]);
  }
  if (!pageNews) {
    return null;
  }

  return (
    <Fragment>
      <ResponsiveSidebar />
      <SPageLayoutWrapper>
        <div>
          <MainSidebarLeft />
        </div>
        <div
          css={css`
            width: 100%;
          `}
        >
          <MainAreaWrapper>
            {articlesToRender?.map((article, i) => {
              if (!article) {
                return null;
              }
              const assets = article?.extras?.filter(
                (extra) => extra?.__typename === "Asset"
              ) as IAsset[];
              return (
                <div
                  css={`
                    ${cutCorner}${styles.articleWrapper}
                  `}
                  key={i}
                >
                  <div>
                    <div
                      css={`
                        ${styles.articleImage}
                      `}
                    >
                      {assets?.map((asset, i) => (
                        <Fragment key={`asset-${i}`}>
                          <Img
                            key={asset?.src}
                            quality={400}
                            src={asset?.src}
                            alt={asset?.title ?? ""}
                          />
                          {asset?.description && (
                            <p
                              css={
                                css`
                                  max-width: 400px;
                                  padding: 6.75px 0 0;
                                  font-style: italic;
                                  font-size: 12px;
                                ` as any
                              }
                            >
                              {asset.description}
                            </p>
                          )}
                        </Fragment>
                      ))}
                    </div>
                    <div
                      css={`
                        ${styles.articleContent}
                      `}
                    >
                      {article?.headline && <h2>{article?.headline}</h2>}
                      {article?.text && (
                        <RichText
                          overwriteStyle={styles.articleText}
                          textContent={article?.text}
                        ></RichText>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div style={{ padding: 0 }}>
              <nav css={styles.nextAndPrevPage}>
                {currentPage < amountOfPages && (
                  <a
                    onClick={(e) => {
                      setCurrentPage(currentPage + 1);
                    }}
                    href={"#"}
                  >
                    « Ältere Nachrichten
                  </a>
                )}
                {currentPage > 1 && (
                  <a
                    onClick={(e) => {
                      setCurrentPage(currentPage - 1);
                    }}
                    href={"#"}
                  >
                    Neuere Nachrichten »
                  </a>
                )}
              </nav>
            </div>
          </MainAreaWrapper>
        </div>
      </SPageLayoutWrapper>
    </Fragment>
  );
};
PageNewsT7.displayName = "PageNewsT7";
export { PageNewsT7 };
