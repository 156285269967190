async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

const parseDate = (inDate: string) => {
    if (inDate.includes("/")) {
        const d = new Date(inDate.split("/").reverse().join("-"));
        return d.toLocaleDateString("de-DE");
    } else {
        return inDate;
    }
};

export const courseSubmit = async (data, course) => {
    const { errors } = data;
    if (!errors || Object.keys(errors).length === 0) {
        if (!data.privacy_acknowledgement && typeof window !== undefined) {
            window.alert(`Bitte Datenschutzerklärung akzeptieren`);
            return;
        }

        const body = {
            ...data,
            ...(data?.datum ? { datum: parseDate(`${data.datum}`) } : {}),
            ...(data?.date ? { date: parseDate(`${data.date}`) } : {}),
            courseId: course.id,
        };

        const r = await (
            await fetch(
                `${
                    process.env.NEXT_PUBLIC_GEDK_API_ENDPOINT ?? "https://api-next.api.gedk.caisy.site"
                }/api/v1/ihb/course/signup`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(body),
                }
            )
        ).json();

        if (r?.errorMsg) {
            return {
                errorMsg: r.errorMsg,
            };
        } else if (r.success) {
            return {
                success: true,
            };
        }

        console.log(` r`, r);
        if (r?.success === false) {
            console.log(` r`, r);
            return;
        }
        // delete data.checkbox;
        // const mailRes = await postData(`/api/mail`, body);
        // console.info(`Mail API said: `, mailRes);
    } else if (typeof window !== undefined) {
        window.alert(`Error: ${errors}`);
    }
};
