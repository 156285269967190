import React, { FunctionComponent, useContext } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { Context } from "src/common";
import { css } from "styled-components";

import { handleFormSubmit } from "../../../../../../common/utils/formSubmit";
import * as styles from "./ContactForm.style";
import { SubmitWrapper } from "./ContactForm.style";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

export const ContactForm: FunctionComponent = () => {
  const pageProps = useContext(Context);
  const palette = pageProps?.Settings;
  const { register, handleSubmit, errors, reset } = useForm();
  const [success, setSuccess] = React.useState(false);

  const universalTexts = pageProps?.UniversalTexts;

  const onSubmit = (data): void => {
    // Kontaktformular: d-26922068a30343d98a725c3fdaa663da
    // Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
    // Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441

    const fcValue = (document.querySelector(".frc-captcha-solution") as any)
      ?.value;

    let title: string | undefined;
    if (typeof window !== "undefined") {
      title = document.title;
    }
    handleFormSubmit({
      ...data,
      fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
      line: pageProps?.CompanyInformationPrivate?.line,
      subject: `Neue Anfrage über Ihre Homepage ${
        data?.Name ? `von ${data?.Name} ` : ""
      }${title ? `- ${title}` : ""}`,
      dealerMail: pageProps?.CompanyInformationPublic?.email,
      templateId: "d-26922068a30343d98a725c3fdaa663da",
    }).then((r) => {
      reset();
      setSuccess(true);
    });
  };

  const name = universalTexts?.name || "Name";
  const eMail = universalTexts?.eMail || "E-Mail";
  const message = universalTexts?.message || "Nachricht";

  return (
    <div css={styles.contactForm}>
      {universalTexts?.contactForm && <h3>{universalTexts?.contactForm}</h3>}
      {success ? (
        <form>
          <h1>DANKE!</h1>
          Wir werden uns sobald wie möglich bei Ihnen melden.
        </form>
      ) : (
        <form className="withfc" onSubmit={handleSubmit(onSubmit)}>
          <div css={styles.inputsWrapper}>
            <label htmlFor={"Name"}>{name}</label>
            <input
              required={true}
              type="text"
              name="Name"
              ref={register({
                options: { required: true, unique: true },
                min: 12,
              })}
            />
            <label htmlFor={"E-Mail"}>{eMail}</label>
            <input
              required={true}
              type="email"
              name="E-Mail"
              ref={register({
                options: { required: true, unique: true },
                min: 4,
              })}
            />
            <label htmlFor={"Nachricht"}>{message}</label>
            <textarea
              rows={8}
              required={true}
              name="Nachricht"
              ref={register({ required: true })}
            />
          </div>
          <FriendlyCaptcha />
          <SubmitWrapper color={palette.themeContrastColour}>
            <div>
              <input
                css={css`
                  position: absolute;
                  opacity: 0;
                  pointer-events: none;
                `}
                type="privacy"
                placeholder="Ich akzeptiere die Datenschutzerklärung"
                name="privacy"
              />
              <input
                required={true}
                type="checkbox"
                placeholder="Ich akzeptiere die Datenschutzerklärung"
                name="checkbox"
                ref={register({ required: true })}
              />
              <label htmlFor={"checkbox"} css={styles.datenschutzAkzeptieren}>
                Ich akzeptiere die{" "}
                <a target="_blank" href={`/${pageProps.PageImprint?.slug}`}>
                  {" "}
                  Datenschutzerklärung
                </a>
              </label>
            </div>
            <button type="submit">
              {universalTexts?.Submit || "Absenden"}
            </button>
          </SubmitWrapper>
        </form>
      )}
    </div>
  );
};
